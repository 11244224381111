import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import {
  Grid,
  Header,
  Divider,
  Input,
  Dropdown,
} from "semantic-ui-react";
import AdminNotesList from "../AdminNotesList";
import { useParams } from "react-router";
import { useStore } from "../../../app/stores/store";
import { PagingParams } from "../../../app/models/pagination";

const adminOptions = [
  { key: "Arabic", text: "Arabic", value: "Arabic" },
  { key: "Chinese", text: "Chinese", value: "Chinese" },
];

export default observer(function AdminNotes() {
  const { t } = useTranslation(["common", "systemAdmin"]);
  //const { id } = useParams<{ id: string }>();
  const { adminNotesStore } = useStore();

  const {
    getAdminNotes,
    loadClientAdminNotes,
    loadUserAdminNotes,
    pagination,
    pagingParams,
    setPagingParams,
  } = adminNotesStore;
  
  useEffect(() => {
    loadClientAdminNotes();
}, [loadClientAdminNotes]);

function listOnClick(value: number) {
  if (value > 0 && pagination && value < pagination?.totalPages + 1) {
    setPagingParams(new PagingParams(value));
      loadClientAdminNotes();
  }
}

  return (
    <>
      <Grid stackable>
        <Grid.Row columns={4}>
          <Grid.Column>
            <Header
              as="h1"
              content={t("logs.notes_intro", { ns: "systemAdmin" }).toString()}
              className="modal-text-color"
              textAlign="left"
            />
          </Grid.Column>
          <Grid.Column>
            <Input action="Search" placeholder="Search..." />
          </Grid.Column>
          <Grid.Column textAlign="right">
            <Dropdown
              button
              className="icon"
              floating
              labeled
              options={adminOptions}
              search
              text={t("logs.buttons.by_admin", { ns: "systemAdmin" })}
            />
          </Grid.Column>
          <Grid.Column>
            <Dropdown
              button
              className="icon"
              floating
              labeled
              options={adminOptions}
              search
              text={t("logs.buttons.by_type", { ns: "systemAdmin" })}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Divider />
      <Grid stackable>
        <Grid.Column stackable>
          <AdminNotesList adminNotes={getAdminNotes} pagination={pagination} pagingParams={pagingParams} 
          listOnClick={listOnClick} />
        </Grid.Column>
      </Grid>
    </>
  );
});
