import { observer } from "mobx-react-lite";
import { Button, Table } from "semantic-ui-react";
import { CustomerSearch } from "../../app/models/search";
import { formatPhoneNumber } from "../../app/common/util/functions";
import { useState } from "react";
import CustomerVehicleSearch from "./CustomerVehicleSearch";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../app/stores/store";
import CustomerVehicleSelection from "../tickets/recentTickets/CustomerVehicleSelection";
import { resetTicket } from "../../app/common/util/ticketfunctions";

interface Props {
  customer: CustomerSearch;
}

export default observer(function CustomerSearch({ customer }: Props) {
  const { t } = useTranslation(["common", "search"]);

  const navigate = useNavigate();

  const [expand, setExpand] = useState<boolean>(false);

  const handleExpand = () => {
    setExpand(!expand);
  };

  const { modalStore } = useStore();

  function isAddress() {
    if (
      customer.addressLine1 ||
      customer.addressLine2 ||
      customer.city ||
      customer.state ||
      customer.postalCode
    ) {
      return true;
    }
    return false;
  }

  return (
    <>
      <Table.Row verticalAlign="top">
        <Table.Cell width={4} className="noTextAlignMobile">
          {customer.businessName && `${customer.businessName}: `}
          {customer.lastName}
          {customer.firstName && `, ${customer.firstName}`}
          <br />
          {isAddress() ? (
            <>
              {customer.addressLine1 && (
                <>
                  {`${customer.addressLine1} `}
                  {customer.addressLine2 && `${customer.addressLine2} `}
                </>
              )}
              {customer.city && (
                <>
                  {`${customer.city}`}
                  {`${customer.state ? ", " : " "}` }
                  {customer.state && `${customer.state} `}
                </>
              )}
              {customer.postalCode && `${customer.postalCode}`}
            </>
          ) : (
            <i>{t("unknownAddress", { ns: "common" })}</i>
          )}

          <br />
          {`${
            customer.isCommercial
              ? t("main", { ns: "customer" })
              : t("cell", { ns: "common" })
          }: ${
            customer.primaryPhone
              ? formatPhoneNumber(customer.primaryPhone)
              : "--"
          }`}
        </Table.Cell>
        <Table.Cell className="noTextAlignMobile">
          <br />
          <br />
          {`${
            customer.isCommercial
              ? t("alt", { ns: "customer" })
              : t("home", { ns: "common" })
          }: ${
            customer.secondaryPhone
              ? formatPhoneNumber(customer.secondaryPhone)
              : "--"
          }`}
        </Table.Cell>
        <Table.Cell textAlign="right" className="noTextAlignMobile">
          <Button
            size="small"
            color="blue"
            icon="car"
            onClick={handleExpand}
            content={t("see_vehicles", { ns: "common" })}
          />
          <Button
            size="small"
            color="green"
            type="button"
            icon="plus"
            onClick={(e) => {
              resetTicket();
              modalStore.openModal(
                <CustomerVehicleSelection
                  customerId={customer.id ?? ""}
                  isCommercial={false}
                  businessName={customer.businessName}
                  lastName={customer.lastName}
                  firstName={customer.firstName}
                />
              );
            }}
            content={t("ticket_create", { ns: "common" })}
          />
          <Button
            size="small"
            type="button"
            icon="address card"
            onClick={() => navigate(`/customer/${customer.id}`)}
            content={t("details", { ns: "common" })}
          />
        </Table.Cell>
      </Table.Row>
      {expand && <CustomerVehicleSearch id={customer.id} />}
    </>
  );
});
