import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../../api/agent";
import { NextChargeAdjustments } from "../../models/systemAdmin";

//TODO: has no API, so set up as best as could until API is built. As of now, this is in the store, has an API call(needs to be double checked to make sure it aligns with the API), the start of the DTO, and the start of a UI.

export default class NextChargeAdjustmentsStore {
  constructor() {
    makeAutoObservable(this);
    reaction(
      () => this.predicate.keys(),
      () => {
        this.nextChargeAdjustmentsRegistry.clear();
        this.loadNextChargeAdjustments();
      }
    );
  }

  loadingInitial = false;
  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };
  predicate = new Map().set("all", true);
  nextChargeAdjustmentsRegistry = new Map<Date, NextChargeAdjustments>();

  loadNextChargeAdjustments = async () => {
    this.loadingInitial = true;
    try {
      //   this.nextChargeAdjustmentsRegistry.clear();
      //   const result = await agent.SystemAdmin.getNextChargeAdjustments(
      //     this.axiosParams
      //   );
      //   runInAction(() => {
      //     result.forEach((nextChargeAdjustments) => {
      //       this.setNextChargeAdjustments(nextChargeAdjustments);
      //     });
      //   });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoadingInitial(false);
    }
  };

  private setNextChargeAdjustments = (
    nextChargeAdjustments: NextChargeAdjustments
  ) => {
    // nextChargeAdjustments.date = new Date(nextChargeAdjustments.date);
    // this.nextChargeAdjustmentsRegistry.set(
    //   nextChargeAdjustments.date,
    //   nextChargeAdjustments
    // );
  };

  get getNextChargeAdjustments() {
    return Array.from(this.nextChargeAdjustmentsRegistry.values()).sort(
      (a, b) => b.count - a.count
    );
  }

  get axiosParams() {
    const params = new URLSearchParams();
    this.predicate.forEach((value, key) => {
      if (key === "StartDate" || key === "EndDate") {
        params.append(key, (value as Date).toISOString());
      } else {
        params.append(key, value);
      }
    });
    return params;
  }

  setPredicate = (startDate?: Date, endDate?: Date) => {
    const resetPredicate = () => {
      this.predicate.forEach((value, key) => {
        if (key !== "startDate") this.predicate.delete(key);
      });
    };

    resetPredicate();
    if (startDate) this.predicate.set("StartDate", startDate);
    if (endDate) this.predicate.set("EndDate", endDate);
  };
}
