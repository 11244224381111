import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Divider,
  Form,
  Grid,
  Header,
  Label,
  List,
  Message,
  Radio,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { ErrorMessage, Formik, FormikErrors } from "formik";
import * as Yup from "yup";
import MyTextInput from "../../../app/common/form/MyTextInput";
import { Link } from "react-router-dom";
import StateSelect from "../../../app/common/form/StateSelect";
import { quickVinOptions } from "../../../app/common/options/option";
import PageHeader from "../../../app/layout/PageHeader";
import PhoneNumberInput from "../../../app/common/form/PhoneNumberInput";
import { useStore } from "../../../app/stores/store";
import { SettingsQuickVinFormValues } from "../../../app/models/quickVin";
import ValidationErrors from "../../errors/ValidationErrors";
import { RegexConstants } from "../../../app/common/util/regexConstants";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { PhoneNumberType } from "../../../app/models/enums";

export default observer(function QuickVin() {
  const { t } = useTranslation(["common", "advancedSettings", "error"]);

  const { quickVinStore } = useStore();
  const phoneRegExp = new RegExp(RegexConstants.phoneRegExp);
  const postalCodeRegExp = new RegExp(RegexConstants.postalCodeExp);
  const businessNameRegExp = new RegExp(RegexConstants.businessNameRegExp);

  const {
    loadSettingsQuickVin,
    updateSettingsQuickVin,
    createSettingsQuickVin,
    loadingInitial,
  } = quickVinStore;

  const validationSchema = Yup.object({
    isActive: Yup.boolean().required(),
    businessName: Yup.string()
      .required(t("quickvin.required_ClientName", { ns: "errors" }))
      .matches(
        businessNameRegExp,
        t("registration.required_ClientName", { ns: "errors" })
      )
      .max(500),
    contactName: Yup.string()
      .required(t("quickvin.required_ContactName", { ns: "errors" }))
      .max(255),
    addressLine1: Yup.string()
      .required(t("registration.required_address", { ns: "errors" }))
      .max(500),
    city: Yup.string()
      .required(t("registration.required_city", { ns: "errors" }))
      .max(255),
    state: Yup.string()
      .required(t("registration.required_state", { ns: "errors" }))
      .max(50),
    postalCode: Yup.string()
      .required(t("registration.required_postalcode", { ns: "errors" }))
      .matches(
        postalCodeRegExp,
        t("registration.invalid_postalcode", { ns: "errors" })
      )
      .max(50),
    businessPhoneId: Yup.string()
      .required(t("registration.invalid_PhoneNumber", { ns: "errors" }))
      .matches(
        phoneRegExp,
        t("registration.invalid_PhoneNumber", { ns: "errors" })
      ),
  });

  const [settingsQuickVin, setSettingsQuickVin] =
    useState<SettingsQuickVinFormValues>(new SettingsQuickVinFormValues());

  const [completed, setCompleted] = useState<boolean>(false);

  useEffect(() => {
    loadValues();
  }, [loadSettingsQuickVin]);

  function loadValues() {
    loadSettingsQuickVin().then((setting) => {
      setSettingsQuickVin(new SettingsQuickVinFormValues(setting));
    });
  }

  function handleFormSubmit(
    values: SettingsQuickVinFormValues,
    setErrors: (errors: FormikErrors<SettingsQuickVinFormValues>) => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) {
    const action = values.id
      ? () => updateSettingsQuickVin(values)
      : () => createSettingsQuickVin(values);

    action()
      .then(() => {
        loadValues();
        setSubmitting(false);
        setCompleted(true);
      })
      .catch((error) => {
        setSubmitting(false);
        setErrors({ error: error });
      });
  }

  if (loadingInitial)
    return (
      <LoadingComponent
        content={t("quickVin_loading", { ns: "advancedSettings" })}
      />
    );

  return (
    <>
      <PageHeader
        header={t("quickvin", { ns: "common" })}
        type={"h2"}
        divider={true}
        getAlerts={true}
        showHelp
        helpName="settings.advanceSettings.carFaxQuickVin"
        addTitle={true}
      />
      <Container className="page">
        <PageHeader
          header={`${t("quickvin.carfax", { ns: "advancedSettings" })}:`}
          type={"h3"}
          divider={false}
          getAlerts={true}
          addTitle={false}
        />
        <Formik
          validationSchema={validationSchema}
          enableReinitialize
          initialValues={settingsQuickVin}
          onSubmit={(values, { setErrors, setSubmitting }) =>
            handleFormSubmit(values, setErrors, setSubmitting)
          }
        >
          {({
            handleSubmit,
            isValid,
            isSubmitting,
            dirty,
            handleBlur,
            handleChange,
            errors,
            values,
            touched,
          }) => (
            <Form
              className="ui form error"
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              {completed && isValid && (
                <Message positive>
                  {t("settingsupdated", {
                    ns: "common",
                  })}
                </Message>
              )}
              <ErrorMessage
                name="error"
                render={() => <ValidationErrors errors={errors.error} />}
              />
              <Grid stackable>
                <Grid.Row>
                  <Grid.Column>
                    <label className="myLabel required">
                      {t("quickvin.form", { ns: "advancedSettings" })}
                    </label>
                    <Form.Group grouped>
                      {quickVinOptions.map((data, index) => (
                        <Form.Field key={`isActive[${data.value}]`}>
                          <Radio
                            id={`isActive[${index}]`}
                            key={`isActive[${data.value}]`}
                            value={data.value}
                            name={"isActive"}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            label={data.text}
                            checked={
                              values.isActive.toString() ===
                              data.value.toString()
                            }
                          />
                        </Form.Field>
                      ))}
                    </Form.Group>
                    {touched.isActive && errors.isActive && (
                      <Label basic color="red">
                        {errors.isActive}
                      </Label>
                    )}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Message
                      info
                      header={t("quickvin.banner1", {
                        ns: "advancedSettings",
                      })}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <MyTextInput
                      placeholder={t("name", { ns: "advancedSettings" })}
                      label={`${t("name", { ns: "advancedSettings" })}:`}
                      name="businessName"
                      className="required"
                      autoCapitalizeFirstLetter={false}
                      maxLength={500}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <MyTextInput
                      placeholder={t("address.address", { ns: "common" })}
                      label={`${t("address.address", { ns: "common" })}:`}
                      name="addressLine1"
                      className="required"
                      autoCapitalizeFirstLetter={false}
                      maxLength={500}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <MyTextInput
                      placeholder={t("address.city", { ns: "common" })}
                      label={`${t("address.city", { ns: "common" })}:`}
                      name="city"
                      className="required"
                      autoCapitalizeFirstLetter={false}
                      maxLength={255}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <StateSelect
                      label={`${t("address.state", { ns: "common" })}:`}
                      placeholder={t("address.state", { ns: "common" })}
                      name="state"
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <MyTextInput
                      placeholder={t("address.postalcode", { ns: "common" })}
                      label={`${t("address.postalcode", { ns: "common" })}:`}
                      name="postalCode"
                      className="required"
                      autoCapitalizeFirstLetter={false}
                      maxLength={50}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <PhoneNumberInput
                      placeholder={t("phonenumber", { ns: "common" })}
                      label={`${t("phonenumber", { ns: "common" })}:`}
                      name="businessPhoneId"
                      className="required"
                      autoFormat={true}
                      phoneType={PhoneNumberType.Landline}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <MyTextInput
                      placeholder={t("quickvin.www", {
                        ns: "advancedSettings",
                      })}
                      label={`${t("quickvin.www", {
                        ns: "advancedSettings",
                      })}:`}
                      name="url"
                      autoCapitalizeFirstLetter={false}
                      maxLength={500}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Message
                      info
                      header={t("quickvin.banner2", {
                        ns: "advancedSettings",
                      })}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <MyTextInput
                      placeholder={t("contact", { ns: "advancedSettings" })}
                      label={`${t("contact", { ns: "advancedSettings" })}:`}
                      name="contactName"
                      className="required"
                      autoCapitalizeFirstLetter={false}
                      maxLength={255}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <MyTextInput
                      placeholder={t("email", { ns: "advancedSettings" })}
                      label={`${t("email", { ns: "advancedSettings" })}:`}
                      name="contactEmail"
                      autoCapitalizeFirstLetter={false}
                      maxLength={255}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Button
                      color="green"
                      className="save_button"
                      icon="check"
                      content={t("save", { ns: "common" })}
                      disabled={isSubmitting || !dirty || !isValid}
                      loading={isSubmitting}
                      type="submit"
                    />
                    <Button
                      className="save_button"
                      as={Link}
                      to="/settings"
                      icon="cancel"
                      type="reset"
                      content={t("cancel", { ns: "common" })}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          )}
        </Formik>

        <Divider />
        <p>{t("quickvin.p1", { ns: "advancedSettings" })}</p>
        <p>
          <span className="intro">
            {`${t("quickvin.p2_bold", { ns: "advancedSettings" })}: `}
          </span>
          {t("quickvin.p2", { ns: "advancedSettings" })}
        </p>

        <Divider horizontal>
          <Header as="h4" className="intro">
            {t("quickvin.save_bold", { ns: "advancedSettings" })}
          </Header>
        </Divider>
        <p>{t("quickvin.p3", { ns: "advancedSettings" })}</p>

        <Divider horizontal>
          <Header as="h4" className="intro">
            {t("quickvin.use_bold", { ns: "advancedSettings" })}
          </Header>
        </Divider>
        <p>{t("quickvin.p4", { ns: "advancedSettings" })}</p>
        <p>
          <span className="intro">
            {`${t("quickvin.reasons_bold", { ns: "advancedSettings" })}: `}
          </span>
        </p>
        <List>
          <List.Item>
            <List.Content>
              <List.Header>
                {t("quickvin.reason1_bold", { ns: "advancedSettings" })}
              </List.Header>
              <List.Description>
                {t("quickvin.reason1", { ns: "advancedSettings" })}
              </List.Description>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content>
              <List.Header>
                {t("quickvin.reason2_bold", { ns: "advancedSettings" })}
              </List.Header>
              <List.Description>
                {t("quickvin.reason2", { ns: "advancedSettings" })}
              </List.Description>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content>
              <List.Header>
                {t("quickvin.reason3_bold", { ns: "advancedSettings" })}
              </List.Header>
              <List.Description>
                {t("quickvin.reason3", { ns: "advancedSettings" })}
              </List.Description>
            </List.Content>
          </List.Item>
        </List>

        <Divider horizontal>
          <Header as="h4" className="intro">
            {t("quickvin.why_use_bold", { ns: "advancedSettings" })}
          </Header>
        </Divider>
        <p>{t("quickvin.why_use_p1", { ns: "advancedSettings" })}</p>

        <Divider horizontal>
          <Header as="h4" className="intro">
            {t("quickvin.why_customers_bold", { ns: "advancedSettings" })}
          </Header>
        </Divider>
        <p>{t("quickvin.why_customers_p1", { ns: "advancedSettings" })}</p>
        <p>
          <span className="intro">
            {`${t("quickvin.nutshell", { ns: "advancedSettings" })}: `}
          </span>
        </p>
        <List>
          <List.Item>
            <List.Content>
              <List.Header>
                {t("quickvin.reason1_bold", { ns: "advancedSettings" })}
              </List.Header>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content>
              <List.Header>
                {t("quickvin.reason2_bold", { ns: "advancedSettings" })}
              </List.Header>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content>
              <List.Header>
                {t("quickvin.reason3_bold", { ns: "advancedSettings" })}
              </List.Header>
            </List.Content>
          </List.Item>
        </List>

        <Divider horizontal>
          <Header as="h4" className="intro">
            {t("quickvin.else_bold", { ns: "advancedSettings" })}
          </Header>
        </Divider>
        <p>{t("quickvin.else_p1", { ns: "advancedSettings" })}</p>
        <p>{`${t("quickvin.more_info", { ns: "advancedSettings" })}: `}</p>
      </Container>
    </>
  );
});
