import { useField } from "formik";
import React from "react";
import { Checkbox, Form, Label } from "semantic-ui-react";
import MyHelpButton from "./MyHelpButton";

interface Props {
  name: string;
  label?: string;
  options?: string;
  updateValue?: (checked?: boolean, id?: string) => void;
  disabled: boolean;
  className?: string;
  showHelp?: boolean;
  helpName?: string;
}

export default function MyCheckbox(props: Readonly<Props>) {
  const [field, meta, helpers] = useField(props.name);
  let labelClassName: string = "myLabel";
  if (props.className) labelClassName = `${labelClassName} ${props.className}`;

  return (
    <Form.Field error={meta.touched && !!meta.error}>
      <Checkbox
        {...field}
        type="checkbox"
        checked={field.value}
        disabled={props.disabled}
        value={field.value ? 1 : 0}
        onChange={() => {
          helpers.setValue(!field.value);
          field.checked = !field.value;
          if (props.updateValue) props.updateValue(field.checked);
        }}
        label={
          <label className={labelClassName} htmlFor={props.name}>
            {`${props.label} `}
            {props.showHelp && (
              <MyHelpButton
                name={`${props.name}.help`}
                help={props.helpName? props.helpName: ""}
                color={"blue"}
              />
            )}
          </label>
        }
        id={props.name}
        onBlur={() => helpers.setTouched(true)}
      />

      {meta.touched && meta.error && (
        <Label basic color="red">
          {meta.error}
        </Label>
      )}
    </Form.Field>
  );
}
