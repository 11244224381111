import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Menu, Tab } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../app/stores/store";
import IpLookup from "./IpLookup";
import ManageAnnouncements from "./ManageAnnouncements";
import { useNavigate } from "react-router-dom";
import TrialEmails from "./TrialEmails";

interface Props {
  activeSubTab: number;
}

export default observer(function ToolsTabs(props: Props) {
  const { t } = useTranslation(["common", "systemAdmin"]);
  const navigate = useNavigate();

  const { systemAdminStore } = useStore();
  const { setActiveSubTab, activeSubTab } = systemAdminStore;

  useEffect(() => {
    setActiveSubTab(props.activeSubTab);
  }, [setActiveSubTab, props.activeSubTab]);

  const panes = [
    {
      menuItem: (
        <Menu.Item
          onClick={() => {
            navigate(`/admin/Tools/IpLookup`);
          }}
        >
          {t("tools.ip", { ns: "systemAdmin" })}
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane active={0 === activeSubTab}>
          <IpLookup />
        </Tab.Pane>
      ),
    },
    {
      menuItem: (
        <Menu.Item
          onClick={() => {
            navigate(`/admin/Tools/ManageAnnouncements`);
          }}
        >
         {"Manage Announcements"}
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane active={1 === activeSubTab}>
          <ManageAnnouncements />
        </Tab.Pane>
      ),
    },
    {
      menuItem: (
        <Menu.Item
          onClick={() => {
            navigate(`/admin/Tools/TrialEmails`);
          }}
        >
         {"Free Trial Requests"}
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane active={2 === activeSubTab}>
          <TrialEmails />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <>
      <Tab
        menu={{ fluid: true }}
        menuPosition="left"
        panes={panes}
        activeIndex={activeSubTab}
        onTabChange={(e, data) => {
          setActiveSubTab(data.activeIndex);
        }}
      />
    </>
  );
});
