import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../../app/stores/store";
import { PDFViewer } from "@react-pdf/renderer";
import { TicketType } from "../../../../app/models/enums";
import { useParams } from "react-router-dom";
import { VehicleFormValues } from "../../../../app/models/vehicle";
import { CustomerFormValues } from "../../../../app/models/customer";
import { TicketSession } from "../../../../app/models/ticket";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import TicketPdfDocument from "../TicketPdfDocument";
import { resetTicket } from "../../../../app/common/util/ticketfunctions";

export default observer(function TicketPdf() {
  const { t } = useTranslation(["common", "tickets"]);

  const {
    ticketSessionStore,
    ticketStore,
    clientStore,
    vehicleStore,
    customerStore,
    pricingStore,
    disclaimerStore,
  } = useStore();

  const {
    loadTicket,
    setSelectedTicketSession,
    setSelectedPONumber,
    setBatchTicketItems,
    setBatchTicketPaymentItems,
    populateJobLabors,
    populateLaborMap,
    loadingInitial,
    selectedTicketSession,
    sortedSelectedTicketTicketItems
  } = ticketSessionStore;

  const {
    loadSettingsTaxRate,
    loadDropdownPayTypeList,
    dropdownPayTypesList,
    loadSettingsPayType,
    getPayType,
  } = pricingStore;
  const { setSelectedVehicle } = vehicleStore;
  const { setSelectedCustomer } = customerStore;
  const { loadSettingDisclaimer, selectedSettingsDisclaimer } = disclaimerStore;
  const {
    selectedSettingsTicketFormatting,
    loadSettingsTicketFormatting,
    selectedSettingsTicketInputDefaults,
    loadSettingsTicketInputDefaults,
  } = ticketStore;

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    loadSettingsTaxRate();
  }, [loadSettingsTaxRate]);

  useEffect(() => {
    loadSettingsTicketFormatting();
  }, [loadSettingsTicketFormatting]);

  useEffect(() => {
    loadSettingsTicketInputDefaults();
  }, [loadSettingsTicketInputDefaults]);

  useEffect(() => {
    loadDropdownPayTypeList();
  }, [loadDropdownPayTypeList]);

  useEffect(() => {
    loadSettingDisclaimer();
  }, [loadSettingDisclaimer]);

  useEffect(() => {
    loadSettingsPayType(true);
  }, [loadSettingsPayType]);

  const [paymentMethod, setPaymentMethod] = useState<string>();
  const [totalPayments, setTotalPayments] = useState<number>(0.0);

  const [ticketLoaded, setTicketLoaded] = useState<boolean>(false);



  useEffect(() => {
    resetTicket();
    if (id) {
      setTicketLoaded(true);

      loadTicket(id)
        .then((result) => {
          if (result) {
            setSelectedVehicle(new VehicleFormValues(result.vehicle));
            setSelectedCustomer(new CustomerFormValues(result.customer));
            setSelectedPONumber(result.poNumber);

            let ticketSession = new TicketSession(result);
            setSelectedTicketSession(ticketSession);

            if (result.jobLabors && result.jobLabors.length > 0) {
              populateJobLabors(result.jobLabors);
            }

            if (result.ticketItems && result.ticketItems.length > 0) {
              setBatchTicketItems(result.ticketItems);
            }

            if (result.ticketPayments && result.ticketItems.length > 0) {
              setBatchTicketPaymentItems(result.ticketPayments);

              let payments: number = 0;
              result.ticketPayments.forEach((x, index) => {
                payments += x.amount ?? 0;
              });

              setTotalPayments(payments);
            }

            if (result.ticketType === TicketType.Estimate) {
              let payMethod = dropdownPayTypesList.find((x) => {
                return x.value === result?.estimatePaymentMethod;
              });
              if (payMethod?.text) setPaymentMethod(payMethod.text.toString());
            } else {
              let ticketPaymentItem = result.ticketPayments.find((x) => {
                return (x.amount ?? 0) > 0;
              });

              if (ticketPaymentItem) {
                let payMethod = getPayType(
                  ticketPaymentItem?.paymentTypeId ?? ""
                );
                if (payMethod?.isStandard) {
                  if (payMethod.name === "Cash" || payMethod.name === "Check") {
                    setPaymentMethod(payMethod.name);
                  } else if (/Charge/.exec(payMethod.name)) {
                    setPaymentMethod("Charge");
                  }
                }
              }
            }

            if (result.labors && result.labors.length > 0) {
              populateLaborMap(result.labors);
            }
            setHeader(
              `${
                   t("view", { ns: "common" })
              } ${t(
                `estimate.${
                  result.ticketType === TicketType.Estimate ? "est" : "invoice"
                }`,
                { ns: "tickets" }
              )} #${
                result.ticketType === TicketType.Estimate
                  ? result.estimateNumber
                  : result.invoiceNumber
              }`
            );
          }
          setTicketLoaded(false);
        })
        .finally(() => setTicketLoaded(false));
    }
  }, [loadTicket, id, setSelectedTicketSession]);

  const { loadLogo, loadClientDetails } = clientStore;

  const [header, setHeader] = useState<string>();

  useEffect(() => {
    document.title = header ?? ""
  }, []);

  useEffect(() => {
    loadLogo();
  }, [loadLogo]);

  useEffect(() => {
    loadClientDetails();
  }, [loadClientDetails]);

  if (loadingInitial && ticketLoaded)
    return <LoadingComponent content={t("loading", { ns: "common" })} />;

  return (
    <PDFViewer showToolbar={true} width={"100%"} height={window.innerHeight}>
      <TicketPdfDocument
        selectedTicketSession={selectedTicketSession ?? new TicketSession()}
        paymentMethod={paymentMethod ?? ""}
        selectedSettingsTicketFormatting={selectedSettingsTicketFormatting}
        selectedSettingsTicketInputDefaults={
          selectedSettingsTicketInputDefaults
        }
        selectedSettingsDisclaimer={selectedSettingsDisclaimer}
        totalPayments={totalPayments}
        ticketItems={sortedSelectedTicketTicketItems ?? []}
      />
    </PDFViewer>
  );
});
