import { observer } from "mobx-react-lite";
import React from "react";
import { Container, Grid, Header, Table } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { useStore } from "../../../app/stores/store";
import {
  formatCurrency,
  getNegative,
} from "../../../app/common/util/functions";
import PageHeader from "../../../app/layout/PageHeader";

interface Props {
  startDate: Date;
  endDate: Date;
}

export default observer(function CustomerHistory({
  startDate,
  endDate,
}: Props) {
  const { t } = useTranslation(["common", "reports"]);

  const { customerHistoryReportStore } = useStore();
  const { getCustomerHistory, selectedCustomer, calculateTotalSales, calculateTicketTotalSales } =
    customerHistoryReportStore;

  return (
    <>
      <Container className="page">
        <Grid stackable columns={2} className="headerPrint">
          <Grid.Row>
            <Grid.Column>
              <PageHeader
                header={`${t("customer", { ns: "common" })}: ${
                  selectedCustomer.isCommercial
                    ? selectedCustomer.companyName
                    : `${selectedCustomer.lastName}, ${
                        selectedCustomer.firstName
                          ? selectedCustomer.firstName
                          : "--"
                      }`
                }`}
                type={"h3"}
                divider={false}
                addTitle={false}
              />
            </Grid.Column>
            <Grid.Column>
              <Header
                content={`${format(startDate, "PP")} - ${format(
                  endDate,
                  "PP"
                )}`}
                as="h2"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {getCustomerHistory.length > 0 && (
          <>
            <Table stackable>
              {getCustomerHistory.map((x) => (
                <>
                  <Table.Header>
                    <Table.Row key={`vehicle.${x.vehicleId}`}>
                      <Table.HeaderCell textAlign="left" colSpan={9}>
                        {(x.year ?? 0) > 0 && `${x.year} `}
                        {x.make && `${x.make} `}
                        {x.model} {x.engine && `${x.engine}`}
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Header key={`table.${x.vehicleId}`}>
                    <Table.Row>
                      <Table.HeaderCell textAlign="left">
                        {t("estimate.invoice", { ns: "tickets" })}
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="left">
                        {t("date", { ns: "common" })}
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="left">
                        {t("description", { ns: "common" })}
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="right">
                        {t("estimate.parts", { ns: "tickets" })}
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="right">
                        {t("misc", { ns: "tickets" })}
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="right">
                        {t("estimate.labor", { ns: "tickets" })}
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="right">
                        {t("estimate.tax", { ns: "tickets" })}
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="right">
                        {t("total", { ns: "common" })}
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        {t("pay_type.header", { ns: "reports" })}
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {x.tickets &&
                      x.tickets.length > 0 &&
                      x.tickets.map((z) => (
                        <Table.Row key={z.invoiceNumber}>
                          <Table.Cell textAlign="left">
                            {z.invoiceNumber}
                          </Table.Cell>
                          <Table.Cell textAlign="left">
                            {z.invoiceDate && format(z.invoiceDate, "P")}
                          </Table.Cell>
                          <Table.Cell textAlign="left">
                            {z.description}
                          </Table.Cell>
                          <Table.Cell textAlign="right">
                            {formatCurrency(z.totalParts)}
                          </Table.Cell>
                          <Table.Cell textAlign="right">
                            {formatCurrency(z.totalMisc)}
                          </Table.Cell>
                          <Table.Cell textAlign="right">
                            {formatCurrency(z.totalLabor)}
                          </Table.Cell>
                          <Table.Cell textAlign="right">
                            {formatCurrency(z.totalTax)}
                          </Table.Cell>
                          <Table.Cell textAlign="right">
                            {formatCurrency(z.totalAmount)}
                          </Table.Cell>
                          <Table.Cell>{z.payType}</Table.Cell>
                        </Table.Row>
                      ))}
                  </Table.Body>
                  <Table.Body>
                    <Table.Row key={"totals"} className="terms_bold">
                      <Table.Cell colSpan={7}>
                        {t("vehicleTotals", { ns: "reports" })}:
                      </Table.Cell>
                      <Table.Cell
                        data-label={`${t("revenue", { ns: "reports" })}:`}
                        textAlign="right"
                        style={{
                          color: getNegative(
                            calculateTicketTotalSales(x.tickets ?? []).totalAmount
                          ),
                        }}
                      >
                        {formatCurrency(calculateTicketTotalSales(x.tickets ?? []).totalAmount)}
                      </Table.Cell>
                      <Table.Cell></Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </>
              ))}
              <Table.Body>
                <Table.Row key={"grandTotals"} className="terms_bold greenRow">
                  <Table.Cell colSpan={7}>
                    {t("grandTotals", { ns: "reports" })}:
                  </Table.Cell>
                  <Table.Cell
                    data-label={`${t("revenue", { ns: "reports" })}:`}
                    textAlign="right"
                    style={{
                      color: getNegative(
                        calculateTotalSales(getCustomerHistory).totalAmount
                      ),
                    }}
                  >
                    {formatCurrency(
                      calculateTotalSales(getCustomerHistory).totalAmount
                    )}
                  </Table.Cell>
                  <Table.Cell></Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </>
        )}
      </Container>
    </>
  );
});
