import React, { useEffect } from "react";
import {
  Button,
  Divider,
  Icon,
  Message,
  Table,
  TableHeader,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../app/stores/store";
import PageHeader from "../../../app/layout/PageHeader";
import { observer } from "mobx-react-lite";
import { Parts } from "../../../app/models/parts";
import MyPaging from "../../../app/common/form/MyPaging";
import { PagingParams } from "../../../app/models/pagination";

interface Props {
  partCode?: string;
  description?: string;
  addItem: (values: Parts) => void;
  cancelSearch: () => void;
}

export default observer(function PartSearchResult(props: Props) {
  const { t } = useTranslation(["common", "tickets"]);

  const { partsStore } = useStore();
  const {
    partsList,
    pagination,
    pagingParams,
    resetSearchPartRegistry,
    setPredicate,
    setPagingParams,
    searchParts
  } = partsStore;

  useEffect(() => {
    if (props.partCode) setPredicate("partCode", props.partCode);
    if (props.description) setPredicate("name", props.description);
  }, [props.partCode, props.description, setPredicate]);

  function listOnClick(value: number) {
    if (value > 0 && pagination && value < pagination?.totalPages + 1) {
      setPagingParams(new PagingParams(value));
      resetSearchPartRegistry();
      searchParts();
    }
  }

  return (
    <>
      <PageHeader
        type={"h3"}
        header={t("pops.add_prt_itm", {
          ns: "tickets",
        })}
        divider={true}
        addTitle={false}
      />
      <Table>
        <TableHeader>
          <Table.Row>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell>
              {t("partNo", {
                ns: "tickets",
              })}
              .
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t("description", {
                ns: "common",
              })}
            </Table.HeaderCell>
          </Table.Row>
        </TableHeader>
        <Table.Body>
          {partsList && partsList.length > 0 ? (
            <>
              {partsList.map((data, index) => (
                <Table.Row key={data.partCode}>
                  <Table.Cell>
                    <Button
                      color="green"
                      icon="plus"
                      type="button"
                      size="tiny"
                      onClick={() => {
                        props.addItem(data);
                      }}
                    />
                  </Table.Cell>
                  <Table.Cell>{data.partCode}</Table.Cell>
                  <Table.Cell>{data.name}</Table.Cell>
                </Table.Row>
              ))}
            </>
          ) : (
            <Table.Row key={"noItems"}>
              <Table.Cell colSpan={3}>
                <Message
                  color="yellow"
                  className="textAlignCenter fontStyleItalic"
                >
                  {t("grid_noItems", { ns: "common" })}
                </Message>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan="3">
              <MyPaging
                itemCount={pagination ? pagination.totalItems : 10}
                pageSize={pagingParams.pageSize}
                currentPage={pagination ? pagination.currentPage : 1}
                onClick={listOnClick}
                onChange={listOnClick}
                floated="right"
              ></MyPaging>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
      <Divider />
      <Button type="button" onClick={props.cancelSearch}>
        <Icon name="x" />
        {t("cancel", { ns: "common" })}
      </Button>
    </>
  );
});
