import axios from "axios";
import { PaginatedResult } from "../../models/pagination";
import {
  AdminNotes,
  AuthenticationLogs,
  CarFaxShopDataReport,
  EmailLog,
  QuickVinNightlyLog,
} from "../../models/systemAdmin";
import { requests, responseBody } from "../Requests";

export const SystemAdminLogs = {
  getClientAdminNotes: (params: URLSearchParams, id?: string) =>
    axios
      .get<PaginatedResult<AdminNotes[]>>(
        `/SystemAdminLogs/getAdminNotes?${id && `clientId=${id}`}`,
        {
          params,
        }
      )
      .then(responseBody),
  getUserAdminNotes: (params: URLSearchParams, id?: string) =>
    axios
      .get<PaginatedResult<AdminNotes[]>>(
        `/SystemAdminLogs/getAdminNotes?${id && `userId=${id}`}`,
        {
          params,
        }
      )
      .then(responseBody),

  addAdminNotes: (note: string, clientId: string, userId?: string) =>
    requests.post<void>(`/SystemAdminLogs/addAdminNote`, {
      Note: note,
      ClientId: clientId,
      UserId: userId,
    }),

  getAuthenticationLog: (
    params: URLSearchParams,
    clientId?: string,
    userId?: string
  ) =>
    axios
      .get<PaginatedResult<AuthenticationLogs[]>>(
        `/SystemAdminLogs/getAuthenticationLogs?clientId=${
          clientId ? clientId : ""
        }&userId=${userId ? userId : ""}`,
        { params }
      )
      .then(responseBody),
  getEmailLog: (params: URLSearchParams) =>
    axios
      .get<PaginatedResult<EmailLog[]>>(`/SystemAdminLogs/getEmailLog`, {
        params,
      })
      .then(responseBody),
  getQuickVinNightlyLog: (params: URLSearchParams) =>
    axios
      .get<PaginatedResult<QuickVinNightlyLog[]>>(
        `/SystemAdminLogs/listQuickVinResults`,
        { params }
      )
      .then(responseBody),
  getCarFaxShopData: (params: URLSearchParams, id: string) =>
    axios
      .get<PaginatedResult<CarFaxShopDataReport[]>>(
        `/SystemAdminLogs/getCarFaxShopData/${id}`,
        { params }
      )
      .then(responseBody),
};
