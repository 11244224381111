import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import {
  Grid,
  Header,
  Divider
} from "semantic-ui-react";

const adminOptions = [
  { key: "Arabic", text: "Arabic", value: "Arabic" },
  { key: "Chinese", text: "Chinese", value: "Chinese" },
];

export default observer(function ANSettle() {
  const { t } = useTranslation(["common", "systemAdmin"]);

  const [startdate, setStartDate] = useState<Date | Date[] | undefined>(
    undefined
  );
  const [enddate, setendDate] = useState<Date | Date[] | undefined>(
    undefined
  );

  return (
    <>
      <Grid stackable>
        <Grid.Row columns={1}>
          <Grid.Column>
            *Date Search function here*
            {/* <Form.Group equal>
              <MySelectInput
                label={t("date_range", { ns: "reports" }) + ":"}
                options={dateRangeOptions}
                placeholder={t("date_range", { ns: "reports" })}
                name="dateRange"
              />
              <label>{t("start_date", { ns: "reports" }) + ":"}</label>
              <SemanticDatepicker
                name="startDate"
                placeholder={t("start_date", { ns: "reports" })}
                onChange={(e, d) => {
                  setStartDate(d.value);
                }}
              />
              <label>{t("end_date", { ns: "reports" }) + ":"}</label>
              <SemanticDatepicker
                name="endDate"
                placeholder={t("end_date", { ns: "reports" })}
                onChange={(e, d) => {
                  setStartDate(d.value);
                }}
              />
            </Form.Group>
            <Form.Group className="save_button">
              <Button
                color="green"
                className="save_button"
                icon="check"
                content={t("get_report", { ns: "reports" })}
              />
            </Form.Group> */}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Divider />
      <Grid stackable>
        <Grid.Row columns={1}>
          <Grid.Column>
            <Header
              as="h2"
              content={t("reports.anSettle", {
                ns: "systemAdmin",
              }).toString()}
              className="modal-text-color"
              textAlign="left"
            />
          </Grid.Column>
        </Grid.Row>
        <Divider />
      </Grid>
    </>
  );
});
