export class RegexConstants {
  static readonly nameRegExp: string = "^([a-zA-Z.'-,]).{1,50}$";
  static readonly fullNameRegExp: string = "^([a-zA-Z.'-, ]).{2,100}$";
  static readonly businessNameRegExp: string =
    "^([a-zA-Z0-9!@#$%.'-,&<>\"^*|()_=+:'`;~/?]).{1,255}$";
  static readonly userNameExp: string =
    "^(?=.{4,32}$)(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+(?<![_.])$";
  static readonly passwordExp: string =
    "^(?=[^\\d_].*\\d)(?=.*[a-z])(?=.*[A-Z])(?!@#$%.*\\s).{8,50}$";
  static readonly phoneRegExp: string | RegExp =
    "^(\\+\\d{1,2}\\s)?\\(?\\d{3}\\)?[\\s.-]\\d{3}[\\s.-]\\d{4}$";
  //"^(\\+\\d{1,2}\\s)?\\(?\\d{3}\\)?[\\s.-]\\d{3}[\\s.-]\\d{4}[ ]*((x){0,1}(\\d){1,5}){0,1}$";
  static readonly postalCodeExp: string | RegExp =
    "^(\\d{5}-\\d{4}|\\d{5}|\\d{9})$|^([a-zA-Z]\\d[a-zA-Z]( )?\\d[a-zA-Z]\\d)$";
  static readonly decimalExp: string | RegExp =
    "^[-+]?[0-9]+[.]?[0-9]*([eE][-+]?[0-9]+)?$";
  static readonly numericRegex: RegExp = /^\d+$/;
  //static readonly decimalPlacesRegex: RegExp = /^\d+(\.\d{1,2})?$/;
  static readonly threeDecimalPlacesRegex: RegExp = /^\d+(\.\d{1,3})?$/;
  static readonly creditCardValidation: RegExp = new RegExp(
    "^3[47][0-9]{13}$|^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$|^5[1-5][0-9]{14}|^(222[1-9]|22[3-9]\d|2[3-6]\d{2}|27[0-1]\d|2720)[0-9]{12}$|^4[0-9]{12}(?:[0-9]{3})?$"
  );
  static readonly creditCardCVVValidation: RegExp = new RegExp("^[0-9]{3,4}$");
}
