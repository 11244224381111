export interface Vehicle {
  id?: string;
  year?: number;
  makeId?: number;
  makeName?: string;
  modelId?: string;
  modelName?: string;
  engineId?: string;
  engineName?: string;
  vin?: string;
  vehicleNumber?: string;
  tag?: string;
  mileageIn?: number;
  mileageOut?: number;
  isCustom: boolean;
  customVehicleId?: string;
  createdDate?: Date;
  error?:string
}
export class Vehicle implements Vehicle {
  constructor(init?: VehicleFormValues) {
    if (init) {

      this.id =  init.id;
      this.year = init.year ? Number(init.year) : undefined;
      this.makeId = init.makeId;
      this.makeName = init.makeName;
      this.modelId = init.modelId;
      this.modelName = init.modelName;
      this.engineId = init.engineId;
      this.engineName = init.engineName;
      this.vin = init.vin;
      this.vehicleNumber = init.vehicleNumber;
      this.tag = init.tag;
      this.mileageIn = init.mileageIn ? Number(init.mileageIn) : undefined;
      this.mileageOut = init.mileageOut ? Number(init.mileageOut) : undefined;
      this.isCustom = false;
      this.customVehicleId = init.customVehicleId;
      if (init.isCustom && init.isCustom.toString() === "true") this.isCustom = true;
      this.createdDate =init.createdDate ? new Date(init.createdDate ) : new Date();
      this.error = init.error;
    }
  }
}
export class VehicleFormValues {
  id?: string = undefined;
  year?: number = undefined;
  makeId?: number = undefined;
  makeName?: string = undefined;
  modelId?: string = undefined;
  modelName?: string = undefined;
  engineId?: string = undefined;
  engineName?: string = undefined;
  vin?: string = undefined;
  vehicleNumber?: string = undefined;
  tag?: string = undefined;
  mileageIn?: number = undefined;
  mileageOut?: number = undefined;
  isCustom: boolean = false;
  customVehicleId?: string = undefined;
  createdDate?: Date = undefined;
  error?:string = undefined

  constructor(vehicle?: VehicleFormValues) {
    if (vehicle) {

      this.id =  vehicle.id;
      //only set year when its positive.  I don't care if they enter bogus here
      this.year = (vehicle.year ?? 0) > 1 ? vehicle.year : 0 ;
      this.makeId = vehicle.makeId;
      //remove custom when set
      this.makeName = vehicle.makeName && vehicle.makeName.toLowerCase() !== "custom" ?  vehicle.makeName : undefined;
      this.modelId = vehicle.modelId;
      this.modelName = vehicle.modelName;
      this.engineId = vehicle.engineId;
      this.engineName = vehicle.engineName;
      this.vin = vehicle.vin;
      this.vehicleNumber = vehicle.vehicleNumber;
      this.tag = vehicle.tag;
      this.mileageIn = vehicle.mileageIn ? Number(vehicle.mileageIn) : undefined;
      this.mileageOut = vehicle.mileageOut ? Number(vehicle.mileageOut) : undefined;
      this.isCustom = vehicle.isCustom; 
      this.customVehicleId = vehicle.customVehicleId;
      this.createdDate = vehicle.createdDate ? new Date(vehicle.createdDate ) : new Date();
      this.error = vehicle.error;
    }
  }
}
