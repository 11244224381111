import React from "react";
import { observer } from "mobx-react-lite";
import { DropdownItemProps, Select, Table } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { TicketItem } from "../../app/models/ticket";

interface Props {
  ticketItem: TicketItem;
  technicianList: DropdownItemProps[];
  handleTechChange: (ticketItemId: string, value: string) => void;
}

export default observer(function TechTrackingRow({
  ticketItem,
  technicianList,
  handleTechChange,
}: Props) {
  const { t } = useTranslation(["common", "miscSettings"]);

  return (
    <Table.Row key={`ticketItem.${ticketItem.id}`}>
      <Table.Cell width={10} verticalAlign={"middle"}>
        {ticketItem.description}
      </Table.Cell>
      <Table.Cell verticalAlign={"middle"}>{ticketItem.quantity}</Table.Cell>
      <Table.Cell textAlign="right">
        <Select
          float="right"
          placeholder={`(${t("unassigned", {
            ns: "tickets",
          })})`}
          name={"techs"}
          options={technicianList}
          label=""
          value={ticketItem.technicianId ?? undefined}
          clearable
          selection
          search
          selectOnBlur={true}
          onChange={(e, d) => {
            handleTechChange(ticketItem.id ?? "", d.value?.toString() ?? "");
          }}
        />
      </Table.Cell>
    </Table.Row>
  );
});
