import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Menu, Tab } from "semantic-ui-react";
import { useStore } from "../../../app/stores/store";
import VehicleSelection from "./VehicleSelection";
import JobCategoryForm from "./JobCategoryForm";
import JobType from "./JobType";
import Job from "./Job";
import SelectTime from "./SelectTime";
import Summary from "./Summary";
import Finalize from "./Finalize";
import TicketCustomerDetails from "../../customers/TicketCustomerDetails";
import { TicketWorkflowSteps } from "../../../app/models/enums";
import { useNavigate, useParams } from "react-router-dom";
import { VehicleFormValues } from "../../../app/models/vehicle";
import { CustomerFormValues } from "../../../app/models/customer";
import { TicketSession } from "../../../app/models/ticket";
import { JobLabor } from "../../../app/models/jobLabor";
import TicketBackButton from "./TicketBackButton";
import { v4 as uuid } from "uuid";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { resetTicket } from "../../../app/common/util/ticketfunctions";

interface Props {
  activeTab: number;
  initial?: boolean;
}

export default observer(function TicketDashboard(props: Props) {
  const { t } = useTranslation(["common", "recentTickets"]);
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { complete } = useParams<{ complete: string }>();

  const {
    customerStore,
    vehicleStore,
    ticketStore,
    ticketSessionStore,
    modalStore,
    jobLaborStore,
    pricingStore,
    miscellaneousChargeStore,
    technicianStore,
    quickVinStore,
    vendorStore,
  } = useStore();

  const {
    activeTab,
    setActiveTab,
    loadSettingsWorkflow,
    selectedWorkflow,
    loadSettingsTicketDefaults,
    selectedSettingsTicketDefaults,
    loadSettingsTicketNumber,
    selectedSettingsTicketNumber,
    loadSettingsTicketInputDefaults,
    selectedSettingsTicketInputDefaults,
  } = ticketStore;

  const {
    loadTicketSession,
    setSelectedTicketSession,
    setSelectedPONumber,
    setJobLaborsToTicketItems,
    startTicketSession,
    setBatchTicketItems,
    calculateTotals,
    setBatchTicketPaymentItems,
    resetTicketPaymentItems,
    populateJobLabors,
    addJobLabor,
    populateLaborMap,
    setSelectedJobLabor,
    loadingInitial,
    calculateMiscellaneousCharges,

  } = ticketSessionStore;

  const { loadDsiJobList } = jobLaborStore;

  const { setSelectedVehicle } =
    vehicleStore;

  const { setSelectedCustomer } = customerStore;

  const { loadSettingsQuickVin, selectedSettingsQuickVin } = quickVinStore;

  const {
    loadSettingsLaborRatesDropdown,
    loadSettingsPricing,
    loadSettingsTaxRate,
    selectedSettingsTaxRate,
    dropdownPayTypesList,
    loadDropdownPayTypeList,
  } = pricingStore;

  const {
    resetPreviousTicketMiscellaneousCharges,
    alwaysMiscellaneousChargeItems,
    loadMiscellaneousCharges,
    loadPreviousTicketMiscellaneousCharges,
    setUpdateMiscellaneousCharges,
  } = miscellaneousChargeStore;

  const { selectedSettingsTechnician, loadSettingsTechnician } =
    technicianStore;

  const { loadSettingVendors, selectedSettingsVendor } = vendorStore;

  useEffect(() => {
    loadSettingsTechnician();
  }, [loadSettingsTechnician]);

  useEffect(() => {
    loadSettingsQuickVin();
  }, [loadSettingsQuickVin]);

  useEffect(() => {
    loadSettingsTaxRate();
  }, [loadSettingsTaxRate]);

  useEffect(() => {
    loadSettingsPricing();
  }, [loadSettingsPricing]);

  useEffect(() => {
    loadDropdownPayTypeList();
  }, [loadDropdownPayTypeList]);

  useEffect(() => {
    loadSettingsWorkflow();
  }, [loadSettingsWorkflow]);

  useEffect(() => {
    loadSettingsLaborRatesDropdown();
  }, [loadSettingsLaborRatesDropdown]);

  useEffect(() => {
    loadSettingsTicketInputDefaults();
  }, [loadSettingsTicketInputDefaults]);

  useEffect(() => {
    setActiveTab(props.activeTab);
  }, [setActiveTab, props.activeTab]);

  useEffect(() => {
    loadSettingsTicketNumber();
  }, [loadSettingsTicketNumber]);
  useEffect(() => {
    loadSettingsTicketDefaults();
  }, [loadSettingsTicketDefaults]);

  useEffect(() => {
    loadMiscellaneousCharges();
  }, [loadMiscellaneousCharges]);

  useEffect(() => {
    resetPreviousTicketMiscellaneousCharges();
    if (complete === "true") loadPreviousTicketMiscellaneousCharges(id ?? "");
  }, [loadPreviousTicketMiscellaneousCharges, complete, id]);

  useEffect(() => {
    setUpdateMiscellaneousCharges(false);
  }, []);

  useEffect(() => {
    loadSettingVendors();
  }, [loadSettingVendors]);

  useEffect(() => {
    resetTicket();
    if (id) {
      setTicketLoaded(true);
      loadTicketSession(id, activeTab)
        .then((result) => {
          if (result) {
            setSelectedVehicle(new VehicleFormValues(result.vehicle));
            setSelectedCustomer(new CustomerFormValues(result.customer));
            setSelectedPONumber(result.poNumber);

            let ticketSession = new TicketSession(result);
            setSelectedTicketSession(ticketSession);

            if (result.jobLabors && result.jobLabors.length > 0) {
              populateJobLabors(result.jobLabors);
              let jobLabor: JobLabor | undefined = Array.from(
                result.jobLabors.values()
              ).find((x) => {
                return x.isComplete === false;
              });
              if (jobLabor) {
                setSelectedJobLabor(jobLabor);
                if (
                  jobLabor.job?.jobTypeId &&
                  result.vehicle?.modelId &&
                  result.vehicle.engineId
                ) {
                  loadDsiJobList(
                    jobLabor.job.jobTypeId,
                    result.vehicle.modelId,
                    result.vehicle.engineId,
                    result.vehicle.isCustom
                  );
                }
              } else {
                let jobLabor = new JobLabor();
                jobLabor.id = uuid();
                setSelectedJobLabor(jobLabor);
                addJobLabor(jobLabor);
              }
            }

            if (
              (!result.ticketItems || result.ticketItems.length < 1) &&
              result.jobLabors &&
              result.jobLabors.length > 0
            ) {
              setJobLaborsToTicketItems(
                alwaysMiscellaneousChargeItems,
                false,
                result.salesTaxOptionType
              );
              calculateMiscellaneousCharges(alwaysMiscellaneousChargeItems);
            } else if (result.ticketItems && result.ticketItems.length > 0) {
              setBatchTicketItems(result.ticketItems);
            }

            if (result.ticketPayments && result.ticketItems.length > 0) {
              setBatchTicketPaymentItems(result.ticketPayments);
            } else {
              resetTicketPaymentItems();
            }

            if (complete === "false") {
              calculateTotals();
            }

            if (result.labors && result.labors.length > 0) {
              populateLaborMap(result.labors);
            }
          } else {
            startTicketSession(
              id,
              selectedWorkflow,
              selectedSettingsTicketDefaults,
              selectedSettingsTicketNumber
            );
          }
        })
        .finally(() => setTicketLoaded(false));
    }
  }, [loadTicketSession]);

  const [ticketLoaded, setTicketLoaded] = useState<boolean>(false);

  const panes = [
    {
      menuItem: selectedWorkflow.isCustomerInfoFirst && (
        <Menu.Item
          key={TicketWorkflowSteps.Customer1}
          disabled={
            activeTab < TicketWorkflowSteps.Customer1 ||
            activeTab > TicketWorkflowSteps.Vehicle
          }
          onClick={() => {
            navigate(`/ticket/start/customer/${id}/`);
          }}
          className="width815"
        >
          {t("customer", { ns: "tickets" })}
        </Menu.Item>
      ),
      render: () =>
        selectedWorkflow.isCustomerInfoFirst && (
          <Tab.Pane
            as="div"
            active={TicketWorkflowSteps.Customer1 === activeTab}
            key={"customerFirst"}
          >
            <TicketCustomerDetails
              workflowStep={TicketWorkflowSteps.Customer1}
              settingsTicketInputDefaults={selectedSettingsTicketInputDefaults}
              isCustomerInfoFirst={true}
              isAdd={
                selectedWorkflow.isCustomerInfoFirst && !(complete === "true")
              }
            />
          </Tab.Pane>
        ),
    },
    {
      menuItem: (
        <Menu.Item
          key={TicketWorkflowSteps.Vehicle}
          disabled={activeTab <= TicketWorkflowSteps.Vehicle}
          className="width815"
        >
          {t("vehicle", { ns: "tickets" }).toString()}
        </Menu.Item>
      ),

      render: () => (
        <Tab.Pane
          as="div"
          active={TicketWorkflowSteps.Vehicle === activeTab}
          key={"vehicle"}
        >
          <VehicleSelection
            key={id}
            autoCapitalizeFirstLetter={
              selectedSettingsTicketInputDefaults.capitalizeFirstCharacter
            }
            workflowStep={activeTab}
            quickVinIsActive={selectedSettingsQuickVin.isActive}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: (
        <Menu.Item
          key={TicketWorkflowSteps.JobCategory}
          disabled={activeTab <= TicketWorkflowSteps.JobCategory}
          onClick={() => {
            navigate(`/ticket/jobCategory/${id}/${complete}`);
          }}
          className="width815"
        >
          {t("jobCategory", { ns: "tickets" }).toString()}
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane
          as="div"
          active={TicketWorkflowSteps.JobCategory === activeTab}
          key={"jobCategory"}
        >
          <JobCategoryForm
            autoCapitalizeFirstLetter={
              selectedSettingsTicketInputDefaults.capitalizeFirstCharacter
            }
            miscellaneousChargeItems={alwaysMiscellaneousChargeItems}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: (
        <Menu.Item
          key={TicketWorkflowSteps.JobType}
          disabled={
            activeTab <= TicketWorkflowSteps.JobType ||
            activeTab >= TicketWorkflowSteps.Summary
          }
          onClick={() => {
            navigate(`/ticket/jobType/${id}/${complete}`);
          }}
          className="width815"
        >
          {t("jobType", { ns: "tickets" })}
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane
          as="div"
          active={TicketWorkflowSteps.JobType === activeTab}
          key={"jobType"}
        >
          <JobType
            autoCapitalizeFirstLetter={
              selectedSettingsTicketInputDefaults.capitalizeFirstCharacter
            }
            alwaysMiscellaneousChargeItems={alwaysMiscellaneousChargeItems}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: (
        <Menu.Item
          key={TicketWorkflowSteps.Job}
          disabled={
            activeTab <= TicketWorkflowSteps.Job ||
            activeTab >= TicketWorkflowSteps.Summary
          }
          onClick={() => {
            navigate(`/ticket/job/${id}/${complete}`);
          }}
          className="width815"
        >
          {t("job", { ns: "tickets" })}
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane
          as="div"
          active={TicketWorkflowSteps.Job === activeTab}
          key={"job"}
        >
          <Job
            autoCapitalizeFirstLetter={
              selectedSettingsTicketInputDefaults.capitalizeFirstCharacter
            }
            alwaysMiscellaneousChargeItems={alwaysMiscellaneousChargeItems}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: (
        <Menu.Item
          key={TicketWorkflowSteps.Time}
          disabled={
            activeTab <= TicketWorkflowSteps.Time ||
            activeTab >= TicketWorkflowSteps.Summary
          }
          onClick={() => {
            navigate(`/ticket/Time/${id}`);
          }}
          className="width815"
        >
          {t("estimate.time", { ns: "tickets" })}
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane
          as="div"
          active={TicketWorkflowSteps.Time === activeTab}
          key={"selectTime"}
        >
          <SelectTime
            autoCapitalizeFirstLetter={
              selectedSettingsTicketInputDefaults.capitalizeFirstCharacter
            }
            alwaysMiscellaneousChargeItems={alwaysMiscellaneousChargeItems}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: (
        <Menu.Item
          key={TicketWorkflowSteps.Summary}
          disabled={activeTab <= TicketWorkflowSteps.Summary}
          onClick={() => {
            navigate(`/ticket/summary/${id}/${complete}`);
          }}
          className="width815"
        >
          {t("summary", { ns: "tickets" })}
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane
          as="div"
          active={TicketWorkflowSteps.Summary === activeTab}
          key={"summary"}
        >
          <Summary
            defaultMarkup={
              selectedSettingsVendor.enablePartMarkup
                ? selectedSettingsVendor.defaultMarkup
                : undefined
            }
            settingsSalesTaxOption={selectedSettingsTaxRate.saleTaxOption}
            settingsTaxRate={selectedSettingsTaxRate.taxRate}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: !selectedWorkflow.isCustomerInfoFirst && (
        <Menu.Item
          key={TicketWorkflowSteps.Customer2}
          disabled={activeTab <= TicketWorkflowSteps.Customer2}
          onClick={() => {
            navigate(`/ticket/customer/${id}/${complete}`);
          }}
          className="width815"
        >
          {t("customer", { ns: "tickets" })}
        </Menu.Item>
      ),
      render: () =>
        !selectedWorkflow.isCustomerInfoFirst && (
          <Tab.Pane
            as="div"
            active={TicketWorkflowSteps.Customer2 === activeTab}
            key={"customerSecond"}
          >
            <TicketCustomerDetails
              workflowStep={TicketWorkflowSteps.Customer2}
              settingsTicketInputDefaults={selectedSettingsTicketInputDefaults}
              isCustomerInfoFirst={false}
              isAdd={false}
            />
          </Tab.Pane>
        ),
    },
    {
      menuItem: (
        <Menu.Item
          key={TicketWorkflowSteps.Finalize}
          disabled={activeTab <= TicketWorkflowSteps.Finalize}
          onClick={() => {
            navigate(`/ticket/finalize/${id}/${complete}`);
          }}
          className="width815"
        >
          {t("finalize", { ns: "tickets" })}
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane
          as="div"
          active={TicketWorkflowSteps.Finalize === activeTab}
          key={"finalize"}
        >
          <Finalize
            isCustomerInfoFirst={selectedWorkflow.isCustomerInfoFirst}
            formatPhoneNumber={
              selectedSettingsTicketInputDefaults.formatPhoneNumber
            }
            autoCapitalizeFirstLetter={
              selectedSettingsTicketInputDefaults.capitalizeFirstCharacter
            }
            estimatePaymentMethodId={
              selectedSettingsTicketDefaults.defaultPayTypeId
            }
            technicianTrackingEnabled={
              selectedSettingsTechnician.technicianTrackingEnabled
            }
            technicianPartsTrackingEnabled={
              selectedSettingsTechnician.technicianPartsTrackingEnabled
            }
            dropdownPayTypesList={dropdownPayTypesList}
          />
        </Tab.Pane>
      ),
    },
    //{
    //   menuItem: (
    //     <Menu.Item
    //       key={TicketWorkflowSteps.Complete}
    //       disabled={activeTab <= TicketWorkflowSteps.Complete}
    //       onClick={() => {
    //         navigate(`/ticket/print/${id}`);
    //       }}
    //       className="width815"
    //     >
    //       {t("complete", { ns: "tickets" })}
    //     </Menu.Item>
    //   ),
    //   render: () => (
    //     <Tab.Pane as="div" active={TicketWorkflowSteps.Complete === activeTab}>
    //       <TicketFinalize />
    //     </Tab.Pane>
    //   ),
    // },
  ];

  if (loadingInitial && ticketLoaded) {
    return <LoadingComponent content={t("loading", { ns: "tickets" })} />;
  }

  return (
    <Tab
      menu={{ fluid: true, pointing: true, stackable: true }}
      menuPosition="left"
      panes={panes}
      activeIndex={activeTab}
      onTabChange={(e, data) => {
        if (
          activeTab > TicketWorkflowSteps.Vehicle &&
          data.activeIndex === TicketWorkflowSteps.Vehicle
        ) {
          modalStore.openModal(
            <TicketBackButton backUrl={`/ticket/vehicle/${id}/${complete}`} />
          );
        } else {
          setActiveTab(data.activeIndex);
        }
      }}
    />
  );
});
