import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { Grid, Header, Divider, Table, Input, Form } from "semantic-ui-react";
import { useStore } from "../../../app/stores/store";
import MyPaging from "../../../app/common/form/MyPaging";
import { PagingParams } from "../../../app/models/pagination";
import { Link } from "react-router-dom";

export default observer(function ManageUsers() {
  const { t } = useTranslation(["common", "systemAdmin"]);

  const { userSystemAdminStore } = useStore();
  const {
    loadUserList,
    getUserList,
    ClientUsersRegistry,
    pagination,
    pagingParams,
    setPagingParams,
    resetAllPredicates,
    setPredicate,
  } = userSystemAdminStore;

  useEffect(() => {
    resetAllPredicates();
  }, [resetAllPredicates]);

  function listOnClick(value: number) {
    if (value > 0 && pagination && value < pagination?.totalPages + 1) {
      setPagingParams(new PagingParams(value));
      loadUserList();
    }
  }

  return (
    <Grid stackable>
      <Grid.Row columns={2}>
        <Grid.Column>
          <Header
            as="h1"
            content={t("users.intro", { ns: "systemAdmin" }).toString()}
            className="modal-text-color"
            textAlign="left"
          />
        </Grid.Column>
      </Grid.Row>
      <Divider />
      <Form className="ui form error" autoComplete="off">
        <Grid stackable>
          <Grid.Column stackable>
            <Table striped>
              <Table.Header>
                <Table.Row>
                <Table.HeaderCell name="userName">
                    {t("username", { ns: "common" })}
                  </Table.HeaderCell>
                  <Table.HeaderCell name="companyName">
                    {t("companyname", { ns: "common" })}
                  </Table.HeaderCell>
                  <Table.HeaderCell name="email">
                    {t("email", { ns: "common" })}
                  </Table.HeaderCell>
                  <Table.HeaderCell name="lastName">
                    {t("last_name", { ns: "common" })}
                  </Table.HeaderCell>
                  <Table.HeaderCell name="firstName">
                    {t("first_name", { ns: "common" })}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                <Table.Row>
                  <Table.Cell name="userName">
                    <Input
                      onChange={(e, d) => {
                        setPagingParams(new PagingParams());
                        ClientUsersRegistry.clear();
                        setPredicate("Username", d.value);
                        loadUserList();
                      }}
                      placeholder={""}
                      name={""}
                      icon={"search"}
                    />
                  </Table.Cell>

                  <Table.Cell name="companyName">
                    <Input
                      onChange={(e, d) => {
                        ClientUsersRegistry.clear();
                        setPredicate("CompanyName", d.value);
                        loadUserList();
                      }}
                      placeholder={""}
                      name={""}
                      icon={"search"}
                    />
                  </Table.Cell>

                  <Table.Cell name="email">
                    <Input
                      onChange={(e, d) => {
                        setPagingParams(new PagingParams());
                        ClientUsersRegistry.clear();
                        setPredicate("EmailAddress", d.value);
                        loadUserList();
                      }}
                      placeholder={""}
                      name={""}
                      icon={"search"}
                    />
                  </Table.Cell>
                  <Table.Cell name="lastName">
                    <Input
                      onChange={(e, d) => {
                        setPagingParams(new PagingParams());
                        ClientUsersRegistry.clear();
                        setPredicate("LastName", d.value);
                        loadUserList();
                      }}
                      placeholder={""}
                      name={""}
                      icon={"search"}
                    />
                  </Table.Cell>
                  <Table.Cell name="firstName">
                    <Input
                      onChange={(e, d) => {
                        setPagingParams(new PagingParams());
                        ClientUsersRegistry.clear();
                        setPredicate("FirstName", d.value);
                        loadUserList();
                      }}
                      placeholder={""}
                      name={""}
                      icon={"search"}
                    />
                  </Table.Cell>
                </Table.Row>
                {getUserList.map((x) => (
                  <Table.Row key={`user.${x.userId}`}>
                    <Table.Cell name="userName">
                      <Link to={`/admin/userManage/${x.userId}`}>
                        {x.username}
                      </Link>
                    </Table.Cell>
                    <Table.Cell>
                      <Link to={`/admin/company/${x.clientId}`}>
                        {x.clientName}
                      </Link>
                    </Table.Cell>

                    <Table.Cell name="email">{x.emailAddress}</Table.Cell>
                    <Table.Cell name="lastName">{x.lastName}</Table.Cell>
                    <Table.Cell name="firstName">{x.firstName}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>

              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colSpan="8">
                    <MyPaging
                      itemCount={pagination ? pagination.totalItems : 25}
                      pageSize={pagingParams.pageSize}
                      currentPage={pagination ? pagination.currentPage : 1}
                      onClick={listOnClick}
                      onChange={listOnClick}
                      floated="right"
                    ></MyPaging>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </Grid.Column>
        </Grid>
      </Form>
    </Grid>
  );
});
