import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import {
  ClientDetails,
  ClientDetailsFormValues,
  LogoFormValues,
} from "../models/client";

export default class ClientStore {
  loadingInitial = false;
  activeTab: number = 0;
  selectedLogo: LogoFormValues = {
    logoImage: undefined,
    removeLogo: false,
    fileInput: undefined,
  };

  setSelectedLogo = (values: LogoFormValues) => {
    this.selectedLogo = values;
  };

  constructor() {
    makeAutoObservable(this);
  }

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  setActiveTab = (activeTab: string | number | undefined) => {
    this.activeTab = activeTab ? Number(activeTab) : 0;
  };

  setLogoImage = (logo: string) => {
    this.selectedLogo.logoImage = logo;
  };

  loadLogo = async () => {
    this.loadingInitial = true;
    try {
      const result = await agent.Client.getLogo();
      runInAction(() => {
        this.selectedLogo = result;
      });
      return result;
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.loadingInitial = false;
      });
    }
  };

  selectedClientDetails: ClientDetailsFormValues = {
    id: undefined,
    name: "",
    additionalInformationHeader: undefined,
    additionalInformationFooter: undefined,
    email: "",
    addressLine1: "",
    addressLine2: undefined,
    city: "",
    state: "",
    postalCode: "",
    country: undefined,
    commercialNumber: "",
    faxNumber: undefined,
  };

  loadClientDetails = async () => {
    this.loadingInitial = true;
    try {
      const result = await agent.Client.getClientDetails();
      runInAction(() => {
        this.selectedClientDetails = result;
      });
      return result;
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoadingInitial(false);
    }
  };

  setSelectedClientDetails = (values: ClientDetailsFormValues) => {
    this.selectedClientDetails = values;
  };

  updateClientDetails = async (values: ClientDetailsFormValues) => {
    try {
      let mynew: ClientDetails = new ClientDetails(values);
      await agent.Client.updateClientDetails(mynew);

      runInAction(() => {
        this.setSelectedClientDetails(values);
      });
    } catch (error) {
      console.log(error);
    }
  };

  uploading: boolean = false;

  uploadPhoto = async (file: any) => {
    this.uploading = true;
    try {
      await agent.Client.updateLogo(file);
      runInAction(() => {
        this.uploading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => (this.uploading = false));
    }
  };

  removePhoto = async () => {
    try {
      await agent.Client.removeLogo();
      runInAction(() => {
      });
    } catch (error) {
      console.log(error);
    }
  }
}
