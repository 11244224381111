import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Accordion, Menu } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../app/stores/store";
import { AccessLevel } from "../../app/models/enums";

export default observer(function SysAdminSidebar() {
  const navigate = useNavigate();
  const { userStore } = useStore();
  const { checkAccessLevel } = userStore;

  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [activeSubTab, setActiveSubTab] = useState<number>(0);

  function handleClick(activeIndex: number, activeSubIndex: number) {
    setActiveIndex(activeIndex);
    setActiveSubTab(activeSubIndex);
  }

  const Logs = (
    <Menu secondary vertical>
      <Menu.Item
        active={activeIndex === 2 && activeSubTab === 0 }
        name="adminNotes"
        onClick={() => {
          navigate(`/admin/Logs/AdminNotes`);
        }}
      />
      <Menu.Item
        active={activeIndex === 2 && activeSubTab === 1}
        name="authLogs"
        onClick={() => {
          navigate(`/admin/Logs/AuthLogs`);
        }}
      />
      <Menu.Item
        active={activeIndex === 2 && activeSubTab === 2}
        name="carFaxNightly"
        onClick={() => {
          navigate(`/admin/Logs/CarFaxNightly`);
        }}
      />
    </Menu>
  );

  const Tools = (
    <Menu secondary vertical>
      <Menu.Item
        name="ipLookup"
        onClick={() => {
          navigate(`/admin/Tools/IpLookup`);
        }}
      />
      <Menu.Item
        name="manageAnnouncements"
        onClick={() => {
          navigate("/admin/Tools/ManageAnnouncements");
        }}
      />
      <Menu.Item
        name="trialEmails"
        onClick={() => {
          navigate("/admin/Tools/TrialEmails");
        }}
      />
    </Menu>
  );

  const Reports = (
    <Menu secondary vertical>
      <Menu.Item
        name="multiRecurring"
        onClick={() => {
          navigate("/admin/Reports/MultiRecurring");
        }}
      />
      <Menu.Item
        name="noLogins"
        onClick={() => {
          navigate("/admin/Reports/noLogins");
        }}
      />
      <Menu.Item
        name="subscriptionEnded"
        onClick={() => {
          navigate("/admin/Reports/subEnded");
        }}
      />
      <Menu.Item
        name="authNetSettlement"
        onClick={() => {
          navigate("/admin/Reports/authNetSettle");
        }}
      />
      <Menu.Item
        name="activeServiceTypes"
        onClick={() => {
          navigate("/admin/Reports/activeServiceTypes");
        }}
      />
      <Menu.Item
        name="carFaxData"
        onClick={() => {
          navigate("/admin/Reports/carFaxData");
        }}
      />
    </Menu>
  );

  const Stats = (
    <Menu secondary vertical>
      <Menu.Item
        name="overview"
        onClick={() => {
          navigate("/admin/Stats/Overview");
        }}
      />
      <Menu.Item
        name="signUp"
        onClick={() => {
          navigate("/admin/Stats/SignUp");
        }}
      />
      <Menu.Item
        name="auth"
        onClick={() => {
          navigate("/admin/Stats/Auth");
        }}
      />
      <Menu.Item
        name="trialActivity"
        onClick={() => {
          navigate("/admin/Stats/TrialActivity");
        }}
      />
      <Menu.Item
        name="coUsers"
        onClick={() => {
          navigate("/admin/Stats/CoUsers");
        }}
      />
      <Menu.Item
        name="marketing"
        onClick={() => {
          navigate("/admin/Stats/Marketing");
        }}
      />
      <Menu.Item
        name="extensions"
        onClick={() => {
          navigate("/admin/Stats/Extensions");
        }}
      />
    </Menu>
  );

  const AdminTools = (
    <Menu secondary vertical>
      <Menu.Item
        name="cancellationReport"
        onClick={() => {
          navigate("/admin/AdminTools/cancellationReport");
        }}
      />
      <Menu.Item
        name="recurringCharges"
        onClick={() => {
          navigate("/admin/AdminTools/recurringCharges");
        }}
      />
      <Menu.Item
        name="nextChargeAdjustment"
        onClick={() => {
          navigate("/admin/AdminTools/nextChargeAdjustment");
        }}
      />
      <Menu.Item
        name="earlyChargeReport"
        onClick={() => {
          navigate("/admin/AdminTools/earlyChargeReport");
        }}
      />
      <Menu.Item
        name="subscriberReport"
        onClick={() => {
          navigate("/admin/AdminTools/subscriberReport");
        }}
      />
    </Menu>
  );

  return (
    <Accordion as={Menu} vertical>
      <Menu.Item
        name="users"
        onClick={() => {
          navigate("/admin/userList");
        }}
      />

      <Menu.Item
        name="companies"
        onClick={() => {
          navigate("/admin/companyList");
        }}
      />

      <Menu.Item>
        <Accordion.Title
          active={activeIndex === 2}
          content={"Logs"}
          onClick={() => {
            handleClick(2, 0);
          }}
        />
        <Accordion.Content active={activeIndex === 2} content={Logs} />
      </Menu.Item>
      {checkAccessLevel(
        AccessLevel.SystemAdministrator | AccessLevel.Manager
      ) && (
        <>
          <Menu.Item>
            <Accordion.Title
              active={activeIndex === 3}
              content={"Tools"}
              index={3}
              onClick={() => {
                handleClick(3, 1);
              }}
            />
            <Accordion.Content active={activeIndex === 3} content={Tools} />
          </Menu.Item>

          <Menu.Item
            name="companyReview"
            onClick={() => {
              navigate("/admin/companyReview");
            }}
          />

          <Menu.Item>
            <Accordion.Title
              active={activeIndex === 3}
              content={"Reports"}
              index={3}
              onClick={() => {
                handleClick(3, 1);
              }}
            />
            <Accordion.Content active={activeIndex === 3} content={Reports} />
          </Menu.Item>

          <Menu.Item>
            <Accordion.Title
              active={activeIndex === 4}
              content={"Stats"}
              index={4}
              onClick={() => {
                handleClick(4, 1);
              }}
            />
            <Accordion.Content active={activeIndex === 4} content={Stats} />
          </Menu.Item>
          <Menu.Item>
            <Accordion.Title
              active={activeIndex === 5}
              content={"AdminTools"}
              index={5}
              onClick={() => {
                handleClick(5, 1);
              }}
            />
            <Accordion.Content
              active={activeIndex === 5}
              content={AdminTools}
            />
          </Menu.Item>
        </>
      )}
    </Accordion>
  );
});
