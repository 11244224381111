import { observer } from "mobx-react-lite";
import { Message, Table } from "semantic-ui-react";
import { useStore } from "../../../app/stores/store";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import MyPaging from "../../../app/common/form/MyPaging";
import { PagingParams } from "../../../app/models/pagination";
import { convertEmailTypeToString } from "../../../app/common/util/functions";
import { EmailType } from "../../../app/models/enums";

interface Props {
    clientId: string;
    userId: string;
}

export default observer(function ViewUsersEmailLogs(props: Props) {
  const { t } = useTranslation(["common"]);
  const { emailLogsStore } = useStore();
  const {
    loadEmailLog,
    getEmailLog,
    pagination,
    pagingParams,
    setPagingParams,
  } = emailLogsStore;

  useEffect(() => {
    loadEmailLog(props.clientId, props.userId);
  }, [loadEmailLog, props.clientId, props.userId]);

  function listOnClick(value: number) {
    if (value > 0 && pagination && value < pagination?.totalPages + 1) {
      setPagingParams(new PagingParams(value));
      // loadEmailLog();
    }
  }

  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Date Created</Table.HeaderCell>
          <Table.HeaderCell>Email</Table.HeaderCell>
          <Table.HeaderCell>Type</Table.HeaderCell>
          <Table.HeaderCell>Success/Emails</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {getEmailLog && getEmailLog.length > 0 ? (
          getEmailLog.map((x) => (
            <Table.Row key={`emailLog.${x.clientId}`}>
              <Table.Cell>{x.createdDate && format(x.createdDate, "P")}</Table.Cell>
              <Table.Cell>{x.email}</Table.Cell>
              <Table.Cell>{convertEmailTypeToString(x.emailType??EmailType.AccountVerification)}</Table.Cell>
              <Table.Cell>{x.success}</Table.Cell>
            </Table.Row>
          ))
        ) : (
          <Table.Row key={"noitems"}>
            <Table.Cell width="8" colSpan={8}>
              <Message
                color="yellow"
                className="textAlignCenter fontStyleItalic"
              >
                {t("grid_noItems", { ns: "common" })}
              </Message>
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan="6">
            <MyPaging
              itemCount={pagination ? pagination.totalItems : 25}
              pageSize={pagingParams.pageSize}
              currentPage={pagination ? pagination.currentPage : 1}
              onClick={listOnClick}
              onChange={listOnClick}
              floated="right"
            ></MyPaging>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
});
