import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  DropdownItemProps,
  Form,
  Grid,
  Message,
} from "semantic-ui-react";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { useStore } from "../../app/stores/store";
import { useTranslation } from "react-i18next";
import PageHeader from "../../app/layout/PageHeader";
import * as Yup from "yup";
import { ErrorMessage, Formik, FormikErrors } from "formik";
import ValidationErrors from "../errors/ValidationErrors";
import { ClientSubscriptionsFormValues } from "../../app/models/clientSubscription";
import { Link, useNavigate } from "react-router-dom";
import sslBadgeGif from "../../app/common/img/ssl_badge.gif";
import creditCardLogos from "../../app/common/img/credit_card_logos.gif";
import sslBadgePng from "../../app/common/img/ssl-badge.png";
import { formatCurrency, getNegative } from "../../app/common/util/functions";
import SubscriptionMobileMenu from "./SubscriptionMobileMenu";
import SubscriptionContent from "./SubscriptionContent";
import SubscriptionDisplay from "./SubscriptionDisplay";
import MyRadioGroup from "../../app/common/form/RadioButtonGroups/MyRadioGroup";
import { Options, planIntervalOptions } from "../../app/common/options/option";
import MySelectInput from "../../app/common/form/MySelectInput";

export default observer(function RenewalOptions() {
  const { subscriptionStore } = useStore();
  const {
    updateRenewalTerms,
    loadSubscription,
    calculateSubscription,
    loadingInitial,
    getCurrentSubscription,
    clientSubscriptionStatus,
  } = subscriptionStore;

  const { t } = useTranslation(["common", "subscription"]);
  const navigate = useNavigate();

  useEffect(() => {
    loadSubscription().then((result) => {
      setClientSubscriptions(new ClientSubscriptionsFormValues(result));
      calculateSubscription(
        result?.dowellSystemApplication ?? 0,
        result?.userCount ?? 0,
        result?.term ?? 0
      )
        .then((result) => {
          setCurrentSubscriptionAmount(result);
        })
        .catch();
    });
  }, [loadSubscription]);

  useEffect(() => {
    getCurrentSubscription()
      .then((result) => {
        //TODO fix this I haven't figured out how to do so but this is bogus
        if (result?.isCancelled) navigate("/subscription/status");
      })
      .catch();
  }, [getCurrentSubscription]);

  let usersOption: DropdownItemProps[] = [];

  for (
    let i = clientSubscriptionStatus.activeUsers;
    i <= clientSubscriptionStatus.subscriptionUsers;
    i++
  ) {
    let uOption: Options = {
      text:
        i > 1
          ? `${i} ${t("users", { ns: "common" })}`
          : `${i} ${t("user", { ns: "common" })}`,
      value: i,
    };
    usersOption.push(uOption);
  }

  const [currentSubscriptionAmount, setCurrentSubscriptionAmount] =
    useState<number>(0.0);

  const [clientSubscriptions, setClientSubscriptions] =
    useState<ClientSubscriptionsFormValues>(
      new ClientSubscriptionsFormValues()
    );

  const [amount, setAmount] = useState<number>(0.0);

  function handleFormSubmit(
    values: ClientSubscriptionsFormValues,
    setErrors: (errors: FormikErrors<ClientSubscriptionsFormValues>) => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) {
    updateRenewalTerms(values)
      .then(() => {
        setSubmitting(false);
        navigate("/subscription/status");
      })
      .catch((error) => {
        setSubmitting(false);
        setErrors({ error: error });
      });
  }

  const validationSchema = Yup.object({
    //todo User validation
  });

  if (loadingInitial)
    return <LoadingComponent content={t("loading", { ns: "subscription" })} />;

  return (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <SubscriptionMobileMenu
              isCancelled={clientSubscriptionStatus.isCancelled}
            />
            <SubscriptionContent
              isCancelled={clientSubscriptionStatus.isCancelled}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <PageHeader
        header={t("subscription.renewal_options", {
          ns: "subscription",
        })}
        type={"h1"}
        divider={true}
        getAlerts={true}
        addTitle={true}
      />
      <Container className="page">
        <Formik
          initialValues={clientSubscriptions}
          enableReinitialize
          onSubmit={(values, { setErrors, setSubmitting }) =>
            handleFormSubmit(values, setErrors, setSubmitting)
          }
          validationSchema={validationSchema}
        >
          {({
            handleSubmit,
            isSubmitting,
            errors,
            isValid,
            dirty,
            values,
            touched,
            setFieldValue,
            setFieldTouched,
          }) => (
            <Form
              className="ui form error"
              onSubmit={handleSubmit}
              autoComplete="off"
            >
              <Grid stackable>
                <Grid.Row>
                  <Grid.Column>
                    <Message info>
                      <Grid>
                        <Grid.Row>
                          <Grid.Column>
                            {`${t("subscription.renewal_options_message", {
                              ns: "subscription",
                            })} `}
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Message>
                    <ErrorMessage
                      name="error"
                      render={() => <ValidationErrors errors={errors.error} />}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <PageHeader
                      header={t("subscription.renewal_options", {
                        ns: "subscription",
                      })}
                      type={"h2"}
                      divider={true}
                      getAlerts={true}
                      addTitle={false}
                    />
                    <MySelectInput
                      name="userCount"
                      placeholder={t("users", { ns: "common" })}
                      options={usersOption}
                      label={`${t("users", { ns: "common" })}:`}
                      onChange={(d) => {
                        if (
                          d > 0 &&
                          values.dowellSystemApplication &&
                          values.term
                        )
                          calculateSubscription(
                            values.dowellSystemApplication,
                            Number(d),
                            values.term
                          ).then((amount) => setAmount(amount));
                      }}
                      clearable={true}
                    />
                    <MyRadioGroup
                      name={"term"}
                      options={planIntervalOptions}
                      inline={true}
                      label={`${t("subscription.renewalPeriod", {
                        ns: "subscription",
                      })}:`}
                      onChange={(d) => {
                        if (
                          values.userCount &&
                          values.dowellSystemApplication &&
                          d
                        )
                          if (Number(d) != clientSubscriptions.term) {
                            calculateSubscription(
                              values.dowellSystemApplication,
                              values.userCount,
                              Number(d)
                            ).then((amount) => setAmount(amount));
                          }
                      }}
                    />
                    <br />
                    <label className="myLabel">
                      {`${t("amount", {
                        ns: "common",
                      })}: `}
                      {formatCurrency(amount)}
                    </label>
                  </Grid.Column>
                  <Grid.Column>
                    <SubscriptionDisplay
                      clientSubscription={values}
                      subscriptionAmount={
                        clientSubscriptions.userCount != values.userCount ||
                        clientSubscriptions.term != values.term
                          ? amount
                          : currentSubscriptionAmount
                      }
                      isNew={
                        clientSubscriptions.userCount != values.userCount ||
                        clientSubscriptions.term != values.term
                      }
                    />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row columns={2}>
                  <Grid.Column>
                    <Button
                      disabled={!isValid || !dirty || isSubmitting}
                      loading={isSubmitting}
                      className="save_button modal-button-color"
                      positive
                      content={t("update", { ns: "common" })}
                      type="submit"
                    />
                    <Button
                      className="save_button"
                      as={Link}
                      to="/subscription/"
                      icon="cancel"
                      type="reset"
                      content={t("cancel", { ns: "common" })}
                    />
                  </Grid.Column>
                  <Grid.Column textAlign="right">
                    <Link
                      to="/subscription/cancel"
                      style={{
                        color: "red",
                      }}
                    >
                      {t("cancel.cancelheader", { ns: "subscription" })}
                    </Link>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={4} centered>
                  <Grid.Column>
                    <a
                      href="https://verify.authorize.net/anetseal/?pid=04dbc10d-46f3-41e1-80cb-6a3c1737e082"
                      onClick={() => {
                        window.open(
                          "https://verify.authorize.net/anetseal/?pid=04dbc10d-46f3-41e1-80cb-6a3c1737e082",
                          "AuthorizeNetVerification",
                          "width=600,height=430,dependent=yes,resizable=yes,scrollbars=yes,menubar=no,toolbar=no,status=no,directories=no,location=yes"
                        );
                        return false;
                      }}
                      className="text-center"
                      target="_blank"
                      title="http://www.authorize.net/"
                    >
                      <img
                        src="https://verify.authorize.net/anetseal/images/secure90x72.gif"
                        alt="Authorize.Net Merchant - Click to Verify"
                        width="90"
                        height="72"
                      />
                    </a>
                  </Grid.Column>
                  <Grid.Column>
                    <img alt="" src={sslBadgeGif} />
                  </Grid.Column>

                  <Grid.Column>
                    <img alt="" src={creditCardLogos} />
                  </Grid.Column>

                  <Grid.Column>
                    <img alt="" src={sslBadgePng} />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
});
