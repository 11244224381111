import React from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../../app/stores/store";
import { View, Image } from "@react-pdf/renderer";
import compose from "../../../../app/layout/compose";

interface Props {
  logoCenter:boolean;
}

export default observer(function LogoView({logoCenter}: Props) {
  
  const { clientStore } = useStore();

  const { selectedLogo } = clientStore;

  return (
    <View
      style={{
        ...compose("view textAlignCenter height40mm"),
        maxWidth: `${logoCenter? "80mm": "60mm"}`,
        width: `${logoCenter? "80mm": "60mm"}`,
      }}
    >
      {selectedLogo?.logoImage && (
        <Image
          style={{
            ...compose(`image`),
            maxHeight: "40mm",
            maxWidth: `${logoCenter? "80mm": "60mm"}`,
          }}
          src={`data:image/jpeg;base64,${selectedLogo.logoImage}`}
        />
      )}
    </View>
  );
});
