import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Divider, Grid, Button, Icon, Form } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import PageHeader from "../../app/layout/PageHeader";
import CustomerVehicleEdit from "../customerVehicle/CustomerVehicleEdit";
import CustomerEdit from "./CustomerEdit";
import { Formik } from "formik";
import Sidebar from "../tickets/dashboard/Sidebar";
import { TicketWorkflowSteps } from "../../app/models/enums";
import {
  CustomerFormValues,
  TicketCustomerVehicleFormValues,
} from "../../app/models/customer";
import { useStore } from "../../app/stores/store";
import { useNavigate, useParams } from "react-router-dom";
import { Vehicle } from "../../app/models/vehicle";
import { SettingsTicketInputDefaultsFormValues } from "../../app/models/ticket";
import PotentialCustomerMatchingMessage from "./PotentialCustomerMatchingMessage";
import { PagingParams } from "../../app/models/pagination";
import { resetTicket } from "../../app/common/util/ticketfunctions";

interface Props {
  workflowStep: TicketWorkflowSteps;
  isCustomerInfoFirst: boolean;
  settingsTicketInputDefaults: SettingsTicketInputDefaultsFormValues;
  isAdd: boolean;
}

export default observer(function TicketCustomerDetails({
  workflowStep,
  isCustomerInfoFirst,
  settingsTicketInputDefaults,
  isAdd,
}: Props) {
  const { t } = useTranslation(["common", "translation"]);
  const navigate = useNavigate();
  const { ticketSessionStore, customerStore, vehicleStore } = useStore();

  const {
    updateTicketSession,
    setSelectedTicketCustomer,
    setSelectedTicketVehicle,
    calculateTotals
  } = ticketSessionStore;
  const {
    setSelectedCustomer,
    selectedCustomer,
    loadPotentialCustomersList,
    potentialCustomerPredicate,
    setPagingParams,
  } = customerStore;
  const { setSelectedVehicle, selectedVehicle } = vehicleStore;
  const { id } = useParams<{ id: string }>();
  const { complete } = useParams<{ complete: string }>();

  const [ticketCustomer] = useState<TicketCustomerVehicleFormValues>(
    getTicketCustomerVehicleFormValues()
  );

  function getTicketCustomerVehicleFormValues() {
    let initial: TicketCustomerVehicleFormValues =
      new TicketCustomerVehicleFormValues();

    if (!isAdd) {
      if (selectedCustomer) {
        initial.firstName = selectedCustomer.firstName;
        initial.lastName = selectedCustomer.lastName;
        initial.companyName = selectedCustomer.companyName;
        initial.isCommercial = selectedCustomer.isCommercial;
        initial.primaryNumber = selectedCustomer.primaryNumber;
        initial.secondaryNumber = selectedCustomer.secondaryNumber;
        initial.ternaryNumber = selectedCustomer.ternaryNumber;
        initial.email = selectedCustomer.email;

        initial.addressLine1 = selectedCustomer.addressLine1;
        initial.addressLine2 = selectedCustomer.addressLine2;
        initial.city = selectedCustomer.city;
        initial.state = selectedCustomer.state;
        initial.postalCode = selectedCustomer.postalCode;
      }

      if (selectedVehicle) {
        initial.mileageIn = selectedVehicle.mileageIn;
        initial.mileageOut = selectedVehicle.mileageOut;
        initial.tag = selectedVehicle.tag;
        initial.vehicleNumber = selectedVehicle.vehicleNumber;
        initial.vin = selectedVehicle.vin;
      }
    }

    return initial;
  }

  function handleFormSubmit(
    values: TicketCustomerVehicleFormValues,
    setSubmitting: (isSubmitting: boolean) => void
  ) {
    let newCustomer: CustomerFormValues = new CustomerFormValues(
      selectedCustomer
    );
    newCustomer.firstName = values.firstName;
    newCustomer.lastName = values.lastName;
    newCustomer.companyName = values.companyName;
    newCustomer.isCommercial = values.isCommercial;
    newCustomer.primaryNumber = values.primaryNumber;
    newCustomer.secondaryNumber = values.secondaryNumber;
    newCustomer.ternaryNumber = values.ternaryNumber;
    newCustomer.email = values.email;

    newCustomer.addressLine1 = values.addressLine1;
    newCustomer.addressLine2 = values.addressLine2;
    newCustomer.city = values.city;
    newCustomer.state = values.state;
    newCustomer.postalCode = values.postalCode;
    newCustomer.isTaxExempt = selectedCustomer?.isTaxExempt ?? false;
    setSelectedCustomer(newCustomer);
    setSelectedTicketCustomer(newCustomer);

    let newVehicle: Vehicle = new Vehicle(selectedVehicle);
    newVehicle.mileageIn = values.mileageIn;
    newVehicle.mileageOut = values.mileageOut;
    newVehicle.tag = values.tag;
    newVehicle.vehicleNumber = values.vehicleNumber;
    newVehicle.vin = values.vin;
    setSelectedVehicle(newVehicle);
    setSelectedTicketVehicle(newVehicle);

    calculateTotals();
    const action = () =>
      updateTicketSession(
        isCustomerInfoFirst
          ? TicketWorkflowSteps.Customer1
          : TicketWorkflowSteps.Customer2,
        id ?? "",
        newVehicle,
        newCustomer
      );

    action()
      .then(() => {
        let nextStep: string = `/ticket/finalize/${id}/${complete ?? false}`;
        if (isCustomerInfoFirst) {
          nextStep = `/ticket/vehicle/${id}/${complete ?? false}`;
        }

        if (
          !newCustomer.id &&
          (newCustomer.lastName ||
            (newCustomer.isCommercial && newCustomer.companyName))
        ) {
          setPagingParams(new PagingParams());
          potentialCustomerPredicate(
            newCustomer.lastName ?? "",
            newCustomer.isCommercial,
            newCustomer.companyName ?? ""
          );
          loadPotentialCustomersList().then(() => {
            setSubmitting(false);
            navigate(nextStep);
          });
        } else {
          setSubmitting(false);
          navigate(nextStep);
        }
      })
      .catch(() => {
        setSubmitting(false);
      });
  }

  const validationSchema = Yup.object({});

  return (
    <Grid stackable>
      <Grid.Column width="11">
        <PotentialCustomerMatchingMessage workflowStep={workflowStep} />
        <Formik
          validationSchema={validationSchema}
          enableReinitialize
          initialValues={ticketCustomer}
          onSubmit={(values, { setSubmitting }) =>
            handleFormSubmit(values, setSubmitting)
          }
        >
          {({
            handleSubmit,
            isValid,
            isSubmitting,
            dirty,
            handleBlur,
            handleChange,
            errors,
            values,
            touched,
          }) => (
            <Form
              className="ui form error"
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <Grid stackable>
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <PageHeader
                      type="h1"
                      header={t("customerDetails", {
                        ns: "tickets",
                      }).toString()}
                      className="modal-text-color"
                      textAlign="left"
                      divider={false}
                      getAlerts={true}
                      addTitle={true}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Button
                      animated
                      color="green"
                      floated="right"
                      type={"submit"}
                      disabled={isSubmitting || !isValid}
                      loading={isSubmitting}
                    >
                      <Button.Content visible>
                        {workflowStep === TicketWorkflowSteps.Customer2
                          ? t("finalize", { ns: "tickets" })
                          : t("continue", { ns: "tickets" })}
                      </Button.Content>
                      <Button.Content hidden>
                        <Icon name="caret right" />
                      </Button.Content>
                    </Button>
                    {workflowStep === TicketWorkflowSteps.Customer2 && (
                      <Button
                        floated="right"
                        type={"button"}
                        onClick={() => {
                          navigate(`/ticket/summary/${id}/${complete}`);
                        }}
                      >
                        <Icon name="caret left" />
                        {t("back", { ns: "tickets" })}
                      </Button>
                    )}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <Divider />
              <Grid stackable>
                <Grid.Row>
                  <Grid.Column>
                    <PageHeader
                      header={t("customer_info", {
                        ns: "customer",
                      })}
                      type={"h3"}
                      divider={false}
                      addTitle={false}
                    ></PageHeader>
                  </Grid.Column>
                </Grid.Row>
                <CustomerEdit
                  isTicket={true}
                  showDefaults={true}
                  ticketInputDefaults={settingsTicketInputDefaults}
                  initialIsCommercial={values.isCommercial}
                />

                <Grid.Row>
                  <Grid.Column>
                    <PageHeader
                      header={t("vehicleDetails", {
                        ns: "tickets",
                      })}
                      type={"h3"}
                      divider={true}
                      addTitle={false}
                    ></PageHeader>
                  </Grid.Column>
                </Grid.Row>
                <CustomerVehicleEdit
                  autoCapitalizeFirstLetter={
                    settingsTicketInputDefaults.capitalizeFirstCharacter
                  }
                />
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid.Column>
      <Grid.Column width="5">
        <Grid>
          <Grid.Row>
            <Grid.Column></Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Sidebar
              disableButtons={true}
              workflowStep={
                workflowStep ? workflowStep : TicketWorkflowSteps.Customer1
              }
              autoCapitalizeFirstLetter={
                settingsTicketInputDefaults.capitalizeFirstCharacter
              }
            />
          </Grid.Row>
        </Grid>
      </Grid.Column>
    </Grid>
  );
});
