import React from "react";
import { observer } from "mobx-react-lite";
import { Grid, Header, List } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../app/stores/store";
import { getCellColor } from "../../../app/common/util/functions";

export default observer(function CompareServiceOverviewComponent() {
  const { t } = useTranslation(["common", "companySettings"]);

  const { statsStore } = useStore();

  const { statsCompare } = statsStore;

  return (
    <>
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column>
            <List>
              <List.Item>
                <Header as="h3" content="CarFax Stats: " />
              </List.Item>
              <List.Item>
                <b>Auth Pending: </b>
                <span
                  className={getCellColor(statsCompare?.carFaxPending ?? 0)}
                >
                  {statsCompare?.carFaxPending}
                </span>
              </List.Item>
              <List.Item>
                <b>Total Accounts: </b>
                <span
                  className={getCellColor(
                    statsCompare?.carFaxTotalAccount ?? 0
                  )}
                >
                  {statsCompare?.carFaxTotalAccount}
                </span>
              </List.Item>
              <List.Item>
                <b>Pending Data, Companies: </b>
                <span
                  className={getCellColor(
                    statsCompare?.carFaxPendingClient ?? 0
                  )}
                >
                  {statsCompare?.carFaxPendingClient}
                </span>
              </List.Item>
              <List.Item>
                <b>Pending Data, Tickets: </b>
                <span
                  className={getCellColor(
                    statsCompare?.carFaxPendingTickets ?? 0
                  )}
                >
                  {statsCompare?.carFaxPendingTickets}
                </span>
              </List.Item>
              <List.Item>
                <b>Pending Data, Ticket Items: </b>
                <span
                  className={getCellColor(
                    statsCompare?.carFaxPendingTicketsItems ?? 0
                  )}
                >
                  {statsCompare?.carFaxPendingTicketsItems}
                </span>
              </List.Item>
            </List>
          </Grid.Column>
          <Grid.Column>
            <List>
              <List.Item>
                <Header as="h3" content="Sign Up Stats: " />
              </List.Item>
              <List.Item>
                <b>Email Confirmations: </b>
                <span
                  className={getCellColor(statsCompare?.emailConfirmed ?? 0)}
                >
                  {statsCompare?.emailConfirmed}
                </span>
              </List.Item>
              <List.Item>
                <b>Company Waiting For Review: </b>
                <span
                  className={getCellColor(
                    statsCompare?.clientPendingReview ?? 0
                  )}
                >
                  {statsCompare?.clientPendingReview}
                </span>
              </List.Item>
              <List.Item>
                <b>Company Declined: </b>
                <span
                  className={getCellColor(statsCompare?.clientDecline ?? 0)}
                >
                  {statsCompare?.clientDecline}
                </span>
              </List.Item>
              <List.Item>
                <b>Company Approved No Login: </b>
                <span
                  className={getCellColor(
                    statsCompare?.clientApprovalNoLogin ?? 0
                  )}
                >
                  {statsCompare?.clientApprovalNoLogin}
                </span>
              </List.Item>
              <List.Item>
                <b>Company Approved Active: </b>
                <span
                  className={getCellColor(
                    statsCompare?.clientApprovedActive ?? 0
                  )}
                >
                  {statsCompare?.clientApprovedActive}
                </span>
              </List.Item>
              <List.Item>
                <b>Company Subscribed: </b>
                <span
                  className={getCellColor(statsCompare?.clientSubscribed ?? 0)}
                >
                  {statsCompare?.clientSubscribed}
                </span>
              </List.Item>
              <List.Item>
                <b>Company Users Subscribed: </b>
                <span
                  className={getCellColor(
                    statsCompare?.clientUsersSubscribed ?? 0
                  )}
                >
                  {statsCompare?.clientUsersSubscribed}
                </span>
              </List.Item>
            </List>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column>
            <List>
              <List.Item>
                <Header as="h3" content="System Stats: " />
              </List.Item>
              <List.Item>
                <b>Daemon Email Queued: </b>
                <span className={getCellColor(statsCompare?.emailPending ?? 0)}>
                  {statsCompare?.emailPending}
                </span>
              </List.Item>
              <List.Item>
                <b>Daemon Email Errors: </b>
                Send:
                <span
                  className={getCellColor(
                    statsCompare?.totalEmailErrorSent ?? 0
                  )}
                >
                  {statsCompare?.totalEmailErrorSent}
                </span>
                , View:
                <span
                  className={getCellColor(
                    statsCompare?.totalEmailErrorView ?? 0
                  )}
                >
                  {statsCompare?.totalEmailErrorView}
                </span>
                , Bad:
                <span
                  className={getCellColor(
                    statsCompare?.totalEmailErrorBad ?? 0
                  )}
                >
                  {statsCompare?.totalEmailErrorBad}
                </span>
              </List.Item>
              <List.Item>
                <b>Quotes: </b>
                All:
                <span className={getCellColor(statsCompare?.quotesTotal ?? 0)}>
                  {statsCompare?.quotesTotal}
                </span>
                . Custom:
                <span
                  className={getCellColor(statsCompare?.quotesCustomTotal ?? 0)}
                >
                  {statsCompare?.quotesCustomTotal}
                </span>
              </List.Item>
              <List.Item>
                <b>Finalized Invoices: </b>
                <span
                  className={getCellColor(statsCompare?.finalizedInvoices ?? 0)}
                >
                  {statsCompare?.finalizedInvoices}
                </span>
              </List.Item>
              <List.Item>
                <b>Custom Vehicles: </b>
                <span
                  className={getCellColor(statsCompare?.customVehicles ?? 0)}
                >
                  {statsCompare?.customVehicles}
                </span>
              </List.Item>
              <List.Item>
                <b>Multiple Recurrings: </b>
                <span
                  className={getCellColor(statsCompare?.multipleRecurring ?? 0)}
                >
                  {statsCompare?.multipleRecurring}
                </span>
              </List.Item>
            </List>
          </Grid.Column>
          <Grid.Column>
            <List>
              <List.Item>
                <Header as="h3" content="Company Stats: " />
              </List.Item>
              <List.Item>
                <b>Companies (Basic/Pro): </b>
                <span
                  className={getCellColor(statsCompare?.clientBasicTotal ?? 0)}
                >
                  {statsCompare?.clientBasicTotal}
                </span>
                 / 
                <span
                  className={getCellColor(statsCompare?.clientProTotal ?? 0)}
                >
                  {statsCompare?.clientProTotal}
                </span>
              </List.Item>
              <List.Item>
                <b>Users (Basic/Pro): </b>
                <span
                  className={getCellColor(statsCompare?.userBasicTotal ?? 0)}
                >
                  {statsCompare?.userBasicTotal}
                </span>
                /
                <span className={getCellColor(statsCompare?.userProTotal ?? 0)}>
                  {statsCompare?.userProTotal}
                </span>
              </List.Item>
              <List.Item>
                <b>Total Extended Trials: </b>
                <span
                  className={getCellColor(
                    statsCompare?.totalExtendedTrial ?? 0
                  )}
                >
                  {statsCompare?.totalExtendedTrial}
                </span>
              </List.Item>
            </List>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column>
            <List>
              <List.Item>
                <Header as="h3" content="Admin Stats: " />
              </List.Item>
              <List.Item>
                <b>Site Admin Users: </b>
                <span
                  className={getCellColor(statsCompare?.internalStaff ?? 0)}
                >
                  {statsCompare?.internalStaff}
                </span> / 
                <span
                  className={getCellColor(
                    statsCompare?.internalManagerInactive ?? 0
                  )}
                >
                  {statsCompare?.internalManagerInactive}
                </span>
              </List.Item>
              <List.Item>
                <b>System Admin Users: </b>
                <span
                  className={getCellColor(statsCompare?.internalManager ?? 0)}
                >
                  {statsCompare?.internalManager}
                </span> / 
                <span
                  className={getCellColor(
                    statsCompare?.internalManagerInactive ?? 0
                  )}
                >
                  {statsCompare?.internalManagerInactive}
                </span>
              </List.Item>
            </List>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
});
