import { observer } from "mobx-react-lite";
import { Message, Table } from "semantic-ui-react";
import { format } from "date-fns";
import { useStore } from "../../app/stores/store";
import { useEffect } from "react";
import MyPaging from "../../app/common/form/MyPaging";
import { Pagination, PagingParams } from "../../app/models/pagination";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AdminNotes } from "../../app/models/systemAdmin";

interface Props {
  adminNotes: AdminNotes[];
  pagination?: Pagination | null;
  pagingParams : PagingParams;
  listOnClick: (value: number) => void;
}
export default observer(function AdminNotesList({
  adminNotes,
  pagination,
  pagingParams,
  listOnClick,
}: Props) {
  const { t } = useTranslation(["common"]);

  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Date Created</Table.HeaderCell>
          <Table.HeaderCell>Author</Table.HeaderCell>
          <Table.HeaderCell>Affected</Table.HeaderCell>
          <Table.HeaderCell>Note</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {adminNotes && adminNotes.length > 0 ? (
          adminNotes.map((x) => (
            <Table.Row key={`adminNotes.${x.id}`}>
              <Table.Cell>
                {x.createdDate && format(x.createdDate, "P p")}
              </Table.Cell>
              <Table.Cell>
                {x.authorUserId ? (
                  <Link to={`/admin/userManage/${x.authorUserId}`}>
                    {x.createdBy}
                  </Link>
                ) : (
                  x.createdBy
                )}
              </Table.Cell>
              <Table.Cell>
                {x.userId ? (
                  <Link to={`/admin/userManage/${x.userId}`}>
                    {x.affectedName}
                  </Link>
                ) : (
                  x.clientName
                )}
              </Table.Cell>
              <Table.Cell>
                <div
                  dangerouslySetInnerHTML={{
                    __html: x.note,
                  }}
                />
              </Table.Cell>
            </Table.Row>
          ))
        ) : (
          <Table.Row key={"noItems"}>
            <Table.Cell width="8" colSpan={8}>
              <Message
                color="yellow"
                className="textAlignCenter fontStyleItalic"
              >
                {t("grid_noItems", { ns: "common" })}
              </Message>
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan="8">
            <MyPaging
              itemCount={pagination ? pagination.totalItems : 10}
              pageSize={pagingParams.pageSize}
              currentPage={pagination ? pagination.currentPage : 1}
              onClick={listOnClick}
              onChange={listOnClick}
              floated="right"
            ></MyPaging>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
});
