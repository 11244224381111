import axios from "axios";
import { PaginatedResult } from "../../models/pagination";
import {

  DeletedCustomers,
  DeletedTickets,
  GeoLocation,
  FreeTrialRequestReport,
  SetTrial,
} from "../../models/systemAdmin";
import { requests, responseBody } from "../Requests";
import { ClientDetails } from "../../models/client";
import { Announcement, SystemAdminAnnouncement } from "../../models/admin";
import { User } from "../../models/user";

export const SystemAdminTools = {

  getDeletedCustomers: (params: URLSearchParams) =>
    axios
      .get<PaginatedResult<DeletedCustomers[]>>(
        `/SystemAdminTools/getDeletedCustomers`,
        {
          params,
        }
      )
      .then(responseBody),
  getDeletedTickets: (params: URLSearchParams) =>
    axios
      .get<PaginatedResult<DeletedTickets[]>>(
        `/SystemAdminTools/getDeletedTickets`,
        {
          params,
        }
      )
      .then(responseBody),
  restoreCustomer: (id: string) =>
    requests.put<void>(`/SystemAdminTools/restoreCustomer/${id}`, {}),
  restoreTicket: (id: string) =>
    requests.put<void>(`/SystemAdminTools/restoreTicket/${id}`, {}),
  getGeoLocation: (ip: string) =>
    requests.get<GeoLocation>(`/SystemAdminTools/getGeoLocation/${ip}`),
  getAnnouncement: (id: number) =>
    requests.get<SystemAdminAnnouncement>(`/SystemAdminTools/getAnnouncement/${id}`),
  getCurrentAnnouncement: () =>
    requests.get<Announcement[]>(`/SystemAdminTools/getCurrentAnnouncement`),
  getAnnouncementList: () =>
    requests.get<SystemAdminAnnouncement[]>(`/SystemAdminTools/listAnnouncement`),
  updateAnnouncement: (
    id: number | undefined,
    announcement: SystemAdminAnnouncement
  ) => requests.put<void>(`/SystemAdminTools/updateAnnouncement/${id}`, announcement),
  addAnnouncement: (values: SystemAdminAnnouncement) =>
    requests.post<void>(`/SystemAdminTools/addAnnouncement`, values),
  emulateUser: (userName: string) =>
    requests.post<User>(`/account/EmulateUser/${userName}`, userName),
  getFreeTrialRequestReport: (params: URLSearchParams, id: string) =>
    axios
      .get<PaginatedResult<FreeTrialRequestReport[]>>(
        `/SystemAdminTools/listAEFreeTrialRequests`,
        { params }
      )
      .then(responseBody),
  approveFreeTrial: (id: string, values: SetTrial) =>
    requests.post<void>(`/SystemAdminTools/approveFreeTrial/${id}`, values),
  rejectFreeTrial: (id: string, values: SetTrial) =>
    requests.post<void>(`/SystemAdminTools/rejectFreeTrial/${id}`, values),
  followUpFreeTrial: (id: string, values: SetTrial) =>
    requests.post<void>(`/SystemAdminTools/followUpFreeTrial/${id}`, values),
  setTrialRequestType: (id: string, values: SetTrial) =>
    requests.post<void>(`/SystemAdminTools/setTrialRequestType/${id}`, values),

}