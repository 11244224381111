
import axios, { AxiosResponse } from "axios";
import { IpAddress } from "../common/util/iPAdress";


export const responseBody = <T>(response: AxiosResponse<T>) => response.data;
const ipAddressApiURL: string = process.env.REACT_APP_IP_ADDRESS_LOOKUP
  ? process.env.REACT_APP_IP_ADDRESS_LOOKUP
  : "";

export const ipAddressApi = {
  
  ipAddress: () => axios.get<IpAddress>(ipAddressApiURL).then(responseBody),
  
};

const ipAddressAgent = {
  ipAddressApi,
};

export default ipAddressAgent;

