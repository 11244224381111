import { observer } from "mobx-react-lite";
import { Message, Table } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { useStore } from "../../../app/stores/store";
import { useEffect } from "react";
import MyPaging from "../../../app/common/form/MyPaging";
import { PagingParams } from "../../../app/models/pagination";
import { formatCurrency } from "../../../app/common/util/functions";

interface Props {
  clientId: string;
}

export default observer(function ClientBillingSubscription({
  clientId,
}: Props) {
  const { t } = useTranslation(["common"]);
  const { clientSystemAdminStore } = useStore();

  const {
    loadClientBillingSubscriptionInformation,
    getClientBillingSubscriptionInformation,
    pagination,
    setPagingParams,
    pagingParams,
  } = clientSystemAdminStore;

  useEffect(() => {
    loadClientBillingSubscriptionInformation(clientId);
  }, [loadClientBillingSubscriptionInformation, clientId]);

  function listOnClick(value: number) {
    if (value > 0 && pagination && value < pagination?.totalPages + 1) {
      setPagingParams(new PagingParams(value));
      loadClientBillingSubscriptionInformation(clientId);
    }
  }

  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Date</Table.HeaderCell>
          <Table.HeaderCell>Amount</Table.HeaderCell>
          <Table.HeaderCell>Description</Table.HeaderCell>
          <Table.HeaderCell>Status</Table.HeaderCell>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Last/Int</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {getClientBillingSubscriptionInformation &&
        getClientBillingSubscriptionInformation.length > 0 ? (
          getClientBillingSubscriptionInformation.map((x) => (
            <Table.Row key={`client.${x.id}`}>
              <Table.Cell>{format(x.date, "P p")}</Table.Cell>
              <Table.Cell textAlign="right">{formatCurrency( x.amount??0)}</Table.Cell>
              <Table.Cell>{x.description}</Table.Cell>
              <Table.Cell>{x.status}</Table.Cell>
              <Table.Cell>{x.name}</Table.Cell>
              <Table.Cell>{x.lastInt}</Table.Cell>
            </Table.Row>
          ))
        ) : (
          <Table.Row key={"noitems"}>
            <Table.Cell width="8" colSpan={8}>
              <Message
                color="yellow"
                className="textAlignCenter fontStyleItalic"
              >
                {t("grid_noItems", { ns: "common" })}
              </Message>
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan="6">
            <MyPaging
              itemCount={pagination ? pagination.totalItems : 10}
              pageSize={pagingParams.pageSize}
              currentPage={pagination ? pagination.currentPage : 1}
              onClick={listOnClick}
              onChange={listOnClick}
              floated="right"
            ></MyPaging>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
});
