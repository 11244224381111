import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { Grid, Header, Divider, Button, Icon, Table } from "semantic-ui-react";
import { useStore } from "../../app/stores/store";
import format from "date-fns/format";
import { formatPhoneNumber } from "../../app/common/util/functions";
import { Link } from "react-router-dom";
import { SystemAdminNoteType } from "../../app/models/enums";

export default observer(function CompanyReview() {
  const { t } = useTranslation(["common", "systemAdmin"]);

  const { clientReviewStore } = useStore();

  const {
    getCompanyApprovalReviewList,
    loadCompanyApprovalReviewList,
    approveCompany,
    rejectCompany,
  } = clientReviewStore;

  useEffect(() => {
    loadCompanyApprovalReviewList();
  }, [loadCompanyApprovalReviewList]);

  return (
    <>
      <Grid stackable>
        <Grid.Row columns={1}>
          <Grid.Column>
            <Header
              as="h1"
              content={t("company_intro", { ns: "systemAdmin" }).toString()}
              className="modal-text-color"
              textAlign="left"
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Divider />
      <Grid stackable>
        <Grid.Column stackable>
          <Table striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={6}>
                  {t("company", { ns: "common" })}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {t("contact", { ns: "common" })}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {t("address.address", { ns: "common" })}
                </Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {getCompanyApprovalReviewList.map((x) => (
                <Table.Row key={`client.${x.clientId}`}>
                  <Table.Cell>
                    <p>
                      <b>
                        <Link to={`/admin/company/${x.clientId}`}>
                          {x.companyName}
                        </Link>
                      </b>{" "}
                      <br />
                      {format(x.createdDate, "P")} <br />
                      {x.adminNotes.map((y, index) => (
                        <>
                          
                          <div
                            dangerouslySetInnerHTML={{
                              __html: (`! ${y.note}`),
                            }}
                          />
                        </>
                      ))}
                    </p>
                  </Table.Cell>
                  <Table.Cell>
                    {x.name}
                    <br />
                    <Link
                      to={`/admin/company/companyPhoneNumbers/${x.clientId}/${x.primaryPhone}`}
                    >
                      {formatPhoneNumber(x.primaryPhone)}
                    </Link>
                    <br />
                    {x.email}
                  </Table.Cell>
                  <Table.Cell>
                    <Link to={`/admin/company/companyAddresses/${x.clientId}`}>
                      {x.addressLine1 && (
                        <>
                          {x.addressLine1}
                          {x.addressLine2 && `, ${x.addressLine2}`}
                          {x.city && (
                            <>
                              <br />
                              {x.city}
                              {x.state && `, ${x.state}`}
                            </>
                          )}
                        </>
                      )}
                      {x.postalCode && `, ${x.postalCode}`}{" "}
                    </Link>
                  </Table.Cell>
                  <Table.Cell>
                    <Button.Group size="mini">
                      <Button
                        color="green"
                        floated="right"
                        onClick={() => {
                          approveCompany(x.clientId);
                        }}
                      >
                        <Icon name="check" />
                        {t("approved", { ns: "systemAdmin" })}
                      </Button>
                      <Button
                        color="red"
                        floated="right"
                        onClick={() => {
                          rejectCompany(x.clientId);
                        }}
                      >
                        <Icon name="x" />
                        {t("rejected", { ns: "systemAdmin" })}
                      </Button>
                    </Button.Group>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid>
    </>
  );
});
