import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import PageHeader from "../../app/layout/PageHeader";
import { Button, Icon, Message, Table } from "semantic-ui-react";
import MyPaging from "../../app/common/form/MyPaging";
import { useStore } from "../../app/stores/store";
import { format } from "date-fns";
import { PagingParams } from "../../app/models/pagination";
import {
  CustomerFormValues,
  PotentialCustomer,
} from "../../app/models/customer";
import { TicketWorkflowSteps } from "../../app/models/enums";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { formatPhoneNumber } from "../../app/common/util/functions";

interface Props {
  workflowStep: TicketWorkflowSteps;
  setIsOpen?: (value: boolean) => void;
}

export default observer(function PotentialCustomerMatch({
  workflowStep,
  setIsOpen,
}: Props) {
  const { t } = useTranslation(["common", "translation"]);

  const { customerStore, modalStore, ticketSessionStore, vehicleStore } =
    useStore();

  const {
    selectedCustomer,
    pagingParams,
    isAddressSet,
    isAddressLine1Set,
    isAddressLine2Set,
    isAddressCitySet,
    isAddressStateSet,
    isAddressPostalCodeSet,
    isPhoneNumbersSet,
    setPotentialCustomerToCustomer,
    getPotentialCustomers,
    loadPotentialCustomersList,
    setPagingParams,
    clearPotentialCustomers,
    loadingInitial,
    potentialCustomerPagination,
  } = customerStore;
  const { selectedVehicle } = vehicleStore;

  const {
    setSelectedTicketCustomer,
    updateTicketSession,
    selectedTicketSession,
    setSelectedIsTaxExempt
  } = ticketSessionStore;

  useEffect(() => {
    loadPotentialCustomersList();
  }, [loadPotentialCustomersList]);

  function handleFormSubmit(values: PotentialCustomer) {
    setPotentialCustomerToCustomer(values);
    setSelectedTicketCustomer(selectedCustomer ?? new CustomerFormValues());

    if (selectedTicketSession) setSelectedIsTaxExempt(values.isTaxExempt);

    updateTicketSession(
      workflowStep,
      selectedTicketSession?.id ?? "",
      selectedVehicle,
      selectedCustomer
    );
    if (setIsOpen) setIsOpen(false);
    clearPotentialCustomers();
    modalStore.closeModal();
  }

  function listOnClick(value: number) {
    if (
      value > 0 &&
      potentialCustomerPagination &&
      value < potentialCustomerPagination?.totalPages + 1
    ) {
      setPagingParams(new PagingParams(value));
      loadPotentialCustomersList();
    }
  }

  if (loadingInitial)
    return <LoadingComponent content={t("loading", { ns: "common" })} />;

  return (
    <>
      <PageHeader
        header={`${t("potentialMatches.customerSimilarName", {
          ns: "customer",
        })}...`}
        type={"h1"}
        divider={true}
        getAlerts={true}
        addTitle={false}
      />
      <PageHeader
        header={`${t("potentialMatches.createNewCustomer", {
          ns: "customer",
        })}:`}
        type={"h3"}
        divider={false}
        getAlerts={true}
        addTitle={false}
      />
      <Table basic="very" unstackable>
        <Table.Header>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell>
            {t("potentialMatches.customerDescription", { ns: "customer" })}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {t("address.address", { ns: "common" })}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {t("phonenumber", { ns: "common" })}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {t("potentialMatches.recentTicket", { ns: "customer" })}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {t("tickets", { ns: "customer" })}
          </Table.HeaderCell>
        </Table.Header>
        <Table.Body>
          <Table.Row key={"current"}>
            <Table.Cell>
              <Button
                icon={"plus"}
                color="green"
                size="mini"
                onClick={() => {
                  modalStore.closeModal();
                }}
              />
            </Table.Cell>
            <Table.Cell>
              {selectedCustomer && (
                <>
                  {selectedCustomer.isCommercial &&
                    `${selectedCustomer.companyName}: `}
                  {selectedCustomer.lastName
                    ? `${selectedCustomer.lastName}, `
                    : "--, "}
                  {`${
                    selectedCustomer.firstName
                      ? selectedCustomer.firstName
                      : "--"
                  }`}
                </>
              )}
            </Table.Cell>
            <Table.Cell>
              {selectedCustomer && (
                <>
                  {selectedCustomer && isAddressSet && (
                    <>
                      {isAddressLine1Set && `${selectedCustomer.addressLine1}`}
                      {isAddressLine2Set && `${selectedCustomer.addressLine2}`}
                      {isAddressCitySet && ` ${selectedCustomer.city}`}
                      {`${
                        isAddressStateSet ? `, ${selectedCustomer.state}` : " "
                      } `}
                      {isAddressPostalCodeSet && selectedCustomer.postalCode}
                    </>
                  )}
                </>
              )}
            </Table.Cell>
            <Table.Cell>
              {selectedCustomer && isPhoneNumbersSet && (
                <>{`${selectedCustomer.primaryNumber}`}</>
              )}
            </Table.Cell>
            <Table.Cell>{t("now", { ns: "common" })}</Table.Cell>
            <Table.Cell></Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <PageHeader
        header={`${t("potentialMatches.selectExistingCustomer", {
          ns: "customer",
        })}:`}
        type={"h3"}
        divider={false}
        addTitle={false}
      />
      <Table basic="very" unstackable>
        <Table.Header>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell>
            {t("potentialMatches.customerDescription", { ns: "customer" })}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {t("address.address", { ns: "common" })}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {t("phonenumber", { ns: "common" })}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {t("potentialMatches.recentTicket", { ns: "customer" })}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {t("tickets", { ns: "customer" })}
          </Table.HeaderCell>
        </Table.Header>
        <Table.Body>
          {getPotentialCustomers && getPotentialCustomers.length > 0 ? (
            getPotentialCustomers.map((customer, index) => (
              <Table.Row key={`customer.${customer.id}`}>
                <Table.Cell>
                  <Button
                    icon={"check"}
                    color="blue"
                    size="mini"
                    onClick={() => {
                      handleFormSubmit(customer);
                    }}
                  />
                </Table.Cell>
                <Table.Cell>
                  {customer && (
                    <>
                      {customer.isCommercial && `${customer.companyName}: `}
                      {customer.lastName ? `${customer.lastName}, ` : "--, "}
                      {`${customer.firstName ? customer.firstName : "--"}`}
                    </>
                  )}
                </Table.Cell>
                <Table.Cell>
                  {customer && (
                    <>
                      {customer.addressLine1 && (
                        <>
                          {customer.addressLine1}
                          <br />
                        </>
                      )}
                      {customer.addressLine2 && (
                        <>
                          {customer.addressLine2}
                          <br />
                        </>
                      )}
                      {customer.city && `${customer.city}`}
                      {customer.city && customer.state && ", "}
                      {customer.state && `${customer.state} `}
                      {customer.postalCode && `${customer.postalCode}`}
                    </>
                  )}
                </Table.Cell>
                <Table.Cell>
                  {customer.primaryNumber && <>{`${formatPhoneNumber(customer.primaryNumber)}`}</>}
                </Table.Cell>
                <Table.Cell>
                  {customer?.recentTicket && format(customer.recentTicket, "PP")}
                </Table.Cell>
                <Table.Cell>
                  {customer?.ticketCount && customer.ticketCount}
                </Table.Cell>
              </Table.Row>
            ))
          ) : (
            <Table.Row key={"noItems"}>
              <Table.Cell colSpan={6}>
                <Message
                  color="yellow"
                  className="textAlignCenter fontStyleItalic"
                >
                  {t("grid_noItems", { ns: "common" })}
                </Message>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan="6">
              <MyPaging
                itemCount={
                  potentialCustomerPagination
                    ? potentialCustomerPagination.totalItems
                    : 10
                }
                pageSize={pagingParams.pageSize}
                currentPage={
                  potentialCustomerPagination
                    ? potentialCustomerPagination.currentPage
                    : 1
                }
                onClick={listOnClick}
                onChange={listOnClick}
                floated="right"
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
      <Button
        className="save_button"
        color="green"
        onClick={() => {
          if (setIsOpen) setIsOpen(false);
          clearPotentialCustomers();
          modalStore.closeModal();
        }}
        type="button"
      >
        <Icon name="plus" />
        {t("potentialMatches.continueNewCustomer", { ns: "customer" })}
      </Button>
      <Button
        className="save_button"
        onClick={() => modalStore.closeModal()}
        type="reset"
      >
        <Icon name="x" />
        {t("cancel", { ns: "common" })}
      </Button>
    </>
  );
});
