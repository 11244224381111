import { DropdownItemProps } from "semantic-ui-react";
import { requests } from "./Requests";
import { Vehicle } from "../models/vehicle";
import { PaginatedResult } from "../models/pagination";
import axios, { AxiosResponse } from "axios";
const responseBody = <T>(response: AxiosResponse<T>) => response.data;

export const VehicleApi = {
  yearList: () => requests.get<DropdownItemProps[]>("/vehicle/listyears"),
  yearListWithCustom: () =>
    requests.get<DropdownItemProps[]>("/vehicle/listyearsWithCustom"),
  dsiMakeList: (year: number) =>
    requests.get<DropdownItemProps[]>(`/vehicle/listdsimakes/${year}`),
  dsiMakeListWithCustom: (year: number) =>
    requests.get<DropdownItemProps[]>(
      `/vehicle/listdsimakesWithCustom/${year}`
    ),
  modelList: (year: number, make: number) =>
    requests.get<DropdownItemProps[]>(
      `/vehicle/listmodels?year=${year}&MakeId=${make}`
    ),
  dsiModelList: (year: number, make: string) =>
    requests.get<DropdownItemProps[]>(
      `/vehicle/listdsimodels?year=${year}&make=${make}`
    ),
  dsiModelListWithCustom: (year: number, make: string) =>
    requests.get<DropdownItemProps[]>(
      `/vehicle/listdsimodelsWithCustom?year=${year}&make=${make}`
    ),
  dsiEngineList: (year: number, model: string) =>
    requests.get<DropdownItemProps[]>(
      `/engine/listdsiengineconfigs?year=${year}&model=${model}`
    ),

  dsiEngineListWithCustom: (year: number, make: string, model: string) =>
    requests.get<DropdownItemProps[]>(
      `/engine/listdsiengineconfigsWithCustom?year=${year}&make=${make}&model=${model}`
    ),
  listAllMakeList: () =>
    requests.get<DropdownItemProps[]>(`/Vehicle/listAllDsiMakes/`),
  passwordGeneratedList: (count: number) =>
    requests.get<DropdownItemProps[]>(
      `/vehicle/passwordGeneratedList/${count}`
    ),
  listCustomerVehicle: (id: string, params: URLSearchParams) =>
    axios
    .get<PaginatedResult<Vehicle[]>>(`/Vehicle/listVehicles/${id}`, {
      params,
    })
    .then(responseBody),

  updateCustomerVehicle: (values: Vehicle) =>
    requests.put<void>(
      `/Vehicle/updateVehicle/${values.id}`,
      values
    ),
  removeCustomerVehicle: (id: string | undefined) =>
    requests.put<void>(`/Vehicle/removeVehicle/${id}`, {}),
};
