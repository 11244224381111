import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../../api/agent";
import { PayTypeReport, ReportInput } from "../../models/reports";
import { PagingParams, Pagination } from "../../models/pagination";
import { DateRangeOption, ReportType } from "../../models/enums";
import { sortingStrings } from "../../common/util/functions";

export default class PayTypeReportStore {
  constructor() {
    makeAutoObservable(this);
    reaction(
      () => this.predicate.keys(),
      () => {
        this.payTypeRegistry.clear();
        this.loadPayTypeReport();
      }
    );
  }

  loadingInitial = false;
  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  reportCriteria: ReportInput = new ReportInput({
    dateRange: DateRangeOption.Today,
    startDate: new Date(),
    endDate: new Date(),
    reportType: ReportType.Summary,
    filter: "0",
    useAccrual: false,
  });

  payTypeRegistry = new Map<Date, PayTypeReport>();

  resetPayTypeRegistry = () => {
    this.payTypeRegistry.clear();
  };

  loadPayTypeReport = async () => {
    this.loadingInitial = true;
    try {
      this.payTypeRegistry.clear();
      const result = await agent.Reports.getPayTypeReport(this.axiosParams);
      runInAction(() => {
        result.forEach((payType) => {
          this.setPayTypeReport(payType);
        });
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoadingInitial(false);
    }
  };

  private setPayTypeReport = (payTypeReport: PayTypeReport) => {
    if (payTypeReport.date) payTypeReport.date = new Date(payTypeReport.date);
    this.payTypeRegistry.set(payTypeReport.date ?? new Date(), payTypeReport);
  };

  get payTypeReportByPayType() {
    return Array.from(this.payTypeRegistry.values()).sort((a, b) => {
      return (
        sortingStrings(
          a.payType?.toLowerCase() ?? "",
          b.payType?.toLowerCase() ?? ""
        ) ?? 0
      );
    });
  }

  get groupedByPayType() {
    return Object.entries(
      this.payTypeReportByPayType.reduce((groups, payType) => {
        const name = payType.payType ?? "";
        groups[name] = groups[name] ? [...groups[name], payType] : [payType];
        return groups;
      }, {} as { [key: string]: PayTypeReport[] })
    );
  }

  sortPayTypeGroupByInvoice(data: PayTypeReport[]) {
    return Array.from(data.values()).sort((a, b) => {
      return (a.invoiceNumber ?? 0) - (b.invoiceNumber ?? 0);
    });
  }

  calculatePayType = (data: PayTypeReport[]) => {
    const summary = data.reduce(
      (accumulator: PayTypeReport, item: PayTypeReport) => {
        accumulator.amount += item.amount;

        return accumulator;
      },
      {
        date: undefined,
        amount: 0,
        invoiceNumber: undefined,
        payType: undefined,
        isCommercial: undefined,
        businessName: undefined,
        firstName: undefined,
        lastName: undefined,
        description: undefined,
      }
    );

    return summary;
  };

  pagingParams = new PagingParams();
  pagination: Pagination | null = null;
  predicate = new Map().set("all", true);

  get axiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", this.pagingParams.pageNumber.toString());
    params.append("pageSize", this.pagingParams.pageSize.toString());
    this.predicate.forEach((value, key) => {
      if (key === "StartDate" || key === "EndDate") {
        params.append(key, (value as Date).toISOString());
      } else {
        params.append(key, value);
      }
    });
    return params;
  }

  setPredicate = (
    startDate?: Date,
    endDate?: Date,
    type?: ReportType,
    useAccrual?: boolean
  ) => {
    const resetPredicate = () => {
      this.predicate.forEach((value, key) => {
        if (key !== "startDate") this.predicate.delete(key);
      });
    };
    resetPredicate();
    if (startDate) this.predicate.set("StartDate", startDate);
    if (endDate) this.predicate.set("EndDate", endDate);
    if (type) this.predicate.set("Type", type);
    if (useAccrual) this.predicate.set("useAccrual", useAccrual);
  };

  setReportCriteria = (values: ReportInput) => {
    this.reportCriteria = new ReportInput(values);
  };
}
