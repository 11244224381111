import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../../api/agent";
import { TrialActivityReport } from "../../models/systemAdmin";

export default class TrialActivityStore {
  constructor() {
    makeAutoObservable(this);
    reaction(
      () => {},
      () => {
      }
    );
  }

  loadingInitial = false;
  TrialActivityReportRegistry : TrialActivityReport[] = [];

  //TODO: uncomment out when API isn't blowing chunks!

  loadTrialActivityReport = async () => {
    this.loadingInitial = true;
    try {
    //   const result = await agent.SystemAdmin.getTrialActivityReport(
    //   );
    //   runInAction(() => {
    //    this.TrialActivityReportRegistry = result.sort(
    //     (a, b) => {
    //       return a.daysLeft - b.daysLeft;
    //   });
    //   });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoadingInitial(false);
    }
  };


  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };


}
