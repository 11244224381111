import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Message } from "semantic-ui-react";
import { useStore } from "../../app/stores/store";
import { Link } from "react-router-dom";
import PotentialCustomerMatch from "./PotentialCustomerMatch";
import { TicketWorkflowSteps } from "../../app/models/enums";

interface Props {
  workflowStep: TicketWorkflowSteps;
}

export default observer(function PotentialCustomerMatchingMessage({
  workflowStep,
}: Props) {
  const { t } = useTranslation(["common", "customer"]);

  const { customerStore, modalStore } = useStore();

  const { potentialCustomerRegistry, potentialCustomerPagination } = customerStore;
  const [isOpen, setIsOpen] = useState<boolean>();

  useEffect(() => {
    setIsOpen(potentialCustomerRegistry.size > 0);
  }, [potentialCustomerRegistry.size]);

  return (
    <>
      {isOpen && (
        <Message color="yellow">
          {`${t("potentialMatches.thereAre", { ns: "customer" })} ${
            potentialCustomerPagination?.totalItems
          } ${t("potentialMatches.similarNames", { ns: "customer" })}  `}
          <Link
            onClick={(d) => {
              modalStore.openWidestModal(
                <PotentialCustomerMatch
                  workflowStep={workflowStep}
                  setIsOpen={setIsOpen}
                />
              );
            }}
            to={""}
          >
            {t("potentialMatches.showPotentialMatches", { ns: "customer" })}
          </Link>
        </Message>
      )}
    </>
  );
});
