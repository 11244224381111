import { observer } from "mobx-react-lite";
import { Divider, Grid, Header } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import DeletedCustomer from "./DeletedCustomer";
import DeletedTicket from "./DeletedTicket";
import { useParams } from "react-router-dom";

export default observer(function ClientDataRecovery() {
  const { t } = useTranslation(["common", "companySettings"]);
  const { id } = useParams<{ id: string }>();
  return (
    <Grid>
      <Grid.Row columns={1}>
        <Grid.Column>
          <Header
            as="h1"
            content="Deleted Customers"
            className="modal-text-color"
            textAlign="left"
          />
          <Divider />
          <DeletedCustomer clientId={id ?? ""} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={1}>
        <Grid.Column>
          <Header
            as="h1"
            content="Deleted Tickets"
            className="modal-text-color"
            textAlign="left"
          />
          <Divider />
          <DeletedTicket clientId={id ?? ""} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
});
