import React from "react";
import { Button, Icon, Card, Divider } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../app/stores/store";
import { observer } from "mobx-react-lite";
import CustomerForm from "../../customers/CustomerForm";
import { TicketWorkflowSteps } from "../../../app/models/enums";
import { CustomerFormValues } from "../../../app/models/customer";

interface Props {
  workflowStep: TicketWorkflowSteps;
  disableButtons?: boolean;
  setCustomer?: (value: boolean) => void;
}

export default observer(function CustomerDisplay({
  workflowStep,
  disableButtons,
  setCustomer,
}: Props) {
  const { t } = useTranslation(["common", "ticketSettings"]);
  const { modalStore, customerStore, ticketSessionStore } = useStore();

  const { selectedCustomer } = customerStore;

  const { selectedTicketSession } = ticketSessionStore;

  return (
    <Card fluid key="customer">
      <Card.Content>
        <Card.Header as="h2">{t("customer", { ns: "tickets" })}</Card.Header>
        <Divider />
        <Card.Description>
          {selectedCustomer &&
          (selectedCustomer.companyName ||
            selectedCustomer.firstName ||
            selectedCustomer.lastName) ? (
            <>
              {selectedCustomer.isCommercial && (
                <>
                  {selectedCustomer?.companyName}
                  <br />
                </>
              )}
              {(selectedCustomer.firstName || selectedCustomer.lastName) && (
                <>
                  {`${t("name", {
                    ns: "common",
                  })}: `}

                  {selectedCustomer.lastName && (
                    <>
                      {`${selectedCustomer.lastName}`}
                      {selectedCustomer.firstName && `, `}
                    </>
                  )}
                  {selectedCustomer?.firstName}
                  <br />
                </>
              )}
            </>
          ) : (
            <i>{t("unnamedCustomer", { ns: "tickets" })}</i>
          )}

          {selectedCustomer &&
            (selectedCustomer.addressLine1 ||
              selectedCustomer.addressLine2 ||
              selectedCustomer.city ||
              selectedCustomer.state ||
              selectedCustomer.postalCode) && (
              <>
                <br />
                {selectedCustomer.addressLine1 && (
                  <>
                    {selectedCustomer.addressLine1} <br />
                  </>
                )}
                {selectedCustomer.addressLine2 && (
                  <>
                    {selectedCustomer.addressLine2} <br />
                  </>
                )}
                {selectedCustomer.city && (
                  <>
                    {`${selectedCustomer.city}
                          ${
                            selectedCustomer.state
                              ? `, ${selectedCustomer.state}`
                              : " "
                          } `}
                  </>
                )}
                {selectedCustomer.postalCode && selectedCustomer.postalCode}
              </>
            )}
          {selectedCustomer &&
            (selectedCustomer.primaryNumber ||
              selectedCustomer.secondaryNumber ||
              selectedCustomer.ternaryNumber) && (
              <>
                <br />
                <br />
                {selectedCustomer.primaryNumber && (
                  <>
                    {`${
                      selectedCustomer.isCommercial
                        ? t("main", { ns: "customer" })
                        : t("cell", { ns: "common" })
                    }: ${selectedCustomer.primaryNumber}`}
                    <br />
                  </>
                )}
                {selectedCustomer.secondaryNumber && (
                  <>
                    {`${
                      selectedCustomer.isCommercial
                        ? t("secondary", { ns: "customer" })
                        : t("home", { ns: "common" })
                    }: ${selectedCustomer.secondaryNumber}`}
                    <br />
                  </>
                )}
                {selectedCustomer.ternaryNumber && (
                  <>
                    {`${
                      selectedCustomer.isCommercial
                        ? t("fax", { ns: "common" })
                        : t("work", { ns: "customer" })
                    }: ${selectedCustomer.ternaryNumber}`}
                    <br />
                  </>
                )}
                {selectedCustomer.email && (
                  <>
                    {`${t("pops.email", { ns: "tickets" })}: ${
                      selectedCustomer.email
                    }`}
                    <br />
                  </>
                )}
              </>
            )}
          {!disableButtons && (
            <Button
              floated="right"
              color="grey"
              onClick={() =>
                modalStore.openModal(
                  <CustomerForm
                    isTicket={true}
                    isModal={true}
                    isCustomerSet={setCustomer}
                    previousLocation={""}
                    customerFormValues={
                      selectedCustomer ?? new CustomerFormValues()
                    }
                    updateData={true}
                    ticketWorkflowStep={workflowStep}
                    ticketId={selectedTicketSession?.id ?? ""}
                    showDefaults={true}
                  />
                )
              }
            >
              <Icon name="edit" />
              {t("edit", { ns: "common" })}
            </Button>
          )}
        </Card.Description>
      </Card.Content>
    </Card>
  );
});
