import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Header, Divider, Button } from "semantic-ui-react";
import { useStore } from "../../../app/stores/store";
import ViewUsersEmailLogs from "./ViewUsersEmailLogs";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import UserTable from "./UserTable";
import UpdateUser from "./UpdateUser";
import AuthenticationLog from "../AuthenticationLog";
import AddAdminNotes from "../AddAdminNotes";
import AdminNoteList from "../AdminNotesList";
import SystemAdminChangePassword from "./SystemAdminChangePassword";
import SysAdminSidebar from "../SysAdminSidebar";
import { AccessLevel } from "../../../app/models/enums";
import { PagingParams } from "../../../app/models/pagination";

export default observer(function ViewUser() {
  const { t } = useTranslation(["common", "systemAdmin"]);
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const {
    modalStore,
    userStore,
    vehicleStore,
    userSystemAdminStore,
    authenticationLogStore,
    adminNotesStore,
  } = useStore();
  const { passwordGeneratedList } = vehicleStore;
  const { loadUserDetails, userDetails, resendEmail } = userSystemAdminStore;
  const { checkAccessLevel, requestPasswordReset } = userStore;
  const { resetAuthenticationLogs, clearPredicate } = authenticationLogStore;

  const {
    adminNotesRegistry,
    getAdminNotes,
    loadUserAdminNotes,
    resetAdminNotesRegistry,
    pagination: adminNotesPagination,
    pagingParams: adminNotesPagingParams,
    setPagingParams: setAdminNotesPagingParams,
  } = adminNotesStore;

  useEffect(() => {
    resetAuthenticationLogs();
    clearPredicate();
  }, []);

  useEffect(() => {
    loadUserDetails(id ?? "");
  }, [loadUserDetails, id]);

  useEffect(() => {
    passwordGeneratedList(15);
  }, [passwordGeneratedList]);
  
  useEffect(() => {
    adminNotesRegistry.clear();
  }, []);

  useEffect(() => {
    loadUserAdminNotes(id);
  }, [loadUserAdminNotes, adminNotesRegistry.size, id]);

  function adminNotesListOnClick(value: number) {
    if (
      value > 0 &&
      adminNotesPagination &&
      value < adminNotesPagination?.totalPages + 1
    ) {
      resetAdminNotesRegistry();
      setAdminNotesPagingParams(new PagingParams(value));
      loadUserAdminNotes(id);
    }
  }

  return (
    <Grid stackable>
      <Grid.Row>
        <Grid.Column width={3}>
          <SysAdminSidebar />
        </Grid.Column>
        <Grid.Column width={13}>
          <Grid.Row>
            <Header
              as="h1"
              content={`View User: ${userDetails?.username}`}
              className="modal-text-color"
              textAlign="left"
            />
          </Grid.Row>
          <Divider />
          <Grid.Row>
            <Grid.Column>
              <UserTable />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Button
                floated="left"
                content="Create Note"
                size="mini"
                onClick={() => {
                  modalStore.openModal(
                    <AddAdminNotes
                      clientId={userDetails?.clientId ?? ""}
                      userId={id ?? ""}
                      loadAdminNotes={loadUserAdminNotes}
                    />
                  );
                }}
                type="button"
              />
              {checkAccessLevel(
                AccessLevel.SystemAdministrator | AccessLevel.Manager
              ) && (
                <Button
                  floated="left"
                  content="Edit"
                  size="mini"
                  onClick={() => {
                    modalStore.openModal(<UpdateUser userId={id ?? ""} />);
                  }}
                  type="button"
                />
              )}
              <Button
                floated="left"
                content={
                  !userDetails?.email || userDetails?.email === ""
                    ? "Set Email to Proceed"
                    : "Send Password Reset Email"
                }
                size="mini"
                disabled={!userDetails?.email || userDetails?.email === ""}
                onClick={() => {
                  requestPasswordReset({
                    emailAddress: userDetails?.email,
                  }).then(() => {
                    resetAdminNotesRegistry();
                    loadUserAdminNotes(id);
                    toast.success("Password Reset Email Sent!");
                  });
                }}
              />
              {checkAccessLevel(
                AccessLevel.SystemAdministrator | AccessLevel.Manager
              ) && (
                <Button
                  floated="left"
                  content="Change Password"
                  size="mini"
                  onClick={() => {
                    modalStore.openModal(
                      <SystemAdminChangePassword userId={id ?? ""} />
                    );
                  }}
                  type="button"
                />
              )}
              {!userDetails?.emailVerified && (
                <Button
                  floated="left"
                  content="Resend Email Verification"
                  size="mini"
                  onClick={() => {
                    resendEmail(id ?? "")
                      .then(() => {
                        resetAdminNotesRegistry();
                        loadUserAdminNotes(id);
                        toast.success("Email Verification Resent");
                      })
                      .catch((error) =>
                        toast.error("Error resending email verification")
                      );
                  }}
                  type="button"
                />
              )}
            </Grid.Column>
          </Grid.Row>
          <Divider />
          <Grid.Row columns={16}>
            <Header
              as="h2"
              content={t("logs.notes_intro", { ns: "systemAdmin" })}
              className="modal-text-color"
              textAlign="left"
            />
            <Grid.Row>
              <Grid.Column>
                <AdminNoteList
                  adminNotes={getAdminNotes}
                  pagination={adminNotesPagination}
                  pagingParams={adminNotesPagingParams}
                  listOnClick={adminNotesListOnClick}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid.Row>
          <Divider />
          <Grid.Row columns={16}>
            <Header
              as="h2"
              content={t("logs.auth_logs_intro", { ns: "systemAdmin" })}
              className="modal-text-color"
              textAlign="left"
            />
            <Grid.Row>
              <Grid.Column>
                <AuthenticationLog userId={id} />
              </Grid.Column>
            </Grid.Row>
          </Grid.Row>
          <Divider />
          <Grid.Row columns={16}>
            <Header
              as="h2"
              content={t("logs.email_logs_intro", { ns: "systemAdmin" })}
              className="modal-text-color"
              textAlign="left"
            />
            <Grid.Row>
              <Grid.Column>
                <ViewUsersEmailLogs
                  clientId={userDetails?.clientId ?? ""}
                  userId={id ?? ""}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid.Row>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
});
