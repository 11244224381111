import React, { useEffect } from "react";
import { Container } from "semantic-ui-react";
import NavBar from "./NavBar";
import { observer } from "mobx-react-lite";
import { Outlet, ScrollRestoration, useLocation } from "react-router-dom";
import HomePage from "../../features/home/HomePage";
import { ToastContainer } from "react-toastify";
import { useStore } from "../stores/store";
import LoadingComponent from "./LoadingComponent";
import ModalContainer from "../common/modals/ModalContainer";
import { useTranslation } from "react-i18next";
import "../../app/common/i18n/i18n.ts";
import Footer from "./Footer";
import { ReCaptchaInstance } from "../common/reCaptcha/reCaptcha";

declare global {
  interface Window {
    grecaptcha: ReCaptchaInstance;
    captchaOnLoad: () => void;
  }
}

function App() {
  const location = useLocation();
  const { commonStore, userStore } = useStore();
  const { t } = useTranslation(["common"]);

  useEffect(() => {
    if (commonStore.token) {
      userStore.getUser().finally(() => commonStore.setAppLoaded());
    } else {
      //   userStore.getFacebookLoginStatus().then(() =>
      commonStore.setAppLoaded();
    }
  }, [commonStore, userStore]);

  if (!commonStore.appLoaded)
    return <LoadingComponent content={t("loading", { ns: "common" })} />;

  return (
    <>
      <ScrollRestoration />
      <ModalContainer />
      <ToastContainer position="bottom-right" hideProgressBar />

      {location.pathname === "/" ? (
        <>
          <NavBar />
          <HomePage />
          <Footer />
        </>
      ) : location.pathname.startsWith("/ticket/pdf/") ||
        location.pathname.startsWith("/reports/pdf/") ? (
        <Outlet />
      ) : (
        <>
          <NavBar />
          <Container className="margins">
            <Outlet />
          </Container>
          <Footer />
        </>
      )}
    </>
  );
}

export default observer(App);
