import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../app/stores/store";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { TicketItemType, TicketType } from "../../../app/models/enums";
import { Link, useNavigate, useParams } from "react-router-dom";
import { VehicleFormValues } from "../../../app/models/vehicle";
import { CustomerFormValues } from "../../../app/models/customer";
import { TicketItem, TicketSession } from "../../../app/models/ticket";

import PageHeader from "../../../app/layout/PageHeader";
import { Button, Divider, Grid, Table } from "semantic-ui-react";
import { format } from "date-fns";
import {
  formatCurrency,
  formatCurrencyDecimal,
  formatDecimal,
} from "../../../app/common/util/functions";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import PdfObjectViewer from "../../../app/common/form/PdfObjectViewer";
import TicketPdfDocument from "./TicketPdfDocument";
import TicketPaymentHistory from "./TicketPaymentHistory";
import { resetTicket } from "../../../app/common/util/ticketfunctions";

interface Props {
  isPrintView: boolean;
}

export default observer(function TicketPdfView({ isPrintView }: Props) {
  const { t } = useTranslation(["common", "tickets"]);
  const navigate = useNavigate();

  const {
    ticketSessionStore,
    ticketStore,
    clientStore,
    vehicleStore,
    customerStore,
    pricingStore,
    disclaimerStore,
    vendorStore,
    technicianStore,
  } = useStore();

  const {
    loadTicket,
    setSelectedTicketSession,
    setSelectedPONumber,
    setBatchTicketItems,
    setBatchTicketPaymentItems,
    populateJobLabors,
    populateLaborMap,
    loadingInitial,
    selectedTicketSession,
    calculateTotals,
    sortedSelectedTicketTicketItems,
  } = ticketSessionStore;

  const {
    loadDropdownPayTypeList,
    dropdownPayTypesList,
    loadSettingsPayType,
    getPayType,
  } = pricingStore;
  const { setSelectedVehicle } =
    vehicleStore;
  const { setSelectedCustomer } = customerStore;
  const { loadSettingDisclaimer, selectedSettingsDisclaimer } = disclaimerStore;
  const {
    selectedSettingsTicketFormatting,
    ticketHistory,
    loadTicketHistoryItems,
    loadSettingsTicketFormatting,
    loadSettingsTicketInputDefaults,
    selectedSettingsTicketInputDefaults,
  } = ticketStore;
  const { loadVendors, getVendor } = vendorStore;
  const { selectedSettingsTechnician, loadSettingsTechnician } =
    technicianStore;
    const { id } = useParams<{ id: string }>();

  useEffect(() => {
    loadSettingsTicketFormatting();
  }, [loadSettingsTicketFormatting]);

  useEffect(() => {
    loadSettingsTicketInputDefaults();
  }, [loadSettingsTicketInputDefaults]);

  useEffect(() => {
    loadSettingsTechnician();
  }, [loadSettingsTechnician]);

  useEffect(() => {
    loadDropdownPayTypeList();
  }, [loadDropdownPayTypeList]);

  useEffect(() => {
    loadSettingDisclaimer();
  }, [loadSettingDisclaimer]);

  useEffect(() => {
    loadSettingsPayType(true);
  }, [loadSettingsPayType]);

  useEffect(() => {
    loadVendors();
  }, [loadVendors]);

  useEffect(() => {
    loadTicketHistoryItems(id ?? "");
  }, [loadTicketHistoryItems, id]);

  const [paymentMethod, setPaymentMethod] = useState<string>();
  const [totalPayments, setTotalPayments] = useState<number>(0.0);

  const [ticketLoaded, setTicketLoaded] = useState<boolean>(false);

  useEffect(() => {
    resetTicket();

    if (id) {
      setTicketLoaded(true);

      loadTicket(id)
        .then((result) => {
          if (result) {
            setSelectedVehicle(new VehicleFormValues(result.vehicle));
            setSelectedCustomer(new CustomerFormValues(result.customer));
            setSelectedPONumber(result.poNumber);

            setSelectedTicketSession(new TicketSession(result));

            if (result.jobLabors && result.jobLabors.length > 0) {
              populateJobLabors(result.jobLabors);
            }

            if (result.ticketItems && result.ticketItems.length > 0) {
              setBatchTicketItems(result.ticketItems);
              let parts = result.ticketItems.filter((x) => {
                return (
                  x.type === TicketItemType.Parts && x.vendorId !== undefined
                );
              });
              setPartsList(parts);
            }

            setTotalPayments(result.paymentReceived);
            //need this due to the history is being displayed off of it
            if (result.ticketPayments && result.ticketItems.length > 0) {
              setBatchTicketPaymentItems(result.ticketPayments);
            }

            if (result.ticketType === TicketType.Estimate) {
              let payMethod = dropdownPayTypesList.find((x) => {
                return x.value === result?.estimatePaymentMethod;
              });
              if (payMethod?.text) setPaymentMethod(payMethod.text.toString());
            } else {
              let ticketPaymentItem = result.ticketPayments.find((x) => {
                return (x.amount ?? 0) > 0;
              });

              if (ticketPaymentItem) {
                let payMethod = getPayType(
                  ticketPaymentItem?.paymentTypeId ?? ""
                );
                if (payMethod?.isStandard) {
                  if (payMethod.name === "Cash" || payMethod.name === "Check") {
                    setPaymentMethod(payMethod.name);
                  } else if (/Charge/.exec(payMethod.name)) {
                    setPaymentMethod("Charge");
                  }
                }
              }
            }

            if (result.labors && result.labors.length > 0) {
              populateLaborMap(result.labors);
            }
            setHeader(
              `${
                isPrintView
                  ? t("estimate.print", { ns: "tickets" })
                  : t("view", { ns: "common" })
              } ${t(
                `estimate.${
                  result.ticketType === TicketType.Estimate ? "est" : "invoice"
                }`,
                { ns: "tickets" }
              )} #${
                result.ticketType === TicketType.Estimate
                  ? result.estimateNumber
                  : result.invoiceNumber
              }`
            );
          }
          setTicketLoaded(false);
        })
        .finally(() => setTicketLoaded(false));
    }
  }, [loadTicket, id, isPrintView, setSelectedTicketSession]);

  const { loadLogo, loadClientDetails } = clientStore;

  useEffect(() => {
    loadLogo();
  }, [loadLogo]);

  useEffect(() => {
    loadClientDetails();
  }, [loadClientDetails]);

  useEffect(() => {
    calculateTotals();
  }, [selectedTicketSession, selectedTicketSession?.ticketPayments, calculateTotals]);

  const [header, setHeader] = useState<string>();

  const [partsList, setPartsList] = useState<TicketItem[]>();

  function getVendorName(vendorId: string) {
    let vendor = getVendor(vendorId);

    return vendor ? vendor.name : t("unassigned", { ns: "tickets" });
  }



  if (loadingInitial && ticketLoaded)
    return <LoadingComponent content={t("loading", { ns: "common" })} />;

  return (
    <>
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column>
            <PageHeader
              header={header ?? ""}
              type={"h1"}
              divider={false}
              getAlerts={true}
              addTitle={true}
            />
          </Grid.Column>
          <Grid.Column textAlign="right">
            <Button
              icon="folder open"
              type="button"
              content={t("open", { ns: "tickets" })}
              target="_blank"
              to={`/ticket/pdf/view/${id}`}
              as={Link}
            />
            {selectedTicketSession?.ticketType === TicketType.Invoice &&
              selectedSettingsTechnician.technicianTrackingEnabled && (
                <Button
                  color="grey"
                  icon="time"
                  type="button"
                  content={t("estimate.techTracking", { ns: "tickets" })}
                  onClick={() => navigate(`/ticket/techTracking/${id}`)}
                />
              )}
            {selectedTicketSession?.ticketType === TicketType.Estimate && (
              <Button
                color="grey"
                icon="wrench"
                type="button"
                content={t("repair_order", { ns: "tickets" })}
                target="_blank"
                to={`/ticket/pdf/repairOrder/${id}`}
                as={Link}
              />
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Divider />

      <PDFDownloadLink
        document={
          <TicketPdfDocument
            selectedTicketSession={selectedTicketSession ?? new TicketSession()}
            paymentMethod={paymentMethod ?? ""}
            selectedSettingsTicketFormatting={selectedSettingsTicketFormatting}
            selectedSettingsTicketInputDefaults={
              selectedSettingsTicketInputDefaults
            }
            selectedSettingsDisclaimer={selectedSettingsDisclaimer}
            totalPayments={totalPayments}
            ticketItems={sortedSelectedTicketTicketItems ?? []}
          />
        }
        fileName={`${header}.pdf`}
      >
        {({ url, loading }) => (loading ? "loading" : "Download PDF")}
      </PDFDownloadLink>

      <PdfObjectViewer
        document={
          <TicketPdfDocument
            selectedTicketSession={selectedTicketSession ?? new TicketSession()}
            paymentMethod={paymentMethod ?? ""}
            selectedSettingsTicketFormatting={selectedSettingsTicketFormatting}
            selectedSettingsTicketInputDefaults={
              selectedSettingsTicketInputDefaults
            }
            selectedSettingsDisclaimer={selectedSettingsDisclaimer}
            totalPayments={totalPayments}
            ticketItems={sortedSelectedTicketTicketItems ?? []}
          />
        }
      ></PdfObjectViewer>
      <TicketPaymentHistory ticketId={id ?? ""} />
      {!isPrintView && partsList && partsList.length > 0 && (
        <>
          <PageHeader
            header={t("partDetails", { ns: "tickets" })}
            type={"h2"}
            divider={false}
            addTitle={false}
          />
          <Table basic="very" striped unstackable className="dnxTable">
            <Table.Header>
              <Table.HeaderCell>
                {t("pops.vendor", { ns: "tickets" })}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {t("partNo", { ns: "tickets" })}.
              </Table.HeaderCell>
              <Table.HeaderCell>
                {t("description", { ns: "common" })}
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="right">
                {t("estimate.unit_cost", { ns: "tickets" })}
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="right">
                {t("pops.mark_up", { ns: "tickets" })}
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="right">
                {t("pops.qty", { ns: "tickets" })}
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="right">
                {t("estimate.price", { ns: "tickets" })}
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="right">
                {t("total", { ns: "common" })}
              </Table.HeaderCell>
            </Table.Header>
            <Table.Body>
              {partsList.map((part) => (
                <Table.Row key={`ticketPayment.${part.id}`}>
                  <Table.Cell
                    data-label={`${t("pops.vendor", { ns: "tickets" })}:`}
                  >
                    {getVendorName(part.vendorId ?? "")}
                  </Table.Cell>
                  <Table.Cell data-label={`${t("partNo", { ns: "tickets" })}:`}>
                    {part.partCode}
                  </Table.Cell>
                  <Table.Cell
                    data-label={`${t("description", { ns: "common" })}:`}
                  >
                    {part.description}
                  </Table.Cell>
                  <Table.Cell
                    textAlign="right"
                    data-label={`${t("estimate.unit_cost", {
                      ns: "tickets",
                    })}:`}
                  >
                    {formatCurrency(part.unitPrice ?? 0)}
                  </Table.Cell>
                  <Table.Cell
                    textAlign="right"
                    data-label={`${t("pops.mark_up", { ns: "tickets" })}:`}
                  >
                    {formatDecimal(part.partMarkup ?? 0, 2)}%
                  </Table.Cell>
                  <Table.Cell
                    textAlign="right"
                    data-label={`${t("pops.qty", { ns: "tickets" })}:`}
                  >
                    {formatDecimal(part.quantity ?? 0, 2)}
                  </Table.Cell>
                  <Table.Cell
                    textAlign="right"
                    data-label={`${t("estimate.price", { ns: "tickets" })}:`}
                  >
                    {formatCurrencyDecimal(part.rate ?? 0, 3)}
                  </Table.Cell>
                  <Table.Cell
                    textAlign="right"
                    data-label={`${t("total", { ns: "common" })}:`}
                  >
                    {formatCurrency(part.subTotal ?? 0)}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </>
      )}
      {!isPrintView && ticketHistory && ticketHistory.length > 0 && (
        <>
          <PageHeader
            header={t("ticketHist", { ns: "tickets" })}
            type={"h2"}
            divider={false}
            addTitle={false}
          />
          <Table striped unstackable className="dnxTable" basic="very">
            <Table.Header>
              <Table.HeaderCell>
                {t("estimate.date", { ns: "tickets" })}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {t("estimate.staff", { ns: "tickets" })}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {t("estimate.action", { ns: "tickets" })}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {t("estimate.description", { ns: "tickets" })}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {t("estimate.type", { ns: "tickets" })}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {t("estimate.part_no", { ns: "tickets" })}
              </Table.HeaderCell>
            </Table.Header>
            <Table.Body>
              {ticketHistory.map((item, index) => (
                <Table.Row key={`ticketHistory.${item.id}`}>
                  <Table.Cell
                    data-label={`${t("estimate.date", { ns: "tickets" })}:`}
                  >
                    {item.createdDate && format(item.createdDate, "Pp")}
                  </Table.Cell>
                  <Table.Cell
                    data-label={`${t("estimate.staff", { ns: "tickets" })}:`}
                  >
                    {item.staffName}
                  </Table.Cell>
                  <Table.Cell
                    data-label={`${t("estimate.action", { ns: "tickets" })}:`}
                  >
                    {item.action}
                  </Table.Cell>
                  <Table.Cell
                    data-label={`${t("estimate.description", {
                      ns: "tickets",
                    })}:`}
                  >
                    {item.description}
                  </Table.Cell>
                  <Table.Cell
                    data-label={`${t("estimate.type", { ns: "tickets" })}:`}
                  >
                    {item.ticketItemType}
                  </Table.Cell>
                  <Table.Cell
                    data-label={`${t("estimate.part_no", { ns: "tickets" })}:`}
                  >
                    {item.partCode}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </>
      )}
    </>
  );
});
