import { format } from "date-fns";
import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../../api/agent";
import { ReportInput, TaxReport } from "../../models/reports";
import { PagingParams, Pagination } from "../../models/pagination";
import { DateRangeOption, ReportType } from "../../models/enums";

export default class TaxReportStore {
  constructor() {
    makeAutoObservable(this);
    reaction(
      () => this.predicate.keys(),
      () => {
        this.taxReportRegistry.clear();
        this.loadTaxReport();
      }
    );
  }

  loadingInitial = false;
  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  reportCriteria: ReportInput = new ReportInput({
    dateRange: DateRangeOption.Today,
    startDate: new Date(),
    endDate: new Date(),
    reportType: ReportType.Summary,
    filter: "0",
  });

  taxReportRegistry = new Map<Date, TaxReport>();

  resetTaxReportRegistry = () => {
    this.taxReportRegistry.clear();
  };

  loadTaxReport = async () => {
    this.loadingInitial = true;
    try {
      this.taxReportRegistry.clear();
      const result = await agent.Reports.getTaxReport(this.axiosParams);
      runInAction(() => {
        result.forEach((taxReport) => {
          this.setTaxReport(taxReport);
        });
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoadingInitial(false);
    }
  };

  private setTaxReport = (taxReport: TaxReport) => {
    taxReport.date = new Date(taxReport.date);
    this.taxReportRegistry.set(taxReport.date, taxReport);
  };

  get getTaxReport() {
    return Array.from(this.taxReportRegistry.values()).sort((a, b) => {
      return a.date.getTime() - b.date.getTime();
    });
  }

  get groupedTaxReportByDate() {
    return Object.entries(
      this.getTaxReport.reduce((groups, taxes) => {

        groups[format(new Date(taxes.date), "MMMM Y")] = groups[
          format(new Date(taxes.date), "MMMM Y")
        ]
          ? [...groups[format(new Date(taxes.date), "MMMM Y")], taxes]
          : [taxes];
        return groups;
      }, {} as { [key: string]: TaxReport[] })
    );
  }

  pagingParams = new PagingParams();
  pagination: Pagination | null = null;
  predicate = new Map().set("all", true);

  get axiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", this.pagingParams.pageNumber.toString());
    params.append("pageSize", this.pagingParams.pageSize.toString());
    this.predicate.forEach((value, key) => {
      if (key === "StartDate" || key === "EndDate") {
        params.append(key, (value as Date).toISOString());
      } else {
        params.append(key, value);
      }
    });
    return params;
  }

  setPredicate = (startDate?: Date, endDate?: Date, type?: ReportType) => {
    const resetPredicate = () => {
      this.predicate.forEach((value, key) => {
        if (key !== "startDate") this.predicate.delete(key);
      });
    };
    resetPredicate();
    if (startDate) this.predicate.set("StartDate", startDate);
    if (endDate) this.predicate.set("EndDate", endDate);
    if (type) this.predicate.set("Type", type);
  };

  setReportCriteria = (values: ReportInput) => {
    this.reportCriteria = new ReportInput(values);
  };

  calculateTax = (data: TaxReport[]) => {
    const summary = data.reduce(
      (accumulator: TaxReport, item: TaxReport) => {
        accumulator.totalLabor += item.totalLabor;
        accumulator.totalParts += item.totalParts;
        accumulator.totalMisc += item.totalMisc;
        accumulator.grossSales += item.grossSales;
        accumulator.nonTaxable += item.nonTaxable;
        accumulator.taxable += item.taxable;
        accumulator.totalTax += item.totalTax;

        return accumulator;
      },
      {
        date: new Date(),
        totalLabor: 0,
        totalParts: 0,
        totalMisc: 0,
        totalTax: 0,
        grossSales: 0,
        nonTaxable: 0,
        taxable: 0,
      }
    );

    return summary;
  };
}
