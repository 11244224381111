import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Container,
  Form,
  Grid,
  Message,
  Table,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import MyTextInput from "../../app/common/form/MyTextInput";
import CustomerSearch from "./CustomerSearch";
import PageHeader from "../../app/layout/PageHeader";
import { useStore } from "../../app/stores/store";
import { CustomerSearchValues } from "../../app/models/customer";
import LoadingComponent from "../../app/layout/LoadingComponent";
import MyPaging from "../../app/common/form/MyPaging";
import { PagingParams } from "../../app/models/pagination";
import CustomerVehicleSelection from "../tickets/recentTickets/CustomerVehicleSelection";

export default observer(function CustomerIndex() {
  const { t } = useTranslation(["common", "customer"]);
  const { customerStore, ticketStore, modalStore } = useStore();
  const {
    loadCustomers,
    setSelectedCustomer,
    loadingInitial,
    pagingParams,
    pagination,
    setPagingParams,
    getCustomers,
    clearCustomerRegistry,
    setPredicate,
    resetFilter,
  } = customerStore;
  const {
    loadSettingsTicketInputDefaults,
    selectedSettingsTicketInputDefaults,
  } = ticketStore;

  useEffect(() => {
    resetFilter();
    clearCustomerRegistry();
  }, []);

  useEffect(() => {
    loadCustomers();
  }, [loadCustomers]);

  useEffect(() => {
    loadSettingsTicketInputDefaults();
  }, [loadSettingsTicketInputDefaults]);

  let resetFilterValues: CustomerSearchValues = {
    phoneNumber: undefined,
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    address: undefined,
    city: undefined,
    postalCode: undefined,
    state: undefined,
    companyName: undefined,
  };

  const [customerSearchValues, setCustomerSearchValues] =
    useState<CustomerSearchValues>(resetFilterValues);

  const [active, setActive] = useState(false);

  const panels = [
    {
      key: "details",
      title: t("advancedSearch", { ns: "customer" }),
      content: {
        content: <CustomerSearch />,
      },
      active: active,
    },
  ];

  function handleFormSubmit(values: CustomerSearchValues) {
    if (values.address) setPredicate("address", values.address);
    if (values.phoneNumber) setPredicate("phoneNumber", values.phoneNumber);
    if (values.firstName) setPredicate("firstName", values.firstName);
    if (values.lastName) setPredicate("lastName", values.lastName);
    if (values.email) setPredicate("email", values.email);
    if (values.city) setPredicate("city", values.city);
    if (values.postalCode) setPredicate("postalCode", values.postalCode);
    if (values.state) setPredicate("state", values.state);
    if (values.companyName) setPredicate("companyName", values.companyName);
    setCustomerSearchValues(values);
  }

  function listOnClick(value: number) {
    if (value > 0 && pagination && value < pagination?.totalPages + 1) {
      setPagingParams(new PagingParams(value));
      clearCustomerRegistry();
      loadCustomers();
    }
  }

  if (loadingInitial)
    return (
      <LoadingComponent
        content={t("customerIndex_loading", { ns: "customer" })}
      />
    );

  return (
    <>
      <PageHeader
        header={t("customerindex", { ns: "common" })}
        type={"h1"}
        divider={true}
        getAlerts={true}
        addTitle={true}
      />
      <Container className="page">
        <Formik
          initialValues={customerSearchValues}
          onSubmit={(values) => handleFormSubmit(values)}
        >
          {({ handleSubmit, values }) => (
            <Form
              className="ui form error"
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <Grid stackable columns={3}>
                <Grid.Column>
                  <MyTextInput
                    placeholder={t("phonenumber", { ns: "common" })}
                    name="phoneNumber"
                    label={`${t("phonenumber", { ns: "common" })}: `}
                    autoCapitalizeFirstLetter={false}
                    maxLength={25}
                  />
                </Grid.Column>
                <Grid.Column>
                  <MyTextInput
                    placeholder={t("first_name", { ns: "common" })}
                    name="firstName"
                    label={`${t("first_name", { ns: "common" })}: `}
                    autoCapitalizeFirstLetter={
                      selectedSettingsTicketInputDefaults.capitalizeFirstCharacter
                    }
                    maxLength={255}
                  />
                </Grid.Column>
                <Grid.Column>
                  <MyTextInput
                    placeholder={t("last_name", { ns: "common" })}
                    name="lastName"
                    label={`${t("last_name", { ns: "common" })}: `}
                    autoCapitalizeFirstLetter={
                      selectedSettingsTicketInputDefaults.capitalizeFirstCharacter
                    }
                    maxLength={255}
                  />
                </Grid.Column>
              </Grid>
              <Accordion
                as={Form.Field}
                panels={panels}
                onClick={() => setActive(true)}
              />
              <Button
                color="green"
                className="save_button"
                icon="filter"
                content={t("buttons.search", { ns: "customer" })}
                type="submit"
                onClick={() => setActive(true)}
              />
              <Button
                color="grey"
                className="save_button"
                content={t("removeSearch", { ns: "customer" })}
                type="button"
                onClick={() => {
                  resetFilter();
                  setCustomerSearchValues(resetFilterValues);
                  setActive(false);
                }}
              />
            </Form>
          )}
        </Formik>
        <Table striped unstackable className="dnxTable">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                {`${t("customername", { ns: "common" })}`}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {`${t("cusotmeraddress", { ns: "customer" })}`}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {`${t("contactinfo", { ns: "customer" })}`}
              </Table.HeaderCell>
              <Table.HeaderCell content={" "} />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {getCustomers && getCustomers.length > 0 ? (
              getCustomers.map((customer) => (
                <Table.Row key={customer.id}>
                  <Table.Cell
                    data-label={`${t("name", { ns: "common" })}:`}
                  >
                    {customer.companyName && customer.isCommercial && (
                      <>
                        {customer.companyName}
                        <br />
                      </>
                    )}
                    {`${customer.lastName}`}
                    {customer.firstName && `, ${customer.firstName}`}
                  </Table.Cell>
                  <Table.Cell
                    data-label={`${t("customeraddressMobile", { ns: "customer" })}:`}
                  >
                    {customer.addressLine1 && (
                      <>
                        {customer.addressLine1}
                        <br />
                      </>
                    )}
                    {customer.addressLine2 && (
                      <>
                        {customer.addressLine2}
                        <br />
                      </>
                    )}
                    {customer.city && `${customer.city}`}
                    {customer.city && customer.state && ", "}
                    {customer.state && `${customer.state} `}
                    {customer.postalCode && `${customer.postalCode}`}
                  </Table.Cell>
                  <Table.Cell
                    data-label={`${t("contactinfoMobile", { ns: "customer" })}:`}
                  >
                    {customer.primaryNumber && (
                      <>
                        {customer.isCommercial ? "P: " : "C: "}
                        {customer.primaryNumber}
                        <br />
                      </>
                    )}
                    {customer.secondaryNumber && (
                      <>
                        {customer.isCommercial ? "A: " : "H: "}
                        {customer.secondaryNumber}
                        <br />
                      </>
                    )}
                    {customer.ternaryNumber && (
                      <>
                        {customer.isCommercial ? "F: " : "W: "}
                        {customer.ternaryNumber}
                        <br />
                      </>
                    )}
                    {customer.email && `E: ${customer.email}`}
                  </Table.Cell>
                  <Table.Cell className="textAlignRight">
                        <Button
                          size="small"
                          as={Link}
                          to={`/customer/${customer.id}`}
                          icon="address card"
                          type="button"
                          content={t("details", { ns: "common" })}
                          onClick={() => setSelectedCustomer(customer)}
                        />
                        <Button
                          icon="plus"
                          size="small"
                          type="button"
                          color="green"
                          content={t("ticket_create", { ns: "common" })}
                          onClick={() => {
                            setSelectedCustomer(customer);
                            modalStore.openModal(
                              <CustomerVehicleSelection
                                customerId={customer?.id ?? ""}
                                isCommercial={false}
                                businessName={customer?.companyName}
                                lastName={customer?.lastName}
                                firstName={customer?.firstName}
                              />
                            );
                          }}
                        />
                  </Table.Cell>
                </Table.Row>
              ))
            ) : (
              <Table.Row key={"noItems"}>
                <Table.Cell width="8" colSpan={8}>
                  <Message
                    color="yellow"
                    className="textAlignCenter fontStyleItalic"
                  >
                    {t("grid_noItems", { ns: "common" })}
                  </Message>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.Cell colSpan="6">
                <MyPaging
                  itemCount={pagination ? pagination.totalItems : 10}
                  pageSize={pagingParams.pageSize}
                  currentPage={pagination ? pagination.currentPage : 1}
                  onClick={listOnClick}
                  onChange={listOnClick}
                  floated="right"
                ></MyPaging>
              </Table.Cell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </Container>
    </>
  );
});
