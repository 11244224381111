import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Button, Form, Label, Radio } from "semantic-ui-react";
import PageHeader from "../../../app/layout/PageHeader";
import MyDateInput from "../../../app/common/form/MyDateInput";
import MyTextArea from "../../../app/common/form/MyTextArea";
import MySelectInput from "../../../app/common/form/MySelectInput";
import { colorOptions, yesNoOptions } from "../../../app/common/options/option";
import { useStore } from "../../../app/stores/store";
import { Formik, FormikErrors } from "formik";
import * as Yup from "yup";
import { SystemAdminAnnouncement } from "../../../app/models/admin";
import { useNavigate, useParams } from "react-router-dom";

export default observer(function UpdateAnnouncement() {
  const navigate = useNavigate();
  const { announcementStore } = useStore();
  const {
    loadAnnouncement,
    updateAnnouncement,
    loadingInitial,
    announcement,
    createAnnouncement,
  } = announcementStore;

  const [myAnnouncement, setMyAnnouncement] = useState<SystemAdminAnnouncement>(
    new SystemAdminAnnouncement(announcement)
  );

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    loadValues();
  }, [loadAnnouncement]);

  function loadValues() {
    if (!isNaN(parseInt(id ?? ""))) {
      loadAnnouncement(parseInt(id ?? "")).then(() =>
        setMyAnnouncement(new SystemAdminAnnouncement(announcement))
      );
    }
  }

  function handleFormSubmit(
    values: SystemAdminAnnouncement,
    setErrors: (errors: FormikErrors<SystemAdminAnnouncement>) => void
  ) {
    const action = values.id
      ? () => updateAnnouncement(values)
      : () => createAnnouncement(values);

    action()
      .then(() => {
        if (id) navigate(`/admin/ViewAnnouncement/${id}`);
        else navigate(`/admin/manageAnnouncements`);
      })
      .catch((error) => {
        console.log(error);
        //setErrors({ error: error });
      });
  }

  const validationSchema = Yup.object({
    isActive: Yup.boolean().required("Active state is required."),
    color: Yup.string().required("Message Color is required."),
  });

  return (
    <>
      <PageHeader
        header="Add or Update Announcement"
        type={"h1"}
        divider={true}
        addTitle={false}
      />

      <Formik
        validationSchema={validationSchema}
        enableReinitialize
        initialValues={myAnnouncement}
        onSubmit={(values, { setErrors }) =>
          handleFormSubmit(values, setErrors)
        }
      >
        {({
          handleSubmit,
          isValid,
          isSubmitting,
          dirty,
          handleBlur,
          handleChange,
          errors,
          values,
          touched,
          setFieldValue,
        }) => (
          <Form className="ui form error" autoComplete="off" onSubmit={handleSubmit}>
            {/* <MySelectInput
            label="Area: "
              placeholder={"Area"}
              name={"announcementType"}
              options={areaOption}
            /> */}
            <label className="required">Is Active?</label>
            <Form.Group inline>
              {yesNoOptions.map((data, index) => (
                <Form.Field key={`isActive[${data.value}]`}>
                  <Radio
                    id={`isActive[${index}]`}
                    key={`isActive[${data.value}]`}
                    value={data.value}
                    name="isActive"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label={data.text}
                    checked={
                      values.isActive.toString() === data.value.toString()
                    }
                  />
                </Form.Field>
              ))}
              {touched.isActive && errors.isActive ? (
                <Label basic color="red">
                  {errors.isActive}
                </Label>
              ) : null}
            </Form.Group>

            <MyDateInput label={"Start Date: "} name={"startDate"} />
            <MyDateInput label={"End Date: "} name={"endDate"} />
            <MySelectInput
              label={"Color Options: "}
              placeholder={"Type"}
              name={"color"}
              options={colorOptions}
              clearable={true}
            />
            <MyTextArea
              label={"Header: "}
              placeholder={"Header"}
              name={"header"}
              rows={2}
              maxLength={255}
            />
            <MyTextArea
              label={"Body: "}
              placeholder={"Body"}
              name={"body"}
              rows={5}
              maxLength={2000}
            />
            <Button
              className="save_button"
              icon="check"
              disabled={isSubmitting || !dirty || !isValid}
              loading={isSubmitting}
              content={"Submit"}
              color={"green"}
              type="submit"
            />
            <Button
              icon="cancel"
              className="save_button"
              type="reset"
              content={"Cancel"}
              onClick={() => navigate(`/admin/ManageAnnouncements/`)}
            />
          </Form>
        )}
      </Formik>
    </>
  );
});
