import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Container, Grid } from "semantic-ui-react";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { useStore } from "../../app/stores/store";
import { useTranslation } from "react-i18next";
import TrialsDetails from "./TrialsDetails";
import { Application, ClientStatus } from "../../app/models/enums";
import PageHeader from "../../app/layout/PageHeader";
import SubscriptionMobileMenu from "./SubscriptionMobileMenu";
import SubscriptionContent from "./SubscriptionContent";
import { calculateTrialExpirationDate } from "../../app/common/util/functions";

export default observer(function AvailableTrials() {
  const { t } = useTranslation(["common", "subscription"]);
  const { subscriptionStore, userStore } = useStore();
  const {
    loadingInitial,
    getAvailableTrials,
    availableTrials,
    getCurrentSubscription,
    clientSubscriptionStatus,
    getTrialExtensionAvailable,
    trialExtensionAvailable,
  } = subscriptionStore;
  const { user } = userStore;

  const [isExtension, setIsExtension] = useState<boolean>(
    !(
      user?.status === (ClientStatus.Active | ClientStatus.SignUpInProgress) ||
      user?.status === (ClientStatus.Active | ClientStatus.Trial)
    )
  );

  const [expires, setExpires] = useState<Date>(new Date());

  useEffect(() => {
    getCurrentSubscription()
      .then((results) => {
        getAvailableTrials().then(() => {
          setExpires(
            calculateTrialExpirationDate(
              isExtension,
              results ?? clientSubscriptionStatus
            )
          );
        });
        getTrialExtensionAvailable();
      })
      .catch();
  }, [getCurrentSubscription, getAvailableTrials]);

  if (loadingInitial)
    return <LoadingComponent content={t("loading", { ns: "subscription" })} />;

  return (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <SubscriptionMobileMenu
              isCancelled={clientSubscriptionStatus.isCancelled}
            />
            <SubscriptionContent
              isCancelled={clientSubscriptionStatus.isCancelled}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <PageHeader
        header={t("availabletrials", { ns: "subscription" })}
        type={"h1"}
        divider={true}
        getAlerts={true}
        addTitle={true}
      />

      <Container className="page-width" textAlign="left">
        {(!isExtension ||
          trialExtensionAvailable) && (
            <Grid stackable>
              {(availableTrials & Application.RealTimeLaborGuidePro) ===
                Application.RealTimeLaborGuidePro && (
                <Grid.Row>
                  <TrialsDetails
                    isExtension={isExtension}
                    application={Application.RealTimeLaborGuidePro}
                    expirationDate={expires}
                  ></TrialsDetails>
                </Grid.Row>
              )}
              {(availableTrials & Application.RealTimeLaborGuide) ===
                Application.RealTimeLaborGuide && (
                <Grid.Row>
                  <TrialsDetails
                    isExtension={isExtension}
                    application={Application.RealTimeLaborGuide}
                    expirationDate={expires}
                  ></TrialsDetails>
                </Grid.Row>
              )}
            </Grid>
          )}
      </Container>
    </>
  );
});
