import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import {
  PackageJobs,
  PackageJobsFormValues,
  PackageJobsItems,
} from "../models/packageJobs";
import { TicketItem } from "../models/ticket";

export default class PackageJobStore {
  loadingInitial = false;
  activeTab: number = 0;

  PackageJobsRegistry = new Map<string | undefined, PackageJobs>();

  constructor() {
    makeAutoObservable(this);
    reaction(
      () => {},
      () => {
        this.PackageJobsRegistry.clear();
        this.loadPackageJobs();
      }
    );
  }

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  setActiveTab = (activeTab: string | number | undefined) => {
    this.activeTab = activeTab ? Number(activeTab) : 0;
  };

  selectedPackageJobs: PackageJobsFormValues | undefined = undefined;

  resetPackageJobs = () => {
    this.PackageJobsRegistry.clear();
  };

  loadPackageJobs = async () => {
    this.loadingInitial = true;
    try {
      this.PackageJobsRegistry.clear();
      const result = await agent.packageJobs.listPackageJobs();
      runInAction(() => {
        result.forEach((packageJobs) => {
          this.setPackageJobs(packageJobs);
        });
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoadingInitial(false);
    }
  };

  get getPackageJobs() {
    return Array.from(this.PackageJobsRegistry.values()).sort(
      (a, b) => b.createdDate!.getTime() - a.createdDate!.getTime()
    );
  }

  setSelectedPackageJobs = (values: PackageJobsFormValues) => {
    this.selectedPackageJobs = values;
  };

  private setPackageJobs = (packageJobs: PackageJobs) => {
    packageJobs.createdDate = new Date(packageJobs.createdDate!);
    this.PackageJobsRegistry.set(packageJobs.id, packageJobs);
  };

  private getPackageJob = (id: string) => {
    return this.PackageJobsRegistry.get(id);
  };

  createPackageJobs = async (
    values: PackageJobsFormValues,
    ticketItems: TicketItem[]
  ) => {
    try {
      let myNew: PackageJobs = new PackageJobs(values);
      if (ticketItems.length > 0)
        ticketItems.forEach((ticket, index) => {
          let packageJobItem: PackageJobsItems = {
            isTaxable: ticket.isTaxable,
            itemDescription: ticket.description,
            jobId: ticket.jobId,
            partId: ticket.partId,
            miscellaneousChargeId: ticket.miscellaneousChargeId,
            laborRateId: ticket.laborRate,
            partName: ticket.partCode,
            quantity: ticket.quantity,
            unitPrice: ticket.rate,
            timeType: ticket.timeType,
            type: ticket.type,
          };
          myNew.packageJobItems.push(packageJobItem);
        });

      await agent.packageJobs.addPackageJobs(myNew);

      runInAction(() => {
        this.PackageJobsRegistry.clear();
      });
    } catch (error) {
      console.log(error);
    }
  };

  updatePackageJobs = async (values: PackageJobsFormValues) => {
    try {
      let myNew: PackageJobs = new PackageJobs(values);
      await agent.packageJobs.updatePackageJobs(myNew);

      runInAction(() => {
        if (values.id) {
          let updatedPackageJob = {
            ...this.getPackageJob(values.id),
            ...values,
          };
          this.PackageJobsRegistry.set(
            values.id,
            updatedPackageJob as PackageJobs
          );
          this.selectedPackageJobs = updatedPackageJob as PackageJobs;
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  togglePackageJobs = async (id: string) => {
    try {
      await agent.packageJobs.togglePackageJobs(id);
      runInAction(() => {
        if (id) {
          let updatedPackageJob = this.getPackageJob(id);
          if (updatedPackageJob)
            updatedPackageJob.isEnabled = !updatedPackageJob.isEnabled;
          this.PackageJobsRegistry.set(id, updatedPackageJob as PackageJobs);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  removePackageJobs = async (id: string) => {
    try {
      await agent.packageJobs.removePackageJobs(id);

      runInAction(() => {
        this.PackageJobsRegistry.delete(id);
      });
    } catch (error) {
      console.log(error);
    }
  };

  loadPackageJob = async (id: string) => {
    this.loadingInitial = true;
    try {
      let packageJob = await agent.packageJobs.getPackageJobs(id);

      runInAction(() => {
        this.selectedPackageJobs = packageJob;
      });
      this.setLoadingInitial(false);
      return packageJob;
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
    }
  };

  addPackageJobToTicketSession = async (
    packageJobId: string,
    ticketSessionId: string
  ) => {
    try {
      await agent.packageJobs.addPackageJobToTicketSession(
        packageJobId,
        ticketSessionId
      );

      runInAction(() => {});
    } catch (error) {
      console.log(error);
    }
  };
}
