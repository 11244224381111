import { CSSProperties } from "react";
export interface CSSClasses {
  [key: string]: CSSProperties;
}

const styles: CSSClasses = {
  flex: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
  },
  body: {
    fontFamily: "Times-Roman",
    fontSize: "10.5pt",
  },
  writeBox: {
    border: "1px dotted black",
  },
  writeUnderline: {
    borderBottom: "1px solid black",
  },
  fontSize6pt: {
    fontSize: "6pt",
  },

  whiteSpaceNoWrap: {
    whiteSpace: "nowrap",
  },
  textAlignCenter: {
    textAlign: "center",
  },
  textAlignRight: {
    textAlign: "right",
  },
  metaHead: {
    textAlign: "right",
    backgroundColor: "#eee",
  },
  floatRight: {
    float: "right",
  },

  invoiceText: {
    color: "#fff",
    fontFamily: "Times-Bold",
    fontSize: "11.25pt",
    fontWeight: "bold",
    textTransform: "uppercase",
    letterSpacing: "5mm",
  },
  borderSolid: {
    border: "1px solid #000",
  },
  borderSolidTop: {
    borderTop: "1px solid #000",
  },
  borderSolidBottom: {
    borderBottom: "1px solid #000",
  },
  borderSolidLeftRight: {
    borderLeft: "1px solid #000",
    borderRight: "1px solid #000",
  },
  borderSolidLeft: {
    borderLeft: "1px solid #000",
  },
  borderSolidRight: {
    borderRight: "1px solid #000",
  },
  border3px: {
    border: "3px solid #000",
  },
  border3pxTop: {
    borderTop: "3px solid #000",
  },
  border3pxLeftRight: {
    borderLeft: "3px solid #000",
    borderRight: "3px solid #000",
  },
  border3pxBottom: {
    borderBottom: "3px solid #000",
  },

  borderDotted: {
    border: "1px dotted #000",
  },

  fontSize16px: {
    fontSize: "12pt",
    fontFamily: "Times-Bold",
    fontWeight: "bold",
  },
  darkHeader: {
    color: "#fff",
    backgroundColor: "#aaa",
  },
  "customer-title": {
    fontSize: "4mm",
    fontFamily: "Times-Bold",
    fontWeight: "bold",
  },
  "invoice-wrapper": {
    position: "relative",
    background: "#fff",
    padding: "8mm 8mm 8mm 8mm",
  },
  dividerBar: {
    backgroundColor: "#000",
    height: "8mm",
    maxHeight: "8mm",
  },
  backgroundColorEEE: {
    backgroundColor: "#eee",
  },

  //Height styles
  height38mm: {
    height: "38mm",
    maxHeight: "38mm",
  },
  height40mm: {
    height: "40mm",
    maxHeight: "40mm",
  },
  height44mm: {
    height: "44mm",
    maxHeight: "44mm",
  },
  height46mm: {
    height: "46mm",
    maxHeight: "46mm",
  },
  height50mm: {
    height: "50mm",
    maxHeight: "50mm",
  },
  lineHeight: {
    height: "7mm",
    maxHeight: "7mm",
  },

  //Width Styles
  pageWidth: {
    width: "200mm",
  },
  width15: {
    width: "15mm",
    maxWidth: "15mm",
  },
  width17: {
    width: "17mm",
    maxWidth: "17mm",
  },
  width18: {
    width: "18mm",
    maxWidth: "18mm",
  },
  width20: {
    width: "20mm",
    maxWidth: "20mm",
  },
  width21: {
    width: "21mm",
    maxWidth: "21mm",
  },
  width22: {
    width: "22mm",
    maxWidth: "22mm",
  },
  width23: {
    width: "23mm",
    maxWidth: "23mm",
  },
  width24: {
    width: "24mm",
    maxWidth: "24mm",
  },
  width26: {
    width: "26mm",
    maxWidth: "26mm",
  },
  width27: {
    width: "27mm",
    maxWidth: "27mm",
  },
  width31:{
    width: "31mm",
    maxWidth: "31mm",
  },
  width34: {
    width: "34mm",
    maxWidth: "34mm",
  },
  width38: {
    width: "38mm",
    maxWidth: "38mm",
  },
  width39: {
    width: "39mm",
    maxWidth: "39mm",
  },
  width40: {
    width: "40mm",
    maxWidth: "40mm",
  },
  width41: {
    width: "41mm",
    maxWidth: "41mm",
  },
  width50: {
    width: "50mm",
    maxWidth: "50mm",
  },
  width52: {
    width: "52mm",
    maxWidth: "52mm",
  },
  width59: {
    width: "59mm",
    maxWidth: "59mm",
  },
  width90: {
    width: "90mm",
    maxWidth: "90mm",
  },
  width93: {
    width: "93mm",
    maxWidth: "93mm",
  },
  width98: {
    width: "98mm",
    maxWidth: "98mm",
  },
  width100: {
    width: "100mm",
    maxWidth: "100mm",
  },
  width114: {
    width: "114mm",
    maxWidth: "114mm",
  },
  width130: {
    width: "130mm",
    maxWidth: "130mm",
  },
  width174: {
    width: "174mm",
    maxWidth: "174mm",
  },

  //Margin styles
  noMarginTop: {
    marginTop: "0mm",
  },
  marginBottom1mm: {
    marginBottom: "1mm",
  },
  marginTop: {
    marginTop: "1mm",
  },
  noMarginLeft: {
    marginLeft: "0mm",
  },
  noMarginRight: {
    marginRight: "0mm",
  },
  noMarginLeftRight: {
    marginLeft: "0mm",
    marginRight: "0mm",
  },
  noMargin: {
    marginTop: "0mm",
    marginBottom: "0mm",
    marginLeft: "0mm",
    marginRight: "0mm",
  },

  //Padding Styles
  noPadding: {
    paddingTop: "0mm",
    paddingBottom: "0mm",
    paddingLeft: "0mm",
    paddingRight: "0mm",
  },
  noPaddingLeftRight: {
    paddingLeft: "0mm",
    paddingRight: "0mm",
  },
  noPaddingLeft: {
    paddingLeft: "0mm",
  },
  padding5pxLeft: {
    paddingLeft: "1.5mm",
  },
  padding5pxRight: {
    paddingRight: "1.5mm",
  },
  padding1mmTop: {
    paddingTop: "1mm",
  },
  padding1mmBottom: {
    paddingBottom: "1mm",
  },
  padding2mmTop: {
    paddingTop: "2mm",
  },
  padding2mmBottom: {
    paddingBottom: "2mm",
  },
};

export default styles;
