import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { Grid, Header, Divider } from "semantic-ui-react";
import AuthenticationLog from "../AuthenticationLog";
import { useStore } from "../../../app/stores/store";
import { useParams } from "react-router-dom";

export default observer(function AuthLogs() {
  const { t } = useTranslation(["common", "systemAdmin"]);
  const { authenticationLogStore } = useStore();
  const {resetAuthenticationLogs, clearPredicate} = authenticationLogStore

  const { ip } = useParams<{ ip: string }>();


  useEffect(() => {
    resetAuthenticationLogs();
    clearPredicate();
  }, []);

  return (
    <>
      <Grid stackable>
        <Grid.Row>
          <Grid.Column>
            <Header
              as="h1"
              content={t("logs.auth_intro", { ns: "systemAdmin" }).toString()}
              className="modal-text-color"
              textAlign="left"
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Divider />
      <Grid stackable>
        <Grid.Column stackable>
          <AuthenticationLog ipAddress={ip} />
        </Grid.Column>
      </Grid>
    </>
  );
});
