import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { Announcement, SystemAdminAnnouncement } from "../../models/admin";

export default class AnnouncementStore {
  constructor() {
    makeAutoObservable(this);
  }

  loadingInitial = false;

  announcement: SystemAdminAnnouncement = {
    id: undefined,
    startDate: new Date(),
    endDate: new Date(),
    announcementType: undefined,
    color: undefined,
    header: undefined,
    body: undefined,
    isActive: false,
    createdDate: undefined,
  };

  currentAnnouncement: Announcement [] = [];

  loadAnnouncement = async (id: number) => {
    this.loadingInitial = true;
    try {
      const result = await agent.SystemAdminTools.getAnnouncement(id);
      runInAction(() => {
        if (result.createdDate)
          result.createdDate = new Date(result.createdDate);
        if (result.startDate) result.startDate = new Date(result.startDate);
        if (result.endDate) result.endDate = new Date(result.endDate);
        this.announcement = result;
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.loadingInitial = false;
      });
    }
  };

  loadCurrentAnnouncement = async () => {
    this.loadingInitial = true;
    try {
      const result = await agent.SystemAdminTools.getCurrentAnnouncement();
      runInAction(() => {
        this.currentAnnouncement = result;
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.loadingInitial = false;
      });
    }
  };

  AnnouncementListRegistry = new Map<
    number | undefined,
    SystemAdminAnnouncement
  >();

  loadAnnouncementList = async () => {
    this.loadingInitial = true;
    try {
      this.AnnouncementListRegistry.clear();
      const result = await agent.SystemAdminTools.getAnnouncementList();
      runInAction(() => {
        result.forEach((announcementList) => {
          this.setAnnouncementList(announcementList);
        });
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoadingInitial(false);
    }
  };

  private setAnnouncementList = (announcementList: SystemAdminAnnouncement) => {
    if (announcementList.createdDate)
      announcementList.createdDate = new Date(announcementList.createdDate);
    if (announcementList.startDate) announcementList.startDate = new Date();
    if (announcementList.endDate)
      announcementList.endDate = new Date(announcementList.endDate);
    this.AnnouncementListRegistry.set(announcementList.id, announcementList);
  };

  get getAnnouncementList() {
    return Array.from(this.AnnouncementListRegistry.values()).sort(
      (a, b) => b.createdDate!.getTime() - a.createdDate!.getTime()
    );
  }

  setAnnouncement = (value: SystemAdminAnnouncement | undefined) => {
    if (value !== undefined) this.announcement = value;
  };

  updateAnnouncement = async (announcement: SystemAdminAnnouncement) => {
    try {
      await agent.SystemAdminTools.updateAnnouncement(announcement.id, announcement);
    } catch (error) {
      console.log(error);
    } finally {
      this.AnnouncementListRegistry.clear();
    }
  };

  createAnnouncement = async (values: SystemAdminAnnouncement) => {
    try {
      let myValue: SystemAdminAnnouncement = new SystemAdminAnnouncement(
        values
      );
      await agent.SystemAdminTools.addAnnouncement(myValue);
      runInAction(() => {
        this.AnnouncementListRegistry.clear();
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };
}
