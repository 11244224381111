import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Grid, Menu, Tab, Dropdown, DropdownMenu } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import ManageUsers from "./Users/ManageUsers";
import { useStore } from "../../app/stores/store";
import ManageCompanies from "./Clients/ManageClients";
import LogsTabs from "./records/LogsTabs";
import ToolsTabs from "./tools/ToolsTabs";
import CompanyReview from "./CompanyReview";
import ReportsTabs from "./reports/ReportsTabs";
import StatsTabs from "./stats/StatsTabs";
import SysAdminSidebar from "./SysAdminSidebar";
import { useNavigate } from "react-router-dom";
import AdminToolsTabs from "./adminTools/AdminToolsTabs";
import { AccessLevel } from "../../app/models/enums";

interface Props {
  activeTab: number;
  activeSubTab: number | undefined;
}

export default observer(function LandingTabs(props: Props) {
  const { t } = useTranslation(["common"]);
  const navigate = useNavigate();

  const { systemAdminStore, userStore } = useStore();
  const { setActiveTab, activeTab, setActiveSubTab, activeSubTab } =
    systemAdminStore;
  const { checkAccessLevel } = userStore;

  useEffect(() => {
    setActiveTab(props.activeTab);
    setActiveSubTab(props.activeSubTab);
  }, [setActiveTab, props.activeTab, setActiveSubTab, props.activeSubTab]);

  const panes = [
    {
      menuItem: (
        <Menu.Item
          onClick={() => {
            navigate(`/admin/UserList`);
          }}
        >
          {t("users", { ns: "common" })}
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane active={0 === activeTab}>
          <ManageUsers />
        </Tab.Pane>
      ),
    },
    {
      menuItem: (
        <Menu.Item
          onClick={() => {
            navigate(`/admin/companyList`);
          }}
        >
          {t("companies", { ns: "common" })}
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane active={1 === activeTab}>
          <ManageCompanies />
        </Tab.Pane>
      ),
    },
    {
      menuItem: (
        <Menu.Item
          onClick={() => {
            navigate(`/admin/Logs/AdminNotes`);
          }}
        >
          {t("logs", { ns: "common" })}
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane active={2 === activeTab}>
          <LogsTabs activeSubTab={0} />
        </Tab.Pane>
      ),
    },
    // {
    //   menuItem: (
    //     <Menu.Item
    //       onClick={() => {
    //         navigate(`/admin/Import/`);
    //       }}
    //     >
    //       {t("import", { ns: "common" })}
    //     </Menu.Item>
    //   ),
    //   render: () => (
    //     <Tab.Pane active={7 === activeTab}>
    //       <ManageUsers />
    //     </Tab.Pane>
    //   ),
    // },
  ];

  if (checkAccessLevel(AccessLevel.SystemAdministrator | AccessLevel.Manager)) {
    panes.push({
      menuItem: (
        <Menu.Item
          onClick={() => {
            navigate(`/admin/Tools/IpLookup`);
          }}
        >
          {t("tools", { ns: "common" })}
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane active={3 === activeTab}>
          <ToolsTabs activeSubTab={0} />
        </Tab.Pane>
      ),
    });
      panes.push({
        menuItem: (
          <Menu.Item
            onClick={() => {
              navigate(`/admin/companyReview`);
            }}
          >
            {t("companyReview", { ns: "common" })}
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane active={4 === activeTab}>
            <CompanyReview />
          </Tab.Pane>
        ),
      });
      panes.push({
        menuItem: (
          <Menu.Item
            onClick={() => {
              navigate(`/admin/Reports/MultiRecurring`);
            }}
          >
            {t("reports", { ns: "common" })}
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane active={5 === activeTab}>
            <ReportsTabs activeSubTab={0} />
          </Tab.Pane>
        ),
      });
      panes.push({
        menuItem: (
          <Menu.Item
            onClick={() => {
              navigate(`/admin/Stats/Overview`);
            }}
          >
            {t("stats", { ns: "common" })}
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane active={6 === activeTab}>
            <StatsTabs activeSubTab={0} />
          </Tab.Pane>
        ),
      });
      panes.push({
        menuItem: (
          <Menu.Item
            onClick={() => {
              navigate(`/admin/AdminTools/cancellationReport`);
            }}
          >
            {"Admin Tools"}
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane active={7 === activeTab}>
            <AdminToolsTabs activeSubTab={0} />
          </Tab.Pane>
        ),
      });
  }

  return (
    <Grid stackable>
      <Grid.Column width={3}>
        <SysAdminSidebar />
      </Grid.Column>
      <Grid.Column width={13}>
        <Tab
          menu={{ fluid: true }}
          menuPosition="left"
          panes={panes}
          activeIndex={activeTab ?? activeSubTab}
          onTabChange={(e, data) => {
            setActiveTab(data.activeIndex);
          }}
        />
      </Grid.Column>
    </Grid>
  );
});
