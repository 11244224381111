import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import { DropdownItemProps } from "semantic-ui-react";
import { VehicleFormValues } from "../models/vehicle";
import { CustomVehicle } from "../models/customvehicles";
import { ApplicationConstants } from "../common/util/applicationConstants";

export default class VehicleStore {
  yearList: DropdownItemProps[] = [];
  makeList: DropdownItemProps[] = [];
  modelList: DropdownItemProps[] = [];
  engineList: DropdownItemProps[] = [];
  passwordList: DropdownItemProps[] = [];
  selectedVehicle: VehicleFormValues = {
    id: undefined,
    year: undefined,
    makeId: undefined,
    makeName: undefined,
    modelId: undefined,
    modelName: undefined,
    engineId: undefined,
    engineName: undefined,
    vin: undefined,
    vehicleNumber: undefined,
    tag: undefined,
    mileageIn: undefined,
    mileageOut: undefined,
    isCustom: false,
    error: undefined,
  };

  loadingInitial = false;
  loading = false;
  predicate = new Map().set("all", true);

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.predicate.keys(),
      () => {
        this.handleResetSelectedVehicle();
        this.yearList = [];
        this.loadYearList();

      }
    );
  }

  resetVehicleArrays=()=> {
      this.yearList= [];
      this.makeList= [];
      this.modelList= [];
      this.engineList = [];
      this.passwordList = [];
  }


  setSelectedVehicle = (vehicle: VehicleFormValues) => {
    this.selectedVehicle = new VehicleFormValues(vehicle);

    if (this.selectedVehicle.year) {
      this.loadDsiMakeListWithCustom(this.selectedVehicle.year);
      if (this.selectedVehicle.makeName) {
        this.loadDsiModelListWithCustom(
          this.selectedVehicle.year,
          this.selectedVehicle.makeName
        );
        if (this.selectedVehicle.modelId)
          this.loadDsiEngineListWithCustom(
            this.selectedVehicle.year,
            this.selectedVehicle.makeName,
            this.selectedVehicle.modelId
          );
      }
    }
  };

  loadYearList = async () => {
    this.loadingInitial = true;
    try {
      const result = await agent.VehicleApi.yearList();
      runInAction(() => {
        this.yearList = result;
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.loadingInitial = false;
      });
    }
  };

  loadYearListWithCustom = async () => {
    this.loadingInitial = true;
    try {
      const result = await agent.VehicleApi.yearListWithCustom();
      runInAction(() => {
        this.yearList = result;
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.loadingInitial = false;
      });
    }
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  loadDsiMakeListWithCustom = async (year: number) => {
    this.loadingInitial = true;
    try {
      const result = await agent.VehicleApi.dsiMakeListWithCustom(year);
      runInAction(() => {
        this.makeList = result;
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.loadingInitial = false;
      });
    }
  };

  // loadModelList = async () => {
  //   this.loadingInitial = true;
  //   try {
  //     const result = await agent.Vehicle.modelList(
  //       this.selectedVehicle?.year!,
  //       this.selectedVehicle?.makeId!
  //     );
  //     if (result.length === 0) this.loadEngineList();
  //     runInAction(() => {
  //       this.modelList = result;
  //       this.loadingInitial = false;
  //     });
  //   } catch (error) {
  //     console.log(error);
  //     this.setLoadingInitial(false);
  //   }
  // };

  loadDsiModelListWithCustom = async (year: number, makeName: string) => {
    this.loadingInitial = true;
    try {
      const result = await agent.VehicleApi.dsiModelListWithCustom(year, makeName);
      runInAction(() => {
        this.modelList = result;
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.loadingInitial = false;
      });
    }
  };

  loadDsiEngineListWithCustom = async (
    year: number,
    makeName: string,
    modelId: string
  ) => {
    this.loadingInitial = true;
    try {
      if (!this.selectedVehicle?.modelId) return;

      const result = await agent.VehicleApi.dsiEngineListWithCustom(
        year,
        makeName,
        modelId
      );
      runInAction(() => {
        this.engineList = result;
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.loadingInitial = false;
      });
    }
  };

  handleResetSelectedVehicle = () => {
    if (this.selectedVehicle) {
      this.selectedVehicle = {
        year: undefined,
        makeId: undefined,
        makeName: undefined,
        //vehicleToDriveTypeId: undefined,
        modelId: undefined,
        modelName: undefined,
        engineId: undefined,
        engineName: undefined,
        isCustom: false,
      };
    }
    this.resetEngineList();
  };

  setSelectedVehicleYear = (year: number | undefined) => {
    if (this.selectedVehicle) this.selectedVehicle.year = year;
  };

  setSelectedVehicleMakeId = (makeId: number | undefined) => {
    if (this.selectedVehicle) this.selectedVehicle.makeId = makeId;
  };

  setSelectedVehicleMake = (make: string | undefined) => {
    if (this.selectedVehicle) this.selectedVehicle.makeName = make;
  };

  setSelectedVehicleModelId = (modelId: string | undefined) => {
    if (this.selectedVehicle) this.selectedVehicle.modelId = modelId;
  };

  setSelectedVehicleModel = (model: string | undefined) => {
    if (this.selectedVehicle) this.selectedVehicle.modelName = model;
  };

  setSelectedVehicleEngineId = (engineId: string | undefined) => {
    if (this.selectedVehicle) this.selectedVehicle.engineId = engineId;
  };

  setSelectedVehicleEngine = (engine: string | undefined) => {
    if (this.selectedVehicle) this.selectedVehicle.engineName = engine;
  };

  setSelectedVehicleIsCustom = (isCustom: boolean) => {
    if (this.selectedVehicle) this.selectedVehicle.isCustom = isCustom;
  };

  resetMakeList = () => {
    this.makeList = [];
  };

  resetModelList = () => {
    this.modelList = [];
  };

  resetEngineList = () => {
    this.engineList = [];
  };

  get isYearSelected() {
    let retVal = false;
    if (this.selectedVehicle?.year)
    retVal = this.selectedVehicle.year >= 1;
    return retVal;
  }

  get isMakeSelected() {
    let retVal = false;
    if (this.selectedVehicle?.makeName)
    retVal = this.selectedVehicle.makeName.length > 0;
    return retVal;
  }

  get isModelSelected() {
    let retVal = false;
    let model = this.selectedVehicle?.isCustom ? this.selectedVehicle.modelName : this.selectedVehicle?.modelId;

    if (model)
    retVal = model.length > 0;

    return retVal;
  }

  get isEngineSelected() {
    let retVal = false;
    let engine = this.selectedVehicle?.isCustom ? this.selectedVehicle.engineName : this.selectedVehicle?.engineId;

    if (engine && engine != ApplicationConstants.notSpecified)
    retVal = engine.length > 0;

    return retVal;
  }

  get getIsVehicleSelected() {
    let retVal = false;
    if (this.selectedVehicle && !this.loadingInitial) {
      if (this.selectedVehicle.engineId)
      retVal = this.selectedVehicle.engineId?.length > 0;
      // TODO: This is where I think things break down to be fixed later
      //if (this.modelList.length === 0) retVal = this.isMakeSelected;
    }
    return retVal;
  }

  loadAllMakeList = async () => {
    this.loadingInitial = true;
    try {
      const result = await agent.VehicleApi.listAllMakeList();

      runInAction(() => {
        this.makeList = result;
      });
      return result;
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoadingInitial(false);
    }
  };

  setCustomerVehicleValues = (values: VehicleFormValues) => {
    if (this.selectedVehicle) {
      this.selectedVehicle.vin = values.vin;
      this.selectedVehicle.vehicleNumber = values.vehicleNumber;
      this.selectedVehicle.tag = values.tag;
      this.selectedVehicle.mileageIn = values.mileageIn;
      this.selectedVehicle.mileageOut = values.mileageOut;
    }
  };

  passwordGeneratedList = async (count: number) => {
    this.loadingInitial = true;
    try {
      const result = await agent.VehicleApi.passwordGeneratedList(count);

      runInAction(() => {
        this.passwordList = result;
      });
      return result;
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoadingInitial(false);
    }
  };

  selectCustomVehicle = (values: CustomVehicle, year?: number) => {

    this.setSelectedVehicleYear(year);

    this.setSelectedVehicleMake(values.makeName);

    this.setSelectedVehicleModel(values.modelName);
    this.setSelectedVehicleModelId(values.modelName);

    if (values.engineName) {
      this.setSelectedVehicleEngineId(values.id);
      this.setSelectedVehicleEngine(values.engineName);
    }

    this.setSelectedVehicleIsCustom(true);
    this.selectedVehicle.customVehicleId = values.id;

    return this.selectedVehicle
  };
}
