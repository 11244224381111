import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Header, Divider, Input, List, Button } from "semantic-ui-react";
import { useStore } from "../../../app/stores/store";
import { useParams } from "react-router-dom";

export default observer(function IpLookup() {
  const { t } = useTranslation(["common", "systemAdmin"]);

  const { geoLocationStore } = useStore();

  const { loadGeoLocation, geoLocation } = geoLocationStore;
  const { ip } = useParams<{ ip: string }>();


  useEffect(() => {
    if (ip) loadGeoLocation(ip);
  }, [loadGeoLocation, ip]);

  const [newSelectedIp, setNewSelectedIp] = useState<string | undefined>(undefined);

  return (
    <>
      <Grid stackable>
        <Grid.Row columns={2}>
          <Grid.Column>
            <Header
              as="h1"
              content={t("tools.ip_intro", { ns: "systemAdmin" }).toString()}
              className="modal-text-color"
              textAlign="left"
            />
          </Grid.Column>
          <Grid.Column textAlign="right">
            <Input
              placeholder="Search..."
              onChange={(e, d) => {
                let val :string|undefined = d.value && d.value.length > 0 ? d.value : undefined;
                setNewSelectedIp(val);}}
            />
            <Button content={'Search'} onClick={(e, d) =>{ if (newSelectedIp) loadGeoLocation(newSelectedIp);}} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Divider />
      <Grid stackable>
        <Grid.Column stackable>
          <List divided verticalAlign="middle">
            <List.Item>
              <b>Latitude: </b> {geoLocation?.latitude}
            </List.Item>
            <List.Item>
              <b>Longitude: </b> {geoLocation?.longitude}
            </List.Item>
            <List.Item>
              <b>Host Name: </b> {geoLocation?.hostname}
            </List.Item>
            <List.Item>
              <b>Zip Code: </b> {geoLocation?.zip}
            </List.Item>
            <List.Item>
              <b>City: </b> {geoLocation?.city}
            </List.Item>
            <List.Item>
              <b>Region: </b> {geoLocation?.region_name}
            </List.Item>
            <List.Item>
              <b>Region Code: </b>
              {geoLocation?.region_code}
            </List.Item>
            <List.Item>
              <b>Country: </b> {geoLocation?.country_name}
            </List.Item>
            <List.Item>
              <b>Country Code: </b>
              {geoLocation?.country_code}
            </List.Item>
            <List.Item>
              <b>Continent: </b>
              {geoLocation?.continent_name}
            </List.Item>
            <List.Item>
              <b>Continent Code: </b>
              {geoLocation?.continent_code}
            </List.Item>
            <List.Item>
              <b>Time Zone: </b> {geoLocation?.time_zone?.code}
            </List.Item>
          </List>
        </Grid.Column>
      </Grid>
    </>
  );
});
