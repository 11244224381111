import React, { useState } from "react";
import {
  Icon,
  Input,
  Label,
  Menu,
} from "semantic-ui-react";

interface Props {
  itemCount: number;
  pageSize: number;
  currentPage: number;
  onChange?: (value: any) => void;
  onClick?: (value: any) => void;
  floated?: "right";
}

export default function MyPaging(props: Readonly<Props>) {
  let pages =
    props.pageSize > 0 ? Math.ceil(props.itemCount / props.pageSize) : 1;

  let maxButtons = pages > 5 ? 5 : pages;

  
  let firstButton: number = 1;
  if (props.currentPage - 2 > firstButton) {
    firstButton = props.currentPage - 2;
  } else if (pages > 5 && firstButton + maxButtons > pages) {
    firstButton = pages - 5;
  }

  if ( (firstButton + maxButtons) > pages)
  {
   firstButton = pages - maxButtons +1
  }

  const [pageValue, setPageValue] = useState<string>("1");

  return (
    <>
      {pages > 1 && (
          <Menu size="mini" key="paging" floated={props.floated} pagination>
            {pages > 5 && (
              <Menu.Item
                key="fastback"
                as="a"
                icon
                onClick={() => {
                  if (props.onClick) props.onClick(1);
                }}
                disabled={props.currentPage === 1}
              >
                <Icon name="angle double left"></Icon>
              </Menu.Item>
            )}
            <Menu.Item
              key="back"
              as="a"
              icon
              onClick={() => {
                if (props.onClick && props.currentPage > 1)
                  props.onClick(props.currentPage - 1);
              }}
              disabled={props.currentPage === 1}
            >
              <Icon name="angle left"></Icon>
            </Menu.Item>
            {(() => {
              const options = [];
              for (let i = firstButton; i < firstButton + maxButtons; i++) {
                options.push(
                  <Menu.Item
                    key={`page${i}`}
                    as="a"
                    onClick={() => {
                      if (props.onClick) props.onClick(i);
                    }}
                    disabled={props.currentPage === i}
                    content={i}
                  ></Menu.Item>
                );
              }
              return options;
            })()}
            <Menu.Item
              key="forward"
              as="a"
              icon
              onClick={() => {
                if (props.onClick && props.currentPage < pages)
                  props.onClick(props.currentPage + 1);
              }}
              disabled={props.currentPage === pages}
            >
              <Icon name="angle right"></Icon>
            </Menu.Item>
            {pages > 5 && (
              <Menu.Item
                key="fastforward"
                as="a"
                icon
                onClick={() => {
                  if (props.onClick) props.onClick(pages);
                }}
                disabled={props.currentPage === maxButtons - 5}
              >
                <Icon name="angle double right"></Icon>
              </Menu.Item>
            )}

          <Input
            className="myInput"
            type="text"
            values={pageValue}
            onChange={(e, d) => {
              setPageValue(d.value);
            }}
            onBlur={(e: any, d: any) => {

              if(props.onChange) props.onChange(pageValue);
            }}
            onKeyPress={(e: any, d: any) => {
              if ((e.nativeEvent.code === "Enter" || e.nativeEvent.code === "NumpadEnter") && props.onChange)
                  props.onChange(pageValue);
            }}

            placeholder={props.currentPage}
          ></Input>
          <Label className="myLabel background-color">{` of ${pages} pages`}</Label>
          </Menu>
      )}
    </>
  );
}
