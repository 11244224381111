import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Accordion, Menu } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../app/stores/store";
import { Application, ClientStatus } from "../../app/models/enums";

export default observer(function SettingsNavBar() {
  const { t } = useTranslation([
    "common",
    "users",
    "errors",
    "settings",
    "registration",
    "disclaimerSettings",
    "pricingSettings",
  ]);

  const {
    userStore: { checkStatus, checkApplication },
  } = useStore();

  const navigate = useNavigate();

  const Company = (
    <Menu secondary vertical>
      <Menu.Item
        id="settingsCompany"
        name="company"
        onClick={() => {
          navigate("/settings/company");
        }}
      />
      <Menu.Item
        id="settingsCompanyLogo"
        name="companyLogo"
        onClick={() => {
          navigate("/settings/companyLogo");
        }}
      />
    </Menu>
  );

  const Pricing = (
    <Menu secondary vertical>
      <Menu.Item
        id="settingsLaborRates"
        name="laborRates"
        onClick={() => {
          navigate("/settings/laborRates");
        }}
      />
      <Menu.Item
        id="settingsSalesTax"
        name="salesTax"
        onClick={() => {
          navigate("/settings/salesTax");
        }}
      />
      <Menu.Item
        id="settingsPayTypes"
        name="payTypes"
        onClick={() => {
          navigate("/settings/payTypes");
        }}
      />
    </Menu>
  );

  const Ticket = (
    <Menu secondary vertical>
      <Menu.Item
        id="settingsFormatting"
        name="formatting"
        onClick={() => {
          navigate("/settings/formatting");
        }}
      />
      <Menu.Item
        id="settingsNumbers"
        name="numbers"
        onClick={() => {
          navigate("/settings/numbers");
        }}
      />
      <Menu.Item
        id="settingsInputDefaults"
        name="inputDefaults"
        onClick={() => {
          navigate("/settings/inputDefaults");
        }}
      />
      <Menu.Item
        id="settingsTicketDefaults"
        name="ticketDefaults"
        onClick={() => {
          navigate("/settings/ticketDefaults");
        }}
      />
    </Menu>
  );

  const Disclaimers = (
    <Menu secondary vertical>
      <Menu.Item
        id="settingsInvoiceDisclaimers"
        name="invoiceDisclaimers"
        onClick={() => {
          navigate("/settings/invoiceDisclaimers");
        }}
      />
      <Menu.Item
        id="settingsEstimateDisclaimers"
        name="estimateDisclaimers"
        onClick={() => {
          navigate("/settings/estimateDisclaimers");
        }}
      />
    </Menu>
  );

  const Workflow = (
    <Menu secondary vertical>
      <Menu.Item
        id="settingsTicketPath"
        name="ticketPath"
        onClick={() => {
          navigate("/settings/ticketPath");
        }}
      />
      <Menu.Item
        id="settingsManufacturers"
        name="manufacturers"
        onClick={() => {
          navigate("/settings/manufacturers");
        }}
      />
      <Menu.Item
        id="settingsCustomVehicles"
        name="customVehicles"
        onClick={() => {
          navigate("/settings/customVehicles");
        }}
      />
    </Menu>
  );

  const Advanced = (
    <Menu secondary vertical>
      <Menu.Item
        id="settingsQuickVin"
        name="quickVIN"
        onClick={() => {
          navigate("/settings/quickvin");
        }}
      />
      {checkStatus(ClientStatus.Trial | ClientStatus.Active) &&
        checkApplication(Application.RealTimeLaborGuidePro) && (
          <>
            <Menu.Item
              id="settingsImport"
              name="import"
              onClick={() => {
                navigate("/settings/import");
              }}
            />
            <Menu.Item
              id="settingsPaymentProcessing"
              name="paymentProcessing"
              onClick={() => {
                navigate("/settings/paymentProcessing");
              }}
            />
          </>
        )}
    </Menu>
  );

  const [activeIndex, setActiveIndex] = useState<number>(0);

  function handleClick(value: number) {
    setActiveIndex(value);
  }

  return (
    <Accordion as={Menu} vertical className="desktopMenu">
      <Menu.Item>
        <Accordion.Title
          active={activeIndex === 1}
          content={t("company", { ns: "common" })}
          index={1}
          onClick={() => {
            handleClick(1);
          }}
        />
        <Accordion.Content active={activeIndex === 1} content={Company} />
      </Menu.Item>

      <Menu.Item>
        <Accordion.Title
          active={activeIndex === 2}
          content={t("pricing", { ns: "common" })}
          index={2}
          onClick={() => {
            handleClick(2);
          }}
        />
        <Accordion.Content active={activeIndex === 2} content={Pricing} />
      </Menu.Item>

      <Menu.Item>
        <Accordion.Title
          active={activeIndex === 3}
          content={t("ticket", { ns: "common" })}
          index={3}
          onClick={() => {
            handleClick(3);
          }}
        />
        <Accordion.Content active={activeIndex === 3} content={Ticket} />
      </Menu.Item>

      <Menu.Item>
        <Accordion.Title
          active={activeIndex === 4}
          content={t("disclaimers", { ns: "common" })}
          index={4}
          onClick={() => {
            handleClick(4);
          }}
        />
        <Accordion.Content active={activeIndex === 4} content={Disclaimers} />
      </Menu.Item>

      <Menu.Item>
        <Accordion.Title
          active={activeIndex === 5}
          content={t("workflow", { ns: "common" })}
          index={5}
          onClick={() => {
            handleClick(5);
          }}
        />
        <Accordion.Content active={activeIndex === 5} content={Workflow} />
      </Menu.Item>
      {checkStatus(ClientStatus.Trial | ClientStatus.Active) &&
        checkApplication(Application.RealTimeLaborGuidePro) && (
          <>
            <Menu.Item
              id="settingsTech"
              name="tech"
              onClick={() => {
                navigate("/settings/techs");
              }}
            />

            <Menu.Item
              id="settingsVendor"
              name="vendor"
              onClick={() => {
                navigate("/settings/vendors");
              }}
            />
          </>
        )}

      <Menu.Item
        id="settingsMisc"
        name="misc"
        onClick={() => {
          navigate("/settings/miscCharges");
        }}
      />

      <Menu.Item
        id="settingsPackageJobs"
        name="packageJobs"
        onClick={() => {
          navigate("/settings/packageJobs");
        }}
      />

      <Menu.Item>
        <Accordion.Title
          active={activeIndex === 6}
          content={t("advanced", { ns: "common" })}
          index={6}
          onClick={() => {
            handleClick(6);
          }}
        />
        <Accordion.Content active={activeIndex === 6} content={Advanced} />
      </Menu.Item>
    </Accordion>
  );
});
