import { observer } from "mobx-react-lite";
import { Button, Table } from "semantic-ui-react";
import { format } from "date-fns";
import { accessLevelOptions } from "../../../app/common/options/option";
import { useState } from "react";
import { useStore } from "../../../app/stores/store";
import { UserFormValues } from "../../../app/models/user";
import { Link, useNavigate } from "react-router-dom";

export default observer(function UserTable() {
  const navigate = useNavigate();
  const { userSystemAdminStore, userStore, adminNotesStore } = useStore();
  const {
    userDetails,
    toggleByPassLocationAuthorization,
    toggleByPassAdditionalAuthorization,
    loadUserDetails
  } = userSystemAdminStore;
  const { toggleUser, emulateUser } = userStore;
  const { loadUserAdminNotes, resetAdminNotesRegistry } = adminNotesStore;

  const [user] = useState<UserFormValues>(new UserFormValues());

  return (
    <Table striped>
      <Table.Row key={`client.${userDetails}`}>
        <Table.Cell className="terms_bold lessPaddingSysAdminTables">Date Created:</Table.Cell>
        <Table.Cell className="lessPaddingSysAdminTables">
          {userDetails?.createdDate
            ? format(userDetails.createdDate, "MM/dd/yyyy")
            : "N/A"}
        </Table.Cell>
        <Table.Cell className="terms_bold lessPaddingSysAdminTables">Primary Phone:</Table.Cell>
        <Table.Cell className="lessPaddingSysAdminTables">
          {user.primaryNumber ? user.primaryNumber : "--"}
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell className="terms_bold lessPaddingSysAdminTables">Username:</Table.Cell>
        <Table.Cell className="lessPaddingSysAdminTables">
          {userDetails?.username}{" "}
          <Button
            size="mini"
            content={"Login As User"}
            onClick={() => {
              emulateUser(userDetails?.username).then(() => {
                navigate("/");
              });
            }}
          />
        </Table.Cell>
        <Table.Cell className="terms_bold lessPaddingSysAdminTables">Cell Phone:</Table.Cell>
        <Table.Cell className="lessPaddingSysAdminTables">{user.cellNumber ? user.cellNumber : "--"}</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell className="terms_bold lessPaddingSysAdminTables">Email Address:</Table.Cell>
        <Table.Cell className="lessPaddingSysAdminTables">{userDetails?.email}</Table.Cell>
        <Table.Cell className="terms_bold lessPaddingSysAdminTables">Access Level:</Table.Cell>
        <Table.Cell className="lessPaddingSysAdminTables">
          {
            accessLevelOptions.find(
              (values) => values.value === userDetails?.accessLevel
            )?.text
          }
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell className="terms_bold lessPaddingSysAdminTables">Company Name:</Table.Cell>
        <Table.Cell className="lessPaddingSysAdminTables">
          <Link
            className="tableLink"
            to={`/admin/company/${userDetails?.clientId}`}
          >
            {userDetails?.companyName}
          </Link>
        </Table.Cell>
        <Table.Cell className="terms_bold lessPaddingSysAdminTables">Status:</Table.Cell>
        <Table.Cell className="lessPaddingSysAdminTables">
          {userDetails?.isActive ? "Active" : "Disabled"}{" "}
          <Button
            size="mini"
            content={userDetails?.isActive ? "Disable" : "Activate"}
            onClick={() => {
              toggleUser(userDetails?.id ?? "").then(() => {
                resetAdminNotesRegistry();
                loadUserDetails(userDetails?.id ?? "");
                loadUserAdminNotes(userDetails?.id ?? "");
              });
            }}
          />
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell className="terms_bold lessPaddingSysAdminTables">First Name:</Table.Cell>
        <Table.Cell className="lessPaddingSysAdminTables">{userDetails?.firstName}</Table.Cell>
        <Table.Cell className="terms_bold lessPaddingSysAdminTables">Auth Location Check:</Table.Cell>
        <Table.Cell className="lessPaddingSysAdminTables">
          {userDetails?.authCountryCheck ? "Bypass Allowed" : "Active"}{" "}
          <Button
            size="mini"
            content={
              userDetails?.authCountryCheck ? "Remove Bypass" : "Allow Bypass"
            }
            onClick={() => {
              toggleByPassLocationAuthorization(userDetails?.id).then(() => {
                resetAdminNotesRegistry();
                loadUserDetails(userDetails?.id ?? "");
                loadUserAdminNotes(userDetails?.id ?? "");
              });
            }}
          />
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell className="terms_bold lessPaddingSysAdminTables">Last Name:</Table.Cell>
        <Table.Cell className="lessPaddingSysAdminTables">{userDetails?.lastName}</Table.Cell>
        <Table.Cell className="terms_bold lessPaddingSysAdminTables">Additional Auth Checks:</Table.Cell>
        <Table.Cell className="lessPaddingSysAdminTables">
          {userDetails?.additionalAuthCheck ? "Bypass Once" : "Active"}{" "}
          <Button
            size="mini"
            content={
              userDetails?.additionalAuthCheck
                ? "Remove Bypass"
                : "Allow Bypass Once"
            }
            onClick={() => {
              toggleByPassAdditionalAuthorization(userDetails?.id).then(() => {
                resetAdminNotesRegistry();
                loadUserDetails(userDetails?.id ?? "");
                loadUserAdminNotes(userDetails?.id ?? "");
              });
            }}
          />
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell className="terms_bold lessPaddingSysAdminTables"></Table.Cell>
        <Table.Cell className="lessPaddingSysAdminTables"></Table.Cell>
      </Table.Row>
    </Table>
  );
});
