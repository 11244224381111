import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Grid, Header, Table } from "semantic-ui-react";
import { useStore } from "../../../app/stores/store";

export default observer(function EarlyChargeReport() {
  const { t } = useTranslation(["common", "systemAdmin"]);
  const { earlyChargeStore } = useStore();
  const { loadEarlyChargeReport, getEarlyChargeReport } = earlyChargeStore;

  useEffect(() => {
    loadEarlyChargeReport();
  }, [loadEarlyChargeReport]);

  return (
    <>
      <Grid stackable>
        <Grid.Row columns={1}>
          <Grid.Column>
            <Header
              as="h1"
              content={"Early Charge Report"}
              className="modal-text-color"
              textAlign="left"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1}>
          <Grid.Column>
            <Header
              as="h3"
              content={"Total Early Charges:"}
              className="modal-text-color"
              textAlign="left"
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid stackable>
        <Grid.Row columns={1}>
          <Table basic="very" striped unstackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell textAlign="left">Company ID</Table.HeaderCell>
                <Table.HeaderCell>Charge 1</Table.HeaderCell>
                <Table.HeaderCell>Charge 2</Table.HeaderCell>
                <Table.HeaderCell>Plan Intercal</Table.HeaderCell>
                <Table.HeaderCell>Day Difference</Table.HeaderCell>
                <Table.HeaderCell>Next Charge</Table.HeaderCell>
                <Table.HeaderCell>New Plan Intercal</Table.HeaderCell>
                <Table.HeaderCell>Early By</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell textAlign="left">{"x"}</Table.Cell>
                <Table.Cell>{"x"}</Table.Cell>
                <Table.Cell>{"x"}</Table.Cell>
                <Table.Cell>{"x"}</Table.Cell>
                <Table.Cell>{"x"}</Table.Cell>
                <Table.Cell>{"x"}</Table.Cell>
                <Table.Cell>{"x"}</Table.Cell>
                <Table.Cell>{"x"}</Table.Cell>
                <Table.Cell>
                  <Button primary size="small" content={"Fix"} />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Button primary size="small" content={"Fix All"} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
        <Grid.Column>
            <span>
              **Skips companies that were charged more than 3 days early.
            </span>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
});
