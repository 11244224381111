import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import {
  Customer,
  CustomerChangeLog,
  CustomerFormValues,
  CustomerNote,
  CustomerNoteFormValues,
  PotentialCustomer,
} from "../models/customer";
import { Pagination, PagingParams } from "../models/pagination";
import { v4 as uuid } from "uuid";
import i18n from "../common/i18n/i18n";
import { sortingStrings } from "../common/util/functions";

export default class CustomerStore {
  loadingInitial = false;

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.predicate.keys(),
      () => {
        this.customerRegistry.clear();
        this.potentialCustomerRegistry.clear();
        this.customerNotesRegistry.clear();
        this.customerChangeLogsRegistry.clear();
        this.loadCustomers();
      }
    );
  }

  resetCustomerStoreRegistries = () => {
    this.customerRegistry.clear();
    this.potentialCustomerRegistry.clear();
    this.customerNotesRegistry.clear();
    this.customerChangeLogsRegistry.clear();
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  pagingParams = new PagingParams();
  pagination: Pagination | null = null;
  predicate = new Map().set("all", true);

  potentialCustomerPagination: Pagination | null = null;

  selectedCustomer: CustomerFormValues | undefined = undefined;

  potentialCustomerRegistry = new Map<string | undefined, PotentialCustomer>();

  setSelectedCustomer = (values: CustomerFormValues) => {
    this.selectedCustomer = values;
  };

  get isCustomerNameSet() {
    return (
      this.selectedCustomer &&
      (this.isCompanySet || this.isFistNameSet || this.isLastNameSet)
    );
  }

  get isCompanySet() {
    let setCompanyName: boolean = false;
    if (
      this.selectedCustomer?.isCommercial &&
      this.selectedCustomer?.companyName &&
      this.selectedCustomer.companyName.length > 0
    )
      setCompanyName = true;

    return setCompanyName;
  }

  get isFistNameSet() {
    return (
      this.selectedCustomer?.firstName &&
      this.selectedCustomer.firstName.length > 0
    );
  }

  get isLastNameSet() {
    let setLastName: boolean = false;

    if (
      this.selectedCustomer?.lastName &&
      this.selectedCustomer.lastName.length > 0
    )
      setLastName = true;

    return setLastName;
  }

  get isAddressSet() {
    return (
      this.selectedCustomer &&
      (this.isAddressLine1Set ||
        this.isAddressLine2Set ||
        this.isAddressCitySet ||
        this.isAddressPostalCodeSet)
    );
  }

  get isAddressLine1Set() {
    return (
      this.selectedCustomer?.addressLine1 &&
      this.selectedCustomer.addressLine1.length > 0
    );
  }

  get isAddressLine2Set() {
    return (
      this.selectedCustomer?.addressLine2 &&
      this.selectedCustomer.addressLine2.length > 0
    );
  }

  get isAddressCitySet() {
    return this.selectedCustomer?.city && this.selectedCustomer.city.length > 0;
  }

  get isAddressStateSet() {
    return (
      this.selectedCustomer?.state && this.selectedCustomer.state.length > 0
    );
  }

  get isAddressPostalCodeSet() {
    return (
      this.selectedCustomer?.postalCode &&
      this.selectedCustomer.postalCode.length > 0
    );
  }

  get isPhoneNumbersSet() {
    return (
      this.selectedCustomer &&
      (this.isPrimaryNumberSet ||
        this.isSecondaryNumberSet ||
        this.isTernaryNumberSet)
    );
  }

  get isPrimaryNumberSet() {
    return (
      this.selectedCustomer?.primaryNumber &&
      this.selectedCustomer.primaryNumber.length > 0
    );
  }

  get isSecondaryNumberSet() {
    return (
      this.selectedCustomer?.secondaryNumber &&
      this.selectedCustomer.secondaryNumber.length > 0
    );
  }

  get isTernaryNumberSet() {
    return (
      this.selectedCustomer?.ternaryNumber &&
      this.selectedCustomer.ternaryNumber.length > 0
    );
  }


  get isEmailSet() {
    return (
      this.selectedCustomer?.email && this.selectedCustomer.email.length > 0
    );
  }

  loadCustomer = async (id: string) => {
    this.loadingInitial = true;
    try {
      const result = await agent.customer.getCustomer(id);
      runInAction(() => {
        if (result.createdDate)
          result.createdDate = new Date(result.createdDate);
        if (result.updatedDate)
          result.updatedDate = new Date(result.updatedDate);
        this.selectedCustomer = result;
      });
      return result;
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.loadingInitial = false;
      });
    }
  };

  customerChangeLogsRegistry = new Map<string, CustomerChangeLog>();
  loadCustomerChangeLogs = async (id: string) => {
    this.loadingInitial = true;
    try {
      this.customerChangeLogsRegistry.clear();
      const result = await agent.customer.listCustomerChangeLogs(id);

      runInAction(() => {
        result.forEach((customerChangeLog) => {
          this.setCustomerChangeLog(customerChangeLog);
        });
      });
      return result;
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.loadingInitial = false;
      });
    }
  };

  private setCustomerChangeLog = (customer: CustomerChangeLog) => {
    if (customer.createdDate)
      customer.createdDate = new Date(customer.createdDate);
    this.customerChangeLogsRegistry.set(customer.id ?? "", customer);
  };

  get customerChangeLogs() {
    return Array.from(this.customerChangeLogsRegistry.values()).sort(
      (a, b) => b.createdDate!.getTime() - a.createdDate!.getTime()
    );
  }

  resetCustomerChangeLogs = () => {
    this.customerChangeLogsRegistry.clear();
  };

  customerRegistry = new Map<string | undefined, Customer>();

  loadCustomers = async () => {
    this.loadingInitial = true;
    try {
      this.customerRegistry.clear();
      const result = await agent.customer.listCustomers(this.axiosParams);
      runInAction(() => {
        result.data.forEach((customer) => {
          this.setCustomer(customer);
        });
      });
      this.setPagination(result.pagination);
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoadingInitial(false);
    }
  };

  private setCustomer = (customer: Customer) => {
    if (customer.createdDate) {
      customer.createdDate = new Date(customer.createdDate);
    }
    if (customer.updatedDate) {
      customer.updatedDate = new Date(customer.updatedDate);
    }

    this.customerRegistry.set(customer.id, customer);
  };

  get getCustomers() {
    return Array.from(this.customerRegistry.values()).sort((a, b) => {
      let temp =
        sortingStrings(
          a.lastName?.toLowerCase() ?? "",
          b.lastName?.toLowerCase() ?? ""
        ) ?? 0;
      if (temp === 0) {
        temp =
          sortingStrings(
            a.firstName?.toLowerCase() ?? "",
            b.firstName?.toLowerCase() ?? ""
          ) ?? 0;
      }
      return temp;
    });
  }

  clearCustomerRegistry = () => {
    this.customerRegistry.clear();
  };

  removeCustomer = async (id: string) => {
    try {
      await agent.customer.removeCustomer(id);
      runInAction(() => {
        this.customerRegistry.clear();
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  updateCustomer = async (values: CustomerFormValues) => {
    try {
      let myNew: Customer = new Customer(values);
      await agent.customer.updateCustomer(myNew);
      runInAction(() => {
        this.selectedCustomer = values;
        this.customerRegistry.clear();
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  selectedCustomerNote: CustomerNoteFormValues = {
    customerId: "",
    notes: "",
  };

  customerNotesRegistry = new Map<string | undefined, CustomerNote>();
  get customerNotes() {
    return Array.from(this.customerNotesRegistry.values()).sort(
      (a, b) => b.createdDate!.getTime() - a.createdDate!.getTime()
    );
  }

  setSelectedCustomerNote = (values: CustomerNoteFormValues) => {
    this.selectedCustomerNote = values;
  };

  loadCustomerNotes = async (customerId: string) => {
    this.loadingInitial = true;
    try {
      this.customerNotesRegistry.clear();
      const result = await agent.customer.listCustomerNotes(customerId);
      runInAction(() => {
        result.forEach((customerNote) => {
          this.setCustomerNote(customerNote);
        });
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoadingInitial(false);
    }
  };

  private setCustomerNote(customerNote: CustomerNote) {
    if (customerNote.createdDate)
      customerNote.createdDate = new Date(customerNote.createdDate);
    this.customerNotesRegistry.set(customerNote.id, customerNote);
  }
  private getCustomerNote(id: string) {
    return this.customerNotesRegistry.get(id);
  }

  createCustomerNotes = async (values: CustomerNoteFormValues) => {
    try {
      let myNew: CustomerNote = new CustomerNote(values);
      myNew.id = uuid();
      await agent.customer.addCustomerNote(myNew);

      runInAction(() => {
        this.customerNotesRegistry.clear();
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  updateCustomerNotes = async (values: CustomerNoteFormValues) => {
    try {
      let myNew: CustomerNote = new CustomerNote(values);

      await agent.customer.updateCustomerNote(myNew);

      runInAction(() => {
        if (values.id) {
          let customerNote = {
            ...this.getCustomerNote(values.id),
            ...values,
          };
          this.customerNotesRegistry.set(
            values.id,
            customerNote as CustomerNote
          );
        }
        this.setSelectedCustomerNote(values);
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  removeCustomerNotes = async (id: string) => {
    try {
      await agent.customer.removeCustomerNote(id);
      runInAction(() => {
        this.customerNotesRegistry.delete(id);
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  get axiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", this.pagingParams.pageNumber.toString());
    params.append("pageSize", this.pagingParams.pageSize.toString());
    this.predicate.forEach((value, key) => {
      if (key === "startDate") {
        params.append(key, (value as Date).toISOString());
      } else {
        params.append(key, value);
      }
    });
    return params;
  }

  setPredicate = (predicate: string, value?: string) => {
    const resetPredicate = () => {
      this.predicate.forEach((value, key) => {
        if (key !== "startDate") this.predicate.delete(key);
      });
    };
    resetPredicate();
    this.predicate.set(predicate, value);
  };

  potentialCustomerPredicate = (
    lastName: string,
    isBusiness: boolean,
    businessName: string
  ) => {
    this.predicate.clear();
    this.predicate.set("lastName", lastName);
    if (isBusiness) this.predicate.set("businessName", businessName);
  };

  resetFilter = () => {
    this.predicate.clear();
  };

  setPagination = (pagination: Pagination) => {
    this.pagination = pagination;
  };

  setPagingParams = (pagingParams: PagingParams) => {
    this.pagingParams = pagingParams;
  };

  get getPotentialCustomers() {
    return Array.from(this.potentialCustomerRegistry.values()).sort(
      (a, b) =>
      {
        //any with no recent tickets will be last setting to 0 will put the 1/1/1970 date in the place of it
        let date1 = new Date( b.recentTicket??0);
        let date2 = new Date( a.recentTicket??0);
        return date1.getTime() - date2.getTime()
      }    
    );
  }

  clearPotentialCustomers = () => {
    this.potentialCustomerRegistry.clear();
  };

  loadPotentialCustomersList = async () => {
    this.loadingInitial = true;
    try {
      this.potentialCustomerRegistry.clear();
      const result = await agent.customer.getPotentialCustomer(
        this.axiosParams
      );
      runInAction(() => {
        result.data.forEach((potentialCustomer) => {
          this.setPotentialCustomer(potentialCustomer);
        });
        this.setPotentialCustomerPagination(result.pagination);
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoadingInitial(false);
    }
  };

  setPotentialCustomerPagination = (pagination: Pagination) => {
    this.potentialCustomerPagination = pagination;
  };

  private setPotentialCustomer = (potentialCustomer: PotentialCustomer) => {
    if (potentialCustomer.recentTicket) potentialCustomer.recentTicket = new Date(potentialCustomer.recentTicket);
    this.potentialCustomerRegistry.set(potentialCustomer.id, potentialCustomer);
  };

  setPotentialCustomerToCustomer = (potentialCustomer: PotentialCustomer) => {
    if (!this.selectedCustomer)
      this.selectedCustomer = new CustomerFormValues();

    this.selectedCustomer.id = potentialCustomer.id;
    this.selectedCustomer.isCommercial = potentialCustomer.isCommercial;
    this.selectedCustomer.isTaxExempt = potentialCustomer.isTaxExempt;
    this.selectedCustomer.firstName = potentialCustomer.firstName;
    this.selectedCustomer.lastName = potentialCustomer.lastName;
    this.selectedCustomer.companyName = potentialCustomer.companyName;
    this.selectedCustomer.email = potentialCustomer.email;

    this.selectedCustomer.addressLine1 = potentialCustomer.addressLine1;
    this.selectedCustomer.addressLine2 = potentialCustomer.addressLine2;
    this.selectedCustomer.city = potentialCustomer.city;
    this.selectedCustomer.state = potentialCustomer.state;
    this.selectedCustomer.postalCode = potentialCustomer.postalCode;

    this.selectedCustomer.ternaryNumber = potentialCustomer.ternaryNumber;
    this.selectedCustomer.primaryNumber = potentialCustomer.primaryNumber;
    this.selectedCustomer.secondaryNumber = potentialCustomer.secondaryNumber;
  };
}
