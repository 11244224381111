import { makeAutoObservable } from "mobx";
import React from "react";

interface Modal {
  open: boolean;
  body: React.JSX.Element | null;
  className : string | null;
}

export default class ModalStore {
  modal: Modal = {
    open: false,
    body: null,
    className: null
    
  };

  constructor() {
    makeAutoObservable(this);
  }

  openWidestModal = (content: React.JSX.Element) => {
    this.modal.open = true;
    this.modal.body = content;
    this.modal.className = "widestModal";
  };

  openWiderModal = (content: React.JSX.Element) => {
    this.modal.open = true;
    this.modal.body = content;
    this.modal.className = "widerModal";
  };

  openTallModal = (content: React.JSX.Element) => {
    this.modal.open= true;
    this.modal.body= content;
    this.modal.className = "tallModal";
  }

  openModal = (content: React.JSX.Element) => {
    this.modal.open = true;
    this.modal.body = content;
    this.modal.className= "";
  };

  closeModal = () => {
    this.modal.open = false;
    this.modal.body = null;
  };

}
