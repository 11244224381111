import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../../app/stores/store";
import { View, Text } from "@react-pdf/renderer";
import compose from "../../../../app/layout/compose";
import { TicketType } from "../../../../app/models/enums";

export default observer(function VehiclePdfView() {
  const { t } = useTranslation(["common", "tickets"]);

  const { ticketSessionStore } = useStore();

  const { selectedTicketSession } = ticketSessionStore;

  return (
    <View
      style={{
        ...compose("view noPaddingLeftRight"),
        flex: 1,
        marginLeft: "1mm",
        marginRight: "0mm",
      }}
    >
      <View
        style={{
          ...compose(
            "view flex noPadding noMargin borderSolidBottom borderSolidRight borderSolidTop width114"
          ),
          flex: 1,
        }}
      >
        <View
          style={{
            ...compose(
              "view borderSolidLeft noMarginLeft noPaddingLeft width20 borderSolidRight"
            ),
            flex: 1,
          }}
        >
          <Text
            style={{
              ...compose(
                "text metaHead padding5pxRight padding1mmTop noMarginLeft noPaddingLeft"
              ),
              flex: 1,
            }}
          >
            {t("year", { ns: "common" })}
          </Text>
        </View>
        <View
          style={{
            ...compose(
              "view borderSolidRight padding5pxRight padding1mmTop padding5pxLeft noMarginLeft width31"
            ),
            flex: 1,
          }}
        >
          <Text
            style={{
              ...compose("text noMarginLeft "),
              flex: 1,
            }}
          >
            {(selectedTicketSession?.vehicle?.year ?? 0) > 1 &&
              selectedTicketSession?.vehicle?.year}
          </Text>
        </View>
        <View
          style={{
            ...compose(
              "view borderSolidRight noMarginLeft width21 noPaddingLeft"
            ),
            flex: 1,
          }}
        >
          <Text
            style={{
              ...compose(
                "text metaHead padding5pxRight padding1mmTop noMarginLeft noPaddingLeft"
              ),
              flex: 1,
            }}
          >
            {t("make", { ns: "common" })}
          </Text>
        </View>
        <View
          style={{
            ...compose("view padding1mmTop padding5pxLeft noMarginLeft width41"),
            flex: 1,
          }}
        >
          <Text
            style={{
              ...compose("text noMarginLeft "),
              flex: 1,
            }}
          >
            {selectedTicketSession?.vehicle?.makeName &&
              selectedTicketSession.vehicle.makeName}
          </Text>
        </View>
      </View>
      <View
        style={{
          ...compose(
            "view flex noPadding noMargin borderSolidBottom borderSolidRight width114"
          ),
          flex: 1,
        }}
      >
        <View
          style={{
            ...compose(
              "view borderSolidRight borderSolidLeft noMarginLeft noPaddingLeft width20"
            ),
            flex: 1,
          }}
        >
          <Text
            style={{
              ...compose(
                "text metaHead padding5pxRight padding1mmTop noMarginLeft noPaddingLeft "
              ),
              flex: 1,
            }}
          >
            {t("model", { ns: "common" })}
          </Text>
        </View>
        <View
          style={{
            ...compose(
              "view padding5pxRight padding1mmTop padding5pxLeft noMarginLeft width93"
            ),
            flex: 1,
          }}
        >
          <Text
            style={{
              ...compose("text noMarginLeft noPaddingLeft width93"),
              flex: 1,
            }}
          >
            {selectedTicketSession?.vehicle?.modelName &&
              selectedTicketSession.vehicle.modelName}
          </Text>
        </View>
      </View>
      <View
        style={{
          ...compose(
            "view flex noPadding noMargin borderSolidBottom borderSolidRight width114"
          ),
          flex: 1,
        }}
      >
        <View
          style={{
            ...compose(
              "view borderSolidRight borderSolidLeft noMarginLeft noPaddingLeft width20"
            ),
            flex: 1,
          }}
        >
          <Text
            style={{
              ...compose(
                "text metaHead padding5pxRight padding1mmTop noMarginLeft noPaddingLeft"
              ),
              flex: 1,
            }}
          >
            {t("engine", { ns: "common" })}
          </Text>
        </View>
        <View
          style={{
            ...compose(
              "view padding5pxRight padding1mmTop padding5pxLeft noMarginLeft width93"
            ),
            flex: 1,
          }}
        >
          <Text
            style={{
              ...compose("text noMarginLeft noPaddingLeft"),
              flex: 1,
            }}
          >
            {selectedTicketSession?.vehicle?.engineName &&
              selectedTicketSession.vehicle.engineName}
          </Text>
        </View>
      </View>
      <View
        style={{
          ...compose(
            "view flex noPadding noMargin borderSolidBottom borderSolidRight width114"
          ),
          flex: 1,
        }}
      >
        <View
          style={{
            ...compose(
              "view borderSolidRight borderSolidLeft noMarginLeft noPaddingLeft width20"
            ),
            flex: 1,
          }}
        >
          <Text
            style={{
              ...compose(
                "text metaHead padding5pxRight padding1mmTop noMarginLeft noPaddingLeft"
              ),
              flex: 1,
            }}
          >
            {t("estimate.vin", { ns: "tickets" })}
          </Text>
        </View>
        <View
          style={{
            ...compose(
              "view padding5pxRight padding1mmTop padding5pxLeft noMarginLeft width93"
            ),
            flex: 1,
          }}
        >
          <Text
            style={{
              ...compose("text noMarginLeft noPaddingLeft"),
              flex: 1,
            }}
          >
            {selectedTicketSession?.vehicle?.vin &&
              selectedTicketSession.vehicle.vin}
          </Text>
        </View>
      </View>
      <View
        style={{
          ...compose(
            "view flex noPadding noMargin borderSolidBottom borderSolidRight width114"
          ),
          flex: 1,
        }}
      >
        <View
          style={{
            ...compose(
              "view borderSolidRight borderSolidLeft noMarginLeft width20 noPaddingLeft"
            ),
            flex: 1,
          }}
        >
          <Text
            style={{
              ...compose(
                "text metaHead padding5pxRight padding1mmTop noMarginLeft noPaddingLeft "
              ),
              flex: 1,
            }}
          >
            {t("tag", { ns: "common" })}
          </Text>
        </View>
        <View
          style={{
            ...compose(
              "text borderSolidRight padding5pxLeft padding1mmTop noMarginLeft width31"
            ),
            flex: 1,
          }}
        >
          <Text>
            {selectedTicketSession?.vehicle?.tag &&
              selectedTicketSession.vehicle.tag}
          </Text>
        </View>
        <View
          style={{
            ...compose(
              "view borderSolidRight noMarginLeft width21 noPaddingLeft"
            ),
            flex: 1,
          }}
        >
          <Text
            style={{
              ...compose(
                "text metaHead padding5pxRight padding1mmTop noMarginLeft noPaddingLeft"
              ),
              flex: 1,
            }}
          >
            {t("vehicle", { ns: "common" })} #
          </Text>
        </View>
        <View
          style={{
            ...compose(
              "view padding5pxRight padding1mmTop padding5pxLeft noMarginLeft width41"
            ),
            flex: 1,
          }}
        >
          <Text>
            {selectedTicketSession?.vehicle?.vehicleNumber &&
              selectedTicketSession.vehicle.vehicleNumber}
          </Text>
        </View>
      </View>
      <View
        style={{
          ...compose(
            "view flex noPadding noMargin borderSolidRight borderSolidTop borderSolidBottom width114"
          ),
          flex: 1,
          marginTop: "1mm",
        }}
      >
        <View
          style={{
            ...compose(
              "view borderSolidRight borderSolidLeft noMarginLeft noPaddingLeft width20"
            ),
            flex: 1,
          }}
        >
          <Text
            style={{
              ...compose(
                "text metaHead padding5pxRight padding1mmTop noMarginLeft noPaddingLeft"
              ),
              flex: 1,
            }}
          >
            {t("estimate.mileageIn", { ns: "tickets" })}
          </Text>
        </View>
        <View
          style={{
            ...compose(
              "view padding1mmTop borderSolidRight padding5pxLeft noMarginLeft width31"
            ),
            flex: 1,
          }}
        >
          <Text
            style={{
              ...compose("text noMarginLeft "),
              flex: 1,
            }}
          >
            {selectedTicketSession?.vehicle?.mileageIn &&
            selectedTicketSession?.vehicle?.mileageIn > 0
              ? selectedTicketSession.vehicle.mileageIn
              : " "}
          </Text>
        </View>
        <View
          style={{
            ...compose(
              `view borderSolidRight noMarginLeft width21 noPaddingLeft`
            ),
            flex: 1,
          }}
        >
          <Text
            style={{
              ...compose(
                `text metaHead padding1mmTop noMarginLeft noPaddingLeft`
              ),
              flex: 1,
            }}
          >
            {selectedTicketSession?.ticketType === TicketType.Estimate
              ? t("estimate.parts", { ns: "tickets" })
              : t("estimate.mileageOut", { ns: "tickets" })}
          </Text>
        </View>
        <View
          style={{
            ...compose(
              `view padding5pxRight padding1mmTop noMarginLeft ${
                selectedTicketSession?.ticketType === TicketType.Estimate
                  ? "width41"
                  : "width34"}`
            ),
            flex: 1,
            flexGrow: 1,
            flexShrink: 1
          }}
        >
          <Text
            style={{
              ...compose("text padding5pxRight padding5pxLeft noMarginLeft "),
              flex: 1,

            }}
          >
            {selectedTicketSession?.ticketType === TicketType.Estimate
              ? selectedTicketSession?.returnParts
                ? `${t("return_parts", { ns: "tickets" })}`
                : `${t("doNotReturnParts", { ns: "tickets" })}`
              : selectedTicketSession?.vehicle?.mileageOut &&
                selectedTicketSession?.vehicle?.mileageOut > 0 &&
                selectedTicketSession.vehicle.mileageOut}
          </Text>
        </View>
      </View>
    </View>
  );
});
