import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import {
  Divider,
  Grid,
  Button,
  Icon,
  Image,
  Select,
  Card,
  Input,
  Message,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import TimeControl from "./TimeControl";
import PageHeader from "../../../app/layout/PageHeader";
import Sidebar from "./Sidebar";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../../app/stores/store";
import { TicketWorkflowSteps, TimeType } from "../../../app/models/enums";
import SelectJobAddons from "./SelectJobAddons";
import {
  formatCurrency,
  round2Decimals,
} from "../../../app/common/util/functions";
import { JobAddOn } from "../../../app/models/jobLabor";
import { Labor } from "../../../app/models/labor";
import { SettingsLaborRates } from "../../../app/models/pricing";
import PotentialCustomerMatchingMessage from "../../customers/PotentialCustomerMatchingMessage";
import SummaryButton from "./SummaryButton";
import { MiscellaneousCharge } from "../../../app/models/miscellaneousCharges";

interface Props {
  autoCapitalizeFirstLetter: boolean;
  alwaysMiscellaneousChargeItems: MiscellaneousCharge[];
}

export default observer(function SelectTime({
  autoCapitalizeFirstLetter,
  alwaysMiscellaneousChargeItems,
}: Props) {
  const { t } = useTranslation(["common", "translation"]);
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { complete } = useParams<{ complete: string }>();

  const {
    ticketSessionStore,
    vehicleStore,
    pricingStore,
    jobLaborStore,
    modalStore,
    ticketStore,
    customerStore,
    userStore,
  } = useStore();

  const {
    selectedTicketSession,
    updateTicketSession,
    selectedJobLabor,
    newSelectedJobLabor,
    getJobAddonsList,
    updatesJobLabor,
    addLabor,
    jobLaborRegistry,
    handleSetSelectedRate,
    setSelectedJobLaborJobRequiresEngine,
  } = ticketSessionStore;
  const { selectedVehicle } = vehicleStore;
  const { selectedCustomer } = customerStore;
  const { user, resendEmail } = userStore;
  const {
    dropdownLaborRatesList,
    getActiveLaborRates,
    activeLaborRates,
    selectedSettingsPricing,
    getPrimaryLaborRate,
    getLaborRate,
  } = pricingStore;

  const { handleImage, loadLabor, getJobRequiresEngine, loadCustomLabor } =
    jobLaborStore;

  const { selectedSettingsTicketFormatting, loadSettingsTicketFormatting } =
    ticketStore;

  const [engineRequired, setEngineRequired] = useState<boolean>(
    selectedVehicle.engineId === "-1" &&
      (selectedJobLabor?.job?.jobRequiresEngine ?? false)
  );

  const [primaryLaborRate] = useState<SettingsLaborRates | undefined>(
    getPrimaryLaborRate()
  );

  useEffect(() => {
    if (selectedJobLabor?.job?.jobId) {
      if (selectedVehicle.isCustom) {
        loadCustomLabor(selectedVehicle, selectedJobLabor.job.jobId).then(
          (labor) => {
            setCustomTime(labor?.customTime);
            setCustomAmount(
              round2Decimals(
                round2Decimals(
                  labor?.customRate ?? primaryLaborRate?.rate ?? 0
                ) * round2Decimals(labor?.customTime ?? 0)
              )
            );
            addLabor(labor ?? new Labor());
            setMyLabor(labor ?? new Labor());
          }
        );
      } else {
        getJobRequiresEngine(selectedJobLabor.job.jobId).then((result) => {
          setEngineRequired(
            selectedVehicle.engineId === "-1" && (result ?? false)
          );
          setSelectedJobLaborJobRequiresEngine(result ?? false);

          if (!engineRequired) {
            loadLabor(selectedVehicle, selectedJobLabor.job?.jobId ?? 0).then(
              (result) => {
                if (result) {
                  if (result.highTime) setHighTime(result.highTime);
                  if (result.averageTime) setAverageTime(result.averageTime);
                  if (result.lowTime) setLowTime(result.lowTime);

                  if (result.customTime && result.customRate) {
                    setCustomTime(result.customTime);
                    setCustomAmount(
                      round2Decimals(
                        round2Decimals(
                          result.customRate ?? primaryLaborRate?.rate ?? 0
                        ) * round2Decimals(result.customTime ?? 0)
                      )
                    );
                  }

                  if (result.hasJobAddon && selectedTicketSession) {
                    let addons = getJobAddonsList(
                      Array.from(jobLaborRegistry.values()),
                      result
                    );

                    if (user?.isDataActive) {
                      if (addons && addons.length > 0) {
                        modalStore.openModal(
                          <SelectJobAddons
                            addonTime={getAddonTime}
                            addonList={addons}
                          />
                        );
                      } else if (
                        result.hasJobAddon &&
                        selectedJobLabor.selectedJobAddons?.length > 0
                      ) {
                        getAddonTime(selectedJobLabor.selectedJobAddons);
                      }
                    }
                  }

                  //these need to be added last because if I don't it breaks the labor addons
                  addLabor(result);
                  setMyLabor(result);
                }
              }
            );
          }
        });
      }
    }
  }, [
    loadLabor,
    getJobAddonsList,
    getJobRequiresEngine,
    jobLaborRegistry,
    loadCustomLabor,
    selectedJobLabor?.job?.jobId,
    selectedJobLabor?.selectedJobAddons,
    addLabor,
    modalStore,
    selectedTicketSession,
    selectedVehicle,
    setSelectedJobLaborJobRequiresEngine,
    user?.isDataActive,
    engineRequired,
    primaryLaborRate?.rate,
  ]);

  const [myLabor, setMyLabor] = useState<Labor>(new Labor());

  useEffect(() => {
    if (!selectedSettingsTicketFormatting) loadSettingsTicketFormatting();
  }, [loadSettingsTicketFormatting, selectedSettingsTicketFormatting]);

  useEffect(() => {
    getActiveLaborRates(selectedVehicle.engineId !== "-1");
  }, [getActiveLaborRates, selectedVehicle.engineId]);

  const [highTime, setHighTime] = useState<number>(
    user?.isDataActive && myLabor.highTime ? myLabor.highTime : 0
  );
  const [averageTime, setAverageTime] = useState<number>(
    user?.isDataActive && myLabor.averageTime ? myLabor.averageTime : 0
  );
  const [lowTime, setLowTime] = useState<number>(
    user?.isDataActive && myLabor.lowTime ? myLabor.lowTime : 0
  );

  const [addonTime, setAddonTime] = useState<number>(0);

  const [selectedRate, setSelectedRate] = useState<
    SettingsLaborRates | undefined
  >(primaryLaborRate);

  function isTimeActive(value: TimeType) {
    let conditions: boolean =
      !selectedVehicle.isCustom &&
      (user?.isDataActive ?? false) &&
      !engineRequired;

    return conditions && (activeLaborRates & value) === value;
  }

  function getAddonTime(jobAddons: JobAddOn[]) {
    let time: number = 0;

    if (jobAddons) {
      jobAddons.forEach((addon) => {
        if (addon.time) {
          time += addon.time;
        }
      });
    }
    setAddonTime(time);
  }

  function calculateAmount(time: number, rate: number) {
    let amount: number = 0;
    amount = Number(time) * Number(rate);
    return amount;
  }

  const [customTime, setCustomTime] = useState<number | undefined>();
  const [customAmount, setCustomAmount] = useState<number | undefined>();

  function handleSelection(timeType: TimeType, isCustom: boolean) {
    if (selectedJobLabor && id) {
      let time: number = customTime ?? 1;
      let amount: number = customAmount ?? 1;
      let rate: number = selectedRate?.rate ?? 0;
      let rateId: string | undefined = selectedRate?.id;

      if (!isCustom) {
        switch (timeType) {
          case TimeType.Low:
            time = lowTime;
            break;
          case TimeType.Average:
            time = averageTime;
            break;
          case TimeType.High:
            time = highTime;
            break;
        }
      } else if (Number(time) * Number(rate) !== amount && time !== 0) {
        rate = Number(amount) / Number(time);
        //need to fix the rate
        rateId = undefined;
      }

      let totalTime = time;

      if (!isCustom) {
        totalTime = round2Decimals(time + addonTime);
      }

      handleSetSelectedRate(
        myLabor,
        timeType,
        rateId ?? "",
        rate,
        time,
        totalTime,
        isCustom,
        activeLaborRates,
        true,
        selectedSettingsTicketFormatting.showRemoveAndReplace
      );

      if (myLabor) {
        addLabor(myLabor);
      }

      updatesJobLabor(selectedJobLabor);
      updateTicketSession(
        TicketWorkflowSteps.Time,
        id,
        selectedVehicle,
        selectedCustomer
      ).then(() => {
        let jobTypeSet: boolean = selectedJobLabor.job?.jobTypeId !== undefined;
        newSelectedJobLabor();
        if (jobTypeSet) navigate(`/ticket/job/${id}/${complete}`);
        else {
          newSelectedJobLabor();
          navigate(`/ticket/jobCategory/${id}/${complete}`);
        }
      });
    }
  }

  return (
    <Grid stackable>
      <Grid.Column width={11}>
        <PotentialCustomerMatchingMessage
          workflowStep={TicketWorkflowSteps.Finalize}
        />
        <Grid stackable>
          <Grid.Row columns={2}>
            <Grid.Column verticalAlign="bottom">
              <PageHeader
                type="h1"
                header={t("select_time", { ns: "tickets" }).toString()}
                className="modal-text-color"
                textAlign="left"
                divider={false}
                addTitle={true}
              />
            </Grid.Column>
            <Grid.Column verticalAlign="bottom">
              <Button
                floated="right"
                className="no_padding_image_buttons"
                type="button"
                onClick={() => {
                  navigate(`/ticket/jobCategory/${id}/${complete}`);
                }}
              >
                <Image
                  src={
                    selectedJobLabor?.job?.jobCategoryId &&
                    handleImage(selectedJobLabor.job.jobCategoryId)
                  }
                />
              </Button>
              <SummaryButton
                id={id ?? ""}
                selectedVehicle={selectedVehicle}
                selectedCustomer={selectedCustomer}
                completed={complete ?? "false"}
                alwaysMiscellaneousChargeItems={alwaysMiscellaneousChargeItems}
              />
              <Button
                floated="right"
                onClick={() => {
                  if (selectedJobLabor?.job?.jobTypeId)
                    navigate(`/ticket/job/${id}/${complete}`);
                  else navigate(`/ticket/jobCategory/${id}/${complete}`);
                }}
              >
                <Icon name="caret left" />
                {t("back", { ns: "tickets" })}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider />
        <Grid stackable>
          <Grid.Row>
            <Grid.Column>
              <span className="myLabel">{`${
                t("job", { ns: "tickets" }) + ":"
              } ${selectedJobLabor?.job?.job}`}</span>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column verticalAlign={"middle"}>
              <span className="myLabel">
                {t("rate", { ns: "tickets" }) + ": "}
                <Select
                  //value =
                  className="selection dropdown"
                  options={dropdownLaborRatesList}
                  defaultValue={selectedRate ? selectedRate.id : undefined}
                  onChange={(d, e) => {
                    if (e.value) {
                      if (selectedSettingsPricing?.laborRates) {
                        let rate = getLaborRate(e.value.toString());
                        setSelectedRate(rate);
                      }
                    }
                  }}
                />
              </span>
            </Grid.Column>
          </Grid.Row>
          {selectedVehicle.isCustom && (
            <Grid.Row>
              <Grid.Column>
                <Message color="yellow">
                  <p>
                    <strong>{t("notice", { ns: "tickets" })}</strong>{" "}
                    {t("noticeCustomVehicle", { ns: "tickets" })}
                  </p>
                  <p>{t("addCustomTime", { ns: "tickets" })}</p>
                </Message>
              </Grid.Column>
            </Grid.Row>
          )}
          {!user?.isDataActive && (
            <Grid.Row>
              <Grid.Column>
                <Message color="yellow">
                  <p>{t("dataActive.laborTimes", { ns: "errors" })}</p>
                  <p>{t("dataActive.signUp", { ns: "errors" })} </p>
                  <Button
                    onClick={() => {
                      resendEmail();
                    }}
                    color="blue"
                  >
                    {t("dataActive.resendVerification", { ns: "errors" })}
                  </Button>{" "}
                  <p>{t("dataActive.contactSupport", { ns: "errors" })}</p>
                  <p>
                    {t("dataActive.approval", { ns: "errors" })}{" "}
                    <Link to={"/settings"}>
                      {t("dataActive.settings", { ns: "errors" })}
                    </Link>
                    . {t("dataActive.customizations", { ns: "errors" })}
                  </p>
                </Message>
              </Grid.Column>
            </Grid.Row>
          )}

          {engineRequired && (
            <Grid.Row>
              <Grid.Column>
                <Message color="yellow">
                  <p>
                    <strong>{t("notice", { ns: "tickets" })}</strong>{" "}
                    {t("noEngine", { ns: "tickets" })}
                  </p>
                  <p>{t("selectEngine", { ns: "tickets" })}</p>
                </Message>
              </Grid.Column>
            </Grid.Row>
          )}
          <Grid.Row centered className="fill-height">
            {!selectedVehicle.isCustom &&
              user?.isDataActive &&
              isTimeActive(TimeType.High) && (
                <Grid.Column mobile={16} tablet={8} computer={4}>
                  <TimeControl
                    name={"high"}
                    header={t("high", { ns: "tickets" })}
                    hours={round2Decimals(highTime + addonTime).toString()}
                    amount={formatCurrency(
                      calculateAmount(
                        highTime + addonTime,
                        selectedRate ? selectedRate.rate : 0
                      )
                    )}
                    className={"fill-height twoHundoWidth"}
                    timeType={TimeType.High}
                    onClick={handleSelection}
                  />
                </Grid.Column>
              )}
            {!selectedVehicle.isCustom &&
              user?.isDataActive &&
              isTimeActive(TimeType.Average) && (
                <Grid.Column mobile={16} tablet={8} computer={4}>
                  <TimeControl
                    name={"average"}
                    header={t("average", { ns: "tickets" })}
                    hours={round2Decimals(averageTime + addonTime).toString()}
                    amount={formatCurrency(
                      calculateAmount(
                        averageTime + addonTime,
                        selectedRate ? selectedRate.rate : 0
                      )
                    )}
                    className={"fill-height twoHundoWidth"}
                    timeType={TimeType.Average}
                    onClick={handleSelection}
                  />
                </Grid.Column>
              )}
            {!selectedVehicle.isCustom &&
              user?.isDataActive &&
              isTimeActive(TimeType.Low) && (
                <Grid.Column mobile={16} tablet={8} computer={4}>
                  <TimeControl
                    name={"low"}
                    header={t("low", { ns: "tickets" })}
                    hours={round2Decimals(lowTime + addonTime).toString()}
                    amount={formatCurrency(
                      calculateAmount(
                        lowTime + addonTime,
                        selectedRate ? selectedRate.rate : 0
                      )
                    )}
                    className={"fill-height twoHundoWidth"}
                    timeType={TimeType.Low}
                    onClick={handleSelection}
                  />
                </Grid.Column>
              )}
            <Grid.Column mobile={16} tablet={8} computer={4}>
              <Card className="twoHundoWidth">
                <Card.Header className="card-header" textAlign="center">
                  {t("custom_time", { ns: "tickets" })}
                </Card.Header>
                <Card.Content textAlign="center">
                  <Grid>
                    <Grid.Row>
                      <Grid.Column>
                        <Input
                          iconPosition={"left"}
                          className={"input width170"}
                          name={"customTime"}
                          type={"number"}
                          icon={"clock"}
                          value={customTime}
                          onChange={(e, d) => {
                            if (e.currentTarget.value) {
                              setCustomTime(
                                round2Decimals(Number(e.currentTarget.value))
                              );
                              setCustomAmount(
                                round2Decimals(
                                  round2Decimals(
                                    Number(e.currentTarget.value)
                                  ) *
                                    round2Decimals(
                                      selectedRate ? selectedRate.rate : 1
                                    )
                                )
                              );
                            }
                          }}
                          maxLength={100}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <Input
                          iconPosition={"left"}
                          className={"input width170"}
                          name={"customTime"}
                          type={"number"}
                          icon={"dollar"}
                          value={customAmount}
                          onChange={(e, d) => {
                            if (e.currentTarget.value) {
                              setCustomAmount(
                                round2Decimals(Number(e.currentTarget.value))
                              );
                              setCustomTime(1);
                            }
                          }}
                          maxLength={100}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Card.Content>
                <Card.Content extra>
                  <Button
                    fluid
                    color="green"
                    floated="right"
                    onClick={() => {
                      handleSelection(TimeType.Custom, true);
                    }}
                  >
                    <Icon name="thumbs up" />
                    {t("add_button", { ns: "tickets" })}
                  </Button>
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              {user?.isDataActive && myLabor?.hasJobComment && (
                <Message info>
                  <>
                    {myLabor.comments.map((comment) => (
                      <Message.Item key={comment.commentId}>
                        {comment.description}
                      </Message.Item>
                    ))}
                  </>
                </Message>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Grid.Column>
      <Grid.Column width={5}>
        <Grid>
          <Grid.Row>
            <Grid.Column></Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Sidebar
              workflowStep={TicketWorkflowSteps.Time}
              autoCapitalizeFirstLetter={autoCapitalizeFirstLetter}
            />
          </Grid.Row>
        </Grid>
      </Grid.Column>
    </Grid>
  );
});
