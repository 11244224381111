import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Divider,
  Form,
  Grid,
  Message,
} from "semantic-ui-react";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { useStore } from "../../app/stores/store";
import { useTranslation } from "react-i18next";
import CreditCard from "../../app/common/form/CreditCard";
import PageHeader from "../../app/layout/PageHeader";
import * as Yup from "yup";
import { ErrorMessage, Formik, FormikErrors } from "formik";
import ValidationErrors from "../errors/ValidationErrors";
import { ClientSubscriptionsFormValues } from "../../app/models/clientSubscription";
import { Link, useNavigate } from "react-router-dom";
import sslBadgeGif from "../../app/common/img/ssl_badge.gif";
import creditCardLogos from "../../app/common/img/credit_card_logos.gif";
import sslBadgePng from "../../app/common/img/ssl-badge.png";
import { RegexConstants } from "../../app/common/util/regexConstants";
import {
  creditCardValidationLuhnCheck,
  expirationDateCheck,
  formatCurrency,
} from "../../app/common/util/functions";
import MyRadioGroup from "../../app/common/form/RadioButtonGroups/MyRadioGroup";
import { serviceTypeOptions } from "../../app/common/options/option";
import SubscriptionDisplay from "./SubscriptionDisplay";
import BillingAddress from "./BillingAddress";
import SubscriptionMobileMenu from "./SubscriptionMobileMenu";
import SubscriptionContent from "./SubscriptionContent";
import { toast } from "react-toastify";
import MyCheckbox from "../../app/common/form/MyCheckbox";
import BillingAddressForm from "../../app/common/form/BillingAddressForm";

export default observer(function ServiceType() {
  const { subscriptionStore, userStore } = useStore();
  const { getUser } = userStore;
  const {
    updateServiceOptions,
    loadSubscription,
    prorateSubscription,
    calculateSubscription,
    loadingInitial,
    getCurrentSubscription,
    clientSubscriptionStatus,
  } = subscriptionStore;

  const { t } = useTranslation(["common", "subscription"]);
  const navigate = useNavigate();

  const [readMoar, setReadMoar] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    loadSubscription().then((result) => {
      setClientSubscriptions(new ClientSubscriptionsFormValues(result));
      calculateSubscription(
        result?.dowellSystemApplication ?? 0,
        result?.userCount ?? 0,
        result?.term ?? 0
      )
        .then((result) => {
          setCurrentSubscriptionAmount(result);
        })
        .catch();
    }).finally(()=> setLoading(false));
  }, [loadSubscription]);

  useEffect(() => {
    getCurrentSubscription()
      .then((result) => {
        if (result?.isCancelled) navigate("/subscription/status");
      })
      .catch();
  }, [getCurrentSubscription]);

  const [currentSubscriptionAmount, setCurrentSubscriptionAmount] =
    useState<number>(0.0);

  const [clientSubscriptions, setClientSubscriptions] =
    useState<ClientSubscriptionsFormValues>(
      new ClientSubscriptionsFormValues()
    );

  const [newSubscriptionAmount, setNewSubscriptionAmount] =
    useState<number>(0.0);

  const [proRatedAmount, setProRatedAmount] = useState<number>(0.0);

  function handleFormSubmit(
    values: ClientSubscriptionsFormValues,
    setErrors: (errors: FormikErrors<ClientSubscriptionsFormValues>) => void,
    setSubmitting: (isSubmitting: boolean) => void,
  ) {
    updateServiceOptions(values)
      .then(() => {
        getUser();
        setSubmitting(false);
        navigate("/subscription/status");
        toast.success(
          proRatedAmount > 0
            ? t("toast.paymentSuccessful", { ns: "subscription" }).toString()
            : t("toast.refundSuccessful", { ns: "subscription" }).toString()
        );
      })
      .catch((error) => {
        setSubmitting(false);
        setErrors({ error: error });
      });
  }

  const validationSchema = Yup.object({
    dowellSystemApplication: Yup.number()
      .test("application-different", "", (dowellSystemApplication) => {
        return (
          dowellSystemApplication != clientSubscriptions.dowellSystemApplication
        );
      })
      .required(t("subscription.serviceType", { ns: "errors" })),
      firstName: Yup.string().required(
        t("registration.required_FirstName", { ns: "errors" })
      ),
      lastName: Yup.string().required(
        t("registration.required_LastName", { ns: "errors" })
      ),
      email: Yup.string()
        .email(t("registration.invalid_Email", { ns: "errors" }))
        .required(t("registration.required_Email", { ns: "errors" })),
      addressLine1: Yup.string().required(
        t("registration.required_address", { ns: "errors" })
      ),
      city: Yup.string().required(
        t("registration.required_city", { ns: "errors" })
      ),
      state: Yup.string().required(
        t("registration.required_state", { ns: "errors" })
      ),
      postalCode: Yup.string().required(
        t("registration.required_postalcode", { ns: "errors" })
      ),
      country: Yup.string().required(
        t("registration.required_country", { ns: "errors" })
      ),
      billingPhone: Yup.string().required(
        t("registration.required_PhoneNumber", { ns: "errors" })
      ),  
    creditCardNumber: Yup.string()
      .when("useCreditCardOnFile", {
        is: false,
        then: Yup.string()
          .required(t("subscription.creditCardRequired", { ns: "errors" }))
          .test(
            "test-card-number",
            t("subscription.creditCardInvalid", { ns: "errors" }),
            (item, testContext) => {
              return creditCardValidationLuhnCheck(
                testContext.parent.creditCardNumber ?? ""
              );
            }
          )

          .matches(
            RegexConstants.creditCardValidation,
            t("subscription.creditCardInvalid", { ns: "errors" })
          )
          .nullable(),
      })
      .nullable(),
    expirationMonth: Yup.string()
      .when("useCreditCardOnFile", {
        is: false,
        then: Yup.string()
          .required(t("subscription.expirationMonthRequired", { ns: "errors" }))
          .test(
            "validate-expiry-month",
            t("subscription.expirationMonthInvalid", { ns: "errors" }),
            (item, testContext) => {
              return expirationDateCheck(
                testContext.parent.expirationYear,
                testContext.parent.expirationMonth
              );
            }
          )
          .nullable(),
      })
      .nullable(),
    expirationYear: Yup.string()
      .when("useCreditCardOnFile", {
        is: false,
        then: Yup.string()
          .required(t("subscription.expirationYearRequired", { ns: "errors" }))
          .test(
            "validate-expiry-year",
            t("subscription.expirationYearInvalid", { ns: "errors" }),
            (item, testContext) => {
              return expirationDateCheck(
                testContext.parent.expirationYear,
                testContext.parent.expirationMonth
              );
            }
          )
          .nullable(),
      })
      .nullable(),
    securityCode: Yup.string()
      .when("useCreditCardOnFile", {
        is: false,
        then: Yup.string()
          .required(t("subscription.cvvRequired", { ns: "errors" }))
          .matches(
            RegexConstants.creditCardCVVValidation,
            t("subscription.cvvInvalid", { ns: "errors" })
          )
          .nullable(),
      })
      .nullable(),
  });

  if (loadingInitial)
    return <LoadingComponent content={t("loading", { ns: "subscription" })} />;

  return (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <SubscriptionMobileMenu
              isCancelled={clientSubscriptionStatus.isCancelled}
            />
            <SubscriptionContent
              isCancelled={clientSubscriptionStatus.isCancelled}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <PageHeader
        header={t("service_type.label", { ns: "common" })}
        type={"h1"}
        divider={true}
        getAlerts={true}
        addTitle={true}
      />
      <Container className="page">
        <Formik
          initialValues={clientSubscriptions}
          enableReinitialize
          onSubmit={(values, { setErrors, setSubmitting }) =>
            handleFormSubmit(values, setErrors, setSubmitting)
          }
          validationSchema={validationSchema}
        >
          {({
            handleSubmit,
            isSubmitting,
            errors,
            isValid,
            dirty,
            values,
            touched,
            setFieldValue,
            setFieldTouched,
          }) => (
            <Form
              className="ui form error"
              onSubmit={handleSubmit}
              autoComplete="off"
            >
              <Grid stackable>
                <Grid.Row>
                  <Grid.Column>
                    <Message info>
                      <Grid>
                        <Grid.Row>
                          <Grid.Column>
                            {`${t("subscription.service_type_message", {
                              ns: "subscription",
                            })} `}

                            <Button
                              type={"button"}
                              floated="right"
                              size="tiny"
                              icon={readMoar ? "minus" : "plus"}
                              content={t(readMoar ? "readLess" : "readMore", {
                                ns: "subscription",
                              })}
                              onClick={() => setReadMoar(!readMoar)}
                            ></Button>
                          </Grid.Column>
                        </Grid.Row>
                        {readMoar && (
                          <Grid.Row>
                            <Grid.Column>
                              <>
                                {`${t("subscription.chargeProrated", {
                                  ns: "subscription",
                                })}`}
                                <br />
                                <br />
                                <strong>{`${t("quickvin.p2_bold", {
                                  ns: "advancedSettings",
                                })}:`}</strong>
                                {` ${t(
                                  "subscription.noChangeToSubscriptionProfile",
                                  {
                                    ns: "subscription",
                                  }
                                )}.`}{" "}
                                <Link to={"/subscription/BillingInformation"}>
                                  {t("billingInformation", {
                                    ns: "subscription",
                                  })}
                                </Link>
                              </>
                            </Grid.Column>
                          </Grid.Row>
                        )}
                      </Grid>
                    </Message>
                    <ErrorMessage
                      name="error"
                      render={() => <ValidationErrors errors={errors.error} />}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <PageHeader
                      header={t("service_type.label", { ns: "common" })}
                      type={"h2"}
                      divider={true}
                      getAlerts={true}
                      addTitle={false}
                    />
                    <MyRadioGroup
                      name={"dowellSystemApplication"}
                      options={serviceTypeOptions}
                      inline={true}
                      label={`${t("service_type.label", {
                        ns: "common",
                      })}:`}
                      onChange={(value) => {
                        if (
                          value ==
                          clientSubscriptions.dowellSystemApplication?.toString()
                        ) {
                          setProRatedAmount(0.0);
                        } else {
                          prorateSubscription(
                            Number(value),
                            values.userCount ?? 0,
                            values.term ?? 0
                          ).then((result) => {
                            if (result && result < 0) {
                              setFieldValue("useCreditCardOnFile", true);
                              values.useCreditCardOnFile = true;
                            }
                            setProRatedAmount(result);
                          });
                          calculateSubscription(
                            Number(value),
                            values.userCount ?? 0,
                            values.term ?? 0
                          )
                            .then((result) => {
                              setNewSubscriptionAmount(result);
                            })
                            .catch();
                        }
                      }}
                    />
                    <br />
                    <label className="myLabel">
                      {`${t("subscription.prorated", {
                        ns: "subscription",
                      })}: `}
                      {formatCurrency(proRatedAmount)}
                    </label>
                  </Grid.Column>
                  <Grid.Column>
                    <SubscriptionDisplay
                      clientSubscription={values}
                      subscriptionAmount={
                        values.dowellSystemApplication ==
                        clientSubscriptions.dowellSystemApplication
                          ? currentSubscriptionAmount
                          : newSubscriptionAmount
                      }
                      isNew={
                        values.dowellSystemApplication !=
                        clientSubscriptions.dowellSystemApplication
                      }
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <MyCheckbox
                      label={`${t("subscription.useCreditCardOnFile", {
                        ns: "subscription",
                      })}`}
                      name={"useCreditCardOnFile"}
                      disabled={proRatedAmount < 0}
                    ></MyCheckbox>
                  </Grid.Column>
                </Grid.Row>
                {values.useCreditCardOnFile ? (
                  <BillingAddress clientSubscription={clientSubscriptions} />
                ) : (
                  <BillingAddressForm></BillingAddressForm>
                )}
                <CreditCard
                  showCreditCardFields={!values.useCreditCardOnFile}
                ></CreditCard>
                <Divider />
                <Grid.Row>
                  <Grid.Column>
                    <label className="myLabel">
                      {`${t("subscription.today_amount_due", {
                        ns: "subscription",
                      })}: `}
                      {formatCurrency(proRatedAmount)}
                    </label>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Button
                      disabled={!isValid || !dirty || isSubmitting}
                      loading={isSubmitting}
                      className="save_button modal-button-color"
                      positive
                      content={t("update", { ns: "common" })}
                      type="submit"
                    />
                    <Button
                      className="save_button"
                      as={Link}
                      to="/subscription/status"
                      icon="cancel"
                      type="reset"
                      content={t("cancel", { ns: "common" })}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={4} centered>
                  <Grid.Column>
                    <a
                      href="https://verify.authorize.net/anetseal/?pid=04dbc10d-46f3-41e1-80cb-6a3c1737e082"
                      onClick={() => {
                        window.open(
                          "https://verify.authorize.net/anetseal/?pid=04dbc10d-46f3-41e1-80cb-6a3c1737e082",
                          "AuthorizeNetVerification",
                          "width=600,height=430,dependent=yes,resizable=yes,scrollbars=yes,menubar=no,toolbar=no,status=no,directories=no,location=yes"
                        );
                        return false;
                      }}
                      className="text-center"
                      target="_blank"
                      title="http://www.authorize.net/"
                    >
                      <img
                        src="https://verify.authorize.net/anetseal/images/secure90x72.gif"
                        alt="Authorize.Net Merchant - Click to Verify"
                        width="90"
                        height="72"
                      />
                    </a>
                  </Grid.Column>
                  <Grid.Column>
                    <img alt="" src={sslBadgeGif} />
                  </Grid.Column>

                  <Grid.Column>
                    <img alt="" src={creditCardLogos} />
                  </Grid.Column>

                  <Grid.Column>
                    <img alt="" src={sslBadgePng} />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
});
