import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import {
  Button,
  Container,
  Form,
  Grid,
  Icon,
  Label,
  Message,
  Radio,
  Table,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { ErrorMessage, Formik, FormikErrors } from "formik";
import { Link } from "react-router-dom";
import { SettingsTechnicianFormValues } from "../../app/models/technician";
import {
  assignmentOptions,
  yesNoOptions,
} from "../../app/common/options/option";
import PageHeader from "../../app/layout/PageHeader";
import AddTechs from "./AddTechs";
import { format } from "date-fns";
import ValidationErrors from "../errors/ValidationErrors";
import ConfirmDelete from "../../app/common/form/ConfirmDelete";
import SettingsNavBar from "../settings/SettingsNavBar";
import LoadingComponent from "../../app/layout/LoadingComponent";
import MyHelpButton from "../../app/common/form/MyHelpButton";
import SettingsDropdownMenuMobile from "../settings/SettingsDropdownMenuMobile";

export default observer(function Techs() {
  const { t } = useTranslation(["common", "miscSettings"]);

  const { technicianStore, modalStore, ticketStore } = useStore();

  const {
    loadTechnician,
    toggleTechnician,
    getTechnicians,
    setSelectedTechnician,
    loadSettingsTechnician,
    createSettingsTechnician,
    updateSettingsTechnician,
    removeTechnician,
    loadingInitial,
    loadingListInitial,
    selectedSettingsTechnician
  } = technicianStore;
  
  const {loadSettingsTicketInputDefaults, selectedSettingsTicketInputDefaults} = ticketStore

  useEffect(() => {
    loadSettingsTicketInputDefaults();
  }, [loadSettingsTicketInputDefaults]);

  const validationSchema = Yup.object({
    technicianTrackingEnabled: Yup.boolean().required(),
    technicianPartsTrackingEnabled: Yup.boolean().required(),
  });

  const [completed, setCompleted] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(
    loadingInitial || loadingListInitial
  );

  useEffect(() => {
    loadValues();
  }, []);

  function loadValues() {
    loadSettingsTechnician()
      .then(() => {
        loadTechnician().then(() => {
          setLoading(false);
        });
      })
      .catch(() => setLoading(false));
  }

  function handleFormSubmit(
    values: SettingsTechnicianFormValues,
    setErrors: (errors: FormikErrors<SettingsTechnicianFormValues>) => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) {
    const action = values.id
      ? () => updateSettingsTechnician(values)
      : () => createSettingsTechnician(values);

    action()
      .then(() => {
        loadValues();
        setCompleted(true);
        setSubmitting(false);
      })
      .catch((error) => {
        setSubmitting(false);
        setErrors({ error: error });
      });
  }

  if (loading)
    return <LoadingComponent content={t("tech_loading", { ns: "settings" })} />;

  return (
    <Grid stackable>
      <Grid.Column width={3} className="mobileHeight">
        <SettingsNavBar />
        <SettingsDropdownMenuMobile />
      </Grid.Column>
      <Grid.Column width={13}>
        <PageHeader
          header={t("tech.intro", { ns: "miscSettings" })}
          type={"h1"}
          divider={true}
          getAlerts={true}
          addTitle={true}
        />

        <Container className="page">
          <Button
            color="green"
            className="save_button"
            type="button"
            onClick={() =>
              modalStore.openModal(
                <AddTechs
                  name={""}
                  header={t("tech.addTechnician", {
                    ns: "miscSettings",
                  })}
                  isAdd={true}
                  autoCapitalizeFirstLetter={selectedSettingsTicketInputDefaults.capitalizeFirstCharacter}
                />
              )
            }
          >
            <Icon name="plus" />
            {`${t("tech.new", { ns: "miscSettings" })} `}
            <MyHelpButton
              name={"rwar"}
              help="settings.technician.addTechnician"
              color={"violet"}
            ></MyHelpButton>
          </Button>

          <Table striped unstackable className="dnxTable">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell textAlign="left"></Table.HeaderCell>
                <Table.HeaderCell>
                  {t("name", { ns: "common" })}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {t("created", { ns: "miscSettings" })}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {t("status", { ns: "common" })}
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="right"></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {getTechnicians && getTechnicians.length > 0 ? (
                getTechnicians.map((technician) => (
                  <Table.Row key={`technicianRow.${technician.id}`}>
                    <Table.Cell className="textAlignLeft">
                      <Button 
                        color="grey"
                        icon="edit"
                        type="button"
                        onClick={() => {
                          setSelectedTechnician(technician);
                          modalStore.openModal(
                            <AddTechs
                              name={""}
                              header={t("tech.editTechnician", {
                                ns: "miscSettings",
                              })}
                              isAdd={false}
                              autoCapitalizeFirstLetter={selectedSettingsTicketInputDefaults.capitalizeFirstCharacter}
                            />
                          );
                        }}
                      ></Button>
                    </Table.Cell>
                    <Table.Cell data-label={`${t("name", { ns: "common" })}:`}>
                      {technician.lastName}
                      {technician.firstName && `, ${technician.firstName}`}
                    </Table.Cell>
                    <Table.Cell data-label={`${t("created", { ns: "miscSettings" })}:`}>
                      {format(technician.createdDate, "PP")}
                    </Table.Cell>
                    <Table.Cell data-label={`${t("status", { ns: "common" })}:`}>
                      <Button
                        basic
                        color={technician.isActive === true ? "green" : "red"}
                        type="button"
                        onClick={() => {
                          if (technician.id) toggleTechnician(technician.id);
                        }}
                        content={
                          technician.isActive === true
                            ? t("enabled", { ns: "miscSettings" })
                            : t("disabled", { ns: "miscSettings" })
                        }
                      />
                    </Table.Cell>
                    <Table.Cell  className="textAlignRight">
                      <Button
                        color="grey"
                        icon="trash"
                        type="button"
                        onClick={() =>
                          modalStore.openModal(
                            <ConfirmDelete
                              header={t("tech.removeTechnician", {
                                ns: "miscSettings",
                              })}
                              value={technician.id ? technician.id : ""}
                              text={`${technician.lastName}, ${technician.firstName}`}
                              deleteValue={removeTechnician}
                            />
                          )
                        }
                      ></Button>
                    </Table.Cell>
                  </Table.Row>
                ))
              ) : (
                <Table.Row key={"noItems"}>
                  <Table.Cell width="5" colSpan={5}>
                    <Message
                      color="yellow"
                      className="textAlignCenter fontStyleItalic"
                    >
                      {t("grid_noItems", { ns: "common" })}
                    </Message>
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
          <br></br>
          <PageHeader
            header={`${t("tech.tracking", { ns: "miscSettings" })}`}
            type={"h2"}
            divider={true}
            helpName="settings.technician.general"
            showHelp
            addTitle={false}
          />
          <Formik
            validationSchema={validationSchema}
            enableReinitialize
            initialValues={selectedSettingsTechnician}
            onSubmit={(values, { setErrors, setSubmitting }) =>
              handleFormSubmit(values, setErrors, setSubmitting)
            }
          >
            {({
              handleSubmit,
              isValid,
              isSubmitting,
              dirty,
              handleBlur,
              handleChange,
              errors,
              values,
              touched,
            }) => (
              <Form
                className="ui form error"
                autoComplete="off"
                onSubmit={handleSubmit}
              >
                {completed && isValid && (
                  <Message positive>
                    {t("settingsupdated", {
                      ns: "common",
                    })}
                  </Message>
                )}
                <ErrorMessage
                  name="error"
                  render={() => <ValidationErrors errors={errors.error} />}
                />
                <Grid>
                  <Grid.Row columns={2}>
                    <Grid.Column>
                      <label
                        className="myLabel"
                        htmlFor="technicianTrackingEnabled"
                      >
                        {`${t("tech.enable_tracking", {
                          ns: "miscSettings",
                        })}:`}
                      </label>
                      <MyHelpButton
                        name="techoptions"
                        help="settings.technician.toggleTechTracking"
                        color={"blue"}
                      />
                      <Form.Group inline>
                        {yesNoOptions.map((data, index) => (
                          <Form.Field
                            key={`technicianTrackingEnabled[${data.value}]`}
                          >
                            <Radio
                              id={`technicianTrackingEnabled[${index}]`}
                              key={`technicianTrackingEnabled[${data.value}]`}
                              value={data.value}
                              name="technicianTrackingEnabled"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              label={data.text}
                              checked={
                                values.technicianTrackingEnabled.toString() ===
                                data.value
                              }
                              className="myLabel"
                            />
                          </Form.Field>
                        ))}
                        {touched.technicianTrackingEnabled &&
                          errors.technicianTrackingEnabled && (
                            <Label basic color="red">
                              {errors.technicianTrackingEnabled}
                            </Label>
                          )}
                      </Form.Group>
                    </Grid.Column>
                    <Grid.Column>
                      <label
                        className="myLabel"
                        htmlFor="technicianPartsTrackingEnabled"
                      >
                        {`${t("tech.assignment_options", {
                          ns: "miscSettings",
                        })}:`}
                      </label>
                      <Form.Group inline>
                        {assignmentOptions.map((data, index) => (
                          <Form.Field
                            key={`technicianPartsTrackingEnabled[${data.value}]`}
                          >
                            <Radio
                              id={`technicianPartsTrackingEnabled[${index}]`}
                              key={`ratetechnicianPartsTrackingEnabledTypes[${data.value}]`}
                              value={data.value}
                              name={"technicianPartsTrackingEnabled"}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              label={data.text}
                              checked={
                                values.technicianPartsTrackingEnabled.toString() ===
                                data.value.toString()
                              }
                              className="myLabel"
                            />
                          </Form.Field>
                        ))}
                      </Form.Group>
                      {touched.technicianPartsTrackingEnabled &&
                        errors.technicianPartsTrackingEnabled && (
                          <Label basic color="red">
                            {errors.technicianPartsTrackingEnabled}
                          </Label>
                        )}
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={1}>
                    <Grid.Column>
                      <Button
                        color="green"
                        className="save_button"
                        icon="check"
                        content={t("save", { ns: "common" })}
                        disabled={isSubmitting || !dirty || !isValid}
                        loading={isSubmitting}
                        type="submit"
                      />
                      <Button
                        className="save_button"
                        as={Link}
                        to="/settings"
                        icon="cancel"
                        type="reset"
                        content={t("cancel", { ns: "common" })}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form>
            )}
          </Formik>
        </Container>
      </Grid.Column>
    </Grid>
  );
});
