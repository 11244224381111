import { Address } from "./address";
import { Phone } from "./phone";

export interface Client {
  id: string;
  name: string;
  address: Address;
  phone: Phone;
  additionalInfo: string;
  email: string;
  createdDate: Date;
  updatedDate: Date;
  isActive: boolean;
  error?: string;
}

export interface Logo {
  logoImage?: any;
  fileInput?: string;
  removeLogo: boolean;
  error?: string;
}
export class Logo implements Logo {
  constructor(init?: LogoFormValues) {
    if (init) {
      this.logoImage = init.logoImage ? init.logoImage : undefined;
      this.removeLogo = init.removeLogo;
      this.fileInput = init.fileInput;
      this.error = init.error;
    }
  }
}

export class LogoFormValues {
  logoImage?: any = undefined;
  removeLogo: boolean = false;
  fileInput?: string = undefined;
  error?: string = undefined;

  constructor(logo?: LogoFormValues) {
    if (logo) {
      this.logoImage = logo.logoImage;
      this.removeLogo = logo.removeLogo;
      this.fileInput = logo.fileInput;
      this.error = logo.error;
    }
  }
}

export interface ClientDetails {
  id?: string;
  name?: string;
  additionalInformationHeader?: string;
  additionalInformationFooter?: string;
  email?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  country?: string;
  commercialNumber?: string;
  faxNumber?: string;
  error?: string;
}

export class ClientDetails implements ClientDetails {
  constructor(init?: ClientDetailsFormValues) {
    if (init) {
      this.id = init.id;
      this.name = init.name;
      this.additionalInformationHeader = init.additionalInformationHeader;
      this.additionalInformationFooter = init.additionalInformationFooter;
      this.email = init.email;
      this.addressLine1 = init.addressLine1;
      this.addressLine2 = init.addressLine2;
      this.city = init.city;
      this.state = init.state;
      this.postalCode = init.postalCode;
      this.country = init.country;
      this.commercialNumber = init.commercialNumber;
      this.faxNumber = init.faxNumber;
      this.error = init.error;
    }
  }
}

export class ClientDetailsFormValues {
  id?: string = undefined;
  name?: string = undefined;
  additionalInformationHeader?: string = undefined;
  additionalInformationFooter?: string = undefined;
  email?: string = undefined;
  addressLine1?: string = undefined;
  addressLine2?: string = undefined;
  city?: string = undefined;
  state?: string = undefined;
  postalCode?: string = undefined;
  country?: string = undefined;
  commercialNumber?: string = undefined;
  faxNumber?: string = undefined;
  error?: string = undefined;

  constructor(clientDetails?: ClientDetailsFormValues) {
    if (clientDetails) {
      this.id = clientDetails.id;
      this.name = clientDetails.name;
      this.additionalInformationHeader =
        clientDetails.additionalInformationHeader;
      this.additionalInformationFooter =
        clientDetails.additionalInformationFooter;
      this.email = clientDetails.email;
      this.addressLine1 = clientDetails.addressLine1;
      this.addressLine2 = clientDetails.addressLine2;
      this.city = clientDetails.city;
      this.state = clientDetails.state;
      this.postalCode = clientDetails.postalCode;
      this.country = clientDetails.country;
      this.commercialNumber = clientDetails.commercialNumber;
      this.faxNumber = clientDetails.faxNumber;
      this.error = clientDetails.error;
    }
  }
}
