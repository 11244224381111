import React from "react";
import { observer } from "mobx-react-lite";
import {
    Divider,
    Header,
} from "semantic-ui-react";


export default observer(function SignUp() {


  return (
    <>
     <Header
              as="h1"
              content={"Coming Soon"}
              className="modal-text-color"
              textAlign="left"
            />
            <Divider />

    </>
  );
});
