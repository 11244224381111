import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import "../../../app/layout/printStyles.css";
import { View, Text } from "@react-pdf/renderer";
import compose from "../../../app/layout/compose";
import TicketDetailPdfView from "./TicketViewComponents/TicketDetailPdfView";
import CustomerPdfView from "./TicketViewComponents/CustomerPdfView";
import VehiclePdfView from "./TicketViewComponents/VehiclePdfView";
import { SettingsTicketFormatting, SettingsTicketInputDefaults } from "../../../app/models/ticket";

interface Props {
  selectedSettingsTicketFormatting : SettingsTicketFormatting
  selectedSettingsTicketInputDefaults: SettingsTicketInputDefaults
}

export default observer(function RepairOrderHeaderPdf({selectedSettingsTicketFormatting, selectedSettingsTicketInputDefaults}: Props) {
  const { t } = useTranslation(["common", "tickets"]);

  return (
    <View fixed>
      <View
        style={{
          ...compose("flex pageWidth height46mm"),
        }}
      >
        <CustomerPdfView settingsTicketInputDefaults={selectedSettingsTicketInputDefaults} />
        <VehiclePdfView />
        <TicketDetailPdfView
          showEstimateNumber={
            selectedSettingsTicketFormatting.showEstimateNumber
          }
          showPoNumber={selectedSettingsTicketFormatting.showPONumber}
        />
      </View>
      <View
        style={{
          ...compose(
            "view pageWidth flex dividerBar noPadding noMarginTop marginBottom1mm"
          ),
          position: "relative",
        }}
      >
        <Text
          style={{
            ...compose("text invoiceText"),
            flex: 0.65,
            alignSelf: "center",
          }}
        ></Text>
        <Text
          style={{
            ...compose("text invoiceText"),
            flex: 1,
            alignSelf: "center",
          }}
        >
          {t("estimate.repair_order", { ns: "tickets" })}
        </Text>
        <Text
          style={{
            ...compose("text fontSize6pt textAlignRight"),
            flex: 0.5,
            alignSelf: "flex-end",
            color: "#fff",
            marginBottom: "2.5mm",
            marginRight: "2mm",
          }}
          render={({ pageNumber, totalPages }) =>
            `${t("page", { ns: "tickets" })} ${pageNumber} ${t("of", {
              ns: "tickets",
            })} ${totalPages}`
          }
        />
      </View>
    </View>
  );
});
