import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import {
  Button,
  Container,
  Form,
  Grid,
  Icon,
  Input,
  Label,
  Message,
  Radio,
  Table,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { ErrorMessage, Formik, FormikErrors } from "formik";
import { Link, useNavigate, useParams } from "react-router-dom";
import PageHeader from "../../app/layout/PageHeader";
import { useStore } from "../../app/stores/store";
import CustomVehiclesForm from "./CustomVehiclesForm";
import ValidationErrors from "../errors/ValidationErrors";
import ConfirmDelete from "../../app/common/form/ConfirmDelete";
import { yesNoOptions } from "../../app/common/options/option";
import MyPaging from "../../app/common/form/MyPaging";
import { PagingParams } from "../../app/models/pagination";
import { SettingsWorkflowFormValues } from "../../app/models/ticket";
import { CustomVehicle } from "../../app/models/customvehicles";
import PickCustomVehicleYear from "./PickCustomVehicleYear";
import { TicketWorkflowSteps } from "../../app/models/enums";
import LoadingComponent from "../../app/layout/LoadingComponent";

interface Props {
  isTicket: boolean;
}

export default observer(function CustomVehicles({ isTicket }: Props) {
  const { t } = useTranslation(["common", "workflowSettings"]);
  const navigate = useNavigate();

  const {
    modalStore,
    customVehicleStore,
    ticketStore,
    vehicleStore,
    customerStore,
    ticketSessionStore,
  } = useStore();

  const {
    loadCustomVehicleList,
    getCustomVehicles,
    setSelectedCustomVehicle,
    removeCustomVehicle,
    getCustomVehicleYearLabel,
    setPagingParams,
    pagingParams,
    pagination,
    resetCustomVehicleRegistry,
    setPredicate,
    resetYears,
  } = customVehicleStore;

  const {
    createSettingsWorkflow,
    updateSettingsWorkflow,
    loadSettingsWorkflow,
    loadingInitial,
    loadSettingsTicketInputDefaults,
    selectedSettingsTicketInputDefaults,
  } = ticketStore;

  const { selectedVehicle, selectCustomVehicle } = vehicleStore;

  const { updateTicketSession } = ticketSessionStore;

  const { selectedCustomer } = customerStore;

  const [settingsWorkFlow, setSettingsWorkFlow] =
    useState<SettingsWorkflowFormValues>(new SettingsWorkflowFormValues());

  useEffect(() => {
    loadValues();
  }, [loadSettingsWorkflow]);

  useEffect(() => {
    loadValues();
  }, [loadSettingsWorkflow]);

  function loadValues() {
    loadSettingsWorkflow().then((settingsTicketInputDefaults) =>
      setSettingsWorkFlow(
        new SettingsWorkflowFormValues(settingsTicketInputDefaults)
      )
    );
  }

  const { ticketId } = useParams<{ ticketId: string }>();
  const { complete } = useParams<{ complete: string }>();

  const validationSchema = Yup.object({});

  useEffect(() => {
    loadCustomVehicleList();
  }, [loadCustomVehicleList]);

  useEffect(() => {
    loadSettingsTicketInputDefaults();
  }, [loadSettingsTicketInputDefaults]);

  const [completed, setCompleted] = useState<boolean>(false);

  function handleFormSubmit(
    values: SettingsWorkflowFormValues,
    setErrors: (errors: FormikErrors<SettingsWorkflowFormValues>) => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) {
    const action = values.id
      ? () => updateSettingsWorkflow(values)
      : () => createSettingsWorkflow(values);

    action()
      .then(() => {
        loadValues();
        setCompleted(true);
        setSubmitting(false);
      })
      .catch((error) => {
        setSubmitting(false);
        setErrors({ error: error });
      });
  }

  function setSelectCustomVehicle(values: CustomVehicle, year?: number) {
    selectCustomVehicle(values, year);

    const action = () =>
      updateTicketSession(
        TicketWorkflowSteps.Vehicle,
        ticketId ?? "",
        selectedVehicle,
        selectedCustomer
      );
    action()
      .then(() => {
        navigate(`/ticket/jobCategory/${ticketId}/${complete}`);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function listOnClick(value: number) {
    if (value > 0 && pagination && value < pagination?.totalPages + 1) {
      setPagingParams(new PagingParams(value));
      resetCustomVehicleRegistry();
      loadCustomVehicleList();
    }
  }

  const [isFiltered, setIsFiltered] = useState<boolean>(false);

  if (loadingInitial)
    return (
      <LoadingComponent
        content={t("customVehicles_loading", { ns: "workflowSettings" })}
      />
    );

  return (
    <>
      <PageHeader
        header={isTicket ? t("customvehicles", { ns: "common" }) : ""}
        type={"h2"}
        divider={true}
        getAlerts={true}
        addTitle={true}
      />
      <Container className="page">
        <Grid columns={2}>
          <Grid.Column verticalAlign="bottom">
            <PageHeader
              header={`${t("customs.existing", {
                ns: "workflowSettings",
              })}:`}
              type={"h3"}
              divider={false}
              helpName="settings.workflow.customVehicle"
              showHelp
              addTitle={false}
            />
          </Grid.Column>
          <Grid.Column verticalAlign="bottom">
            <Button
              floated={"right"}
              color="green"
              size="tiny"
              onClick={() =>
                modalStore.openModal(
                  <CustomVehiclesForm
                    name={"addCustomVehicle"}
                    header={t("customs.addCustomVehicle", {
                      ns: "workflowSettings",
                    })}
                    isAdd={true}
                    isTicket={isTicket}
                    complete={complete === "true"}
                    autoCapitalizeFirstLetter={
                      selectedSettingsTicketInputDefaults.capitalizeFirstCharacter
                    }
                  />
                )
              }
            >
              <Icon name="plus" />
              {t("customs.new", { ns: "workflowSettings" })}
            </Button>
          </Grid.Column>
        </Grid>

        <Table striped unstackable className="dnxTable">
          <Table.Body>
            <Table.Row>
              <Table.Cell textAlign="left"></Table.Cell>
              <Table.Cell className="terms_bold hideMe" verticalAlign="middle">
                {t("year", { ns: "common" })}
              </Table.Cell>
              <Table.Cell className="terms_bold">
                {`${t("customs.make", { ns: "workflowSettings" })} `}
                {(isFiltered || (pagination && pagination.totalItems > 10)) && (
                  <Input
                    //className="myInput"
                    type="text"
                    onChange={(e, d) => {
                      setPredicate("make", d.value);
                      resetCustomVehicleRegistry();

                      setIsFiltered(d.value.length > 0);
                    }}
                    icon={"search"}
                  ></Input>
                )}
              </Table.Cell>
              <Table.Cell className="terms_bold">
                {`${t("customs.model", { ns: "workflowSettings" })} `}
                {(isFiltered || (pagination && pagination.totalItems > 10)) && (
                  <Input
                    //className="myInput"
                    type="text"
                    onChange={(e, d) => {
                      setPredicate("model", d.value);
                      resetCustomVehicleRegistry();

                      setIsFiltered(d.value.length > 0);
                    }}
                    icon={"search"}
                  ></Input>
                )}
              </Table.Cell>
              <Table.Cell className="terms_bold">
                {`${t("customs.engine", { ns: "workflowSettings" })} `}
                {(isFiltered || (pagination && pagination.totalItems > 10)) && (
                  <Input
                    //className="myInput"
                    type="text"
                    onChange={(e, d) => {
                      setPredicate("engine", d.value);
                      resetCustomVehicleRegistry();

                      setIsFiltered(d.value.length > 0);
                    }}
                    icon={"search"}
                  ></Input>
                )}
              </Table.Cell>
              <Table.Cell textAlign="right"></Table.Cell>
            </Table.Row>
          </Table.Body>
          <Table.Body>
            {getCustomVehicles && getCustomVehicles.length > 0 ? (
              getCustomVehicles.map((customVehicle) => (
                <Table.Row key={`customVehicle${customVehicle.id}`}>
                  <Table.Cell className="textAlignLeft">
                    {isTicket ? (
                      <Button
                        color="blue"
                        icon="check"
                        type="button"
                        size="mini"
                        onClick={() => {
                          setSelectedCustomVehicle(customVehicle);
                          if (
                            (customVehicle.lowYear == undefined &&
                              customVehicle.highYear == undefined) ||
                            customVehicle.lowYear === customVehicle.highYear
                          ) {
                            setSelectCustomVehicle(
                              customVehicle,
                              customVehicle.lowYear
                            );
                            //Move on
                          } else {
                            resetYears();
                            modalStore.openModal(
                              <PickCustomVehicleYear
                                name={"pickCustomVehicleYear"}
                                header={
                                  t("customs.pickYear", {
                                    ns: "workflowSettings",
                                  }) +
                                  ` ${customVehicle.makeName ?? ""} ${
                                    customVehicle.modelName ?? ""
                                  }${customVehicle.engineName ? ", " : ""}${
                                    customVehicle.engineName ?? ""
                                  }`
                                }
                                ticketId={ticketId}
                                complete={complete === "true"}
                              />
                            );
                          }
                        }}
                      ></Button>
                    ) : (
                      <Button
                        color="grey"
                        icon="edit"
                        type="button"
                        onClick={() => {
                          setSelectedCustomVehicle(customVehicle);
                          modalStore.openModal(
                            <CustomVehiclesForm
                              name={"updateCustomVehicle"}
                              header={t("customs.editCustomVehicle", {
                                ns: "workflowSettings",
                              })}
                              isAdd={false}
                              isTicket={isTicket}
                              complete={complete === "true"}
                              autoCapitalizeFirstLetter={
                                selectedSettingsTicketInputDefaults.capitalizeFirstCharacter
                              }
                            />
                          );
                        }}
                      ></Button>
                    )}
                  </Table.Cell>
                  <Table.Cell data-label={`${t("year", { ns: "common" })}:`}>
                    {getCustomVehicleYearLabel(customVehicle)}
                  </Table.Cell>
                  <Table.Cell
                    data-label={`${t("customs.make", {
                      ns: "workflowSettings",
                    })}:`}
                  >
                    {customVehicle.makeName}
                  </Table.Cell>
                  <Table.Cell
                    data-label={`${t("customs.model", {
                      ns: "workflowSettings",
                    })}:`}
                  >
                    {customVehicle.modelName}
                  </Table.Cell>
                  <Table.Cell
                    data-label={`${t("customs.engine", {
                      ns: "workflowSettings",
                    })}:`}
                  >
                    {customVehicle.engineName}
                  </Table.Cell>
                  <Table.Cell className="textAlignLeft">
                    {!isTicket && (
                      <Button
                        color="grey"
                        icon="trash"
                        onClick={() => {
                          modalStore.openModal(
                            <ConfirmDelete
                              header={t("customs.removeCustomVehicle", {
                                ns: "workflowSettings",
                              })}
                              value={customVehicle.id ? customVehicle.id : ""}
                              deleteValue={(d) => {
                                removeCustomVehicle(d).then(() => {});
                              }}
                            />
                          );
                        }}
                      ></Button>
                    )}
                  </Table.Cell>
                </Table.Row>
              ))
            ) : (
              <Table.Row key={"noItems"}>
                <Table.Cell width="8" colSpan={8}>
                  <Message
                    color="yellow"
                    className="textAlignCenter fontStyleItalic"
                  >
                    {t("grid_noItems", { ns: "common" })}
                  </Message>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan="6">
                <MyPaging
                  itemCount={pagination ? pagination.totalItems : 10}
                  pageSize={pagingParams.pageSize}
                  currentPage={pagination ? pagination.currentPage : 1}
                  onClick={listOnClick}
                  onChange={listOnClick}
                  floated="right"
                ></MyPaging>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
        <Formik
          validationSchema={validationSchema}
          enableReinitialize
          initialValues={settingsWorkFlow}
          onSubmit={(values, { setErrors, setSubmitting }) =>
            handleFormSubmit(values, setErrors, setSubmitting)
          }
        >
          {({
            handleSubmit,
            isValid,
            isSubmitting,
            dirty,
            handleBlur,
            handleChange,
            errors,
            values,
            touched,
          }) => (
            <Form
              className="ui form error"
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              {completed && isValid && (
                <Message positive>
                  {t("settingsupdated", {
                    ns: "common",
                  })}
                </Message>
              )}
              <ErrorMessage
                name="error"
                render={() => <ValidationErrors errors={errors} />}
              />
              {!isTicket && (
                <>
                  <Grid columns={2}>
                    <Grid.Column>
                      <label className="myLabel" htmlFor="allowCustomVehicle">
                        {`${t("customs.allowance", {
                          ns: "workflowSettings",
                        })}:`}
                      </label>
                      <Form.Group inline>
                        {yesNoOptions.map((data, index) => (
                          <Form.Field key={`allowCustomVehicle[${data.value}]`}>
                            <Radio
                              id={`allowCustomVehicle[${index}]`}
                              key={`allowCustomVehicle[${data.value}]`}
                              value={data.value}
                              name="allowCustomVehicle"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              label={data.text}
                              checked={
                                values.allowCustomVehicle.toString() ===
                                data.value
                              }
                              className="myLabel"
                            />
                          </Form.Field>
                        ))}
                        {touched.allowCustomVehicle &&
                          errors.allowCustomVehicle && (
                            <Label basic color="red">
                              {errors.allowCustomVehicle}
                            </Label>
                          )}
                      </Form.Group>
                    </Grid.Column>
                  </Grid>

                  <Button
                    color="green"
                    className="save_button"
                    icon="check"
                    content={t("save", { ns: "common" })}
                    disabled={isSubmitting || !dirty || !isValid}
                    loading={isSubmitting}
                    type="submit"
                  />
                </>
              )}
              <Button
                className="save_button"
                as={Link}
                to={isTicket ? "/ticket" : "/settings"}
                icon="cancel"
                type="button"
                content={t("cancel", { ns: "common" })}
              />
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
});
