import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import { CustomerTicket } from "../models/ticket";
import { Pagination, PagingParams } from "../models/pagination";

export default class CustomerTicketStore {
  loading = false;
  loadingInitial = false;

  pagination: Pagination | null = null;
  pagingParams = new PagingParams();
  predicate = new Map().set("all", true);

  customerTicketRegistry = new Map<string | undefined, CustomerTicket>();

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.predicate.keys(),
      () => {
        this.pagingParams = new PagingParams();
        this.customerTicketRegistry.clear();
      }
    );
  }

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  setPagingParams = (pagingParams: PagingParams) => {
    this.pagingParams = pagingParams;
  };

  get axiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", this.pagingParams.pageNumber.toString());
    params.append("pageSize", this.pagingParams.pageSize.toString());
    this.predicate.forEach((value, key) => {
      if (key === "startDate") {
        params.append(key, (value as Date).toISOString());
      } else {
        params.append(key, value);
      }
    });
    return params;
  }

  setPagination = (pagination: Pagination) => {
    this.pagination = pagination;
  };

  get getCustomerTickets() {
    let myArray = Array.from(this.customerTicketRegistry.values());
    return myArray.sort((a, b) => {
      return b.createdDate!.getTime() - a.createdDate!.getTime();
    });
  }

  resetCustomerTicketRegistry = () => {
    this.customerTicketRegistry.clear();
  };

  loadCustomerTickets = async (customerId: string) => {
    this.loadingInitial = true;
    try {
      this.customerTicketRegistry.clear();
      const result = await agent.Tickets.listCustomerTickets(
        customerId,
        this.axiosParams
      );
      runInAction(() => {
        result.data.forEach((ticket) => {
          this.setCustomerTicket(ticket);
        });
        this.setPagination(result.pagination);
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoadingInitial(false);
    }
  };


  private setCustomerTicket(customerTicket: CustomerTicket) {
    if (customerTicket.createdDate)
      customerTicket.createdDate = new Date(customerTicket.createdDate);
    else customerTicket.createdDate = new Date();

    this.customerTicketRegistry.set(customerTicket.id, customerTicket);
  }

  removeCustomerTicket = async (ticketId: string) => {
    this.loadingInitial = true;
    try {
      await agent.Tickets.removeTicket(ticketId);
      runInAction(() => {
        this.customerTicketRegistry.delete(ticketId);
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoadingInitial(false);
    }
  };

  setPredicate = (predicate: string, value?: string) => {
    const resetPredicate = () => {
      this.predicate.forEach((value, key) => {
        if (key !== "startDate") this.predicate.delete(key);
      });
    };
    resetPredicate();
    this.predicate.set(predicate, value);
  };

  resetPredicate = () => {
    this.predicate.clear();
  };
}
