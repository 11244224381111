import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import {
  Button,
  Message,
  Table,
} from "semantic-ui-react";
import PageHeader from "../../../app/layout/PageHeader";
import { useStore } from "../../../app/stores/store";
import { format } from "date-fns";
import { useNavigate, useParams } from "react-router-dom";

export default observer(function ViewAnnouncement() {
  const navigate = useNavigate();
  const { announcementStore } = useStore();
  const {
    loadAnnouncement,
    announcement,
  } = announcementStore;

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (!isNaN(parseInt(id ?? ""))) {
      loadAnnouncement(parseInt(id ?? ""));
    }
  }, [id, loadAnnouncement]);


  return (
    <>
      <PageHeader header="View Announcement" type={"h1"} divider={true} addTitle={false}/>
      <>
        <Table collapsing striped>
          <Table.Row>
            <Table.Cell>ID</Table.Cell>
            <Table.Cell>
              {announcement ? announcement.id : undefined}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Area</Table.Cell>
            <Table.Cell>Log In</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Is Active</Table.Cell>
            <Table.Cell> {announcement?.isActive} </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Date Created</Table.Cell>
            <Table.Cell>
              {announcement?.createdDate
                ? format(announcement.createdDate, "Pp")
                : undefined}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Date Start</Table.Cell>
            <Table.Cell>
              {announcement?.startDate
                ? format(announcement.startDate, "Pp")
                : undefined}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Date End</Table.Cell>
            <Table.Cell>
              {announcement?.endDate
                ? format(announcement.endDate, "Pp")
                : undefined}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Type</Table.Cell>
            <Table.Cell>
              {announcement ? announcement.announcementType : undefined}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Header</Table.Cell>
            <Table.Cell>
              {announcement ? announcement.header : undefined}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Note</Table.Cell>
            <Table.Cell>
              {announcement ? announcement.body : undefined}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Preview</Table.Cell>
            <Table.Cell>
              <Message
                header={announcement?.header}
                content={announcement?.body}
                color={announcement?.color}
              />
            </Table.Cell>
          </Table.Row>
        </Table>
        <Button icon={'caret left'} content={"Back"} onClick={() => navigate(`/admin/ManageAnnouncements/`)}/>
      </>
    </>
  );
});
