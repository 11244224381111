import { observer } from "mobx-react-lite";
import React from "react";
import { Modal } from "semantic-ui-react";
import { useStore } from "../../stores/store";
import 'semantic-ui-css/semantic.min.css'

export default observer(function ModalContainer() {
  const { modalStore } = useStore();

  return (
    <Modal
      className={`modalfix ${modalStore.modal.className}`}
      // className={`modalfix`}
      open={modalStore.modal.open}
      onClose={modalStore.closeModal}
      size="tiny"
      closeOnDimmerClick={false}
    >
      <Modal.Content>{modalStore.modal.body}</Modal.Content>
    </Modal>
  );
});
