import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../../api/agent";
import { Pagination, PagingParams } from "../../models/pagination";
import { ClientUsers, UserDetails } from "../../models/systemAdmin";
import { sortingStrings } from "../../common/util/functions";

export default class UserSystemAdminStore {
  constructor() {
    makeAutoObservable(this);
    reaction(
      () => this.predicate.keys(),
      () => {
        this.pagingParams = new PagingParams(1, 10);
        this.ClientUsersRegistry.clear();
        this.loadUserList();
      }
    );
  }

  loadingInitial = false;

  ClientUsersRegistry = new Map<string, ClientUsers>();
  predicate = new Map().set("all", true);
  pagination: Pagination | null = null;
  pagingParams = new PagingParams(1, 10);

  userDetails: UserDetails | undefined = undefined;

  setUserDetails = (value: UserDetails | undefined) => {
    if (value) {
      value.createdDate = new Date(value.createdDate);
    }
    this.userDetails = value;
  };

    clearUserDetails = () => {
    this.userDetails = undefined;
  };
  resetAllPredicates = () => {
    this.predicate.clear();
  };

  loadUserList = async () => {
    this.loadingInitial = true;
    try {
      this.ClientUsersRegistry.clear();
      const result = await agent.SystemAdminUsers.getUserList(this.axiosParams);
      runInAction(() => {
        result.data.forEach((userList) => {
          this.setUserList(userList);
        });
        this.setPagination(result.pagination);
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoadingInitial(false);
    }
  };

  loadUserDetails = async (id: string) => {
    try {
      this.clearUserDetails();
      const result = await agent.SystemAdminUsers.getUserDetails(id);
      runInAction(() => {
        this.setUserDetails(result);
      });
    } catch (error) {
      console.log(error);
    }
  };

  private setUserList = (userList: ClientUsers) => {
    if (userList.createdDate) userList.createdDate = new Date(userList.createdDate);

    this.ClientUsersRegistry.set(userList.userId, userList);
  };

  get getUserList() {
    return Array.from(this.ClientUsersRegistry.values());
    //still not right not sure what is wrong
    // .sort(
    //    (a, b) => b.createdDate!.getTime() - a.createdDate!.getTime()
    // );
  }

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  resetPredicate = (keyToDelete: string) => {
    this.predicate.delete(keyToDelete);
  };

  setPredicate = (
    predicate: string,
    value?: string
  ) => {
    this.predicate.clear();
    if (value) this.predicate.set(predicate, value);
  };

  clearPredicate = () => {
    this.predicate.clear();
  };

  setPagination = (pagination: Pagination) => {
    this.pagination = pagination;
  };

  setPagingParams = (pagingParams: PagingParams) => {
    this.pagingParams = new PagingParams(pagingParams.pageNumber, pagingParams.pageSize);
  };

  get axiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", this.pagingParams.pageNumber.toString());
    params.append("pageSize", this.pagingParams.pageSize.toString());
    this.predicate.forEach((value, key) => {
      if (key === "startDate" || key === "endDate") {
        params.append(key, (value as Date).toISOString());
      } else {
        params.append(key, value);
      }
    });
    return params;
  }

  get getClientUsers() {
    return Array.from(this.ClientUsersRegistry.values()).sort((a, b) => {
      let temp: number = sortingStrings(a.lastName??"", b.lastName??"") ?? 0;

      if (temp === 0) {
        temp = sortingStrings(a.firstName??"", b.firstName??"") ?? 0;
      }

      return temp;
    });
  }

  toggleByPassLocationAuthorization = async (id?: string) => {
    try {
      await agent.SystemAdminUsers.toggleByPassLocationAuthorization(id);
      runInAction(() => {
        //update the users
      });
    } catch (error) {
      console.log(error);
    }
  };
  
  toggleByPassAdditionalAuthorization = async (id?: string) => {
    try {
      await agent.SystemAdminUsers.toggleByPassAdditionalAuthorization(id);
      runInAction(() => {
        //update the users
      });
    } catch (error) {
      console.log(error);
    }
  };

  resendEmail = async (id?: string) => {
    try {
      await agent.Account.resendEmailConfirmWithUserId(id?? "");
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
}
