import { DropdownItemProps } from "semantic-ui-react";
import { CustomerFormValues } from "../../models/customer";
import {
  TicketItemType,
  TicketType,
  TicketWorkflowSteps,
} from "../../models/enums";
import { JobLabor } from "../../models/jobLabor";
import { MiscellaneousCharge } from "../../models/miscellaneousCharges";
import { SettingsTaxRate } from "../../models/pricing";
import {
  SettingsTicketDefaults,
  SettingsTicketNumber,
  SettingsWorkflowFormValues,
  TicketSession,
} from "../../models/ticket";
import { VehicleFormValues } from "../../models/vehicle";
import { store } from "../../stores/store";
import { v4 as uuid } from "uuid";

export function resetTicket() {
  store.vehicleStore.resetMakeList();
  store.vehicleStore.resetModelList();
  store.vehicleStore.resetEngineList();
  store.ticketSessionStore.resetJobLabors();
  store.ticketSessionStore.resetLabors();
  store.ticketSessionStore.resetTicketItems();
  store.ticketSessionStore.resetTicketPaymentItems();
  store.vehicleStore.setSelectedVehicle(new VehicleFormValues());
  store.customerStore.setSelectedCustomer(new CustomerFormValues());
  store.ticketSessionStore.setSelectedPONumber(undefined);
  store.ticketSessionStore.setSelectedTicketSession(new TicketSession());
  store.customerStore.clearPotentialCustomers();
}

export function loadTicketSession(
  id: string,
  complete: boolean,
  workflowSteps: TicketWorkflowSteps,
  alwaysMiscellaneousChargeItems: MiscellaneousCharge[],
  selectedWorkflow: SettingsWorkflowFormValues,
  selectedSettingsTicketDefaults: SettingsTicketDefaults,
  selectedSettingsTicketNumber: SettingsTicketNumber
): boolean {
  if (id) {
    store.ticketSessionStore
      .loadTicketSession(id, workflowSteps)
      .then((result) => {
        if (result) {
          store.vehicleStore.setSelectedVehicle(
            new VehicleFormValues(result.vehicle)
          );
          store.customerStore.setSelectedCustomer(
            new CustomerFormValues(result.customer)
          );
          store.ticketSessionStore.setSelectedPONumber(result.poNumber);

          let ticketSession = new TicketSession(result);
          store.ticketSessionStore.setSelectedTicketSession(ticketSession);

          if (result.jobLabors && result.jobLabors.length > 0) {
            store.ticketSessionStore.populateJobLabors(result.jobLabors);
            let jobLabor: JobLabor | undefined = Array.from(
              result.jobLabors.values()
            ).find((x) => {
              return x.isComplete === false;
            });
            if (jobLabor) {
              store.ticketSessionStore.setSelectedJobLabor(jobLabor);
              if (
                jobLabor.job?.jobTypeId &&
                result.vehicle?.modelId &&
                result.vehicle.engineId
              ) {
                store.jobLaborStore.loadDsiJobList(
                  jobLabor.job.jobTypeId,
                  result.vehicle.modelId,
                  result.vehicle.engineId,
                  result.vehicle.isCustom
                );
              }
            } else {
              let jobLabor = new JobLabor();
              jobLabor.id = uuid();
              store.ticketSessionStore.setSelectedJobLabor(jobLabor);
              store.ticketSessionStore.addJobLabor(jobLabor);
            }
          }


          if (
            (!result.ticketItems || result.ticketItems.length < 1) &&
            result.jobLabors &&
            result.jobLabors.length > 0
          ) {
            store.ticketSessionStore.setJobLaborsToTicketItems(
              alwaysMiscellaneousChargeItems,
              store.ticketSessionStore.hasParts(), result.salesTaxOptionType
            );
            store.ticketSessionStore.calculateMiscellaneousCharges(
              alwaysMiscellaneousChargeItems
            );
          } else if (result.ticketItems && result.ticketItems.length > 0) {
            store.ticketSessionStore.setBatchTicketItems(result.ticketItems);
          }

          if (result.ticketPayments && result.ticketItems.length > 0) {
            store.ticketSessionStore.setBatchTicketPaymentItems(
              result.ticketPayments
            );
          } else {
            store.ticketSessionStore.resetTicketPaymentItems();
          }

          if (complete) {
            store.ticketSessionStore.calculateTotals();
          }

          if (result.labors && result.labors.length > 0) {
            store.ticketSessionStore.populateLaborMap(result.labors);
          }
        } else {
          store.ticketSessionStore.startTicketSession(
            id,
            selectedWorkflow,
            selectedSettingsTicketDefaults,
            selectedSettingsTicketNumber
          );
        }
      })
      .catch(() => {
        return false;
      })
      .finally(() => {
        return true;
      });
  }
  return true;
}

export async function loadTicket(id: string) : Promise<void> {
  store.ticketSessionStore
    .loadTicket(id)
    .then((result) => {
      if (result) {
        store.vehicleStore.setSelectedVehicle(
          new VehicleFormValues(result.vehicle)
        );
        store.customerStore.setSelectedCustomer(
          new CustomerFormValues(result.customer)
        );
        store.ticketSessionStore.setSelectedPONumber(result.poNumber);

        store.ticketSessionStore.setSelectedTicketSession(
          new TicketSession(result)
        );

        if (result.jobLabors && result.jobLabors.length > 0) {
          store.ticketSessionStore.populateJobLabors(result.jobLabors);
        }

        if (result.ticketItems && result.ticketItems.length > 0) {
          store.ticketSessionStore.setBatchTicketItems(result.ticketItems);
        }
        //need this due to the history is being displayed off of it
        if (result.ticketPayments && result.ticketItems.length > 0) {
          store.ticketSessionStore.setBatchTicketPaymentItems(
            result.ticketPayments
          );
        }
        if (result.labors && result.labors.length > 0) {
          store.ticketSessionStore.populateLaborMap(result.labors);
        }
      }
      
    });

}
