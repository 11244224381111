import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import {
  Button,
  Checkbox,
  Divider,
  Grid,
  Icon,
  Message,
  Table,
} from "semantic-ui-react";
import PageHeader from "../../../app/layout/PageHeader";
import { useStore } from "../../../app/stores/store";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default observer(function TrialEmails() {
  const { t } = useTranslation(["common"]);
  const navigate = useNavigate();
  const { announcementStore } = useStore();
  const { loadAnnouncementList, getAnnouncementList } = announcementStore;

  useEffect(() => {
    loadAnnouncementList();
  }, [loadAnnouncementList]);

  return (
    <>
      <Grid>
        <Grid.Row>
          {" "}
          <Grid.Column>
            <PageHeader
              header="Free Trial Requests"
              type={"h1"}
              divider={false}
              addTitle={false}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column></Grid.Column>
          <Grid.Column>
            <Button content="EXPORT" color="blue" size="mini" />
            <Button content="EMAILS" color="blue" size="mini"/>
            <Button content="CLEAN" color="green" size="mini"/>
            <Button content="DIRTY" color="red" size="mini"/>
            <Button content="EMAIL" color="blue" size="mini"/>
            <Button content="EMAIL FOLLOW-UP" color="blue" size="mini"/>
            <Button content="REJECT" color="red" size="mini"/>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Table striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    <Checkbox></Checkbox>
                  </Table.HeaderCell>
                  <Table.HeaderCell>Date</Table.HeaderCell>
                  <Table.HeaderCell>
                    Contact Name/ <br />
                    Company Name
                  </Table.HeaderCell>
                  <Table.HeaderCell>Contact Information</Table.HeaderCell>
                  <Table.HeaderCell>Address</Table.HeaderCell>
                  <Table.HeaderCell className="verticalText">
                    Clean
                  </Table.HeaderCell>
                  <Table.HeaderCell className="verticalText">
                    Imported
                  </Table.HeaderCell>
                  <Table.HeaderCell className="verticalText">
                    Emailed
                  </Table.HeaderCell>
                  <Table.HeaderCell className="verticalText">
                    Followed Up
                  </Table.HeaderCell>
                  <Table.HeaderCell className="verticalText">
                    Rejected
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {getAnnouncementList && getAnnouncementList.length > 0 ? (
                  getAnnouncementList.map((x) => (
                    <Table.Row key={`announcement.${x.id}`}>
                      <Table.Cell>
                        <Checkbox></Checkbox>
                      </Table.Cell>
                      <Table.Cell>5/25/3024</Table.Cell>
                      <Table.Cell>
                        Slater Slateson <br /> Slaters Gunna Slate
                      </Table.Cell>
                      <Table.Cell>
                        Slatersemail@slater.com <br /> 867-5309
                      </Table.Cell>
                      <Table.Cell>
                        1234 Ideclareathumbwar Dr <br /> Tampa, FL 33626
                      </Table.Cell>
                      <Table.Cell>
                        <Icon name="check" color="green"></Icon>
                      </Table.Cell>
                      <Table.Cell>
                        <Icon name="check" color="green"></Icon>
                      </Table.Cell>
                      <Table.Cell>
                        <Icon name="x" color="red"></Icon>
                      </Table.Cell>
                      <Table.Cell>
                        <Icon name="x" color="red"></Icon>
                      </Table.Cell>
                      <Table.Cell>
                        <Icon name="x" color="red"></Icon>
                      </Table.Cell>
                    </Table.Row>
                  ))
                ) : (
                  <Table.Row key={"noitems"}>
                    <Table.Cell width="8" colSpan={8}>
                      <Message
                        color="yellow"
                        className="textAlignCenter fontStyleItalic"
                      >
                        {t("grid_noItems", { ns: "common" })}
                      </Message>
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
});
