import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import { Pagination, PagingParams } from "../models/pagination";
import {
  CustomerSearch,
  TicketItemSearch,
  TicketSearch,
  VehicleSearch,
} from "../models/search";
import { SearchType, TicketType } from "../models/enums";
import { sortingStrings } from "../common/util/functions";

export default class SearchStore {
  loadingInitial = false;
  activeTab: number = 0;

  searchType: SearchType = SearchType.Site;

  setSearchType(newValue: SearchType) {
    this.searchType = newValue;
  }

  customerSearchRegistry = new Map<string, CustomerSearch>();
  customerVehicleSearchRegistry = new Map<string, VehicleSearch>();
  customerTicketSearchRegistry = new Map<string, TicketSearch>();

  vehicleSearchRegistry = new Map<string, VehicleSearch>();
  ticketSearchRegistry = new Map<string, TicketSearch>();
  ticketItemRegistry = new Map<string, TicketItemSearch>();

  customerPagingParams = new PagingParams();
  customerPagination: Pagination | null = null;
  ticketPagingParams = new PagingParams();
  ticketPagination: Pagination | null = null;
  vehiclePagingParams = new PagingParams();
  vehiclePagination: Pagination | null = null;

  predicate = new Map().set("all", true);

  constructor() {
    makeAutoObservable(this);
    this.customerPagingParams = new PagingParams();
    this.ticketPagingParams = new PagingParams();
    this.vehiclePagingParams = new PagingParams();

    this.setSearchType = this.setSearchType.bind(this);

    reaction(
      () => this.predicate.keys(),
      () => {
        this.customerPagingParams = new PagingParams();
        this.ticketPagingParams = new PagingParams();
        this.vehiclePagingParams = new PagingParams();
        this.customerSearchRegistry.clear();
        this.customerVehicleSearchRegistry.clear();
        this.customerTicketSearchRegistry.clear();

        this.vehicleSearchRegistry.clear();
        this.ticketSearchRegistry.clear();
        this.ticketItemRegistry.clear();
        this.loadSearch();
      }
    );
  }

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  setActiveTab = (activeTab: string | number | undefined) => {
    this.activeTab = activeTab ? Number(activeTab) : 0;
  };

  resetSearchRegistry = () => {
    this.customerSearchRegistry.clear();
    this.customerVehicleSearchRegistry.clear();
    this.customerTicketSearchRegistry.clear();
    this.vehicleSearchRegistry.clear();
    this.ticketSearchRegistry.clear();
    this.ticketItemRegistry.clear();
  };

  loadSearch = async () => {
    this.loadingInitial = true;

    try {
      switch (this.searchType) {
        case SearchType.CustomerName:
        case SearchType.CustomerPhone:
          let customers = await agent.SearchApi.getCustomerSearch(
            this.customerAxiosParams
          );
          runInAction(() => {
            customers.data.forEach((customer) => {
              this.setCustomerSearch(customer);
            });
            this.setCustomerPagination(customers.pagination);
          });
          break;
        case SearchType.EstimateNumber:
        case SearchType.InvoiceNumber:
        case SearchType.PONumber:
          let tickets = await agent.SearchApi.getTicketSearch(
            this.ticketAxiosParams
          );
          runInAction(() => {
            tickets.data.forEach((ticket) => {
              this.setTicketSearch(ticket);
            });
            this.setTicketPagination(tickets.pagination);
          });
          break;
        case SearchType.Tag:
        case SearchType.VehicleModel:
        case SearchType.VehicleNumber:
        case SearchType.Vin:
          let vehicles = await agent.SearchApi.getVehicle(
            this.vehicleAxiosParams
          );
          runInAction(() => {
            vehicles.data.forEach((vehicle) => {
              this.setVehicleSearch(vehicle);
            });
            this.setVehiclePagination(vehicles.pagination);
          });
          break;

        case SearchType.Site:
          let siteCustomers = await agent.SearchApi.getCustomerSearch(
            this.customerAxiosParams
          );
          let siteVehicles = await agent.SearchApi.getVehicle(
            this.vehicleAxiosParams
          );
          let siteTickets = await agent.SearchApi.getTicketSearch(
            this.ticketAxiosParams
          );

          runInAction(() => {
            siteCustomers.data.forEach((customer) => {
              this.setCustomerSearch(customer);
            });
            this.setCustomerPagination(siteCustomers.pagination);

            siteVehicles.data.forEach((vehicle) => {
              this.setVehicleSearch(vehicle);
            });
            this.setVehiclePagination(siteVehicles.pagination);
            siteTickets.data.forEach((ticket) => {
              this.setTicketSearch(ticket);
            });
            this.setTicketPagination(siteTickets.pagination);
          });

        default:
          break;
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoadingInitial(false);
    }
  };

  loadCustomerVehicle = async (customerID: string) => {
    this.loadingInitial = true;
    try {
      const result = await agent.SearchApi.getVehiclesByCustomerId(customerID);
      runInAction(() => {
        result.forEach((vehicle) => {
          this.setCustomerVehicleSearch(vehicle);
        });
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoadingInitial(false);
    }
  };

  loadVehicleTicket = async (vehicleId: string) => {
    this.loadingInitial = true;
    try {
      const result = await agent.SearchApi.getTicketByVehicleId(vehicleId);
      runInAction(() => {
        result.forEach((ticket) => {
          this.setCustomerTicketSearch(ticket);
        });
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoadingInitial(false);
    }
  };

  loadTicketItem = async (ticketId: string) => {
    this.loadingInitial = true;
    try {
      const result = await agent.SearchApi.getTicketItem(ticketId);
      runInAction(() => {
        result.forEach((ticket) => {
          this.setTicketItemSearch(ticket);
        });
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoadingInitial(false);
    }
  };

  setCustomerPagination = (pagination: Pagination) => {
    this.customerPagination = pagination;
  };

  setCustomerPagingParams = (pagingParams: PagingParams) => {
    this.customerPagingParams = pagingParams;
  };

  setTicketPagination = (pagination: Pagination) => {
    this.ticketPagination = pagination;
  };

  setTicketPagingParams = (pagingParams: PagingParams) => {
    this.ticketPagingParams = pagingParams;
  };

  setVehiclePagination = (pagination: Pagination) => {
    this.vehiclePagination = pagination;
  };

  setVehiclePagingParams = (pagingParams: PagingParams) => {
    this.vehiclePagingParams = pagingParams;
  };

  get customerAxiosParams() {
    const params = new URLSearchParams();
    params.append(
      "pageNumber",
      this.customerPagingParams.pageNumber.toString()
    );
    params.append("pageSize", this.customerPagingParams.pageSize.toString());
    this.predicate.forEach((value, key) => {
      if (key === "startDate") {
        params.append(key, (value as Date).toISOString());
      } else {
        params.append(key, value);
      }
    });
    return params;
  }

  get ticketAxiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", this.ticketPagingParams.pageNumber.toString());
    params.append("pageSize", this.ticketPagingParams.pageSize.toString());
    this.predicate.forEach((value, key) => {
      if (key === "startDate") {
        params.append(key, (value as Date).toISOString());
      } else {
        params.append(key, value);
      }
    });
    return params;
  }

  get vehicleAxiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", this.vehiclePagingParams.pageNumber.toString());
    params.append("pageSize", this.vehiclePagingParams.pageSize.toString());
    this.predicate.forEach((value, key) => {
      if (key === "startDate") {
        params.append(key, (value as Date).toISOString());
      } else {
        params.append(key, value);
      }
    });
    return params;
  }

  setPredicate = (
    predicate: SearchType,
    value: string | number | undefined
  ) => {
    const resetPredicate = () => {
      this.predicate.forEach((value, key) => {
        if (key !== "startDate") this.predicate.delete(key);
      });
    };

    this.searchType = predicate;

    switch (predicate) {
      case SearchType.CustomerPhone:
        resetPredicate();
        if (value) this.predicate.set("CustomerPhone", value);
        break;
      case SearchType.CustomerName:
        resetPredicate();
        if (value) this.predicate.set("CustomerName", value);
        break;
      case SearchType.Tag:
        resetPredicate();
        if (value) this.predicate.set("VehicleTag", value);
        break;
      case SearchType.Vin:
        resetPredicate();
        if (value) this.predicate.set("VehicleVin", value);
        break;
      case SearchType.VehicleNumber:
        resetPredicate();
        if (value) this.predicate.set("VehicleNumber", value);
        break;
      case SearchType.VehicleModel:
        resetPredicate();
        if (value) this.predicate.set("VehicleModel", value);
        break;
      case SearchType.InvoiceNumber:
        resetPredicate();
        if (value) this.predicate.set("InvoiceNumber", value);
        break;
      case SearchType.EstimateNumber:
        resetPredicate();
        if (value) this.predicate.set("EstimateNumber", value);
        break;
      case SearchType.PONumber:
        resetPredicate();
        if (value) this.predicate.set("PONumber", value);
        break;
      case SearchType.Site:
        resetPredicate();
        if (value) {
          this.predicate.set("Site", value);
        }
        break;
    }
  };

  private setCustomerSearch = (customerSearch: CustomerSearch) => {
    this.customerSearchRegistry.set(customerSearch.id, customerSearch);
  };

  private setVehicleSearch = (vehicleSearch: VehicleSearch) => {
    this.vehicleSearchRegistry.set(vehicleSearch.id, vehicleSearch);
  };

  private setCustomerVehicleSearch = (vehicleSearch: VehicleSearch) => {
    this.customerVehicleSearchRegistry.set(vehicleSearch.id, vehicleSearch);
  };

  private setTicketSearch = (ticketSearch: TicketSearch) => {
    if (ticketSearch.invoiceDate)
      ticketSearch.invoiceDate = new Date(ticketSearch.invoiceDate);
    if (ticketSearch.estimateDate)
      ticketSearch.estimateDate = new Date(ticketSearch.estimateDate);

    this.ticketSearchRegistry.set(ticketSearch.id, ticketSearch);
  };

  private setCustomerTicketSearch = (ticketSearch: TicketSearch) => {
    if (ticketSearch.invoiceDate)
      ticketSearch.invoiceDate = new Date(ticketSearch.invoiceDate);
    if (ticketSearch.estimateDate)
      ticketSearch.estimateDate = new Date(ticketSearch.estimateDate);

    this.customerTicketSearchRegistry.set(ticketSearch.id, ticketSearch);
  };

  private setTicketItemSearch = (ticketItemSearch: TicketItemSearch) => {
    this.ticketItemRegistry.set(ticketItemSearch.id, ticketItemSearch);
  };

  get getCustomerSearch() {
    return Array.from(this.customerSearchRegistry.values()).sort((a, b) => {
      let temp =
        sortingStrings(
          a.lastName?.toLowerCase() ?? "",
          b.lastName?.toLowerCase() ?? ""
        ) ?? 0;
      if (temp === 0) {
        temp =
          sortingStrings(
            a.firstName?.toLowerCase() ?? "",
            b.firstName?.toLowerCase() ?? ""
          ) ?? 0;

          if (temp === 0) {
            temp =
              sortingStrings(
                a.businessName?.toLowerCase() ?? "",
                b.businessName?.toLowerCase() ?? ""
              ) ?? 0;
          }
      }
      return temp;
    });
  }

  get getVehicleSearch() {
    return Array.from(this.vehicleSearchRegistry.values()).sort((a, b) => {
      const itemA: number = a.year ?? 0;
      const itemB: number = b.year ?? 0;
      let temp = itemB - itemA;
      if (temp === 0) {
        temp =
          sortingStrings(
            a.make?.toLowerCase() ?? "",
            b.make?.toLowerCase() ?? ""
          ) ?? 0;

        if (temp === 0) {
          temp =
            sortingStrings(
              a.model?.toLowerCase() ?? "",
              b.model?.toLowerCase() ?? ""
            ) ?? 0;
        }
      }
    return temp;
    });
  }

  get getCustomerVehicleSearch() {
    return Array.from(this.customerVehicleSearchRegistry.values()).sort(
      (a, b) => {
        let temp =
          sortingStrings(
            a.customerId?.toLowerCase() ?? "",
            b.customerId?.toLowerCase() ?? ""
          ) ?? 0;
        if (temp === 0) {
          const itemA: number = a.year ?? 0;
          const itemB: number = b.year ?? 0;
          temp = itemA - itemB;
          if (temp === 0) {
            temp =
              sortingStrings(
                a.make?.toLowerCase() ?? "",
                b.make?.toLowerCase() ?? ""
              ) ?? 0;

            if (temp === 0) {
              temp =
                sortingStrings(
                  a.model?.toLowerCase() ?? "",
                  b.model?.toLowerCase() ?? ""
                ) ?? 0;
            }
          }
        }
        return temp;
      }
    );
  }

  get getTicketSearch() {
    return Array.from(this.ticketSearchRegistry.values()).sort((a, b) => {
      const itemA: number =
        a.ticketType === TicketType.Estimate
          ? a.estimateNumber!
          : a.invoiceNumber!;
      const itemB: number =
        b.ticketType === TicketType.Estimate
          ? b.estimateNumber!
          : b.invoiceNumber!;
      return itemA - itemB;
    });
  }

  get getCustomerTicketSearch() {
    return Array.from(this.customerTicketSearchRegistry.values()).sort(
      (a, b) => {
        const itemA: number =
          a.ticketType === TicketType.Estimate
            ? a.estimateNumber!
            : a.invoiceNumber!;
        const itemB: number =
          b.ticketType === TicketType.Estimate
            ? b.estimateNumber!
            : b.invoiceNumber!;
        return itemA - itemB;
      }
    );
  }

  get getTicketItemSearch() {
    return Array.from(this.ticketItemRegistry.values()).sort(
      (a, b) => a.order - b.order
    );
  }

  filterTicketItemsByTicketId = (id: string) => {
    return this.getTicketItemSearch.filter((x) => x.ticketId === id);
  };

  filterVehiclesByCustomerId = (id: string) => {
    return this.getCustomerVehicleSearch.filter((x) => x.customerId === id);
  };

  filterTicketsByVehicleId = (id: string) => {
    return this.getCustomerTicketSearch.filter((x) => x.vehicleId === id);
  };
}
