import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import {
  Technician,
  TechnicianFormValues,
  SettingsTechnician,
  SettingsTechnicianFormValues,
} from "../models/technician";
import { v4 as uuid } from "uuid";
import { DropdownItemProps } from "semantic-ui-react";
export default class TechnicianStore {
  loadingInitial = false;
  loadingListInitial = false;
  activeTab: number = 0;

  selectedTechnician: TechnicianFormValues = {
    id: undefined,
    firstName: "",
    lastName: "",
    isActive: false,
  };

  technicianRegistry = new Map<string | undefined, Technician>();
  technicianList: DropdownItemProps[] = [];

  selectedSettingsTechnician: SettingsTechnicianFormValues = {
    id: undefined,
    technicianTrackingEnabled: false,
    technicianPartsTrackingEnabled: false,
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  setLoadingListInitial = (state: boolean) => {
    this.loadingListInitial = state;
  };

  setActiveTab = (activeTab: string | number | undefined) => {
    this.activeTab = activeTab ? Number(activeTab) : 0;
  };

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => {},
      () => {
        this.technicianRegistry.clear();
        this.loadTechnician();
      }
    );
  }

  resetTechnicianRegistry = () => {
    this.technicianRegistry.clear();
    this.technicianList = [];
  };

  loadTechnician = async () => {
    this.loadingListInitial = true;
    try {
      this.technicianRegistry.clear();
      const result = await agent.technician.listTechnician();
      result.forEach((technician) => {
        this.setTechnician(technician);
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoadingListInitial(false);
    }
  };

  loadSettingsTechnician = async () => {
    this.loadingInitial = true;
    try {
      const result = await agent.technician.getSettingsTechnician();
      runInAction(() => {
        this.selectedSettingsTechnician = result;
      });
      return result;
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.loadingInitial = false;
      });
    }
  };

  loadReportTechnicianDropdownList = async () => {
    this.loadingInitial = true;
    try {
      const result = await agent.technician.reportDropdownListTechnician();
      runInAction(() => {
        this.technicianList = result;
      });
      return this.technicianList;
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.loadingInitial = false;
      });
    }
  };

  loadTechnicianDropdownList = async () => {
    this.loadingInitial = true;
    try {
      const result = await agent.technician.dropdownListTechnician();
      runInAction(() => {
        this.technicianList = result;
      });
      return this.technicianList;
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.loadingInitial = false;
      });
    }
  };

  get getTechnicians() {
    return Array.from(this.technicianRegistry.values()).sort((a, b) => {
      if (a.lastName.toLowerCase() < b.lastName.toLowerCase()) return -1;
      if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) return 1;
      if (a.lastName.toLowerCase() === b.lastName.toLowerCase()) {
        if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) return -1;
        if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) return 1;
      }
      return 0;
    });
  }

  private setTechnician = (technician: Technician) => {
    technician.createdDate = new Date(technician.createdDate);
    this.technicianRegistry.set(technician.id, technician);
  };

  private getTechnician = (id: string) => {
    return this.technicianRegistry.get(id);
  };

  setSelectedTechnician = (values: TechnicianFormValues) => {
    this.selectedTechnician = values;
  };

  setSelectedSettingsTechnician = (values: SettingsTechnicianFormValues) => {
    this.selectedSettingsTechnician = values;
  };

  createTechnician = async (values: TechnicianFormValues) => {
    try {
      let myNew: Technician = new Technician(values);
      myNew.id = uuid();
      await agent.technician.addTechnician(myNew);

      runInAction(() => {
        this.technicianRegistry.clear();
      });
    } catch (error) {
      console.log(error);
    }
  };

  toggleTechnician = async (id: string) => {
    try {
      await agent.technician.toggleTechnician(id);
      runInAction(() => {
        if (id) {
          let updatedTechnician = this.getTechnician(id);
          if (updatedTechnician)
            updatedTechnician.isActive = !updatedTechnician.isActive;
          this.technicianRegistry.set(id, updatedTechnician as Technician);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  createSettingsTechnician = async (values: SettingsTechnicianFormValues) => {
    try {
      let myNew: SettingsTechnician = new SettingsTechnician(values);
      await agent.technician.addSettingsTechnician(myNew);

      runInAction(() => {
        this.setSelectedSettingsTechnician(values);
      });
    } catch (error) {
      console.log(error);
    }
  };

  updateSettingsTechnician = async (values: SettingsTechnicianFormValues) => {
    try {
      let myNew: SettingsTechnician = new SettingsTechnician(values);
      await agent.technician.updateSettingsTechnician(myNew);

      runInAction(() => {
        this.setSelectedSettingsTechnician(values);
      });
    } catch (error) {
      console.log(error);
    }
  };

  updateTechnician = async (values: TechnicianFormValues) => {
    try {
      let myNew: Technician = new Technician(values);
      await agent.technician.updateTechnician(myNew);

      runInAction(() => {
        if (values.id) {
          let updatedTechnician = {
            ...this.getTechnician(values.id),
            ...values,
          };
          this.technicianRegistry.set(
            values.id,
            updatedTechnician as Technician
          );
          this.selectedTechnician = updatedTechnician as Technician;
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  removeTechnician = async (id: string) => {
    try {
      await agent.technician.removeTechnician(id);
      runInAction(() => {
        this.technicianRegistry.delete(id);
      });
    } catch (error) {
      console.log(error);
    }
  };
}
