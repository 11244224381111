import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../../api/agent";
import { Pagination, PagingParams } from "../../models/pagination";
import {
  ClientAdditionalInformation,
  ClientAvailableTrials,
  ClientBillingSubscriptionInformation,
  ClientCompanyStatuses,
  ClientContacts,
  ClientList,
  ClientSubscriptionHistory,
  SystemAdminClientDetails,
  TrialDetails,
  TrialExtension,
} from "../../models/systemAdmin";
import { sortingStrings } from "../../common/util/functions";
import { ClientDetails } from "../../models/client";
import { Application } from "../../models/enums";

export default class ClientSystemAdminStore {
  constructor() {
    makeAutoObservable(this);
    reaction(
      () => this.predicate.keys(),
      () => {
        this.pagingParams = new PagingParams(1, 10);
        this.ClientListRegistry.clear();
        this.loadClientList();
      }
    );
  }

  loadingInitial = false;

  ClientListRegistry = new Map<string, ClientList>();
  predicate = new Map().set("all", true);
  pagination: Pagination | null = null;
  pagingParams = new PagingParams(1, 10);

  trialDetails: TrialDetails | undefined = undefined;
  ClientBillingSubscriptionInformationRegistry = new Map<
    string,
    ClientBillingSubscriptionInformation
  >();

  clientAdditionalInformation: ClientAdditionalInformation | undefined =
    undefined;
  ClientSubscriptionHistoryRegistry = new Map<
    string,
    ClientSubscriptionHistory
  >();

  clientContacts: ClientContacts | undefined = undefined;


  ClientCompanyStatusRegistry = new Map<string, ClientCompanyStatuses>();
  ClientAvailableTrialsRegistry = new Map<string, ClientAvailableTrials>();

  setClientContacts = (value: ClientContacts) => {
    this.clientContacts = value;
  };
  clearClientContacts = () => {
    this.clientContacts = undefined;
  };

  setClientAdditionalInformation = (value: ClientAdditionalInformation) => {
    value.createdDate = new Date(value.createdDate);
    this.clientAdditionalInformation = value;
  };

  setTrialDetails = (value: TrialDetails) => {
    if (value.trialStart) {
      value.trialStart = new Date(value.trialStart);
    }
    if (value.trialEnd) {
      value.trialEnd = new Date(value.trialEnd);
    }
    this.trialDetails = value;
  };

  clearTrialDetails = () => {
    this.trialDetails = undefined;
  };

  resetAllPredicates = () => {
    this.predicate.clear();
  };

  loadClientList = async () => {
    this.loadingInitial = true;
    try {
      this.ClientListRegistry.clear();
      const result = await agent.SystemAdminClient.getClientList(this.axiosParams);
      runInAction(() => {
        result.data.forEach((item) => {
          this.setClientList(item);
        });
        this.setPagination(result.pagination);
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoadingInitial(false);
    }
  };

  selectedSystemAdminClientDetails: SystemAdminClientDetails = {
    invoiceCount: undefined,
    laborDataAccess: false,
    dowellSystemApplication: Application.None,
    status: undefined,
    createdDate: undefined,
  };

  loadClientInformation = async (id: string) => {
    this.loadingInitial = true;
    try {
      const result = await agent.SystemAdminClient.getClientInformation(id);
      runInAction(() => {
        this.selectedSystemAdminClientDetails = new SystemAdminClientDetails(result);
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.loadingInitial = false;
      });
    }
  };

  loadClientBillingSubscriptionInformation = async (id: string) => {
    try {
      this.ClientBillingSubscriptionInformationRegistry.clear();
      const result =
        await agent.SystemAdminClient.getClientBillingSubscriptionInformation(id, this.axiosParams);
      runInAction(() => {
        result.data.forEach((x) => {
          this.setClientBillingSubscription(x);
        });
        this.setPagination(result.pagination);
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoadingInitial(false);
    }
  };

  loadClientSubscriptionHistory = async (id: string) => {
    try {
      this.ClientSubscriptionHistoryRegistry.clear();
      const result = await agent.SystemAdminClient.getClientSubscriptionHistory(id);

      runInAction(() => {
        result.forEach((x) => {
          this.setClientSubscriptionHistory(x);
        });
      });
    } catch (error) {
      console.log(error);
    }
  };
  loadClientContacts = async (id: string) => {
    try {
      this.clearClientContacts();
      const result = await agent.SystemAdminClient.getClientContacts(id);
      runInAction(() => {
        this.setClientContacts(result);
      });
    } catch (error) {
      console.log(error);
    }
  };

  loadClientCompanyStatus = async (id: string) => {
    try {
      this.ClientCompanyStatusRegistry.clear();
      const result = await agent.SystemAdminClient.getClientCompanyStatuses(id);
      runInAction(() => {
        result.forEach((x) => {
          this.setClientCompanyStatuses(x);
        });
      });
    } catch (error) {
      console.log(error);
    }
  };

  loadClientAvailableTrials = async (id: string) => {
    try {
      this.ClientAvailableTrialsRegistry.clear();
      const result = await agent.SystemAdminClient.getClientAvailableTrials(id);
      runInAction(() => {
        result.forEach((x) => {
          this.setClientAvailableTrials(x);
        });
      });
    } catch (error) {
      console.log(error);
    }
  };

  updateClient = async (id: string, client: ClientDetails) => {
    try {
      await agent.SystemAdminClient.updateClient(id, client);
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  updateClientStatus = async (id: string, client: SystemAdminClientDetails) => {
    try {
      let myNewDetails = new SystemAdminClientDetails(client);

      await agent.SystemAdminClient.updateClientStatus(id, myNewDetails);
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  private setClientList = (clientList: ClientList) => {
    clientList.createdDate = new Date(clientList.createdDate);
    this.ClientListRegistry.set(clientList.id, clientList);
  };

  private setClientBillingSubscription = (
    clientBillingSubscriptionInformation: ClientBillingSubscriptionInformation
  ) => {
    clientBillingSubscriptionInformation.date = new Date(
      clientBillingSubscriptionInformation.date
    );
    this.ClientBillingSubscriptionInformationRegistry.set(
      clientBillingSubscriptionInformation.id,
      clientBillingSubscriptionInformation
    );
  };

  private setClientSubscriptionHistory = (
    clientSubscriptionHistory: ClientSubscriptionHistory
  ) => {
    clientSubscriptionHistory.startDate = new Date(
      clientSubscriptionHistory.startDate
    );
    if (clientSubscriptionHistory.nextCharge) {
      clientSubscriptionHistory.nextCharge = new Date(
        clientSubscriptionHistory.nextCharge
      );
    }
    clientSubscriptionHistory.endDate = new Date(
      clientSubscriptionHistory.endDate
    );
    if (clientSubscriptionHistory.cancelDate) {
      clientSubscriptionHistory.cancelDate = new Date(
        clientSubscriptionHistory.cancelDate
      );
    }

    this.ClientSubscriptionHistoryRegistry.set(
      clientSubscriptionHistory.id,
      clientSubscriptionHistory
    );
  };


  private setClientCompanyStatuses = (
    clientCompanyStatuses: ClientCompanyStatuses
  ) => {
    clientCompanyStatuses.createdDate = new Date(
      clientCompanyStatuses.createdDate
    );
    clientCompanyStatuses.endDate = new Date(clientCompanyStatuses.endDate);

    this.ClientCompanyStatusRegistry.set(
      clientCompanyStatuses.statusType,
      clientCompanyStatuses
    );
  };
  private setClientAvailableTrials = (
    clientAvailableTrials: ClientAvailableTrials
  ) => {
    clientAvailableTrials.endDate = new Date(clientAvailableTrials.endDate);

    this.ClientAvailableTrialsRegistry.set(
      clientAvailableTrials.trialType,
      clientAvailableTrials
    );
  };

  // TODO: Uncomment out when Lex fixes the API for this sort
  get getClientList() {
    return Array.from(this.ClientListRegistry.values());
    // .sort(
    //   (a, b) => b.createdDate!.getTime() - a.createdDate!.getTime()
    // );
  }
  
  get getClientBillingSubscriptionInformation() {
    return Array.from(
      this.ClientBillingSubscriptionInformationRegistry.values()
    ).sort((a, b) => b.date.getTime() - a.date.getTime());
  }

  get getClientSubscriptionHistory() {
    return Array.from(this.ClientSubscriptionHistoryRegistry.values()).sort(
      (a, b) => b.endDate.getTime() - a.endDate.getTime());
  }
  

  get getClientCompanyStatus() {
    return Array.from(this.ClientCompanyStatusRegistry.values()).sort(
      (a, b) => {
        return sortingStrings(a.statusType??"", b.statusType??"") ?? 0;
      }
    );
  }
  get getClientAvailableTrials() {
    return Array.from(this.ClientAvailableTrialsRegistry.values()).sort(
      (a, b) => {
        return sortingStrings(a.trialType??"", b.trialType??"") ?? 0;
      }
    );
  }

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  resetPredicate = (keyToDelete: string) => {
    this.predicate.delete(keyToDelete);
  };

  setPredicate = (
    predicate: string,
    value:
      | string
      | number
      | boolean
      | Date
      | (string | number | boolean | Date)[]
      | undefined
  ) => {
    this.predicate.clear();
    if (value) this.predicate.set(predicate, value);
  };

  clearPredicate = () => {
    this.predicate.clear();
  };

  setPagination = (pagination: Pagination) => {
    this.pagination = pagination;
  };

  setPagingParams = (pagingParams: PagingParams) => {
    this.pagingParams = new PagingParams(pagingParams.pageNumber, 10);
  };

  get axiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", this.pagingParams.pageNumber.toString());
    params.append("pageSize", this.pagingParams.pageSize.toString());
    this.predicate.forEach((value, key) => {
      if (key === "startDate" || key === "endDate") {
        params.append(key, (value as Date).toISOString());
      } else {
        params.append(key, value);
      }
    });
    return params;
  }
}
