import axios from "axios";
import { PaginatedResult } from "../../models/pagination";
import {
  ClientList,
  ClientAvailableTrials,
  ClientBillingSubscriptionInformation,
  ClientCompanyStatuses,
  ClientContacts,
  ClientSubscriptionHistory,
  CompanyApprovalReviewDTO,
  TrialDetails,
  TrialExtension,
  SystemAdminClientDetails,
} from "../../models/systemAdmin";
import { requests, responseBody } from "../Requests";
import { ClientDetails } from "../../models/client";

export const SystemAdminClient = {
  getCompanyApprovalReviewList: () =>
    requests.get<CompanyApprovalReviewDTO[]>(
      "/SystemAdminClient/CompanyApprovalReviewList"
    ),
  getClientList: (params: URLSearchParams) =>
    axios
      .get<PaginatedResult<ClientList[]>>(`/SystemAdminClient/listClients`, {
        params,
      })
      .then(responseBody),
  approveCompany: (id: string) =>
    requests.put<void>(`/SystemAdminClient/approveCompany/${id}`, {}),
  rejectCompany: (id: string) =>
    requests.put<void>(`/SystemAdminClient/rejectCompany/${id}`, {}),
  getClientInformation: (id: string) =>
    requests.get<SystemAdminClientDetails>(
      `/SystemAdminClient/getClientInformation/${id}`
    ),
  getClientContacts: (id: string) =>
    requests.get<ClientContacts>(`/SystemAdminClient/getClientContacts/${id}`),
  getClientCompanyStatuses: (id: string) =>
    requests.get<ClientCompanyStatuses[]>(
      `/SystemAdminClient/getClientCompanyStatuses/${id}`
    ),
  getClientAvailableTrials: (id: string) =>
    requests.get<ClientAvailableTrials[]>(
      `/SystemAdminClient/getClientAvailableTrials/${id}`
    ),
  getClientBillingSubscriptionInformation: (
    id: string,
    params: URLSearchParams
  ) =>
    axios
      .get<PaginatedResult<ClientBillingSubscriptionInformation[]>>(
        `/SystemAdminClient/getClientBillingSubscriptionInformation/${id}?`,
        { params }
      )
      .then(responseBody),
  getClientSubscriptionHistory: (id: string) =>
    requests.get<ClientSubscriptionHistory[]>(
      `/SystemAdminClient/getClientSubscriptionHistory/${id}`
    ),
  updateClient: (id: string, client: ClientDetails) =>
    requests.put<void>(`/SystemAdminClient/updateCompanyDetails/${id}`, client),
  updateClientStatus: (id: string, client: SystemAdminClientDetails) =>
    requests.put<void>(`/SystemAdminClient/UpdateClientStatus/${id}`, client),
  getCompanyPhoneNumberList: (id: string, phoneNumber: string) =>
    requests.get<ClientList[]>(`/SystemAdminClient/listClientPhoneNumbers/${id}/${phoneNumber}`),
  getCompanyAddressList: (id: string) =>
    requests.get<ClientList[]>(`/SystemAdminClient/listClientAddresses/${id}`),
};
