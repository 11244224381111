import React from "react";
import { useTranslation } from "react-i18next";
import MyTextInput from "../../app/common/form/MyTextInput";
import { observer } from "mobx-react-lite";
import { Grid } from "semantic-ui-react";
import WholeNumberInput from "../../app/common/form/WholeNumberInput";

interface Props {
  isCustomerEdit?: boolean;
  autoCapitalizeFirstLetter: boolean;
}

export default observer(function CustomerVehicleEdit(props: Props) {
  const { t } = useTranslation(["common", "translation", "tickets"]);

  return (
    <>
      <Grid.Row columns={1}>
        <Grid.Column>
          <MyTextInput
            label={t("pops.vin", { ns: "tickets" }) + ":"}
            name="vin"
            placeholder={t("pops.vin", { ns: "tickets" })}
            autoCapitalizeFirstLetter={props.autoCapitalizeFirstLetter}
            maxLength={20}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2}>
        <Grid.Column>
          <MyTextInput
            label={t("pops.vehicle_number", { ns: "tickets" }) + ":"}
            name="vehicleNumber"
            placeholder={t("pops.vehicle_number", { ns: "tickets" })}
            autoCapitalizeFirstLetter={props.autoCapitalizeFirstLetter}
            maxLength={20}
          />
        </Grid.Column>
        <Grid.Column>
          <MyTextInput
            label={t("pops.tag", { ns: "tickets" }) + ":"}
            name="tag"
            placeholder={t("pops.tag", { ns: "tickets" })}
            autoCapitalizeFirstLetter={props.autoCapitalizeFirstLetter}
            maxLength={20}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2}>
        {!props.isCustomerEdit && (
          <Grid.Column>
            <WholeNumberInput
              label={t("estimate.mileageIn", { ns: "tickets" }) + ":"}
              name="mileageIn"
              placeholder={t("estimate.mileageIn", { ns: "tickets" })}
              maxLength={20}
            />
          </Grid.Column>
        )}
        <Grid.Column>
          <WholeNumberInput
            label={
              props.isCustomerEdit
                ? t("mileage", { ns: "common" })
                : t("estimate.mileageOut", { ns: "tickets" }) + ":"
            }
            name="mileageOut"
            placeholder={t("estimate.mileageOut", { ns: "tickets" })}
            maxLength={20}
          />
        </Grid.Column>
      </Grid.Row>
    </>
  );
});
