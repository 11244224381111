export interface SettingsQuickVin {
  id?: string;
  isActive: boolean;
  businessName: string;
  contactName: string;
  contactEmail: string;
  url: string;
  addressLine1: string;
  city: string;
  state: string;
  postalCode: string;
  businessPhoneId: string;
  error?: string;
}

export class SettingsQuickVin implements SettingsQuickVin {
  constructor(init?: SettingsQuickVinFormValues) {
    if (init) {
      this.id = init.id;
      this.isActive = init.isActive.toString() === "true";
      this.businessName = init.businessName;
      this.contactName = init.contactName;
      this.contactEmail = init.contactEmail;
      this.url = init.url;
      this.addressLine1 = init.addressLine1;
      this.city = init.city;
      this.state = init.state;
      this.postalCode = init.postalCode;
      this.businessPhoneId = init.businessPhoneId;
      this.error = init.error;
    }
  }
}

export class SettingsQuickVinFormValues {
  id?: string = undefined;
  isActive: boolean = false;
  businessName: string = "";
  contactName: string = "";
  contactEmail: string = "";
  url: string = "";
  addressLine1: string = "";
  city: string = "";
  state: string = "";
  postalCode: string = "";
  businessPhoneId: string = "";
  error?: string = undefined;

  constructor(settingsQuickVin?: SettingsQuickVinFormValues) {
    if (settingsQuickVin) {
      this.id = settingsQuickVin.id;
      this.isActive = settingsQuickVin.isActive;
      this.businessName = settingsQuickVin.businessName;
      this.contactName = settingsQuickVin.contactName;
      this.contactEmail = settingsQuickVin.contactEmail;
      this.url = settingsQuickVin.url;
      this.addressLine1 = settingsQuickVin.addressLine1;
      this.city = settingsQuickVin.city;
      this.state = settingsQuickVin.state;
      this.postalCode = settingsQuickVin.postalCode;
      this.businessPhoneId = settingsQuickVin.businessPhoneId;
      this.error = settingsQuickVin.error;
    }
  }
}

export interface QuickVinLookup {
  ticketId?: string;
  tag?: string;
  state?: string;
  vin?: string;
  result?: string; 
  selectedVehicle?: number;
}