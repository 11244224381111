import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import {
  Divider,
  Header,
  Message,
  SemanticCOLORS,
  SemanticTEXTALIGNMENTS,
} from "semantic-ui-react";
import { useStore } from "../stores/store";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import MyHelpButton from "../common/form/MyHelpButton";

interface Props {
  header: string;
  type: "h1" | "h2" | "h3" | "h4";
  divider: boolean;
  showAlerts?: boolean;
  color?: SemanticCOLORS;
  className?: string;
  textAlign?: SemanticTEXTALIGNMENTS;
  removeDividerPadding?: boolean;
  showHelp?: boolean;
  helpName?: string;
  getAlerts?: boolean;
  addTitle: boolean;
}

export default observer(function PageHeader({
  header,
  type,
  divider,
  color,
  textAlign,
  showAlerts,
  removeDividerPadding,
  helpName,
  showHelp,
  getAlerts,
  addTitle
}: Props) {
  const { commonStore, userStore } = useStore();
  const { alertMessage, loadAlerts } = commonStore;
  const { isLoggedIn } = userStore;

  const { t } = useTranslation(["alerts"]);

  useEffect(() => {
    if (isLoggedIn && getAlerts) loadAlerts();
  }, [isLoggedIn, getAlerts, loadAlerts]);

useEffect(() => {
    if (addTitle) document.title = header; 
}, []);

  return (
    <>
      {showAlerts &&
        !getAlerts &&
        alertMessage &&
        alertMessage.length > 0 && (
          <Message color="blue">
            {alertMessage.length > 0 ? (
              <Message.List>
                {alertMessage.map((message, i) => (
                  <Link
                    key={`alert.${i}`}
                    to={"/account/resendVerificationEmail"}
                  >
                    {t(message, { ns: "alerts" })}
                  </Link>
                ))}
              </Message.List>
            ) : (
              <Link to={"/account/resendVerificationEmail"}>
                <strong>{t(alertMessage, { ns: "alerts" })}</strong>
              </Link>
            )}
          </Message>
        )}
      <Header
        as={type}
        className="modal-text-color "
        textAlign={textAlign ?? "left"}
      >
        {header}
        {showHelp && (
          <MyHelpButton name={helpName!} help={helpName ?? ""} color={"blue"}/>
        )}
      </Header>
      {divider && (
        <Divider
          className={
            removeDividerPadding ? "RemoveTopBottomPadding" : undefined
          }
        />
      )}
    </>
  );
});
