import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import {
    Divider,
    Header,
  Table,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../app/stores/store";

export default observer(function TrialActivity() {
  const { t } = useTranslation(["common", "systemAdmin"]);
  const { trialActivityStore } = useStore();
  const { loadTrialActivityReport, TrialActivityReportRegistry } = trialActivityStore;

  useEffect(() => {
    loadTrialActivityReport();
  }, [loadTrialActivityReport]);
  return (
    <>
     <Header
              as="h1"
              content={t("Trial Activity", { ns: "systemAdmin" })}
              className="modal-text-color"
              textAlign="left"
            />
            <Divider />
<Table singleLine>
  <Table.Header>
    {TrialActivityReportRegistry.map((x) => (
      <Table.Row>
      <Table.HeaderCell></Table.HeaderCell>
      <Table.HeaderCell>{x.daysLeft}</Table.HeaderCell>
    </Table.Row>
    ))}
  </Table.Header>
  <Table.Body>
  {TrialActivityReportRegistry.map((x) => (
    <Table.Row>
      <Table.Cell><b>Auths</b></Table.Cell>
      <Table.Cell>{x.count}</Table.Cell>
      </Table.Row>
         ))}
    </Table.Body>
</Table>
    </>
  );
});
