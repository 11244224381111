import React, { useEffect, useState } from "react";
import { Button, Container } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../app/stores/store";
import PageHeader from "../../../app/layout/PageHeader";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import MyTextInput from "../../../app/common/form/MyTextInput";
import { UserFormValues } from "../../../app/models/user";
import PhoneNumberInput from "../../../app/common/form/PhoneNumberInput";
import { PhoneNumberType } from "../../../app/models/enums";

interface Props {
  userId: string;
}

export default function UpdateUser({ userId }: Readonly<Props>) {
  const { t } = useTranslation(["common"]);
  const { modalStore, userStore, userSystemAdminStore, adminNotesStore } =
    useStore();

  const { getUserDetails, updateUser } = userStore;
  const { loadUserDetails } = userSystemAdminStore;
  const { adminNotesRegistry, loadUserAdminNotes } = adminNotesStore;

  useEffect(() => {
    getUserDetails(userId).then((user) => setUser(new UserFormValues(user)));
  }, [getUserDetails, userId]);

  const [user, setUser] = useState<UserFormValues>(new UserFormValues());

  function handleFormSubmit(values: UserFormValues) {
    updateUser(values).then(() => {
      loadUserDetails(userId);
      adminNotesRegistry.clear();
      loadUserAdminNotes(userId);
      modalStore.closeModal();
    });
  }

  const validationSchema = Yup.object({
    username: Yup.string().required("Username is required."),
    email: Yup.string()
      .email(t("registration.invalid_Email", { ns: "errors" }))
      .required("Email address is required."),
    firstName: Yup.string().required("First name is required."),
    lastName: Yup.string().required("Last name is required."),
  });

  return (
    <>
      <PageHeader
        header="Update User Details"
        type="h2"
        divider={true}
        addTitle={false}
      />

      <Formik
        validationSchema={validationSchema}
        enableReinitialize
        initialValues={user}
        onSubmit={(values) => handleFormSubmit(values)}
      >
        {({
          handleSubmit,
          isValid,
          isSubmitting,
          dirty,
          handleBlur,
          handleChange,
          errors,
          values,
          touched,
          setFieldValue,
        }) => (
          <Form
            className="ui form error"
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <Container className="page">
              <MyTextInput
                placeholder={""}
                name={"username"}
                label="Username"
                autoCapitalizeFirstLetter={false}
                maxLength={255}
              />
              <MyTextInput
                placeholder={""}
                name={"email"}
                label="Email Address"
                autoCapitalizeFirstLetter={false}
                maxLength={255}
              />
              <MyTextInput
                placeholder={""}
                name={"firstName"}
                label="First Name"
                autoCapitalizeFirstLetter={false}
                maxLength={255}
              />
              <MyTextInput
                placeholder={""}
                name={"lastName"}
                label="Last Name"
                autoCapitalizeFirstLetter={false}
                maxLength={255}
              />
              <PhoneNumberInput
                name={"primaryNumber"}
                label="Primary Phone"
                autoFormat={true}
                phoneType={PhoneNumberType.Landline}
              />
              <PhoneNumberInput
                name={"cellNumber"}
                label="Cell Phone"
                autoFormat={true}
                phoneType={PhoneNumberType.Mobile}
              />
              <Button
                className="save_button ui button"
                icon="cancel"
                type="button"
                content={t("close", { ns: "common" })}
                onClick={modalStore.closeModal}
                size="medium"
              />
              <Button
                className="save_button ui button"
                icon="save"
                type="submit"
                color="green"
                disabled={isSubmitting || !dirty || !isValid}
                content={t("save", { ns: "common" })}
                size="medium"
              />
            </Container>
          </Form>
        )}
      </Formik>
    </>
  );
}
