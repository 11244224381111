import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Menu, Tab } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../app/stores/store";
import ManageUsers from "../Users/ManageUsers";
import MultiRecurring from "./MultiRecurring";
import NoLogins from "./NoLogins";
import SubEnded from "./SubEnded";
import ANSettle from "./ANSettle";
import ActiveServiceTypes from "./ActiveServiceTypes";
import CancellationReport from "../adminTools/CancellationReport";
import RecurringCharges from "../adminTools/RecurringCharges";
import { useNavigate } from "react-router-dom";

interface Props {
  activeSubTab: number;
}

export default observer(function ReportsTabs(props: Props) {
  const { t } = useTranslation(["common", "systemAdmin"]);
  const navigate = useNavigate();

  const { systemAdminStore } = useStore();
  const { setActiveSubTab, activeSubTab } = systemAdminStore;

  useEffect(() => {
    setActiveSubTab(props.activeSubTab);
  }, [setActiveSubTab, props.activeSubTab]);

  const panes = [
    {
      menuItem: (
        <Menu.Item
          onClick={() => {
            navigate(`/admin/Reports/MultiRecurring`);
          }}
        >
          {t("reports.multi_tab", { ns: "systemAdmin" })}
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane active={0 === activeSubTab}>
          <MultiRecurring />
        </Tab.Pane>
      ),
    },
    {
      menuItem: (
        <Menu.Item
          onClick={() => {
            navigate(`/admin/Reports/noLogins`);
          }}
        >
          {t("reports.noLogins_tab", { ns: "systemAdmin" })}
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane active={1 === activeSubTab}>
          <NoLogins />
        </Tab.Pane>
      ),
    },
    {
      menuItem: (
        <Menu.Item
          onClick={() => {
            navigate(`/admin/Reports/subEnded`);
          }}
        >
          {t("reports.subEnded_tab", { ns: "systemAdmin" })}
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane active={2 === activeSubTab}>
          <SubEnded />
        </Tab.Pane>
      ),
    },
    {
      menuItem: (
        <Menu.Item
          onClick={() => {
            navigate(`/admin/Reports/authNetSettle`);
          }}
        >
          {t("reports.anSettle_tab", { ns: "systemAdmin" })}
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane active={3 === activeSubTab}>
          <ANSettle />
        </Tab.Pane>
      ),
    },
    {
      menuItem: (
        <Menu.Item
          onClick={() => {
            navigate(`/admin/Reports/activeServiceTypes`);
          }}
        >
          {t("reports.actServTypes_tab", { ns: "systemAdmin" })}
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane active={4 === activeSubTab}>
          <ActiveServiceTypes />
        </Tab.Pane>
      ),
    },
    {
      menuItem: (
        <Menu.Item
          onClick={() => {
            navigate(`/admin/Reports/carFaxData`);
          }}
        >
          {t("reports.carfaxData_tab", { ns: "systemAdmin" })}
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane active={5 === activeSubTab}>
          <ManageUsers />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <>
      <Tab
        menu={{ fluid: true }}
        menuPosition="left"
        panes={panes}
        activeIndex={activeSubTab}
        onTabChange={(e, data) => {
          setActiveSubTab(data.activeIndex);
        }}
      />
    </>
  );
});
