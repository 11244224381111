import { observer } from "mobx-react-lite";
import React from "react";
import {
  Button,
  Checkbox,
  Divider,
  Grid,
  Message,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../app/stores/store";
import { JobAddOn } from "../../../app/models/jobLabor";
import PageHeader from "../../../app/layout/PageHeader";

interface Props {
  addonList: JobAddOn[];
  addonTime: (jobAddons: JobAddOn[]) => void;
}

export default observer(function SelectJobAddons(props: Props) {
  const { t } = useTranslation(["common", "translation"]);
  const { ticketSessionStore, modalStore } = useStore();
  const { setSelectedJobLaborJobAddon } = ticketSessionStore;

  let jobAddonList: JobAddOn[] = [];

  function handleClick(addon: JobAddOn, checked: boolean) {
    if (checked) {
      jobAddonList.push(addon);
    } else {
      jobAddonList = jobAddonList.filter((x) => {
        return x.jobAddonId !== addon.jobAddonId;
      });
    }
  }

  return (
    <>
      <PageHeader
        type="h1"
        header={t("vehicle_questions", { ns: "tickets" }).toString()}
        divider={true}
        addTitle={false}
      />
      <Message info>
        <Message.Header>
          {t("questions_message", { ns: "tickets" })}
        </Message.Header>
      </Message>
      <Grid>
        <Grid.Row columns={1}>
          {props.addonList &&
            props.addonList.length > 0 &&
            props.addonList.map((addon, index) => (
              <Grid.Column key={`addon.${addon.jobAddonId}`}>
                <Checkbox
                  key={`addon${addon.jobAddonId}`}
                  label={addon.description}
                  onClick={(e, d) => {
                    handleClick(addon, d.checked ? d.checked : false);
                  }}
                />
              </Grid.Column>
            ))}
        </Grid.Row>
      </Grid>
      <Divider />
      <Button
        color="green"
        content={t("continue", { ns: "tickets" })}
        className="save_button"
        type="button"
        onClick={() => {
          setSelectedJobLaborJobAddon(jobAddonList);
          if (jobAddonList && jobAddonList.length > 0) props.addonTime(jobAddonList);
          modalStore.closeModal();
        }}
      ></Button>
      <Button
        className="save_button"
        type="reset"
        content={t("close", { ns: "common" })}
        onClick={() => {
          modalStore.closeModal();
        }}
      />
    </>
  );
});
