import React from "react";
import { Icon, Popup, SemanticCOLORS } from "semantic-ui-react";
import MyHelpText from "./MyHelpText";
import MyHelpModal from "./MyHelpModal";

interface Props {
  name: string;
  help: string;
  className?: string;
  seeMore?: boolean;
  color?: SemanticCOLORS;
  position?: "top left" | "top right" | "bottom right" | "bottom left" | "right center" | "left center" | "top center" | "bottom center";
}

export default function MyHelpButton(props: Readonly<Props>) {

  return (
    <Popup
      size="huge"
      trigger={
        <Icon
          className={"bordernone " + props.className}
          name="question circle outline"
          circular
          color={props.color}
          bordered={false}
          size="small"
        ></Icon>
      }
      flowing
      hoverable
      position={props.position}
      content={
        props.seeMore ? (
          <MyHelpModal
            name={`${props.name}.helpModal`}
            help={props.help}
            seeMore={props.seeMore}
          ></MyHelpModal>
        ) : (
          <MyHelpText name={`${props.name}.helpText`} help={props.help} />
        )
      }
    ></Popup>
  );
}
