import { observer } from "mobx-react-lite";
import { Button, Dropdown, Table } from "semantic-ui-react";
import { format } from "date-fns";
import { useStore } from "../../../app/stores/store";
import {
  convertApplicationToString,
  convertClientStatusToString,
  formatCurrency,
} from "../../../app/common/util/functions";
import { AccessLevel, Application, ClientStatus } from "../../../app/models/enums";
import { ClientDetails } from "../../../app/models/client";
import { SystemAdminClientDetails } from "../../../app/models/systemAdmin";

interface Props {
  selectedClientInformation: SystemAdminClientDetails;
}

export default observer(function SystemAdministrationClientDetail({
  selectedClientInformation,
}: Props) {
  const { userStore } = useStore();

  const { checkAccessLevel } = userStore;

  return (
    <Table striped>
      <Table.Row key={`client.${ClientDetails}`}>
        <Table.Cell className="terms_bold  lessPaddingSysAdminTables">
          Id:
        </Table.Cell>
        <Table.Cell className="lessPaddingSysAdminTables">
          {selectedClientInformation?.id}
        </Table.Cell>
        <Table.Cell className="terms_bold  lessPaddingSysAdminTables">
          Date Created:
        </Table.Cell>
        <Table.Cell className="lessPaddingSysAdminTables">
          {selectedClientInformation.createdDate &&
            format(selectedClientInformation.createdDate, "P")}
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell className="terms_bold  lessPaddingSysAdminTables">
          Company Name:
        </Table.Cell>
        <Table.Cell className="lessPaddingSysAdminTables">
          {selectedClientInformation?.name}
        </Table.Cell>
        <Table.Cell className="terms_bold  lessPaddingSysAdminTables">
          Service Type:
        </Table.Cell>
        <Table.Cell>
          {convertApplicationToString(
            selectedClientInformation?.dowellSystemApplication ??
              Application.None
          )}
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell className="terms_bold  lessPaddingSysAdminTables">
          Address Line 1:
        </Table.Cell>
        <Table.Cell className="lessPaddingSysAdminTables">
          {selectedClientInformation?.addressLine1}
        </Table.Cell>
        <Table.Cell className="terms_bold  lessPaddingSysAdminTables">
          Active / Status:
        </Table.Cell>
        <Table.Cell className="lessPaddingSysAdminTables">
          {convertClientStatusToString(selectedClientInformation?.status?? ClientStatus.Deactivated)}
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell className="terms_bold  lessPaddingSysAdminTables">
          Address Line 2:
        </Table.Cell>
        <Table.Cell className="lessPaddingSysAdminTables">
          {selectedClientInformation?.addressLine2}
        </Table.Cell>
        <Table.Cell className="terms_bold  lessPaddingSysAdminTables"></Table.Cell>
        <Table.Cell className="lessPaddingSysAdminTables"></Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell className="terms_bold  lessPaddingSysAdminTables">
          City:
        </Table.Cell>
        <Table.Cell className="lessPaddingSysAdminTables">
          {selectedClientInformation?.city}
        </Table.Cell>
        <Table.Cell className="terms_bold  lessPaddingSysAdminTables">
          Date Trial Ended:
        </Table.Cell>
        <Table.Cell className="lessPaddingSysAdminTables">
          {selectedClientInformation.trialEndDate &&
            format(selectedClientInformation.trialEndDate, "P")}
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell className="terms_bold  lessPaddingSysAdminTables">
          State:
        </Table.Cell>
        <Table.Cell className="lessPaddingSysAdminTables">
          {selectedClientInformation?.state}
        </Table.Cell>
        <Table.Cell className="terms_bold  lessPaddingSysAdminTables">
          Customer GatewayId:
        </Table.Cell>
        <Table.Cell className="lessPaddingSysAdminTables">
          {selectedClientInformation?.customerGatewayId &&
            selectedClientInformation.customerGatewayId}
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell className="terms_bold  lessPaddingSysAdminTables">
          Postal Code:
        </Table.Cell>
        <Table.Cell className="lessPaddingSysAdminTables">
          {selectedClientInformation?.postalCode}
        </Table.Cell>
        <Table.Cell className="terms_bold lessPaddingSysAdminTables">
          Payment GatewayId:
        </Table.Cell>
        <Table.Cell className="lessPaddingSysAdminTables">
          {selectedClientInformation?.paymentGatewayId &&
            selectedClientInformation.paymentGatewayId}
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell className="terms_bold  lessPaddingSysAdminTables">
          Country:
        </Table.Cell>
        <Table.Cell className="lessPaddingSysAdminTables">
          {selectedClientInformation?.country}
        </Table.Cell>
        <Table.Cell className="terms_bold lessPaddingSysAdminTables">
          Subscription End Date:
        </Table.Cell>
        <Table.Cell className="lessPaddingSysAdminTables">
          {selectedClientInformation.subscriptionEndDate &&
            format(selectedClientInformation.subscriptionEndDate, "P")}
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell className="terms_bold  lessPaddingSysAdminTables">
          Main Phone:
        </Table.Cell>
        <Table.Cell className="lessPaddingSysAdminTables">
          {selectedClientInformation?.commercialNumber}
        </Table.Cell>
        <Table.Cell className="terms_bold lessPaddingSysAdminTables">
          Plan Users:
        </Table.Cell>
        <Table.Cell className="lessPaddingSysAdminTables">
        {selectedClientInformation.activeUserCount ?? 0} active users out of {selectedClientInformation?.userCount ?? 0} paid users
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell className="terms_bold lessPaddingSysAdminTables">
          Fax:
        </Table.Cell>
        <Table.Cell className="lessPaddingSysAdminTables">
          {selectedClientInformation?.faxNumber}
        </Table.Cell>
        <Table.Cell className="terms_bold lessPaddingSysAdminTables">
          Next Subscription Fee:
        </Table.Cell>
        <Table.Cell className="lessPaddingSysAdminTables">
         {selectedClientInformation.subscriptionAmount &&
            formatCurrency(selectedClientInformation?.subscriptionAmount)}
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell className="terms_bold lessPaddingSysAdminTables">
          Email:
        </Table.Cell>
        <Table.Cell className="lessPaddingSysAdminTables">
          {selectedClientInformation?.email}
        </Table.Cell>
        <Table.Cell className="terms_bold lessPaddingSysAdminTables">
          Next Charge On:
        </Table.Cell>
        <Table.Cell className="lessPaddingSysAdminTables">
          {selectedClientInformation.nextChargeDate &&
            format(selectedClientInformation.nextChargeDate, "P")}
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell className="terms_bold lessPaddingSysAdminTables">
          Invoice Count:
        </Table.Cell>
        <Table.Cell className="lessPaddingSysAdminTables">
          {selectedClientInformation?.invoiceCount}
        </Table.Cell>
        <Table.Cell className="terms_bold lessPaddingSysAdminTables">
          QuickVIN Status:
        </Table.Cell>
        <Table.Cell className="lessPaddingSysAdminTables">
          {selectedClientInformation?.quickVinEnabled ? "Enabled" : "Disabled"} {" - "}
          {selectedClientInformation?.quickVinApproved ? "Approved" : "Not Approved"}
          {selectedClientInformation?.quickVinApproved && (
            <Button content="Carfax Data" size="mini" />
          )}
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell className="terms_bold lessPaddingSysAdminTables">
          Numbers:
        </Table.Cell>
        <Table.Cell className="lessPaddingSysAdminTables">
          Inv: {selectedClientInformation?.invoiceNumber ?? 1000}, Est:{" "}
          {selectedClientInformation?.estimateNumber ?? 1000}, P.O.:{" "}
          {selectedClientInformation?.poNumber ?? 1000}
        </Table.Cell>
        <Table.Cell className="terms_bold lessPaddingSysAdminTables">
          AE Import Status:
        </Table.Cell>
        <Table.Cell className="lessPaddingSysAdminTables"></Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell className="terms_bold lessPaddingSysAdminTables">
          Labor Data Access:
        </Table.Cell>
        <Table.Cell className="lessPaddingSysAdminTables">
          {selectedClientInformation?.laborDataAccess ? "Active" : "Disabled"}
        </Table.Cell>
        <Table.Cell className="terms_bold lessPaddingSysAdminTables"></Table.Cell>
        <Table.Cell className="lessPaddingSysAdminTables"></Table.Cell>
      </Table.Row>
    </Table>
  );
});
