import React from "react";
import { observer } from "mobx-react-lite";
import { Dropdown, Menu } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useStore } from "../../app/stores/store";
import { Application, ClientStatus } from "../../app/models/enums";

export default observer(function SettingsDropdownMenu() {
  const { t } = useTranslation(["common", "users"]);
  const {
    userStore: { checkStatus, checkApplication },
  } = useStore();

  return (
    <Menu className="mobileDropdownMenu">
      <Dropdown
        fluid
        placeholder={t("select_settings", { ns: "settings" })}
        className="widerDropdownMenu curvedCorners"
      >
        <Dropdown.Menu>
          <Dropdown
            fluid
            selection
            text={t("company", { ns: "common" })}
            className="noBordersDropdown"
          >
            <Dropdown.Menu>
              <Dropdown.Item
                id="settingsCompany"
                as={Link}
                to={`/settings/company`}
                text={t("company", { ns: "common" })}
              ></Dropdown.Item>
              <Dropdown.Item
                id="settingsCompanyLogo"
                as={Link}
                to={`/settings/companyLogo`}
                text={t("logo", { ns: "companySettings" })}
              ></Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown
            fluid
            selection
            text={t("pricing", { ns: "common" })}
            className="noBordersDropdown"
          >
            <Dropdown.Menu>
              <Dropdown.Item
                id="settingsLaborRates"
                as={Link}
                to={`/settings/laborRates`}
                text={t("labor_rate.header", { ns: "pricingSettings" })}
              ></Dropdown.Item>
              <Dropdown.Item
                id="settingsSalesTax"
                as={Link}
                to={`/settings/salesTax`}
                text={t("tax.header", { ns: "pricingSettings" })}
              ></Dropdown.Item>
              <Dropdown.Item
                id="settingsPayTypes"
                as={Link}
                to={`/settings/payTypes`}
                text={t("pay_types.header", { ns: "pricingSettings" })}
              ></Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown
            fluid
            selection
            text={t("ticket", { ns: "common" })}
            className="noBordersDropdown"
          >
            <Dropdown.Menu>
              <Dropdown.Item
                id="settingsFormatting"
                as={Link}
                to={`/settings/formatting`}
                text={t("formatting.header", { ns: "ticketSettings" })}
              ></Dropdown.Item>
              <Dropdown.Item
                id="settingsNumbers"
                as={Link}
                to={`/settings/numbers`}
                text={t("numbers.header", { ns: "ticketSettings" })}
              ></Dropdown.Item>
              <Dropdown.Item
                id="settingsInputDefaults"
                as={Link}
                to={`/settings/inputDefaults`}
                text={t("input_defaults.header", { ns: "ticketSettings" })}
              ></Dropdown.Item>
              <Dropdown.Item
                id="settingsTicketDefaults"
                as={Link}
                to={`/settings/ticketDefaults`}
                text={t("ticket_defaults.header", { ns: "ticketSettings" })}
              ></Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown
            fluid
            selection
            text={t("disclaimers", { ns: "common" })}
            className="noBordersDropdown"
          >
            <Dropdown.Menu>
              <Dropdown.Item
                id="settingsInvoiceDisclaimers"
                as={Link}
                to={`/settings/invoiceDisclaimers`}
                text={t("invoice", { ns: "disclaimerSettings" })}
              ></Dropdown.Item>
              <Dropdown.Item
                id="settingsEstimateDisclaimers"
                as={Link}
                to={`/settings/estimateDisclaimers`}
                text={t("estimate", { ns: "disclaimerSettings" })}
              ></Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown
            fluid
            selection
            text={t("workflow", { ns: "common" })}
            className="noBordersDropdown"
          >
            <Dropdown.Menu>
              <Dropdown.Item
                id="settingsTicketPath"
                as={Link}
                to={`/settings/ticketPath`}
                text={t("ticket_path.header", { ns: "workflowSettings" })}
              ></Dropdown.Item>
              <Dropdown.Item
                id="settingsManufacturers"
                as={Link}
                to={`/settings/manufacturers`}
                text={t("manufacturers.header", { ns: "workflowSettings" })}
              ></Dropdown.Item>
              <Dropdown.Item
                id="settingsCustomVehicles"
                as={Link}
                to={`/settings/customVehicles`}
                text={t("customs.header", { ns: "workflowSettings" })}
              ></Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          {checkStatus(ClientStatus.Trial | ClientStatus.Active) &&
            checkApplication(Application.RealTimeLaborGuidePro) && (
              <>
                <Dropdown.Item
                  id="settingsTech"
                  as={Link}
                  to={`/settings/techs`}
                  text={t("techs.header", { ns: "settings" })}
                />
                <Dropdown.Item
                  id="settingsVendor"
                  as={Link}
                  to={`/settings/vendors`}
                  text={t("vendor.shortHeader", { ns: "settings" })}
                />
              </>
            )}
          <Dropdown.Item
            id="settingsMisc"
            as={Link}
            to={`/settings/miscCharges`}
            text={t("misc_charges.header", { ns: "settings" })}
          />
          <Dropdown.Item
            id="settingsPackageJobs"
            as={Link}
            to={`/settings/packageJobs`}
            text={t("packaged_jobs.header", { ns: "settings" })}
          />
          <Dropdown
            fluid
            selection
            text={t("advanced.header", { ns: "settings" })}
            className="noBordersDropdown"
          >
            <Dropdown.Menu>
              <Dropdown.Item
                id="settingsQuickVin"
                as={Link}
                to={`/settings/quickvin`}
                text={t("quickvin.header", { ns: "advancedSettings" })}
              ></Dropdown.Item>
              <Dropdown.Item
                id="settingsImport"
                as={Link}
                to={`/settings/import`}
                text={t("import.shortHeader", { ns: "advancedSettings" })}
              ></Dropdown.Item>
              <Dropdown.Item
                id="settingsPaymentProcessing"
                as={Link}
                to={`/settings/paymentProcessing`}
                text={t("payprocessing.shortHeader", {
                  ns: "advancedSettings",
                })}
              ></Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Dropdown.Menu>
      </Dropdown>
    </Menu>
  );
});
