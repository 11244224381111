import { observer } from "mobx-react-lite";
import { Icon, Table } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { useStore } from "../../../app/stores/store";
import { useEffect } from "react";
import { PagingParams } from "../../../app/models/pagination";
import MyPaging from "../../../app/common/form/MyPaging";

interface Props {
  clientId: string;
}

export default observer(function ClientUsers({ clientId }: Props) {
  const { userSystemAdminStore } = useStore();

  const {
    loadUserList,
    getClientUsers,
    pagination,
    setPagingParams,
    pagingParams,
    ClientUsersRegistry,
    setPredicate
  } = userSystemAdminStore;


  useEffect(() => {
    ClientUsersRegistry.clear();
    setPredicate("clientId", clientId);
    loadUserList();
  }, [loadUserList, clientId]);

  function listOnClick(value: number) {
    if (value > 0 && pagination && value < pagination?.totalPages + 1) {
      setPagingParams(new PagingParams(value));
      loadUserList();
    }
  }
  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Username</Table.HeaderCell>
          <Table.HeaderCell>Email Address</Table.HeaderCell>
          <Table.HeaderCell>Last Name</Table.HeaderCell>
          <Table.HeaderCell>First Name</Table.HeaderCell>
          <Table.HeaderCell>Active</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {getClientUsers.map((x) => (
          <Table.Row key={`client.${x.clientId}`}>
            <Table.Cell name="userName">
              <Link to={`/admin/userManage/${x.userId}`}>{x.username}</Link>
            </Table.Cell>
            <Table.Cell>{x.emailAddress}</Table.Cell>
            <Table.Cell>{x.lastName}</Table.Cell>
            <Table.Cell>{x.firstName}</Table.Cell>
            <Table.Cell>
              {x.isActive ? (
                <Icon name="check" color="green" />
              ) : (
                <Icon name="x" color="red" />
              )}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan="8">
            <MyPaging
              itemCount={pagination ? pagination.totalItems : 10}
              pageSize={pagingParams.pageSize}
              currentPage={pagination ? pagination.currentPage : 1}
              onClick={listOnClick}
              onChange={listOnClick}
              floated="right"
            ></MyPaging>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
});
