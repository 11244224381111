import { observer } from "mobx-react-lite";
import React from "react";
import { Menu, Tab } from "semantic-ui-react";
import { useStore } from "../../app/stores/store";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Application, ClientStatus } from "../../app/models/enums";

interface Props {
  isCancelled: boolean;
}

export default observer(function SubscriptionContent({ isCancelled }: Props) {
  const { userStore, subscriptionStore } = useStore();
  const { t } = useTranslation(["common", "subscription"]);
  const { user, checkStatus } = userStore;
  const { activeTab, setActiveTab } = subscriptionStore;

  const navigate = useNavigate();

  return (
    <>
      {user?.status && user.status !== ClientStatus.PreTrial && (
        <Menu className="desktopMenu">
          {(user?.application ?? 0) > Application.None && (
            <Menu.Item
              name={t("status", { ns: "common" })}
              active={activeTab == 0}
              onClick={() => {
                setActiveTab(0);
                navigate("/subscription/Status");
              }}
            />
          )}
          {user?.isDataActive &&
            checkStatus(ClientStatus.Trial) &&
            (user?.application ?? 0) > Application.None && (
              <Menu.Item
                name={t("billingInformation", { ns: "subscription" })}
                active={activeTab == 1}
                onClick={() => {
                  setActiveTab(1);
                  navigate("/subscription/BillingInformation");
                }}
              />
            )}
          {user?.status &&
            checkStatus(ClientStatus.Active | ClientStatus.Subscription) &&
            !isCancelled && (
              <>
                <Menu.Item
                  name={t("service_type.label", { ns: "common" })}
                  active={activeTab == 2}
                  onClick={() => {
                    setActiveTab(2);
                    navigate("/subscription/ServiceType");
                  }}
                />
                <Menu.Item
                  name={t("subscription.additionalUsers", {
                    ns: "subscription",
                  })}
                  active={activeTab == 3}
                  onClick={() => {
                    setActiveTab(3);
                    navigate("/subscription/Users");
                  }}
                />
                <Menu.Item
                  name={t("subscription.renewal_options", {
                    ns: "subscription",
                  })}
                  active={activeTab == 4}
                  onClick={() => {
                    setActiveTab(4);
                    navigate("/subscription/RenewalOptions");
                  }}
                />
              </>
            )}

          <Menu.Item
            name={t("availabletrials", { ns: "subscription" })}
            active={activeTab == 5}
            onClick={() => {
              setActiveTab(5);
              navigate("/subscription/AvailableTrials");
            }}
          />
        </Menu>
      )}
    </>
  );
});
