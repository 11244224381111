import { ErrorMessage, Field, Formik, FormikErrors } from "formik";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Label, Message, Radio, Form, Grid } from "semantic-ui-react";
import * as Yup from "yup";
import MyTextInput from "../../app/common/form/MyTextInput";
import { store, useStore } from "../../app/stores/store";
import ValidationErrors from "../errors/ValidationErrors";
import { CustomVehicleFormValues } from "../../app/models/customvehicles";
import PageHeader from "../../app/layout/PageHeader";
import { yearOptions, yesNoOptions } from "../../app/common/options/option";
import { TicketWorkflowSteps } from "../../app/models/enums";
import { useNavigate, useParams } from "react-router-dom";
import WholeNumberInput from "../../app/common/form/WholeNumberInput";
import { v4 as uuid } from "uuid";

interface Props {
  name: string;
  header: string;
  isAdd: boolean;
  isTicket: boolean;
  complete: boolean;
  autoCapitalizeFirstLetter: boolean;
}

export default observer(function CustomVehiclesForm({
  name,
  header,
  isAdd,
  isTicket,
  complete,
  autoCapitalizeFirstLetter,
}: Props) {
  const { t } = useTranslation([
    "common",
    "miscSettings",
    "tickets",
    "workFlowSettings",
  ]);

  const {
    customVehicleStore,
    ticketSessionStore,
    customerStore,
    vehicleStore,
  } = useStore();

  const {
    createCustomVehicle,
    selectedCustomVehicle,
    updateCustomVehicle,
    loadCustomVehicleList,
  } = customVehicleStore;
  const { updateTicketSession } = ticketSessionStore;
  const { selectCustomVehicle, setSelectedVehicle } = vehicleStore;

  const { selectedCustomer } = customerStore;

  const [completed, setCompleted] = useState<boolean>(false);
  const navigate = useNavigate();

  const validationSchema = Yup.object({
    modelName: Yup.string().required(t("modelRequired", { ns: "errors" })),
  });

  const [customVehicle] = useState<CustomVehicleFormValues>(
    isAdd ? new CustomVehicleFormValues() : selectedCustomVehicle
  );

  const { ticketId } = useParams<{ ticketId: string }>();

  const [isSingle, setIsSingle] = useState<boolean>(
    customVehicle.yearOption === 1
  );

  function handleFormSubmit(
    values: CustomVehicleFormValues,
    setErrors: (errors: FormikErrors<CustomVehicleFormValues>) => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) {

    let add = !values.id
    values.id =values.id ?? uuid();
    
    const action = add
      ? () => createCustomVehicle(values)
      : () => updateCustomVehicle(values);

    action()
      .then(() => {
        setCompleted(true);
        setSubmitting(false);
        if (isTicket) {
          let vehicle = selectCustomVehicle(values, values.lowYear);
          updateTicketSession(
            TicketWorkflowSteps.Vehicle,
            ticketId ?? "",
            vehicle,
            selectedCustomer
          ).then(() =>
            navigate(`/ticket/jobCategory/${ticketId}/${complete}`)
          );
        }
        loadCustomVehicleList();
        store.modalStore.closeModal();
      })
      .catch((error) => {
        setSubmitting(false);
        setErrors({ error: error });
      });
  }

  return (
    <Formik
      initialValues={customVehicle}
      validationSchema={validationSchema}
      onSubmit={(values, { setErrors, setSubmitting }) =>
        handleFormSubmit(values, setErrors, setSubmitting)
      }
    >
      {({
        handleSubmit,
        isValid,
        isSubmitting,
        dirty,
        handleBlur,
        handleChange,
        setFieldValue,
        errors,
        values,
        touched,
        setFieldTouched,
      }) => (
        <Form
          className="ui form error"
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <PageHeader
            header={header}
            type={"h3"}
            divider={true}
            getAlerts={true}
            showHelp
            helpName="settings.workflow.addCustomVehicle"
            addTitle={false}
          />
          <Grid>
            <Grid.Row>
              <Grid.Column>
                {completed && isValid && (
                  <Message positive>
                    {t("settingsupdated", {
                      ns: "common",
                    })}
                  </Message>
                )}
                <ErrorMessage
                  name="error"
                  render={() => <ValidationErrors errors={errors.error} />}
                />
              </Grid.Column>
            </Grid.Row>
            {!isTicket && (
              <Grid.Row>
                <Grid.Column>
                  <Form.Group>
                    {!isTicket &&
                      yearOptions.map((data, index) => (
                        <Form.Field key={`yearOption[${data.value}]`} inline>
                          <Radio
                            {...Field}
                            id={`yearOption[${index}]`}
                            key={`yearOption[${data.value}]`}
                            value={data.value}
                            name="yearOption"
                            onChange={(e, d) => {
                              handleChange(e);
                              switch (d.value) {
                                case 1: {
                                  setFieldValue("highYear", undefined);
                                  setFieldTouched("highYear", false);
                                  setIsSingle(true);
                                  break;
                                }
                                case 2: {
                                  setIsSingle(false);
                                  setFieldTouched("lowYear", false);
                                  setFieldValue(
                                    "lowYear",
                                    values.lowYear !== undefined &&
                                      values.lowYear.toString() !== "0"
                                      ? values.lowYear
                                      : undefined
                                  );

                                  setFieldTouched("highYear", false);
                                  setFieldValue(
                                    "highYear",
                                    values.highYear !== undefined &&
                                      values.highYear.toString() !== "9999"
                                      ? values.highYear
                                      : undefined
                                  );

                                  setIsSingle(false);
                                  break;
                                }
                                case 3: {
                                  //this must be string in order to for the value to stick
                                  setFieldValue("lowYear", "0");
                                  setFieldValue("highYear", 9999);
                                  setIsSingle(false);
                                  break;
                                }
                              }
                            }}
                            onBlur={handleBlur}
                            label={data.text}
                            className="myLabel"
                            checked={
                              (values.yearOption
                                ? values.yearOption.toString()
                                : "") === data.value.toString()
                            }
                          />
                        </Form.Field>
                      ))}
                    {touched.yearOption && errors.yearOption && (
                      <Label basic color="red">
                        {errors.yearOption}
                      </Label>
                    )}
                  </Form.Group>
                </Grid.Column>
              </Grid.Row>
            )}
            <Grid.Row columns={3}>
              <Grid.Column verticalAlign={"bottom"}>
                <WholeNumberInput
                  placeholder={
                    isSingle
                      ? t("year", { ns: "common" })
                      : t("lowYear", { ns: "tickets" })
                  }
                  name={"lowYear"}
                  label={
                    isSingle
                      ? `${t("year", { ns: "common" })}:`
                      : `${t("years", { ns: "common" })}:`
                  }
                  disabled={values.yearOption == 3}
                  maxLength={6}
                ></WholeNumberInput>
              </Grid.Column>
              {!isSingle && (
                <>
                  <Grid.Column width={1} verticalAlign={"bottom"}>
                    <label className="myLabel margin-bottom-10px">-</label>
                  </Grid.Column>
                  <Grid.Column verticalAlign={"bottom"}>
                    <WholeNumberInput
                      placeholder={t("highYear", { ns: "tickets" })}
                      name={"highYear"}
                      disabled={values.yearOption == 3}
                      maxLength={6}
                    ></WholeNumberInput>
                  </Grid.Column>
                </>
              )}
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <MyTextInput
                  placeholder={t("make", { ns: "common" })}
                  name={"makeName"}
                  label={`${t("make", { ns: "common" })}:`}
                  showHelp={true}
                  helpName="customVehicles.make"
                  autoCapitalizeFirstLetter={autoCapitalizeFirstLetter}
                  maxLength={255}
                ></MyTextInput>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <MyTextInput
                  placeholder={t("model", { ns: "common" })}
                  name={"modelName"}
                  label={`${t("model", { ns: "common" })}: *`}
                  showHelp={true}
                  helpName="customVehicles.model"
                  autoCapitalizeFirstLetter={autoCapitalizeFirstLetter}
                  maxLength={255}
                  className="required"
                ></MyTextInput>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <MyTextInput
                  placeholder={t("engine", { ns: "common" })}
                  name={"engineName"}
                  label={`${t("engine", { ns: "common" })}:`}
                  showHelp={true}
                  helpName="customVehicles.engine"
                  autoCapitalizeFirstLetter={autoCapitalizeFirstLetter}
                  maxLength={255}
                ></MyTextInput>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Form.Group>
                  <label className="myLabel">
                    {`${t("customs.displayWithStandard", {
                      ns: "workflowSettings",
                    })}:`}
                  </label>
                  {yesNoOptions.map((data, index) => (
                    <Form.Field key={`isActive[${data.value}]`} inline>
                      <Radio
                        {...Field}
                        id={`isActive[${index}]`}
                        key={`isActive[${data.value}]`}
                        value={data.value}
                        name="isActive"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label={data.text}
                        className="myLabel"
                        checked={values.isActive.toString() === data.value}
                      />
                    </Form.Field>
                  ))}
                  {touched.yearOption && errors.yearOption && (
                    <Label basic color="red">
                      {errors.yearOption}
                    </Label>
                  )}
                </Form.Group>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Button
                  color="green"
                  className="save_button"
                  icon="check"
                  content={t("save", { ns: "common" })}
                  disabled={isSubmitting || !dirty || !isValid}
                  //
                  loading={isSubmitting}
                  type="submit"
                />
                <Button
                  className="save_button"
                  onClick={store.modalStore.closeModal}
                  icon="cancel"
                  type="reset"
                  content={t("cancel", { ns: "common" })}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      )}
    </Formik>
  );
});
