import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Menu, Tab } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../app/stores/store";
import { useNavigate } from "react-router-dom";
import CancellationReport from "./CancellationReport";
import RecurringCharges from "./RecurringCharges";
import NextChargeAdjustments from "./NextChargeAdjustments";
import EarlyChargeReport from "./EarlyChargeReport";
import SubscriberReport from "./SubscriberReport";

interface Props {
  activeSubTab: number;
}

export default observer(function AdminToolsTabs(props: Props) {
  const { t } = useTranslation(["common", "systemAdmin"]);
  const navigate = useNavigate();

  const { systemAdminStore } = useStore();
  const { setActiveSubTab, activeSubTab } = systemAdminStore;

  useEffect(() => {
    setActiveSubTab(props.activeSubTab);
  }, [setActiveSubTab, props.activeSubTab]);

  const panes = [
    {
      menuItem: (
        <Menu.Item
          onClick={() => {
            navigate(`/admin/AdminTools/cancellationReport`);
          }}
        >
          {t("Cancellation Report", { ns: "systemAdmin" })}
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane active={0 === activeSubTab}>
          <CancellationReport />
        </Tab.Pane>
      ),
    },
    {
      menuItem: (
        <Menu.Item
          onClick={() => {
            navigate(`/admin/AdminTools/recurringCharges`);
          }}
        >
          {t("Recurring Charges", { ns: "systemAdmin" })}
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane active={1 === activeSubTab}>
          <RecurringCharges />
        </Tab.Pane>
      ),
    },
    {
        menuItem: (
          <Menu.Item
            onClick={() => {
              navigate(`/admin/AdminTools/nextChargeAdjustment`);
            }}
          >
            {"Next Charge Adjustments"}
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane active={2 === activeSubTab}>
            <NextChargeAdjustments />
          </Tab.Pane>
        ),
      },
      {
        menuItem: (
          <Menu.Item
            onClick={() => {
              navigate(`/admin/AdminTools/earlyChargeReport`);
            }}
          >
            {"Early Charge Report"}
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane active={3 === activeSubTab}>
            <EarlyChargeReport />
          </Tab.Pane>
        ),
      },
      {
        menuItem: (
          <Menu.Item
            onClick={() => {
              navigate(`/admin/AdminTools/subscriberReport`);
            }}
          >
            {"Subscriber Report"}
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane active={4 === activeSubTab}>
            <SubscriberReport />
          </Tab.Pane>
        ),
      },
  ];

  return (
    <>
      <Tab
        menu={{ fluid: true }}
        menuPosition="left"
        panes={panes}
        activeIndex={activeSubTab}
        onTabChange={(e, data) => {
          setActiveSubTab(data.activeIndex);
        }}
      />
    </>
  );
});