import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../../api/agent";
import { Pagination, PagingParams } from "../../models/pagination";
import { EmailLog } from "../../models/systemAdmin";
import { v4 as uuid } from "uuid";

export default class EmailLogsStore {
  constructor() {
    makeAutoObservable(this);
    reaction(
    () => this.predicate.keys(),
    () => {
      this.pagingParams = new PagingParams(undefined, 25);
      this.EmailLogRegistry.clear();
    }
    );
  }

  loadingInitial = false;
  pagination: Pagination | null = null;
  pagingParams = new PagingParams(1, 25);

  EmailLogRegistry = new Map<string, EmailLog>();
  predicate = new Map().set("all", true);

  loadEmailLog = async (clientId: string, userId: string | undefined) => {
    this.loadingInitial = true;

    try {
      this.EmailLogRegistry.clear();

      this.setPredicate("clientId", clientId);
      this.setPredicate("userId", userId);

      const result = await agent.SystemAdminLogs.getEmailLog(this.axiosParams);
      runInAction(() => {
        result.data.forEach((x) => {
          this.setEmailLog(x);
        });
        this.setPagination(result.pagination);
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoadingInitial(false);
    }
  };

  private setEmailLog = (emailLog: EmailLog) => {
    if (emailLog.createdDate) emailLog.createdDate = new Date(emailLog.createdDate!);
    
    this.EmailLogRegistry.set(uuid(), emailLog);
  };

  
  get getEmailLog() {
    return Array.from(this.EmailLogRegistry.values()).sort(
      (a, b) => b.createdDate!.getTime() - a.createdDate!.getTime()
    );
  }

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  get axiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", this.pagingParams.pageNumber.toString());
    params.append("pageSize", this.pagingParams.pageSize.toString());
    this.predicate.forEach((value, key) => {
      if (key === "startDate") {
        params.append(key, (value as Date).toISOString());
      } else {
        params.append(key, value);
      }
    });
    return params;
  }

  setPredicate = (
    predicate: string,
    value: string | Date | number | undefined
  ) => {
    const resetPredicate = () => {
      this.predicate.forEach((value, key) => {
        if (key !== "startDate") this.predicate.delete(key);
        if (!value) this.predicate.delete(key);
      });
    };
    switch (predicate) {
      case "clientId":
        resetPredicate();
        if (value) this.predicate.set("clientId", value);
        break;
      case "userId":
        resetPredicate();
        if (value) this.predicate.set("userId", value);
        break;
    }
  };

  setPagination = (pagination: Pagination) => {
    this.pagination = pagination;
  };

  setPagingParams = (pagingParams: PagingParams) => {
    this.pagingParams = new PagingParams(pagingParams.pageNumber, 25);
  };
}
