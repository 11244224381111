import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Form,
  Grid,
  Message,
  Segment,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { ErrorMessage, Formik, FormikErrors } from "formik";
import { Link } from "react-router-dom";
import MyCheckbox from "../../../app/common/form/MyCheckbox";
import PageHeader from "../../../app/layout/PageHeader";
import { useStore } from "../../../app/stores/store";
import { LogoFormValues } from "../../../app/models/client";
import ValidationErrors from "../../errors/ValidationErrors";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import MyHelpButton from "../../../app/common/form/MyHelpButton";
import PhotoUploadWidget from "../../../app/common/imageUpload/PhotoUploadWidget";

export default observer(function CompanyLogo() {
  const { t } = useTranslation(["common", "companySettings"]);

  const { clientStore } = useStore();

  const {
    loadLogo,
    selectedLogo,
    loadingInitial,
    uploadPhoto,
    uploading,
    removePhoto,
  } = clientStore;

  const [completed, setCompleted] = useState<boolean>(false);

  useEffect(() => {
    loadValues();
  }, [loadLogo]);

  function loadValues() {
    loadLogo().then(() => {
    });
  }

  function handleFormSubmit(
    values: LogoFormValues,
    setErrors: (errors: FormikErrors<LogoFormValues>) => void,
    setSubmitting: (isSubmitting: boolean) => void,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => Promise<void | FormikErrors<LogoFormValues>>
  ) {
    if (values.removeLogo === true) {
      values.logoImage = undefined;
      values.removeLogo = false;
      removePhoto()
        .then(() => {
          setSubmitting(false);
          setCompleted(true);
        })
        .catch((error) => {
          setSubmitting(false);
          setErrors({ error: error });
        });
    }
  }

  function handlePhotoUpload(file: any) {
    uploadPhoto(file)
      .then(() => {
        loadValues();
        setCompleted(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  if (loadingInitial)
    return (
      <LoadingComponent
        content={t("compLogo_loading", { ns: "companySettings" })}
      />
    );

  return (
    <>
      <PageHeader
        header={t("logo", { ns: "companySettings" })}
        type={"h2"}
        divider={true}
        getAlerts={true}
        addTitle={true}
      />
      {completed && (
        <Message positive>
          {t("settingsupdated", {
            ns: "common",
          })}
        </Message>
      )}
      <Container className="page">
        <Grid stackable>
          <PageHeader
            header={"Logo Upload"}
            type={"h3"}
            divider={false}
            addTitle={false}
          ></PageHeader>

          <Grid.Row>
            <Grid.Column>
              {t("logo_form.upload", { ns: "companySettings" })}
              <MyHelpButton
                name="company"
                help="settings.company.logo"
                position="bottom left"
                color={"blue"}
                seeMore={true}
              />
              <PhotoUploadWidget
                loading={uploading}
                uploadPhoto={handlePhotoUpload}
              />
            </Grid.Column>
          </Grid.Row>
          <PageHeader
            header={"Logo Preview"}
            type={"h3"}
            divider={false}
            addTitle={false}
          ></PageHeader>
          <Formik
            validationSchema={Yup.object({
              removeLogo: Yup.bool().oneOf(
                [true],
                "Must select in order to remove image."
              ),
            })}
            initialValues={selectedLogo}
            onSubmit={(values, { setErrors, setSubmitting, setFieldValue }) =>
              handleFormSubmit(values, setErrors, setSubmitting, setFieldValue)
            }
          >
            {({
              handleSubmit,
              isValid,
              isSubmitting,
              dirty,
              handleBlur,
              handleChange,
              errors,
              values,
              touched,
              setErrors,
              setFieldValue,
            }) => (
              <Form
                className="ui form error"
                autoComplete="off"
                onSubmit={handleSubmit}
              >
                <ErrorMessage
                  name="error"
                  render={() => <ValidationErrors errors={errors.error} />}
                />
                <Grid.Row>
                  <Grid.Column>
                    {`${t("logo_form.current", {
                      ns: "companySettings",
                    })} 150px by 330px (`}
                    <Link to="/settings/formatting">
                      {t("logo_form.logoPlacement", {
                        ns: "companySettings",
                      })}
                    </Link>
                    {")"}
                    <Segment className={"logoContainer"}>
                      {values.logoImage ? (
                        <img
                          alt="Company Logo"
                          src={`data:image/jpeg;base64,${selectedLogo.logoImage}`}
                          height={"150px"}
                        />
                      ) : (
                        t("noImage", { ns: "companySettings" })
                      )}
                    </Segment>
                    <p className="side_note">
                      *{" "}
                      {`${t("logo_form.previewText", {
                        ns: "companySettings",
                      })}`}
                    </p>
                    <MyCheckbox
                      label={t("logo_form.remove", { ns: "companySettings" })}
                      name={"removeLogo"}
                      disabled={!values.logoImage}
                      showHelp={true}
                      helpName="settings.company.logoRemove"
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Button
                      color="green"
                      className="save_button"
                      //icon="check"
                      content={t("Remove Image", { ns: "common" })}
                      disabled={isSubmitting || !dirty || !isValid || !values.logoImage}
                      loading={isSubmitting}
                      type="submit"
                    />
                    <Button
                      className="save_button"
                      as={Link}
                      to="/settings"
                      icon="cancel"
                      type="reset"
                      content={t("cancel", { ns: "common" })}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Form>
            )}
          </Formik>
        </Grid>
      </Container>
    </>
  );
});
