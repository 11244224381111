import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Grid, Tab } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import CompanyDetails from "./CompanyDetails";
import CompanyLogo from "./CompanyLogo";
import { useStore } from "../../../app/stores/store";
import PageHeader from "../../../app/layout/PageHeader";
import SettingsNavBar from "../SettingsNavBar";
import SettingsDropdownMenuMobile from "../SettingsDropdownMenuMobile";
interface Props {
  activeTab: number;
}

export default observer(function Company(props: Props) {
  const { t } = useTranslation(["common", "companySettings"]);
  const { clientStore } = useStore();
  const { setActiveTab, activeTab } = clientStore;

  useEffect(() => {
    setActiveTab(props.activeTab);
  }, [setActiveTab, props.activeTab]);

  const panes = [
    {
      menuItem: t("commpanydetails", { ns: "companySettings" }),
      render: () => <CompanyDetails />,
    },
    {
      menuItem: t("logo", { ns: "companySettings" }),
      render: () => <CompanyLogo />,
    },
  ];

  return (
    <Grid stackable>
      <Grid.Column width={3} className="mobileHeight">
        <SettingsNavBar />
        <SettingsDropdownMenuMobile />
      </Grid.Column>
      <Grid.Column width={13}>
        <PageHeader
          header={t("company", { ns: "common" })}
          type={"h1"}
          divider={true}
          getAlerts={true}
          addTitle={false}
        />
        <Tab
          menu={{ fluid: true }}
          menuPosition="left"
          panes={panes}
          activeIndex={activeTab}
          onTabChange={(e, data) => setActiveTab(data.activeIndex)}
        />
      </Grid.Column>
    </Grid>
  );
});
