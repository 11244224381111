import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import {
  SettingsDisclaimerFormValues,
  SettingsDisclaimer,
} from "../models/disclaimers";

export default class DisclaimerStore {
  loadingInitial = false;
  activeTab: number = 0;

  selectedSettingsDisclaimer: SettingsDisclaimerFormValues = {
    id: undefined,
    invoiceLeft: "",
    invoiceRight: "",
    estimateMiddle: "",
    estimateLeft: "",
    estimateRight: "",
  };

  constructor() {
    makeAutoObservable(this);
  }

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  setActiveTab = (activeTab: string | number | undefined) => {
    this.activeTab = activeTab ? Number(activeTab) : 0;
  };

  loadSettingDisclaimer = async () => {
    this.loadingInitial = true;
    try {
      const result = await agent.Disclaimers.getSettingsDisclaimer();
      runInAction(() => {
        this.selectedSettingsDisclaimer = result;
      });
      return result;
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.loadingInitial = false;
      });
    }
  };

  setSelectedDisclaimer = (values: SettingsDisclaimerFormValues) => {
    this.selectedSettingsDisclaimer = values;
  };

  createSettingsDisclaimer = async (values: SettingsDisclaimerFormValues) => {
    try {
      let mynew: SettingsDisclaimer = new SettingsDisclaimer(values);
      await agent.Disclaimers.addSettingsDisclaimer(mynew);

      runInAction(() => {
        this.setSelectedDisclaimer(values);
      });
    } catch (error) {
      console.log(error);
    }
  };

  updateSettingsDisclaimerEstimate = async (
    values: SettingsDisclaimerFormValues
  ) => {
    try {
      let mynew: SettingsDisclaimer = new SettingsDisclaimer(values);
      await agent.Disclaimers.updateSettingDisclaimerEstimate(mynew);

      runInAction(() => {
        this.setSelectedDisclaimer(values);
      });
    } catch (error) {
      console.log(error);
    }
  };

  updateSettingsDisclaimerInvoice = async (
    values: SettingsDisclaimerFormValues
  ) => {
    try {
      let mynew: SettingsDisclaimer = new SettingsDisclaimer(values);
      await agent.Disclaimers.updateSettingDisclaimerInvoice(mynew);

      runInAction(() => {
        this.setSelectedDisclaimer(values);
      });
    } catch (error) {
      console.log(error);
    }
  };

  previewMiddleDisclaimer = (value: string) =>{
    if (value.length > 0 )
    {
      //replace _
      let estimateMiddle = value.replaceAll(/[\r]+/g, '');
      estimateMiddle = estimateMiddle.replaceAll("_ ", "_____ ");
          //replace Sign and Date
      estimateMiddle = estimateMiddle.replaceAll("_SIGN_DATE_", 
      "SIGNED ____________________________________ DATE __________________");
    

      return estimateMiddle;
    }
    return value;
  }

}
