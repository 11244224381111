import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../../api/agent";
import { Pagination, PagingParams } from "../../models/pagination";
import { ClientUserTotalStat, CompanyUserStats } from "../../models/systemAdmin";

export default class CompanyUsersStore {
    constructor() {
        makeAutoObservable(this);
        reaction(
          () => this.predicate.keys(),
          () => {
            this.pagingParams = new PagingParams(undefined, 25);
            this.CompanyUsersStoreRegistry.clear();
            this.loadClientUserTotalStat();
          }
        );
      }

  loadingInitial = false;

  CompanyUserStatsRegistry = new Map<number, CompanyUserStats>();
  CompanyUsersStoreRegistry = new Map<string, ClientUserTotalStat>();
  predicate = new Map().set("all", true);
  pagination: Pagination | null = null;
  pagingParams = new PagingParams(1, 25);


  loadCompanyUserStats = async () => {
    this.loadingInitial = true;
    try {
    //   this.CompanyUserStatsRegistry.clear();
    //   const result = await agent.SystemAdmin.getCompanyUserStats();
    //   runInAction(() => {
    //     result.forEach((x) => {
    //       this.setCompanyUserStats(x);
    //     });
    //   });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoadingInitial(false);
    }
  };

  private setCompanyUserStats = (companyUserStats: CompanyUserStats) => {
    // companyUserStats.userCount = new (companyUserStats.userCount);
    // this.CompanyUserStatsRegistry.set(
    //     companyUserStats.id,
    //     companyUserStats
    // );
  };
  
  get getCompanyUserStats() {
    return Array.from(this.CompanyUserStatsRegistry.values()).sort(
    //   (a, b) => b.userCount.getTime() - a.userCount.getTime()
    );
  }


  loadClientUserTotalStat = async () => {
    try {
      this.CompanyUsersStoreRegistry.clear();
      const result = await agent.SystemAdminReports.getClientUserTotalStat(
        this.axiosParams
      );
      runInAction(() => {
        result.data.forEach((x) => {
          this.setClientUserTotalStat(x);
        });
        this.setPagination(result.pagination);
      });
    } catch (error) {
      console.log(error);
    }
  };

  private setClientUserTotalStat = (clientUserTotalStat: ClientUserTotalStat) => {
    // clientUserTotalStat.userCount = new (clientUserTotalStat.userCount);
    // this.CompanyUsersStoreRegistry.set(
    //     clientUserTotalStat.id,
    //     clientUserTotalStat
    // );
  };
  
  get getClientUserTotalStat() {
    return Array.from(this.CompanyUsersStoreRegistry.values()).sort(
    //   (a, b) => b.userCount.getTime() - a.userCount.getTime()
    );
  }
  
  setPredicate = (
    predicate: string,
    value:
      | string
      | number
      | boolean
      | Date
      | (string | number | boolean | Date)[]
      | undefined
  ) => {
    this.predicate.clear();
    if (value) this.predicate.set(predicate, value);
  };

  clearPredicate = () => {
    this.predicate.clear();
  };

  setPagination = (pagination: Pagination) => {
    this.pagination = pagination;
  };

  setPagingParams = (pagingParams: PagingParams) => {
    this.pagingParams = new PagingParams(pagingParams.pageNumber, 25);
  };

  get axiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", this.pagingParams.pageNumber.toString());
    params.append("pageSize", this.pagingParams.pageSize.toString());
    this.predicate.forEach((value, key) => {
      if (key === "startDate" || key === "endDate") {
        params.append(key, (value as Date).toISOString());
      } else {
        params.append(key, value);
      }
    });
    return params;
  }

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };
}
