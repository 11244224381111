import { useField } from "formik";
import React from "react";
import { Form, Label } from "semantic-ui-react";
import NumberFormat from "react-number-format";
import MyHelpButton from "./MyHelpButton";
import { PhoneNumberType } from "../../models/enums";

interface Props {
  name: string;
  label?: string;
  placeholder?: string;
  showHelp?: boolean;
  helpName?: string;
  className?: string;
  autoFormat: boolean;
  phoneType: PhoneNumberType;
}

export default function PhoneNumberInput({
  name,
  label,
  placeholder,
  showHelp,
  helpName,
  className,
  autoFormat,
  phoneType
} : Readonly<Props>) {
  const [field, meta, helpers] = useField(name);

  function getFormat(phoneType: PhoneNumberType, autoFormat: boolean)
  {
    let format:string = "###############";
    if (autoFormat)
    {
      format = "(###) ###-####";
      
      if (phoneType === PhoneNumberType.Commercial)
      {
        format = "(###) ###-#### x#####" 
      }
    }
    return format;
  }

  return (
    <Form.Field
      error={meta.touched && !!meta.error}
      className="ui"
      name={name}
    >
      {label && label.length > 0 && (
        <label
          className={className ? className : "myLabel"}
          htmlFor={name}
        >
          {`${label} `}
          {showHelp && (
            <MyHelpButton
              name={`${name}.help`}
              help={helpName ? helpName : ""}
              color={"blue"}
            />
          )}
        </label>
      )}
      <NumberFormat
        {...field}    
        type="tel"
        format={getFormat(phoneType, autoFormat)}
        mask={autoFormat ? "_" : ""}
        placeholder={placeholder}
        onValueChange={(value) => {
          helpers.setValue(value.formattedValue);
        }}
        id={name}
        maxLength={50}
        name = {name}
        className={className}
      ></NumberFormat>
      {meta.touched && meta.error && (
        <Label basic color="red">
          {meta.error}
        </Label>
      )}
    </Form.Field>
  );
}
