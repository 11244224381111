import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import "../../../app/layout/printStyles.css";
import { TicketItemType, TicketType } from "../../../app/models/enums";
import { View, Text } from "@react-pdf/renderer";
import compose from "../../../app/layout/compose";
import TicketItemPdfView from "./TicketViewComponents/TicketItemPdfView";
import PartsPdfView from "./TicketViewComponents/PartsPdfView";
import {
  SettingsTicketFormatting,
  TicketItem,
  TicketSession,
} from "../../../app/models/ticket";
import EstimateDisclaimerMiddlePdfView from "./TicketViewComponents/EstimateDisclaimerMiddlePdfView";
import { SettingsDisclaimer } from "../../../app/models/disclaimers";

interface Props {
  selectedTicketSession: TicketSession;
  selectedSettingsTicketFormatting: SettingsTicketFormatting;
  selectedSettingsDisclaimers: SettingsDisclaimer;
  ticketItems: TicketItem[];
}

export default observer(function TicketBodyPdf({
  selectedTicketSession,
  selectedSettingsTicketFormatting,
  selectedSettingsDisclaimers,
  ticketItems
}: Props) {
  const { t } = useTranslation(["common", "tickets"]);

  let laborTicketItems: TicketItem[] = [];
  let partTicketItems: TicketItem[] = [];
  let miscTicketItems: TicketItem[] = [];

  if (
    ticketItems &&
    ticketItems.length > 0
  ) {
    laborTicketItems = ticketItems.filter((x) => {
      return x.type === TicketItemType.Labor;
    });

    partTicketItems = ticketItems.filter((x) => {
      return x.type === TicketItemType.Parts;
    });

    miscTicketItems = ticketItems.filter((x) => {
      return (
        x.type === TicketItemType.MiscellaneousCharge ||
        x.type === TicketItemType.AdhocMiscellaneous
      );
    });
  }

  return (
    <View style={{ ...compose("view") }}>
      {selectedTicketSession?.showSeparateCharges ?? false ? (
        <>
          <View style={{ fontWeight: "bold", fontSize: "4mm" }}>
            <Text>{t("estimate.labor", { ns: "tickets" })}</Text>
          </View>
          <TicketItemPdfView
            ticketItems={laborTicketItems}
            ticketItemType={TicketItemType.Labor}
            selectedTicketSession={selectedTicketSession}
            selectedSettingsTicketFormatting={selectedSettingsTicketFormatting}
          />
          <View
            style={{ fontWeight: "bold", paddingTop: "6mm", fontSize: "4mm" }}
          >
            <Text>{t("estimate.parts", { ns: "tickets" })}</Text>
          </View>
          <PartsPdfView
            ticketItems={partTicketItems}
            selectedSettingsTicketFormatting={selectedSettingsTicketFormatting}
            selectedTicketSession={selectedTicketSession}
          />
          <View
            style={{ fontWeight: "bold", paddingTop: "6mm", fontSize: "4mm" }}
          >
            <Text>{t("misc", { ns: "tickets" })}.</Text>
          </View>
          <TicketItemPdfView
            ticketItems={miscTicketItems}
            ticketItemType={TicketItemType.MiscellaneousCharge}
            selectedTicketSession={selectedTicketSession}
            selectedSettingsTicketFormatting={selectedSettingsTicketFormatting}
          />
        </>
      ) : (
        <PartsPdfView
          ticketItems={ticketItems ?? []}
          selectedSettingsTicketFormatting={selectedSettingsTicketFormatting}
          selectedTicketSession={selectedTicketSession}
        />
      )}
      {(selectedSettingsTicketFormatting.showNotesLabel ||
        selectedTicketSession?.notes) && (
        <View style={{ ...compose("view pageWidth") }}>
            <Text style={{ paddingTop: "3mm" }}>
              <Text  style={{ fontFamily: "Times-Bold", fontWeight: "bold", }}>{t("estimate.notes", { ns: "tickets" })}:</Text>{" "} {selectedTicketSession?.notes}
            </Text>
        </View>
      )}
      {selectedTicketSession?.ticketType === TicketType.Estimate &&
        selectedSettingsDisclaimers?.estimateMiddle && (
          <EstimateDisclaimerMiddlePdfView
            pageBreak={ticketItems &&
              ticketItems.length > 9 &&
              !selectedTicketSession.showSeparateCharges
            }
          />
        )}
    </View>
  );
});
