import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import { Button, Container, Form, Grid, Table } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import * as Yup from "yup";
import PageHeader from "../../app/layout/PageHeader";
import { useStore } from "../../app/stores/store";
import { ReportInput } from "../../app/models/reports";
import ReportDateSelector from "../../app/common/form/ReportDateSelector";
import { useParams } from "react-router-dom";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { CustomerFormValues } from "../../app/models/customer";
import ReactToPrint from "react-to-print";
import CustomerHistoryPrint from "./pdf/CustomerHistoryPrint";

export default observer(function CustomerHistory() {
  const { t } = useTranslation(["common", "reports"]);
  const componentRef = useRef<HTMLDivElement | null>(null);
  const { id } = useParams<{ id: string }>();

  const { customerHistoryReportStore, customerStore } = useStore();
  const { loadCustomer, loadingInitial } = customerStore;
  const {
    getCustomerHistory,
    setPredicate,
    selectedCustomer,
    setCustomer,
    resetCustomerHistory,
    setReportCriteria,
    reportCriteria,
  } = customerHistoryReportStore;

  const validationSchema = Yup.object({});

  useEffect(() => {
    resetCustomerHistory();
    if (id) {
      loadCustomer(id).then((customer) => {
        setCustomer(new CustomerFormValues(customer));
      });
    }
  }, [loadCustomer, id, resetCustomerHistory, setCustomer]);
  const [submitCounter, setSubmitCounter] = useState<number>(0);

  function handleFormSubmit(values: ReportInput) {
    setReportCriteria(values);
    setSubmitCounter(submitCounter + 1);
    setPredicate(values.startDate, values.endDate);
  }

  if (loadingInitial)
    return (
      <LoadingComponent
        content={t("customerDetails_loading", { ns: "customer" })}
      />
    );

  return (
    <>
      <PageHeader
        header={`${t("report", { ns: "reports" })}: ${t(
          "features.customerhistory",
          { ns: "subscription" }
        )}`}
        type={"h1"}
        divider={false}
        getAlerts={true}
        addTitle={true}
      />

      <PageHeader
        header={`${t("customer", { ns: "common" })}: ${
          selectedCustomer.isCommercial
            ? selectedCustomer.companyName
            : `${selectedCustomer.lastName}, ${
                selectedCustomer.firstName ? selectedCustomer.firstName : "--"
              }`
        }`}
        type={"h3"}
        divider={true}
        addTitle={false}
      />
      <Container className="page">
        <Formik
          validationSchema={validationSchema}
          initialValues={reportCriteria}
          onSubmit={handleFormSubmit}
        >
          {({ handleSubmit, submitCount, values }) => (
            <Form
              className="ui form error"
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <ReportDateSelector
                cancelButtonLink={`/customer/${selectedCustomer.id}`}
                values={values}
                isUpdate={submitCount > 0}
                hasReportType={false}
                hasResults={getCustomerHistory.length > 0}
              />
            </Form>
          )}
        </Formik>
        {getCustomerHistory && getCustomerHistory.length > 0 && (
          <>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <ReactToPrint
                    trigger={() => (
                      <Button floated="right" icon="print" type="button">
                        {t("estimate.print", { ns: "tickets" })}
                      </Button>
                    )}
                    content={() => componentRef.current}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <div ref={componentRef} className="printMargins">
              <CustomerHistoryPrint
                startDate={reportCriteria.startDate ?? new Date()}
                endDate={reportCriteria.endDate ?? new Date()}
              />
            </div>
          </>
        )}
      </Container>
    </>
  );
});
