import {
  AccessLevel,
  Application,
  ClientStatus,
  CustomerSignatureLine,
  LogoPlacement,
  MiscellaneousChargeStatus,
  PromisedDateOptions,
  RateTypes,
  ReportType,
  SalesTaxOptionType,
  TermEnum,
  TicketType,
} from "../../models/enums";
import i18n from "../i18n/i18n";

export interface Options {
  text: string;
  value: string | number;
}

export const hourlyRateOptions: Options[] = [
  {
    text: i18n.t("ticket_defaults.flatandhourly", { ns: "ticketSettings" }),
    value: RateTypes.Flat | RateTypes.Hourly,
  },
  {
    text: i18n.t("ticket_defaults.flat", { ns: "ticketSettings" }),
    value: RateTypes.Flat,
  },
  {
    text: i18n.t("ticket_defaults.hourly", { ns: "ticketSettings" }),
    value: RateTypes.Hourly,
  },
];

export const promisedDateOptions: Options[] = [
  {
    text: i18n.t("ticket_defaults.blank", { ns: "ticketSettings" }),
    value: PromisedDateOptions.Blank,
  },
  {
    text: i18n.t("ticket_defaults.today", { ns: "ticketSettings" }),
    value: PromisedDateOptions.Today,
  },
  {
    text: i18n.t("ticket_defaults.tomorrow", { ns: "ticketSettings" }),
    value: PromisedDateOptions.Tomorrow,
  },
];

export const yesNoOptions: Options[] = [
  { text: i18n.t("yes", { ns: "common" }), value: "true" },
  { text: i18n.t("no", { ns: "common" }), value: "false" },
];

export const workFlowOptions: Options[] = [
  {
    text: i18n.t("ticket_path.begin", { ns: "workflowSettings" }),
    value: "true",
  },
  {
    text: i18n.t("ticket_path.end", { ns: "workflowSettings" }),
    value: "false",
  },
];

export const defaultTicketOptions: Options[] = [
  {
    text: i18n.t("ticket_path.invoice", { ns: "workflowSettings" }),
    value: TicketType.Invoice.toString(),
  },
  {
    text: i18n.t("ticket_path.estimate", { ns: "workflowSettings" }),
    value: TicketType.Estimate.toString(),
  },
];

export const assignmentOptions: Options[] = [
  { text: i18n.t("tech.labor", { ns: "miscSettings" }), value: "false" },
  { text: i18n.t("tech.laborandparts", { ns: "miscSettings" }), value: "true" },
];

export const taxOptions: Options[] = [
  {
    text: i18n.t("tax.partsandlabor", { ns: "pricingSettings" }),
    value: SalesTaxOptionType.Parts | SalesTaxOptionType.Labor,
  },
  {
    text: i18n.t("tax.partsonly", { ns: "pricingSettings" }),
    value: SalesTaxOptionType.Parts,
  },
  {
    text: i18n.t("tax.laboronly", { ns: "pricingSettings" }),
    value: SalesTaxOptionType.Labor,
  },
  {
    text: i18n.t("tax.partsandlaborfl", { ns: "pricingSettings" }),
    value:
      SalesTaxOptionType.Parts |
      SalesTaxOptionType.Labor |
      SalesTaxOptionType.OnTicket,
  },
];

export const miscChargeStatusOptions: Options[] = [
  {
    text: i18n.t("always", { ns: "miscSettings" }),
    value: MiscellaneousChargeStatus.Always,
  },
  {
    text: i18n.t("alwaysask", { ns: "miscSettings" }),
    value: MiscellaneousChargeStatus.AlwaysAsk,
  },
  {
    text: i18n.t("off", { ns: "common" }),
    value: MiscellaneousChargeStatus.Off,
  },
];

export const quickVinOptions: Options[] = [
  {
    text: i18n.t("quickvin.enable", { ns: "advancedSettings" }),
    value: "true",
  },
  {
    text: i18n.t("quickvin.disable", { ns: "advancedSettings" }),
    value: "false",
  },
];

export const signatureOptions: Options[] = [
  {
    text: i18n.t("formatting.underline", { ns: "ticketSettings" }),
    value: CustomerSignatureLine.Underline,
  },
  {
    text: i18n.t("formatting.box", { ns: "ticketSettings" }),
    value: CustomerSignatureLine.Box,
  },
];
export const logoOptions: Options[] = [
  {
    text: i18n.t("formatting.left", { ns: "ticketSettings" }),
    value: LogoPlacement.Left,
  },
  {
    text: i18n.t("formatting.center", { ns: "ticketSettings" }),
    value: LogoPlacement.Center,
  },
];

export const notesOptions: Options[] = [
  {
    text: i18n.t("formatting.shownoteslabel", { ns: "ticketSettings" }),
    value: "true",
  },
  {
    text: i18n.t("formatting.hidenoteslabel", { ns: "ticketSettings" }),
    value: "false",
  },
];

export const reportTypeOptions: Options[] = [
  { text: i18n.t("detailed", { ns: "reports" }), value: ReportType.Detailed },
  { text: i18n.t("summary", { ns: "reports" }), value: ReportType.Summary },
];
export const yearOptions: Options[] = [
  { text: i18n.t("customs.singleYear", { ns: "workflowSettings" }), value: 1 },
  { text: i18n.t("customs.range", { ns: "workflowSettings" }), value: 2 },
  { text: i18n.t("customs.all", { ns: "workflowSettings" }), value: 3 },
];

export const internalAccessLevelOptions: Options[] = [
  { text: i18n.t("staff", { ns: "abandoned" }), value: AccessLevel.Staff },
  {
    text: i18n.t("staff.manager", { ns: "users" }),
    value: AccessLevel.Manager,
  },
  {
    text: i18n.t("staff.accountOwner", { ns: "users" }),
    value: AccessLevel.Owner,
  }
];


export const accessLevelOptions: Options[] = [
  { text: i18n.t("staff", { ns: "abandoned" }), value: AccessLevel.Staff },
  {
    text: i18n.t("staff.manager", { ns: "users" }),
    value: AccessLevel.Manager,
  },
  {
    text: i18n.t("staff.accountOwner", { ns: "users" }),
    value: AccessLevel.Owner,
  },
];

export const enableOptions: Options[] = [
  {
    text: i18n.t("enabled", { ns: "miscSettings" }),
    value: "true",
  },
  {
    text: i18n.t("disabled", { ns: "miscSettings" }),
    value: "false",
  },
];

export const packageJobOptions: Options[] = [
  {
    text: i18n.t("pops.use_save_values", { ns: "tickets" }),
    value: "true",
  },
  {
    text: i18n.t("pops.match_vehicle", { ns: "tickets" }),
    value: "false",
  },
];

export const serviceTypeOptions = [
  {
    text: i18n.t("service_type.realTimePro", { ns: "common" }),
    value: Application.RealTimeLaborGuidePro,
  },
  {
    text: i18n.t("service_type.realTimeGuide", { ns: "common" }),
    value: Application.RealTimeLaborGuide,
  },
];

export const planIntervalOptions = [
  {
    text: i18n.t("subscription.monthly", { ns: "subscription" }),
    value: TermEnum.Monthly,
  },
  {
    text: i18n.t("subscription.quarterly", { ns: "subscription" }),
    value: TermEnum.Quarterly,
  },
  {
    text: i18n.t("subscription.annual", { ns: "subscription" }),
    value: TermEnum.Annual,
  },
];

//TODO: i18n?
export const clientStatusOptions: Options[] = [
  {
    text: "Terminated-Dnr",
    value: ClientStatus.TerminatedDnr,
  },
  {
    text: "Declined",
    value: ClientStatus.Declined,
  },
  {
    text: "Deactivated",
    value: ClientStatus.Deactivated,
  },

  {
    text: "Sign Up InProgress",
    value: ClientStatus.Active | ClientStatus.SignUpInProgress,
  },
  {
    text: "PreTrial",
    value: ClientStatus.PreTrial,
  },
  // {
  //   text: "Trial",
  //   value: ClientStatus.Trial,
  // },
  // {
  //   text: "Active",
  //   value: ClientStatus.Active,
  // },
  // {
  //   text: "Subscription",
  //   value: ClientStatus.Subscription,
  // },
  // {
  //   text: "SubscriptionExtension",
  //   value: ClientStatus.SubscriptionExtension,
  // },
  {
    text: "Inactive With Expired Trial",
    value: ClientStatus.Expired | ClientStatus.Trial,
  },
  {
    text: "Inactive With Expired Subscription",
    value:
     ClientStatus.Expired | ClientStatus.Subscription,
  },
  {
    text: "Inactive With Expired Subscription Extension",
    value:
      ClientStatus.Expired |
      ClientStatus.SubscriptionExtension,
  },
  {
    text: "Active With Trial",
    value: ClientStatus.Active | ClientStatus.Trial,
  },
  {
    text: "Active With Subscription",
    value: ClientStatus.Active | ClientStatus.Subscription,
  },
  {
    text: "Active With Subscription Extension",
    value: ClientStatus.Active | ClientStatus.SubscriptionExtension,
  },
  {
    text: "Active With Trial And Expired Subscription",
    value:
      ClientStatus.Active |
      ClientStatus.Trial |
      ClientStatus.Expired |
      ClientStatus.Subscription,
  },
  {
    text: "Active With Trial And Expired Subscription Extension",
    value:
      ClientStatus.Active |
      ClientStatus.Trial |
      ClientStatus.Expired |
      ClientStatus.SubscriptionExtension,
  },
];

export const returnPartsOption: Options[] = [
  {
    text: i18n.t("returned", { ns: "tickets" }),
    value: "true",
  },
  {
    text: i18n.t("not_returned", { ns: "tickets" }),
    value: "false",
  },
];

export const areaOption= [{ text: "Login", value: 0 }];

export const colorOptions = [
  { text: "Negative/Error (Red)", value: 0 },
  { text: "Positive/Success (Green)", value: 1 },
  { text: "Info (Blue)", value: 2 },
  { text: "Warning (Yellow)", value: 3}
]
